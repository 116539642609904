.btn-create-extend {
  display: flex;
  float: right;
  margin-bottom: 20px;
}

.title-input-extend {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #19202c;
  margin: 0;
}

.img-thumbnail {
  width: 100px;
  height: 1000px;
}

.div-item-price-area {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding: 5px;
  margin-top: 10px;
}

.div-item-holiday {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding: 5px;
  margin-top: 10px;
}

.btn-create-extend {
  display: flex;
  float: right;
  width: 100px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;

  box-shadow: 2px 3px 2px rgb(216, 216, 216);
  margin-top: 20px;
}
