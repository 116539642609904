.div-chart-pie-month {
  width: 30%;
}

.div-chart-pie-year {
  width: 30%;
}

.div-create-cancel {
  display: flex;
  flex-direction: column;

  .text-create-cancel {
    color: #000000;
    font-weight: 400;
    font-family: "Roboto";
    font-style: normal;
    font-size: 12px;
    margin: 0;
  }
}

.text-user-cancel {
  color: #000000;
  font-weight: 400;
  font-family: "Roboto";
  font-style: normal;
  font-size: 12px;
  margin: 0;
}

.text-address-cancel {
  color: #000000;
  font-weight: 400;
  font-family: "Roboto";
  font-style: normal;
  font-size: 12px;
  display: block;
  width: 200px;
  margin: 0;
}
.div-select-city {
  display: flex;
  flex-direction: row;
  width: 100%;

  .div-select-item {
    display: flex;
    flex-direction: column;

    .div-item-city {
      display: flex;
      flex-direction: column;
      background-color: rgb(255, 255, 255);
      padding: 5px;
      width: 180px;
      border: 1px solid rgb(175, 175, 175);
      border-radius: 5px;
      z-index: 1000;
      margin-left: 10px;

      .div-item-list {
        display: flex;
        align-items: center;
        height: 30px;
        width: 100%;
        border-bottom: 1px solid #d6d6d6;
      }

      .div-item-list-selected {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #48cae4;
        height: 30px;
      }
    }

    .div-select-city-input {
      display: flex;
      align-items: center;
      border-radius: 4px;
      height: 40px;
      border: 1px solid #d6d6d6;
      margin-left: 10px;
      width: 180px;
      padding-left: 10px;
    }
  }
}

.div-chart-pie-total-cancel-customer {
  display: flex;
  flex-direction: row;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;

  .div-total-cancel-order {
    width: 30%;
    border-right: 1px solid #d6d6d6;
    padding: 10px;

    .div-total-customer {
      display: flex;
      flex-direction: row;
      margin-top: 5px;
      .title-total-cancel-customer {
        color: #000000;
        font-weight: 400;
        font-family: "Roboto";
        font-style: normal;
        font-size: 12px;
        width: 100px;
        margin: 0;
      }

      .text-colon {
        color: #000000;
        font-weight: 400;
        font-family: "Roboto";
        font-style: normal;
        font-size: 12px;
        margin: 0;
      }

      .text-number {
        color: #000000;
        font-weight: 400;
        font-family: "Roboto";
        font-style: normal;
        font-size: 12px;
        margin-left: 5px;
        margin: 0;
      }
    }
  }

  .div-pie-chart {
    width: 70%;
  }
}

.text-name {
  color: #000000;
  font-weight: 400;
  font-family: "Roboto";
  font-style: normal;
  font-size: 12px;
  display: block;
}
