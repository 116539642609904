.container-report-order-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap !important;

  .div-item-report {
    display: flex;
    flex-direction: column;
    background-color: rgb(248, 248, 248);
    width: 30%;
    border-radius: 8px;
    padding: 10px;
    margin-top: 20px;
    margin-right: 20px;

    .text-title {
      color: #000000;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      text-decoration: none;
      margin: 0;
    }

    .text-details {
      color: #737373;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-decoration: none;
      margin: 0;
    }

    .text-see-report {
      color: rgb(36, 36, 251);
      font-size: 12px;
      font-family: "Roboto";
      font-weight: 400;
      text-align: right;
      margin: 10px 0 0 0;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 900px) {
  .container-report-order-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .div-item-report {
      display: flex;
      flex-direction: column;
      background-color: rgb(248, 248, 248);
      width: 45%;
      border-radius: 8px;
      padding: 10px;
      margin-top: 20px;
      margin-right: 20px;

      .text-title {
        color: #000000;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-decoration: none;
        margin: 0;
      }

      .text-details {
        color: #737373;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-decoration: none;
        margin: 0;
      }

      .text-see-report {
        color: rgb(36, 36, 251);
        font-size: 12px;
        font-family: "Roboto";
        font-weight: 400;
        text-align: right;
        margin: 10px 0 0 0;
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 490px) {
  .container-report-order-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .div-item-report {
      display: flex;
      flex-direction: column;
      background-color: rgb(248, 248, 248);
      width: 100%;
      border-radius: 8px;
      padding: 10px;
      margin-top: 20px;
      margin-right: 20px;

      .text-title {
        color: #000000;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-decoration: none;
        margin: 0;
      }

      .text-details {
        color: #737373;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-decoration: none;
        margin: 0;
      }

      .text-see-report {
        color: rgb(36, 36, 251);
        font-size: 12px;
        font-family: "Roboto";
        font-weight: 400;
        text-align: right;
        margin: 10px 0 0 0;
        text-decoration: none;
      }
    }
  }
}
