.div-view-edit-hide {
  width: 148vh;
  height: 220vh;
  position: absolute;
  z-index: 1000;
}

.div-container-create {
  display: flex;
  flex-direction: row;

  .div-body {
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 60%;
    padding: 0px 30px;

    .div-input {
      width: 80%;
      height: auto;
      background-color: white;
      border-radius: 8px;
      padding: 10px;
      margin-top: 10px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));

      .div-block {
        background-color: none;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1000;
        padding: 0;
        margin: 0;
      }
      .div-list-input-title {
        display: flex;
        flex-direction: column;
        width: 100%;

        .div-item-list-input {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;

          .input-language {
            width: 500px;
          }
        }
      }

      .icon-question {
        color: #29fff1;
        margin-left: 10px;
      }

      .div-head-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        .title-input {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #212121;
          text-decoration: none;
          margin: 0;
        }

        .icon-question {
          color: #29fff1;
          margin-left: 10px;
        }
      }
      .div-parrent-promo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;

        .div-code-promo {
          display: flex;
          flex-direction: column;
          width: 55%;

          .label-promo {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #212121;
            text-decoration: none;
            margin: 0;
          }
        }

        .div-child-promo {
          display: flex;
          flex-direction: column;
          width: 40%;
        }
      }

      .title-input {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #212121;
        text-decoration: none;
        margin: 0;
      }
      .list-item-kh {
        background-color: rgb(255, 255, 255);
        padding: 5px;
        position: relative;
        width: auto;
        border: 1px solid rgb(175, 175, 175);
        border-radius: 5px;
        z-index: 1000;

        .div-item {
          border-bottom: 1px solid #d6d6d6;

          .text-name {
            font-family: "Roboto";
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
            text-decoration: none;
          }
        }
      }
      .div-list-customer {
        border: 1px solid #d6d6d6;
        border-radius: 4px;
        margin-top: 5px;
        padding: 5px;

        .div-item-customer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-bottom: 1px solid #d6d6d6;
          padding: 5px;

          .text-name-list {
            font-family: "Roboto";
            font-weight: 500;
            font-size: 14px;
            color: #000000;
            text-decoration: none;
            margin: 5px 0 0 0;
          }

          .icon-delete {
            color: #2962ff;
          }
        }
      }
      .div-reduced-order {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;

        .div-body-reduced {
          display: flex;
          flex-direction: column;
          width: 49%;
        }
      }
      .div-minimum-order {
        display: flex;
        flex-direction: column;

        margin: 10px 20px 0px;
        .input-price-minimum {
          width: 50%;
        }
        .text-note {
          font-family: "Roboto";
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
          color: #808080;
          text-decoration: "none";
          margin: 5px 0px 10px 0;
        }
      }
      .text-note {
        font-family: "Roboto";
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        color: #808080;
        text-decoration: "none";
        margin: 5px 0px 10px 0;
      }

      .div-column-limit {
        display: flex;
        flex-direction: column;
        margin-top: 8px;

        .input-price {
          width: 50%;
          margin: 5px 20px;
        }
      }

      .div-time-select {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;

        .div-time {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
      }

      .input-price {
        width: 50%;
        margin: 10px 20px;
      }

      .div-voucher {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        margin-left: 20px;
      }

      .div-list-time-apply {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        width: 100%;

        .div-item-time-apply {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          border-bottom: 1px solid #eeeeee;
          padding: 10px 0px;

          .div-item-right {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 80%;
            .checkbox-date {
              width: 26%;
              font-size: 12px;
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
            }
            .text-all {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 300;
              font-size: 12px;
              color: #202223;
              text-decoration: none;
              margin: 0;
            }
            .text-all-italic {
              font-family: "Roboto";
              font-style: italic;
              font-weight: 300;
              font-size: 12px;
              color: #d8d8d8;
              text-decoration: none;
              margin: 0;
            }

            .div-list-time {
              display: flex;
              flex-direction: column;

              .div-item-time {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 5px;

                .time-picker {
                  border: none;
                  border: 1px solid #d8d8d8;
                  border-radius: 4px;
                }

                .div-select-time {
                  display: flex;
                  flex-direction: column;

                  .div-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .minus {
                      font-family: "Roboto";
                      font-style: normal;
                      font-weight: 500;
                      font-size: 14px;
                      color: #202223;
                      text-decoration: none;
                      margin: 0px 5px;
                    }
                    .icon-remove {
                      color: #202223;
                      margin-left: 10px;
                    }
                  }
                  .text-from-time {
                    font-family: "Roboto";
                    font-style: italic;
                    font-weight: 200;
                    font-size: 12px;
                    color: #b4b4b4;
                    text-decoration: none;
                    margin: 5px 0 0 0;
                  }
                }
              }
            }
          }

          .icon-add-time {
            display: flex;
            color: #2962ff;
          }
          .choose-time {
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            height: 20px;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 200;
            font-size: 12px;
            color: #2962ff;
            text-decoration: none;
          }
        }
      }

      .div-exchange {
        display: flex;
        flex-direction: column;
        margin-left: 20px;

        .label-exchange {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #212121;
          text-decoration: none;
          margin: 10px 0 0 0;
        }
      }
    }

    .div-background-thumnail {
      width: 80%;
      height: auto;
      background-color: white;
      border-radius: 8px;
      padding: 10px;
      margin-top: 10px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));

      .div-block {
        background-color: none;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1000;
        padding: 0;
        margin: 0;
      }

      .title-input {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #000000;
        text-decoration: none;
        margin: 0;
      }

      .img-thumbnail-promotion {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        border-radius: 8px;
        object-fit: contain;
      }

      .upload-thumbnail-promotion {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        border-radius: 8px;
        object-fit: contain;
        margin-left: 10px;
        border: 0.5px solid #9b9b9b;
      }

      .img-background-promotion {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 400px;
        height: 100px;
        border-radius: 8px;
      }
      .upload-background-promotion {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 400px;
        height: 100px;
        border-radius: 8px;
        margin-left: 10px;
        border: 0.5px solid #9b9b9b;
        margin-top: 10px;
      }

      .div-row {
        display: flex;
        flex-direction: row;
      }
    }
  }

  .div-detail {
    width: 29%;

    .div-input {
      width: 100%;
      background-color: rgb(248, 248, 248);
      border-radius: 8px;
      padding: 10px;
      margin-top: 10px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
      .div-block {
        background-color: none;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1000;
        padding: 0;
        margin: 0;
      }

      .title-input {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 450;
        font-size: 14px;
        color: #212121;
        text-decoration: none;
        margin: 0;
      }

      .div-show-in-app {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
        .switch {
          width: 20px;
        }
        .switch-select {
          width: 20px;
          background-color: #00cf3a;
        }

        .label-display {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #212121;
          text-decoration: none;
          margin: 0 0 0 10px;
        }
      }

      .div-push-noti {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        width: 100%;
        .title-input {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #212121;
          text-decoration: none;
          margin: 0;
        }

        .switch {
          margin-right: 10px;
          width: 20px;
        }
        .switch-select {
          margin-right: 10px;
          width: 20px;
          background-color: #00cf3a;
        }

        .div-body-push {
          display: flex;
          flex-direction: column;
          margin: 5px 30px;
        }
      }

      .div-exchange-point {
        display: flex;
        flex-direction: column;
        width: 100%;

        .div-redem-point {
          display: flex;
          margin-top: 10px;
          flex-direction: row;

          .title {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #212121;
            text-decoration: none;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.div-content-title {
  display: flex;
  flex-direction: column;
  padding: 10px;

  .img-short {
    width: 400px;
    height: 100px;
    margin-top: 10px;
  }

  .img-title {
    width: 250px;
    height: 220px;
    margin-top: 10px;
  }

  .img-description {
    width: 200px;
    height: 420px;
    margin-top: 10px;
  }
}

.div-head-add-promotion {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  background-color: #fff;
  width: auto;
  height: 50px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  margin-bottom: 10px;

  .btn-stop-activation {
    border: 1px solid #fb422e;
    background: none;
    color: #fb422e;
    border-radius: 4px;
  }

  .btn-activation {
    border: 1px solid #2962ff;
    background: none;
    color: #2962ff;
    border-radius: 4px;
  }
}

@media (max-width: 1200px) {
  .div-container-create {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    width: 100%;

    .div-body {
      display: flex;
      flex-direction: column;
      align-items: end;
      width: 60%;
      padding: 0px 30px;

      .div-input {
        width: 95%;
        height: auto;
        background-color: white;
        border-radius: 8px;
        padding: 10px;
        margin-top: 10px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));

        .icon-question {
          color: #29fff1;
          margin-left: 10px;
        }

        .div-head-title {
          display: flex;
          flex-direction: row;
          align-items: center;
          .title-input {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #212121;
            text-decoration: none;
            margin: 0;
          }

          .icon-question {
            color: #29fff1;
            margin-left: 10px;
          }
        }
        .div-parrent-promo {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          margin-top: 10px;

          .div-code-promo {
            display: flex;
            flex-direction: column;
            width: 55%;

            .label-promo {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              color: #212121;
              text-decoration: none;
              margin: 0;
            }
          }

          .div-child-promo {
            display: flex;
            flex-direction: column;
            width: 40%;
          }
        }

        .title-input {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #212121;
          text-decoration: none;
          margin: 0;
        }
        .list-item-kh {
          background-color: rgb(255, 255, 255);
          padding: 5px;
          position: relative;
          width: auto;
          border: 1px solid rgb(175, 175, 175);
          border-radius: 5px;
          z-index: 1000;

          .div-item {
            border-bottom: 1px solid #d6d6d6;

            .text-name {
              font-family: "Roboto";
              font-weight: 500;
              font-size: 14px;
              margin: 0;
              color: #000000;
              text-decoration: none;
            }
          }
        }
        .div-list-customer {
          border: 1px solid #d6d6d6;
          border-radius: 4px;
          margin-top: 5px;
          padding: 5px;

          .div-item-customer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 1px solid #d6d6d6;
            padding: 5px;

            .text-name-list {
              font-family: "Roboto";
              font-weight: 500;
              font-size: 14px;
              color: #000000;
              text-decoration: none;
              margin: 5px 0 0 0;
            }

            .icon-delete {
              color: #2962ff;
            }
          }
        }
        .div-reduced-order {
          display: flex;
          flex-direction: row;
          width: 100%;
          margin-top: 10px;

          .div-body-reduced {
            display: flex;
            flex-direction: column;
            width: 50%;
          }
        }
        .div-minimum-order {
          display: flex;
          flex-direction: column;
          margin: 10px 20px 0px;

          .input-price-minimum {
            width: 50%;
          }
          .text-note {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 400;
            font-size: 14px;
            color: #808080;
            text-decoration: "none";
            margin: 5px 0px 10px 0;
          }
        }
        .text-note {
          font-family: "Roboto";
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
          color: #808080;
          text-decoration: "none";
          margin: 5px 0px 10px 0;
        }

        .div-column-limit {
          display: flex;
          flex-direction: column;
          margin-top: 8px;

          .input-price {
            width: 50%;
            margin: 5px 20px;
          }
        }

        .div-time-select {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 10px;

          .div-time {
            display: flex;
            flex-direction: column;
            width: 100%;
          }
        }

        .input-price {
          width: 50%;
          margin: 10px 20px;
        }

        .div-voucher {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          margin-left: 20px;
        }

        .div-list-time-apply {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          width: 100%;

          .div-item-time-apply {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            border-bottom: 1px solid #eeeeee;
            padding: 10px 0px;

            .div-item-right {
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 80%;
              .checkbox-date {
                width: 26%;
                font-size: 12px;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
              }
              .text-all {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                color: #202223;
                text-decoration: none;
                margin: 0;
              }
              .text-all-italic {
                font-family: "Roboto";
                font-style: italic;
                font-weight: 300;
                font-size: 12px;
                color: #d8d8d8;
                text-decoration: none;
                margin: 0;
              }

              .div-list-time {
                display: flex;
                flex-direction: column;

                .div-item-time {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  margin-top: 5px;

                  .time-picker {
                    border: none;
                    border: 1px solid #d8d8d8;
                    border-radius: 4px;
                  }
                  .minus {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    color: #202223;
                    text-decoration: none;
                    margin: 0px 5px;
                  }
                  .icon-remove {
                    color: #202223;
                    margin-left: 10px;
                  }
                }
              }
            }

            .icon-add-time {
              display: flex;
              color: #2962ff;
            }
            .choose-time {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 200;
              font-size: 12px;
              color: #2962ff;
              text-decoration: none;
            }
          }
        }
      }

      .div-background-thumnail {
        width: 80%;
        height: auto;
        background-color: white;
        border-radius: 8px;
        padding: 10px;
        margin-top: 10px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
        .title-input {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: #000000;
          margin: 0;
          text-decoration: none;
        }

        .div-row {
          display: flex;
          flex-direction: row;
        }
      }
    }

    .div-detail {
      width: 39%;

      .div-input {
        width: 100%;
        background-color: rgb(248, 248, 248);
        border-radius: 8px;
        padding: 10px;
        margin-top: 10px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));

        .title-input {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 450;
          font-size: 14px;
          color: #212121;
          text-decoration: none;
          margin: 0;
        }

        .div-show-in-app {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 10px;
          .switch {
            width: 20px;
          }
          .switch-select {
            width: 20px;
            background-color: #00cf3a;
          }

          .label-display {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #212121;
            text-decoration: none;
            margin: 0 0 0 10px;
          }
        }

        .div-push-noti {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          width: 100%;
          .title-input {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #212121;
            text-decoration: none;
            margin: 0;
          }

          .switch {
            margin-right: 10px;
            width: 20px;
          }
          .switch-select {
            margin-right: 10px;
            width: 20px;
            background-color: #00cf3a;
          }

          .div-body-push {
            display: flex;
            flex-direction: column;
            margin: 5px 30px;
          }
        }

        .div-exchange-point {
          display: flex;
          flex-direction: column;
          width: 100%;

          .div-redem-point {
            display: flex;
            margin-top: 10px;
            flex-direction: row;

            .title {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: #212121;
              text-decoration: none;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .div-content-title {
    display: flex;
    flex-direction: column;
    padding: 10px;

    .img-short {
      width: 400px;
      height: 100px;
      margin-top: 10px;
    }

    .img-title {
      width: 250px;
      height: 220px;
      margin-top: 10px;
    }

    .img-description {
      width: 200px;
      height: 420px;
      margin-top: 10px;
    }
  }
}

@media (max-width: 900px) {
  .div-container-create {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    width: 100%;

    .div-body {
      display: flex;
      flex-direction: column;
      align-items: end;
      width: 100%;

      .div-input {
        width: 100%;
        height: auto;
        background-color: white;
        border-radius: 8px;
        padding: 10px;
        margin-top: 10px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));

        .icon-question {
          color: #29fff1;
          margin-left: 10px;
        }

        .div-head-title {
          display: flex;
          flex-direction: row;
          align-items: center;
          .title-input {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #212121;
            text-decoration: none;
            margin: 0;
          }

          .icon-question {
            color: #29fff1;
            margin-left: 10px;
          }
        }
        .div-parrent-promo {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          margin-top: 10px;

          .div-code-promo {
            display: flex;
            flex-direction: column;
            width: 55%;

            .label-promo {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              color: #212121;
              text-decoration: none;
              margin: 0;
            }
          }

          .div-child-promo {
            display: flex;
            flex-direction: column;
            width: 40%;
          }
        }

        .title-input {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #212121;
          margin: 0;
          text-decoration: none;
        }
        .list-item-kh {
          background-color: rgb(255, 255, 255);
          padding: 5px;
          position: relative;
          width: auto;
          border: 1px solid rgb(175, 175, 175);
          border-radius: 5px;
          z-index: 1000;

          .div-item {
            border-bottom: 1px solid #d6d6d6;

            .text-name {
              font-family: "Roboto";
              font-weight: 500;
              font-size: 14px;
              margin: 0;
              color: #000000;
              text-decoration: none;
            }
          }
        }
        .div-list-customer {
          border: 1px solid #d6d6d6;
          border-radius: 4px;
          margin-top: 5px;
          padding: 5px;

          .div-item-customer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 1px solid #d6d6d6;
            padding: 5px;

            .text-name-list {
              font-family: "Roboto";
              font-weight: 500;
              font-size: 14px;
              color: #000000;
              text-decoration: none;
              margin: 5px 0 0 0;
            }

            .icon-delete {
              color: #2962ff;
            }
          }
        }
        .div-reduced-order {
          display: flex;
          flex-direction: row;
          width: 100%;
          margin-top: 10px;

          .div-body-reduced {
            display: flex;
            flex-direction: column;
            width: 50%;
          }
        }
        .div-minimum-order {
          display: flex;
          flex-direction: column;
          margin: 10px 20px 0px;

          .input-price-minimum {
            width: 50%;
          }
          .text-note {
            font-family: "Roboto";
            font-style: italic;
            font-weight: 400;
            font-size: 14px;
            color: #808080;
            text-decoration: "none";
            margin: 5px 0px 10px 0;
          }
        }
        .text-note {
          font-family: "Roboto";
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
          color: #808080;
          text-decoration: "none";
          margin: 5px 0px 10px 0;
        }

        .div-column-limit {
          display: flex;
          flex-direction: column;
          margin-top: 8px;

          .input-price {
            width: 50%;
            margin: 5px 20px;
          }
        }

        .div-time-select {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 10px;

          .div-time {
            display: flex;
            flex-direction: column;
            width: 100%;
          }
        }

        .input-price {
          width: 50%;
          margin: 10px 20px;
        }

        .div-voucher {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          margin-left: 20px;
        }

        .div-list-time-apply {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          width: 100%;

          .div-item-time-apply {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            border-bottom: 1px solid #eeeeee;
            padding: 10px 0px;

            .div-item-right {
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 80%;
              .checkbox-date {
                width: 26%;
                font-size: 12px;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
              }
              .text-all {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                color: #202223;
                text-decoration: none;
                margin: 0;
              }
              .text-all-italic {
                font-family: "Roboto";
                font-style: italic;
                font-weight: 300;
                font-size: 12px;
                color: #d8d8d8;
                text-decoration: none;
                margin: 0;
              }

              .div-list-time {
                display: flex;
                flex-direction: column;

                .div-item-time {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  margin-top: 5px;

                  .time-picker {
                    border: none;
                    border: 1px solid #d8d8d8;
                    border-radius: 4px;
                  }
                  .minus {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    color: #202223;
                    text-decoration: none;
                    margin: 0px 5px;
                  }
                  .icon-remove {
                    color: #202223;
                    margin-left: 10px;
                  }
                }
              }
            }

            .icon-add-time {
              display: flex;
              color: #2962ff;
            }
            .choose-time {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 200;
              font-size: 12px;
              color: #2962ff;
              text-decoration: none;
            }
          }
        }
      }

      .div-background-thumnail {
        width: 100%;
        height: auto;
        background-color: white;
        border-radius: 8px;
        padding: 10px;
        margin-top: 10px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
        .title-input {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: #000000;
          text-decoration: none;
          margin: 0;
        }

        .div-row {
          display: flex;
          flex-direction: row;
        }
      }
    }

    .div-detail {
      width: 100%;
      padding: 20px;
      .div-input {
        width: 100%;
        background-color: rgb(248, 248, 248);
        border-radius: 8px;
        padding: 10px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));

        .title-input {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 450;
          font-size: 14px;
          color: #212121;
          text-decoration: none;
          margin: 0;
        }

        .div-show-in-app {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 10px;
          .switch {
            width: 20px;
          }
          .switch-select {
            width: 20px;
            background-color: #00cf3a;
          }

          .label-display {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #212121;
            text-decoration: none;
            margin: 0 0 0 10px;
          }
        }

        .div-push-noti {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          width: 100%;
          .title-input {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #212121;
            text-decoration: none;
            margin: 0;
          }

          .switch {
            margin-right: 10px;
            width: 20px;
          }
          .switch-select {
            margin-right: 10px;
            width: 20px;
            background-color: #00cf3a;
          }

          .div-body-push {
            display: flex;
            flex-direction: column;
            margin: 5px 30px;
          }
        }

        .div-exchange-point {
          display: flex;
          flex-direction: column;
          width: 100%;

          .div-redem-point {
            display: flex;
            margin-top: 10px;
            flex-direction: row;

            .title {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: #212121;
              text-decoration: none;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .div-content-title {
    display: flex;
    flex-direction: column;
    padding: 10px;

    .img-short {
      width: 400px;
      height: 100px;
      margin-top: 10px;
    }

    .img-title {
      width: 250px;
      height: 220px;
      margin-top: 10px;
    }

    .img-description {
      width: 200px;
      height: 420px;
      margin-top: 10px;
    }
  }
}
