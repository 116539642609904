@import '../../../../index.scss';

.block-content-100 {
    background-color: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    border-radius: 8px;
    padding: 10px;
    margin: 0px 20px 10px 0px;
    width: 100%;
    .total-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;
      width: 60%;
      height: 30px;
      border: 1px solid #d6d6d6;
      border-radius: 4px;
      margin: 5px 0;
      padding: 0px 10px;
      .text-value {
        p {
          font-weight: 500;
        }
      }
      .text-percent {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0px 0px 0px 15px;
        color: #16a249;
        span {
          color: #787878;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
        }
      }
      
    }
    .header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      .text-header {
        p {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
        }

      }
    }
}

.card {
  min-width: 23%;
  height: 63px;
  border-radius: 15px;
  border: none;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  filter: drop-shadow(0px 4px 4px rgba(8, 7, 7, 0.189));
  margin-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  img {
    width: 35px;
    height: 32px;
  }
  .div-details {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 900px) {
  .card {
    width: 100%;
  }
}