.title-business {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.div-head {
  float: right;
}
