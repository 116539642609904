@import "../../@theme/style.scss";

.history-activity {
    display: flex;
    flex-direction: column;
    gap: $gapL;

    .history-activity__item {
        display: flex;

        .history-activity__item--left {
            width: 15%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 1px;

            .history-activity__item--left-time {
                font-size: $textFontSizeM;
                font-weight: $fontWeightXL;
            }

            .history-activity__item--left-date {
                font-size: $textFontSizeM;
                font-weight: $fontWeightL;
                color: $colorGray;
                opacity: $opacityM;
                text-transform: capitalize;
            }

            .history-activity__item--left-admin {
                font-size: $textFontSizeM;
                font-weight: $fontWeightL;
                color: $colorBlue;
            }
        }

        .history-activity__item--middle {
            width: 10%;
            align-items: center;
            display: flex;
            flex-direction: column;
            margin-bottom: -18px;

            .history-activity__item--middle-icon {
                padding: 8px 8px;
                width: fit-content;
                height: fit-content;
                border-radius: $borderRadiusFull;

                &.up {
                    background-color: $colorExtraLightGreen;
                }

                &.down {
                    background-color: $colorExtraLightRed;
                }

                &.setting {
                    background-color: $colorExtraLightBlue;
                }
            }

            .history-activity__item--middle-line {
                width: 2px;
                height: 100%;
                background-color: $colorExtraLightGray;
            }
        }

        .history-activity__item--right {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 75%;

            .history-activity__item--right-top {
                display: flex;
                justify-content: space-between;
                border: 2px solid $colorExtraLightGray;
                padding: 4px 12px;
                border-radius: $borderRadiusL;
                margin-left: 8px;
                width: 100%;
                min-height: 55px;

                .history-activity__item--right-top-title {
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightXL;
                }

                .history-activity__item--right-top-money {
                    display: flex;
                    align-items: center;
                    gap: $gapXS;

                    .history-activity__item--right-top-money-title {
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightL;
                        color: $colorGray;
                        opacity: $opacityM;
                    }
                }

                .history-activity__item--right-top-transiction {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: end;

                    // padding-top: 4px;
                    .history-activity__item--right-top-transiction-number {
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightXL;
                        padding: 4px 8px;
                        width: fit-content;
                        border-radius: $borderRadiusL;
                        white-space: nowrap;

                        &.up {
                            color: $colorGreen;
                            background-color: $colorExtraLightGreen;
                        }

                        &.down {
                            color: $colorRed;
                            background-color: $colorExtraLightRed;
                        }
                    }

                    .history-activity__item--right-top-transiction-detail {
                        display: flex;
                        align-items: center;
                        gap: $gapXS;

                        .history-activity__item--right-top-transiction-detail-label {
                            display: flex;
                            align-items: center;
                            gap: $gapXS;
                            transition: all 0.3s ease;
                            text-decoration: none;
                            cursor: pointer;

                            &:hover {
                                color: $colorBlue
                            }
                        }

                    }
                }
            }

            .history-activity__item--right-bottom {
                display: grid;
                grid-template-columns: repeat(4, minmax(0, 1fr));
                justify-content: space-between;
                // align-items: center;
                border: 2px solid $colorExtraLightGray;
                border-top: none;
                width: 95%;
                padding: 4px 12px;
                // border-radius-t: $borderRadiusL;
                border-bottom-left-radius: $borderRadiusL;
                border-bottom-right-radius: $borderRadiusL;
                margin-left: 8px;
                // min-height: 50px;
                height: 40px;
                transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;
                overflow: hidden;
                /* Ensures content is hidden when height is 0 */
                opacity: 1;
                /* Default opacity */

                .history-activity__item--right-bottom-item {
                    display: flex;
                    align-items: center;

                    .history-activity__item--right-bottom-item-label {
                        margin-right: 4px;
                    }

                    .history-activity__item--right-bottom-item-link {
                        color: $colorBlue;
                    }
                }

                &.hidden {
                    height: 0;
                    opacity: 0;
                    padding: 0 12px;
                }
            }


        }
    }

    .history-activity__empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .history-activity__empty--image {
            height: 100px;
            width: 100px;
        }

        .history-activity__empty--label {
            font-size: $textFontSizeM;
            font-weight: $fontWeightL;
            color: $colorGray;
            opacity: $opacityM;
            font-style: italic;
        }
    }
}