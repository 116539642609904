.div-container-report-customer {
  .text-title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }

  .header-report-customer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;

    .div-tab-header {
      width: 30%;
      height: 65px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
      background-color: white;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-left: 30px;
      padding-right: 30px;

      .div-img {
        width: 48px;
        height: 48px;

        .img {
          width: 48px;
          height: 48px;
          position: relative;
        }
        .text-month {
          position: absolute;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          color: #000000;
          left: 40px;
          top: 30px;
          margin: 0;
        }

        .text-day {
          position: absolute;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          color: #000000;
          left: 48px;
          top: 30px;
        }
      }

      .div-text-tab {
        display: flex;
        flex-direction: column;

        .text-tab-header {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          text-align: center;
          color: #11263c;
          margin: 0;
        }
      }
    }
  }

  .div-chart-user {
    width: 100%;
    min-height: 419px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    margin-top: 50px;
    margin-bottom: 30px;

    .div-time-area {
      display: flex;
      flex-direction: row;

      .text-time {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        color: #11263c;
        margin: 0 10px 0 0;
      }

      .text-area {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        color: #11263c;
        margin-left: 30px;
        margin-right: 10px;
      }
    }
    .divl-total {
      display: flex;
      flex-direction: row;
      .text-total-user {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #11263c;
        margin: 0 10px 0 0;
      }

      .div-total {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 30px;
        border: 1px solid #bbbbbb;
        border-radius: 6px;
      }

      .text-number-total {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #11263c;
        margin: 0 10px 0 0;
        text-decoration: none;
      }
    }
  }

  .div-table {
    width: 100%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
    background-color: white;
    border-radius: 15px;
    padding: 20px;

    .div-header-table {
      display: flex;
      flex-direction: row;

      .div-date {
        display: flex;
        flex-direction: row;
        width: 40%;
        align-items: center;

        .text-date {
          color: #000000;
          font-family: "Roboto";
          font-style: normal;
          font-size: 12px;
          margin-left: 10px;
        }
      }
      .text-time {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        color: #11263c;
        margin: 0 10px 0 0;
      }
    }
  }
}

.btn-details {
  width: 75px;
  height: 21px;
  background-color: #0075ff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  .text-details {
    color: #fff;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
  }
}

.div-content-tool-chart {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 5px;
  border-radius: 5px;

  .date-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: black;
    margin: 0;
  }
  .money-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: black !important;
    margin: 0;
  }

  .money-text-new {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4376cc;
    margin: 0;
  }

  .money-text-old {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #82ca9d;
    margin: 0;
  }
}

@media screen and (max-width: 490px) {
  .header-report-customer {
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    margin-top: 20px;

    .div-tab-header {
      width: 100% !important;
      height: 65px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
      background-color: white;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-left: 30px;
      padding-right: 30px;
      margin-top: 10px;

      .div-img {
        width: 48px;
        height: 48px;

        .img {
          width: 48px;
          height: 48px;
          position: relative;
        }
        .text-month {
          position: absolute;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          color: #000000;
          left: 40px;
          top: 30px;
          margin: 0;
        }

        .text-day {
          position: absolute;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          color: #000000;
          left: 48px;
          top: 30px;
        }
      }

      .div-text-tab {
        display: flex;
        flex-direction: column;

        .text-tab-header {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          text-align: center;
          color: #11263c;
          margin: 0;
        }
      }
    }
  }
}
