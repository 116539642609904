.img-thumbnail {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}

.select-code-phone {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  margin-left: 20px;
  border-color: rgb(216, 216, 216);
}

.add {
  display: flex;
  .withdraw {
    margin-left: 20px;
  }
}

.btn-verify-topup-customer {
  border: none;
  font-family: "Roboto";
  font-size: small;
  border-radius: 5px;
  background-color: aquamarine;
  width: auto;
}

.div-header-customer-topup {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;

  .input-search-topup {
    height: 40px;
    margin-left: 10%;
    width: 100vh;
  }
}

.text-id-topup-customer {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  text-decoration: none;
  margin: 0;
}

.div-name-topup {
  display: flex;
  flex-direction: column;

  .text-name {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }

  .text-phone {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000 !important;
    text-decoration: none;
    margin: 0;
  }
}

.text-cancel-topup {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000000 !important;
  text-decoration: none;
  margin: 0;
}

.text-cancel-topup-hide {
  display: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000000 !important;
  text-decoration: none;
}

.text-pending-topup-customer {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #ecd503;
  text-decoration: none;
  margin: 0;
}

.text-done-topup-customer {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #00cf3a;
  text-decoration: none;
  margin: 0;
}

.text-cancel-topup-customer {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #ff0000 !important;
  text-decoration: none;
  margin: 0;
}

.text-transfered-topup-customer {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #19129a;
  text-decoration: none;
  margin: 0;
}

.div-money-withdraw-topup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .icon-topup {
    color: #00cf3a !important;
    margin-bottom: 5px;
  }
  .icon-withdraw {
    color: #ff0000 !important;
    margin-bottom: 5px;
  }
  .text-topup {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #00cf3a !important;
    text-decoration: none;
    margin: 0;
  }

  .text-withdraw {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #ff0000 !important;
    text-decoration: none;
    margin: 0;
  }
}

.div-money-customer {
  display: flex;
  flex-direction: column;

  .text-money-customer-topup {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }
}
.text-money-customer-topup {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  text-decoration: none;
  margin: 0;
}
.text-description-topup {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  text-decoration: none;
  display: block;
  width: 200px;
  margin: 0;
}
