.select-type {
  margin-left: 20px;
  width: 500px;
  height: 40px;
  border-radius: 5px;
  border-color: rgb(193, 193, 193);
}
.div-header-new {
  display: flex;
  flex-direction: row;

  .field-search {
    width: 80%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid black;
    margin-left: 15px;
    align-items: center;
  }
}

.text-title-new {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #000000;
  text-decoration: none;
  margin: 0;
}

.text-description-new {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #19202c;
  text-decoration: none;
  margin: 0;
}

.text-link-new {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #19202c;
  text-decoration: none;
  margin: 0;
}

.text-new {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #19202c;
  text-decoration: none;
  margin: 0;
}
.text-item-dropdown {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #19202c;
  text-decoration: none;
  margin: 0;
}
