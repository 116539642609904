.btn-add-group-promo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 4px;
  width: auto;
  height: 32px;
  background-color: aqua;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));

  .text-btn {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }
}

.form-input {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .label-input {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }
}

.img-thumbnail-group-promo {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}

.div-item-input {
  display: flex;
  flex-direction: row;
  align-items: center;

  .input-lang {
    width: 320px;
  }
}
