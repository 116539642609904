.div-date-create-reward {
  display: flex;
  flex-direction: column;

  .text-date {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: rgb(0, 0, 0);
    margin: 0;
  }
}

.text-reward {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: black;
  margin: 0;
}

.text-upcoming-reward {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #2c9349;
  margin: 0;
}

.text-cancel-reward {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ff0000;
  margin: 0;
}
