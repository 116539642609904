@import "../../../@theme/style.scss";

.manange-promotion {
    display: flex;
    flex-direction: column;
    gap: $gapL;
    padding-bottom: 16px;

    .manange-promotion__header {
        span {
            font-weight: $fontWeightXL;
            font-size: $textFontSize3XL;
            color: $colorBlack;
        }
    }

    .manange-promotion__filter-content {
        display: flex;
        gap: $gapM;
    }

    .manange-promotion__filter-searching {
        width: 350px;
    }

    .manange-promotion__filter-searching-left {
        display: flex;
        gap: $gapM;
    }
}