ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}
img {
  max-width: 100%;
  height: auto;
}
a {
  text-decoration: none;
  color: inherit;
}
h6 {
  font-size: 12;
  margin-bottom: 0.3rem;
}
