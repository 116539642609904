.tab-block {
  display: flex;
  flex-direction: row;

  .div-tab-item {
    padding: 5px;
    margin-right: 20px;

    .text-tab {
      font-size: 14px;
      font-family: "Roboto";
      font-weight: 500;
      font-style: normal;
      color: rgb(188, 188, 188);
      text-decoration: none;
      margin: 0;
    }
  }

  .div-tab-item-select {
    padding: 5px;
    border-bottom: 2px solid blue;
    margin-right: 20px;

    .text-tab {
      font-size: 14px;
      font-family: "Roboto";
      font-weight: 500;
      font-style: normal;
      color: black;
      text-decoration: none;
      margin: 0;
    }
  }
}

.div-item-list-ctv {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 50%;
  border-radius: 4px;
  padding: 5px;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3));

  .div-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    .image-ctv {
      width: 50px;
      height: 50px;
      border-radius: 8px;
    }

    .div-name-item {
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      .text-name {
        font-size: 14px;
        font-family: "Roboto";
        font-weight: 400;
        font-style: normal;
        color: black;
        margin: 0;
        text-decoration: none;
      }

      .icon-star {
        color: rgb(255, 196, 59);
      }
    }
  }

  .div-cancel {
    display: flex;
    border-left: 1px solid black;
    align-items: end;
    padding: 0px 10px;
    height: 100%;

    .text-cancel {
      font-size: 14px;
      font-family: "Roboto";
      font-weight: 500;
      font-style: normal;
      color: black;
      text-decoration: none;
      margin: 0;
    }
  }
}

.text-no-data {
  display: flex;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  color: black;
  text-decoration: none;
  justify-content: center;
  margin-top: 50px;
  margin: 0;
}

.list-item-collaborator {
  background-color: rgb(255, 255, 255);
  padding: 5px;
  position: absolute;
  width: 50%;
  border: 1px solid rgb(175, 175, 175);
  border-radius: 5px;
  z-index: 1000;

  .item_list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(202, 202, 202);
    margin-bottom: 5px;

    .text-item {
      font-size: 14px;
      font-family: "Roboto";
      font-weight: 500;
      font-style: normal;
      color: black;
      text-decoration: none;
      margin: 0;
    }
  }

  .btn-add {
    font-size: 14px;
    font-family: "Roboto";
    font-weight: 400;
    width: auto;
    height: 30px;
  }
}
