.div-chart-customer-area {
  width: 100%;
  height: 400px;
  background-color: white;
  border-radius: 8px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
}

.text-table {
  display: flex;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 18px !important;
  color: #000000;
  text-decoration: none;
  margin: 0;
}
