.btn-add-topup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-left: 20px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
}

.btn-add-monetary {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-left: 20px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
  margin-top: 20px;
}

.img-thumbnail {
  width: 100px !important;
  height: 100px !important;
}

.input-money-punish {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  height: 35px;
}

.select-code-phone {
  width: 300px !important;
  height: 40px !important;
}

.list-item {
  background-color: rgb(255, 255, 255);
  padding: 5px;
  position: absolute;
  width: 400px;
  border: 1px solid rgb(175, 175, 175);
  border-radius: 5px;
}

.error {
  color: red;
  font-size: 12px;
}

.div-money {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.select-input-wallet {
  height: 40px;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
