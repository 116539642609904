:root {
  --black-color: #000000;
  --white-color: #ffffff;
  --yellow-color: #f2cc0e;
  --red-color: #dc2828;

  // feature colors
  --bg-color: #ffffff;
  --bg-color-2: #f2f3f5;
  --selected-color: #2463eb;
  --border-color: #1f242e;
  --success-color: #00cf3a;
  --bg-success-color: #f2fdf5;
  --error-color: #dc2828;
  --bg-error-color: #fef1f1;
  --pending-color: #c88a04;
  --bg-pending-color: #fefce7;
  --confirm-doing-color: #2463eb;
  --bg-confirm-doing-color: #f0f6ff;

  // main colors
  --main-color: #ff0000;
  --main-color-2: #ed1d24;

  --text-color: #a9afc3;
  --text-muted: #6c757d;

  --track-color: #202324;
  --thumb-color: #454a4d;
  --thumb-hover-color: #596268;

  --fb-color: #3b5998;
  --google-color: #a61c00;
  --twitter-color: #1da1f2;

  --text-shadow: 1px 1px 2px rgba($color: #000000, $alpha: 0.8);
}

// $colorWhite: #ffffff;
// $colorBackgroundWhite: #ffffff1f;
// $colorBlack: #212121;
// $colorBackgroundBlack: #21212125;
// $color1: #2463eb;
// $backGroundColor1: #f0f6ff;
// $color2: #c88a04;
// $backGroundColor2: #fefce7;
// $colorLightGray: #8D8D8D;
// $colorDiscount: #dc2828;
// $backGroundDiscount: #fef1f1;
