@import "../../../@theme/style.scss";

.card__progress-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $gapL;

    .card__progress-bar--image {
        width: 100%;
        height: 100px;
        background-color: $colorExtraLightBlue;
        border-radius: $borderRadiusL;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .card__progress-bar--image-content {
            display: flex;
            flex-direction: column;
            padding-left: 24px;

            .card__progress-bar-image-content-subtext {
                font-size: $textFontSizeXL;
                font-weight: $fontWeightXL;
                color: $colorGray;
                opacity: $opacityS;
            }

            .card__progress-bar--image-content-total {
                display: flex;
                gap: $gapXS;

                .card__progress-bar--image-content-total-number {
                    font-size: $textFontSizeXL;
                    font-weight: $fontWeightXL;
                    padding-top: 2px;
                    color: $colorBlack;
                }

                .card__progress-bar--image-content-total-unit {
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightXL;
                    text-transform: uppercase;
                    padding-top: 2px;
                    color: $colorBlack;
                }
            }

        }

        .card__progress-bar--image-image {
            height: 100%;
        }
    }

    .card__progress-bar--content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: $gapL;
        width: 100%;

        .card__progress-bar--content-child {
            display: flex;
            align-items: center;
            gap: $gapL;
            // padding-top: 8px;

            .card__progress-bar--content-child-progress-bar {
                width: 100%;

                .card__progress-bar--content-child-progress-bar-number {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 2px;

                    .card__progress-bar--content-child-progress-bar-number-label {
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightL;
                        color: $colorGray;
                        opacity: $opacityS;
                    }

                    .card__progress-bar--content-child-progress-bar-number-total {
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightXL;
                        color: $colorBlack;
                    }
                }

                .card__progress-bar--content-child-progress-bar-container {
                    width: 100%;
                    position: relative;
                    border-radius: $borderRadiusFull;
                    background-color: $colorExtraLightGray;
                    text-align: center;

                    span {
                        position: absolute;
                        font-style: italic;
                        font-size: $fontWeightL;
                        color: $colorBlack;

                        &.more-than-overal {
                            color: white;
                        }
                    }

                    .card__progress-bar--content-child-progress-bar-contanier-bar {
                        height: 16px;
                        border-radius: $borderRadiusFull;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .card__progress-bar--content-child-progress-bar-contanier-bar-success {
                        background-color: $colorGreen;
                        box-shadow: rgba(81, 250, 55, 0.56) 0px 1px 8px 0px;
                    }

                    .card__progress-bar--content-child-progress-bar-contanier-bar-cancel {
                        background-color: $colorRed;
                        box-shadow: rgba(247, 55, 55, 0.56) 0px 1px 8px 0px;
                    }
                }
            }
        }
    }
}