/* Absolute Center Spinner */
.app-loading {
  position: fixed;
  // z-index: 999;
  z-index: 1051;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  /* Transparent Overlay */
  &:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .loading {
    width: 80px;
    height: 35px;
    position: relative;

    p {
      top: 0;
      padding: 0;
      margin: 0;
      color: pink;
      animation: text 2s ease both infinite;
      font-size: 12px;
      letter-spacing: 1px;
    }

    span {
      background-color: rgb(252, 222, 255);
      border-radius: 50px;
      display: block;
      height: 10px;
      width: 16px;
      bottom: 0;
      position: absolute;
      transform: translateX(64px);
      animation: loading 2s ease both infinite;

      &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-color: rgb(252, 179, 251);
        border-radius: inherit;
        animation: loading2 2s ease both infinite;
      }
    }
  }
}

@keyframes text {
  0% {
    letter-spacing: 1px;
    transform: translateX(0px);
  }

  40% {
    letter-spacing: 2px;
    transform: translateX(26px);
  }

  80% {
    letter-spacing: 1px;
    transform: translateX(32px);
  }

  90% {
    letter-spacing: 2px;
    transform: translateX(0px);
  }

  100% {
    letter-spacing: 1px;
    transform: translateX(0px);
  }
}

@keyframes loading {
  0% {
    width: 16px;
    transform: translateX(0px);
  }

  40% {
    width: 100%;
    transform: translateX(0px);
  }

  80% {
    width: 16px;
    transform: translateX(64px);
  }

  90% {
    width: 100%;
    transform: translateX(0px);
  }

  100% {
    width: 16px;
    transform: translateX(0px);
  }
}

@keyframes loading2 {
  0% {
    transform: translateX(0px);
    width: 16px;
  }

  40% {
    transform: translateX(0%);
    width: 80%;
  }

  80% {
    width: 100%;
    transform: translateX(0px);
  }

  90% {
    width: 80%;
    transform: translateX(15px);
  }
  100% {
    transform: translateX(0px);
    width: 16px;
  }
}

/*  Show loading */
.app-loading.show {
  display: block;
}

/* Hide loading */
.app-loading.hide {
  display: none;
}
