.div-container {
  .text-title-order-promotion {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 16px;
    color: black;
    text-decoration: none;
    margin: 0;
  }
}
