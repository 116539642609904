.div-title-column {
  display: flex;
  flex-direction: row;
  align-items: center;

  .btn-question {
    border: none;
    background-color: transparent;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-question {
    color: aqua;
    margin-left: 10px;
  }
}

.text-money {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000;
  text-decoration: none !important;
  margin: 0;
}

.div-content {
  width: 160px;

  .text-content {
    color: #000000;
    font-family: "Roboto";
    font-style: normal;
    font-size: 14px;
    margin: 0;
  }
}

.div-date {
  display: flex;
  flex-direction: row;
  width: 25%;
}

.div-info-collaborator {
  display: flex;
  flex-direction: row;
  align-items: center;

  .div-info-name {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    .div-row-name {
      display: flex;
      flex-direction: row;
      align-items: center;

      .text-title-ctv {
        color: #000000;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        margin: 0;
      }

      .text-name-ctv {
        color: #000000;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        margin: 0 0 0 5px;
      }
    }
  }
}

.icon-back {
  width: 50px;
  height: 50px;
}

.div-time-report {
  display: flex;
  flex-direction: column;

  .text-view-order {
    color: #000000;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin: 0;
  }
}

.text-date {
  color: #000000;
  font-family: "Roboto";
  font-style: normal;
  font-size: 12px;
  margin-left: 10px;
}

.text-stt {
  color: #000000;
  font-family: "Roboto";
  font-style: normal;
  font-size: 12px;
  margin-left: 10px;
  font-weight: 500;
}
