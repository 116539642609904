.btn-detail {
  width: 70px;
  height: 25px;
  background-color: #0075ff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  .text-btn-detail {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    margin: 0;
  }
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #000000;
  text-decoration: none;
  margin: 0;
}

.title-chart {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  text-decoration: none;
  margin-bottom: 10px;
}

.div-chart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;

  .div-chart-left {
    width: 70%;
    min-height: 300px;
    padding: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
    background-color: #ffffff;
    border-radius: 15px;
  }

  .div-chart-right {
    width: 28%;
    min-height: 300px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
    background-color: #ffffff;
    border-radius: 15px;

    .title-top {
      display: flex;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 18px !important;
      color: #000000;
      text-decoration: none;
      padding: 10px;
      margin-bottom: 20%;
    }

    .div-right {
      display: flex;
      flex-direction: row;
      align-items: end;

      .div-item-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0px 10px 0px 10px;
        width: 15%;

        .text-name-column {
          display: flex;
          font-family: "Roboto";
          font-weight: 400;
          font-size: 10px !important;
          color: #000000;
          text-decoration: none;
          text-align: center;
          align-items: center;
          justify-content: center;
          height: 50px;
          width: 100%;
          margin: 0;
        }

        .column-top-5 {
          width: 40%;
          height: 80px;
          background-color: #48cae4;
          border-radius: 2px;
        }
        .column-top-4 {
          width: 40%;
          height: 100px;
          background-color: #00b4d8;
          border-radius: 2px;
        }
        .column-top-3 {
          width: 40%;
          height: 120px;
          background-color: #0096c7;
          border-radius: 2px;
        }
        .column-top-2 {
          width: 40%;
          height: 140px;
          background-color: #0077b6;
          border-radius: 2px;
        }
        .column-top-1 {
          width: 40%;
          height: 180px;
          background-color: #023e8a;
          border-radius: 2px;
        }
      }
    }
  }
}

.div-date-invite {
  display: flex;
  flex-direction: row;
  width: 25%;

  .select-range-picker {
    width: 250px;
  }
}

.div-date {
  display: flex;
  flex-direction: row;
  width: 40%;
  align-items: center;

  .text-date {
    color: #000000;
    font-family: "Roboto";
    font-style: normal;
    font-size: 12px;
    margin-left: 10px;
  }
}

.div-content-tool-chart {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 5px;
  border-radius: 5px;

  .date-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: black;
    margin: 0;
  }
  .money-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: black;
    margin: 0;
  }
}

.text-name-invite {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  text-decoration: none;
  margin: 0;
}

@media screen and (max-width: 490px) {
  .div-chart {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;

    .div-chart-left {
      width: 100%;
      min-height: 300px;
      padding: 10px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
      background-color: #ffffff;
      border-radius: 15px;
    }

    .div-chart-right {
      width: 100%;
      min-height: 300px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
      background-color: #ffffff;
      border-radius: 15px;
      margin-top: 10px;

      .title-top {
        display: flex;
        font-family: "Roboto";
        font-weight: 500;
        font-size: 18px !important;
        color: #000000;
        text-decoration: none;
        padding: 10px;
        margin-bottom: 20%;
      }

      .div-right {
        display: flex;
        flex-direction: row;
        align-items: end;

        .div-item-top {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 0px 10px 0px 10px;
          width: 15%;

          .text-name-column {
            display: flex;
            font-family: "Roboto";
            font-weight: 400;
            font-size: 10px !important;
            color: #000000;
            text-decoration: none;
            text-align: center;
            align-items: center;
            justify-content: center;
            height: 50px;
            width: 100%;
            margin: 0;
          }

          .column-top-5 {
            width: 40%;
            height: 80px;
            background-color: #48cae4;
            border-radius: 2px;
          }
          .column-top-4 {
            width: 40%;
            height: 100px;
            background-color: #00b4d8;
            border-radius: 2px;
          }
          .column-top-3 {
            width: 40%;
            height: 120px;
            background-color: #0096c7;
            border-radius: 2px;
          }
          .column-top-2 {
            width: 40%;
            height: 140px;
            background-color: #0077b6;
            border-radius: 2px;
          }
          .column-top-1 {
            width: 40%;
            height: 180px;
            background-color: #023e8a;
            border-radius: 2px;
          }
        }
      }
    }
  }
}
