.img-thumbnail {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}

.select-code-phone {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  margin-left: 20px;
  border-color: rgb(216, 216, 216);
}

.input-search {
  width: 60%;
  height: 40px;
  margin-top: 10px;
}

.text-type {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  margin: 0;
}

.text-content {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  margin: 0;
}

@media screen and (max-width: 490px) {
  .input-search {
    width: 100% !important;
    height: 40px;
    margin-top: 10px;
  }
}
