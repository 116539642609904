.title-column {
  color: black;
  font-weight: 500;
  font-size: 12px;
  font-family: "Roboto";
  text-decoration: none;
  margin: 0;
}

.div-date-review {
  display: flex;
  flex-direction: column;

  .text-date {
    color: black;
    font-weight: 400;
    font-size: 12px;
    font-family: "Roboto";
    text-decoration: none;
    margin: 0;
  }
}

.div-order-review {
  display: flex;
  flex-direction: column;

  .text-id-view {
    color: black;
    font-weight: 400;
    font-size: 12px;
    font-family: "Roboto";
    text-decoration: none;
    margin: 0;
  }
}

.div-star-collaborator {
  display: flex;
  flex-direction: column;

  .text-name {
    color: black;
    font-weight: 400;
    font-size: 12px;
    font-family: "Roboto";
    text-decoration: none;
    margin: 0;
  }
}

.text-review-by-customer {
  display: block;
  color: #000000;
  font-weight: 400;
  font-family: "Roboto";
  font-style: normal;
  font-size: 12px;
  width: 200px;
  margin: 0;
}
