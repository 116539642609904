.common-filter_container {
  @extend .box-common;
  background-color: aquamarine;
  border-radius: 12px;
  @include flexbox(start, center);
  flex-wrap: wrap;
}
.common-filter_button-container {
  @include flexbox(end, end);
  button:last-of-type {
    margin-left: 10px;
  }
}
.common-filter_filter-container {
  margin-bottom: 0.5rem;
}
.date-picker {
  font-size: 12;
}
