$font-01: "Roboto", sans-serif;

.div-sidebar {
  display: flex;
  flex-direction: column;

  .div-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 60px;

    .img-logo {
      width: 100px;
      height: 38px;
    }

    .img-logo-hide {
      width: 60px;
      height: 20px;
    }
  }

  .container-sidebar {
    padding-top: 10px;

    .div-header {
      padding: 10px;
      text-align: center;
      align-items: center;
      justify-content: center;
      display: flex;

      .name-guvi {
        color: rgb(0, 0, 0);
        margin-left: 20px;
      }
    }

    .icon {
      margin-right: 20px;
      color: black;
    }

    .icon-right {
      color: black;
      display: flex;
      margin-left: 25%;
      margin-top: 2px;
    }

    .li-nav-link-none {
      display: none;
      .active-link {
        width: "10%";
        height: 50px;
        background-color: #e6e8f4;
        padding: 5px 8px 5px 12px;
        display: flex;
        text-decoration: none;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        margin: 5px;

        .img-icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        .active-text {
          font-family: "Roboto";
          font-weight: 500;
          font-size: 12px;
          color: #000000;
          text-decoration: none;
        }
      }
      .unactive-link {
        width: "10%";
        height: 50px;
        background-color: "transparent" !important;
        padding: 5px 8px 5px 12px;
        display: flex;
        // border-bottom: 1px solid #e6e8f4;
        align-items: center;
        justify-content: space-between;

        .img-icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        .unactive-text {
          font-family: "Roboto";
          font-weight: 500;
          font-size: 12px;
          color: #000000;
          text-decoration: none;
        }
      }
    }
    .li-nav-link {
      .active-link {
        width: "10%";
        height: 50px;
        background-color: #e6e8f4;
        padding: 5px 8px 5px 12px;
        display: flex;
        text-decoration: none;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        margin: 5px;

        .img-icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        .active-text {
          font-family: "Roboto";
          font-weight: 500;
          font-size: 12px;
          color: #000000;
          text-decoration: none;
        }
      }
      .unactive-link {
        width: "10%";
        height: 50px;
        background-color: "transparent" !important;
        padding: 5px 8px 5px 12px;
        display: flex;
        // border-bottom: 1px solid #e6e8f4;
        align-items: center;
        justify-content: space-between;

        .img-icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        .unactive-text {
          font-family: "Roboto";
          font-weight: 500;
          font-size: 12px;
          color: #000000;
          text-decoration: none;
        }
      }
    }

    .nav-link-sidebar {
      .active-link-nav {
        width: "10%";
        height: 40px;
        padding: 5px 8px 5px 20px;
        display: flex;
        text-decoration: none;
        background-color: #e6e8f4;
        align-items: center;
        margin: 0px 5px;
        border-radius: 8px;

        .active-text-nav {
          font-family: "Roboto";
          font-weight: 500;
          font-size: 12px;
          color: #000000;
          text-decoration: none;
          margin-left: 5px;
        }

        .img-icon {
          width: 15px;
          height: 15px;
          color: #000000;
        }
      }
      .unactive-link-nav {
        width: "10%";
        height: 40px;
        background-color: "transparent" !important;
        padding: 5px 8px 5px 20px;
        display: flex;
        align-items: center;

        .unactive-text-nav {
          font-family: "Roboto";
          font-weight: 500;
          font-size: 12px;
          color: #000000;
          text-decoration: none;
          margin-left: 5px;
        }
        .img-icon {
          width: 15px;
          height: 15px;
          color: #000000;
        }
      }
    }

    .configuration {
      position: absolute;
      width: 100%;

      .active-link-configuration {
        height: 60px;
        background-color: #f9faff;
        padding: 5px 8px 5px 12px;
        display: flex;
        text-decoration: none;

        .img-icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        .active-text-configuration {
          font-family: "Roboto";
          font-weight: 500;
          font-size: 12px;
          color: #000000;
          text-decoration: none;
        }
      }
      .unactive-link-configuration {
        height: 60px;
        background-color: "transparent" !important;
        padding: 5px 8px 5px 12px;
        display: flex;

        .img-icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }

        .unactive-text-configuration {
          font-family: "Roboto";
          font-weight: 500;
          font-size: 12px;
          color: #000000;
          text-decoration: none;
        }
      }
    }
  }
}
.div-version {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;

  .text-version {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
  }
}
