.div-list-item-image {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 380px;
  margin-top: 10px;
  .item {
    display: flex;
    background-color: white;
    width: 171px;
    width: 171px;
    margin-top: 5px;
    border-radius: 8px;
    margin-right: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    cursor: move;

    .body-item {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-items: center;
      cursor: move;

      .img-promotion {
        width: 171px;
        height: 171px;
        border-radius: 8px;
      }

      .text-title {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 12px;
        color: black;
        text-decoration: none;
        padding: 5px;
        text-align: center;
        margin: 0;
      }
    }
  }
}
