.btn-add-service {
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-left: 20px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
}

.img-thumbnail {
  width: 1000px;
  height: 100px;
}

.input-group {
  margin-bottom: 10px;
}

.select-code-phone {
  width: 300px !important;
  height: 40px !important;
}

.btn-add-service-drawer {
  width: 100px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-left: 20px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
}

.title-service {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  text-decoration: none;
}

.input-enlish {
  margin-top: 10px;
}
