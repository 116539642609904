.title-optional {
  color: black;
  font-weight: 500;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  margin-right: 20px;
}

.btn-edit-option {
  border: none;
}

.btn-see-details-option {
  border: none;
  margin-right: 30px;
}

.div-extend {
  display: flex;
  flex-direction: row;

  .div-item-extend {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d6d6d6;
    width: 80px;
    height: 80px;
    border-radius: 8px;
    padding: 5px;
    margin-left: 10px;
    margin-top: 10px;

    .text-extend {
      color: black;
      font-weight: 400;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      text-decoration: none;
    }
  }
}

.div-content-option {
  display: flex;
  flex-direction: column;

  .div-title-option {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
      color: black;
      font-weight: 500;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      text-decoration: none;
      width: 80px;
      margin: 0;
    }

    .colon {
      color: black;
      font-weight: 400;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      text-decoration: none;
      margin-right: 5px;
      margin: 0;
    }

    .details {
      color: black;
      font-weight: 400;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      text-decoration: none;
      margin: 0;
    }
  }
}

.img-unlock-options {
  width: 30px;
  height: 30px;
}
