@import "../../../../../../../../src/@theme/style.scss";

.collaborator-rating {
  display: flex;
  flex-direction: column;
  gap: $gapL;
  padding-bottom: 16px;
  
  .collaborator-rating__overview {
    display: flex;
    gap: $gapL;
    .collaborator-rating__overview--total {
      width: 30%;
    }
    .collaborator-rating__overview--statistic {
      width: 70%;
    }
  }
}



.text-total-star {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #11263c !important;
  text-decoration: none;
  margin: 20px 0 0 0;

  .icon-star {
    color: rgb(255, 235, 54);
  }
}

.time-review {
  display: flex;
  flex-direction: column;

  .text-time {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000 !important;
    text-decoration: none;
    margin: 0;
  }
}

.icon-star {
  color: rgb(255, 235, 54);
}

.name-customer-reivew {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  text-decoration: none;
  margin: 0;
}

.text-quick_review {
  display: block;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  text-decoration: none;
  width: 200px;
  margin: 0;
}

@media (max-width: 1200px) {
  .text-quick_review {
    display: block;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    width: 120px;
    margin: 0;
  }
}
