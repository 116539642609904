.btn-edit-banner {
  width: 50px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-left: 20px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
}

.img-thumbnail-banner {
  width: 300px !important;
  height: 100px !important;
  margin-bottom: 20px;
  border-radius: 5px;
}

.input-group {
  margin-bottom: 10px;
}

.select-code-phone {
  width: 300px !important;
  height: 40px !important;
}

.label-img {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #19202c;
  align-items: center;
}
