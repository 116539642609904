.div-select-city {
  display: flex;
  flex-direction: row;
  width: 100%;

  .div-select-item {
    display: flex;
    flex-direction: column;

    .div-item-city {
      display: flex;
      flex-direction: column;
      background-color: rgb(255, 255, 255);
      padding: 5px;
      width: 180px;
      border: 1px solid rgb(175, 175, 175);
      border-radius: 5px;
      z-index: 1000;
      margin-left: 10px;

      .div-item-list {
        display: flex;
        align-items: center;
        height: 30px;
        width: 100%;
        border-bottom: 1px solid #d6d6d6;
      }

      .div-item-list-selected {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #48cae4;
        height: 30px;
      }
    }

    .div-select-city-input {
      display: flex;
      align-items: center;
      border-radius: 4px;
      height: 40px;
      border: 1px solid #d6d6d6;
      margin-left: 10px;
      width: 180px;
      padding-left: 10px;
    }
  }
}

.div-chart-pie-total-cancel {
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;

  .div-pie-chart-cancel {
    display: flex;
    flex-direction: row;
    width: 100%;

    .div-total-piechart {
      display: flex;
      flex-direction: column;
      width: 30%;
      min-height: 100px;
      border-right: 1px solid #d6d6d6;
      padding: 5px;
      justify-content: center;

      .item-total {
        display: flex;
        flex-direction: row;

        .title-total {
          color: black;
          font-weight: 500;
          font-size: 16px;
          font-family: "Roboto", sans-serif;
          text-decoration: none;
          width: 200px;
        }

        .text-colon {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #000000;
        }

        .number-total {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
          margin-left: 10px;
        }
      }
    }

    .div-pie-cancel {
      width: 70%;
      height: 100%;
    }
  }
}

.div-create-cancel-user-system {
  display: flex;
  flex-direction: column;

  .text-create-cancel {
    color: #000000;
    font-weight: 400;
    font-family: "Roboto";
    font-style: normal;
    font-size: 12px;
    margin: 0;
  }
}
