.div-create-info-reward {
  display: flex;
  flex-direction: column;

  .text-date {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }
}
.div-name-ctv-reward {
  display: flex;
  flex-direction: column;

  .text-name {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }
}

.text-total {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  text-decoration: none;
  margin: 0;
}
.text-pending-reward-info {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #f8da2c;
  text-decoration: none;
  margin: 0;
}

.text-done-reward-info {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #4cca12;
  text-decoration: none;
  margin: 0;
}
.text-cancel-reward-info {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #e53e2f;
  text-decoration: none;
  margin: 0;
}

.text-note {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  text-decoration: none;
  margin: 0;
}

.div-action {
  display: flex;
  flex-direction: column;
  align-items: center;

  .btn-verify-reward {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4cca12;
    border: none;
    width: 80px;
    height: 25px;
    border-radius: 4px;

    .text-btn {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #000000;
      text-decoration: none;
      margin: 0;
    }
  }

  .btn-verify-reward-hide {
    display: none;
  }

  .div-cancel-delete {
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    .text-cancel-reward {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #000000;
      margin: 0 20px 0 10px;
    }
  }
}
