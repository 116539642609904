@import "../../../../../../../@theme/style.scss";

.div-list-item {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-top: 10px;
  border-bottom: 1px solid rgb(208, 208, 208);

  .text-title {
    font-family: Helvetica;
    font-weight: 500;
    font-size: 20px;
    color: black;
    text-decoration: none;
    width: 100%;
  }

  .text-date {
    font-family: Helvetica;
    font-weight: 200;
    font-size: 14px;
    color: rgb(78, 78, 78);
    text-decoration: none;
    width: 100%;
  }
}

.div-pagination {
  margin-top: 10px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-id-activity {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: black;
  margin: 0;
}

.div-service-activity {
  display: flex;
  flex-direction: column;

  .text-service {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin: 0;
    color: black;
    text-decoration: none;
  }
}

.div-create-activity {
  display: flex;
  flex-direction: column;

  .text-create {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin: 0;
    color: #202223;
  }
}

.div-worktime-activity {
  display: flex;
  flex-direction: column;

  .text-worktime {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-decoration: none;
    color: rgb(0, 0, 0);
    text-transform: capitalize;
    margin: 0;
  }
}

.text-address-activity {
  display: block;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: black;
  width: 150px;
}

.text-collaborator {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #023e8a;
}

.text-pending-activity {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ece200 !important;
  text-decoration: none;
  margin: 0;
}

.text-confirm-activity {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #023e8a;
  text-decoration: none;
  margin: 0;
}

.text-doing-activity {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #023e8a;
  text-decoration: none;
  margin: 0;
}

.text-cancel-activity {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ff0000;
  text-decoration: none;
  margin: 0;
}

.text-done-activity {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #00cf3a;
  text-decoration: none;
  margin: 0;
}

.filter-input {
  width: 200px;
  height: 30px;
  border-radius: 5px;
}

.div-name-activity {
  display: flex;
  flex-direction: column;


}

@media (max-width: 1200px) {
  .text-id-activity {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: black;
    margin: 0;
  }

  .text-address-activity {
    display: block;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: black;
    width: 100px;
  }
}


.collaborator-activity {
  display: flex;
  flex-direction: column;
  gap: $gapL;
  padding-bottom: 16px;

  .collaborator-activity__statistics {
    display: flex;
    gap: $gapL;

    .collaborator-activity__statistics--overview {
      display: grid;
      gap: $gapL;
      width: 30%;
    }

    .collaborator-activity__statistics--chart {
      // background-color: $colorWhite;
      // border-radius: $borderRadiusL;
      // padding: 16px 20px;
      width: 70%;
    }
  }


  .collaborator-activity__history {
    display: flex;
    flex-direction: column;
    // grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $gapL;

    .collaborator-activity__history--order {
      width: 100%;
      height: fit-content;
      // background-color: $colorWhite;
      // border-radius: $borderRadiusL;
    }

    .collaborator-activity__history--activities {
      display: flex;
      gap: $gapL;
      .collaborator-activity__history--activities-activity {
        width: 50%;
      }
    }
  }
}

// ~~~ Thẻ thống kê của hoạt động đơn hàng