.title-review {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.div-head-review {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
}

.div-date-review-collaborator {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40%;
  margin: 20px 0;

  .text-date {
    color: #000000;
    font-family: "Roboto";
    font-style: normal;
    font-size: 12px;
    margin-left: 10px;
  }
}

.div-create-review {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .text-date-review {
    color: #000000;
    font-weight: 400;
    font-family: "Roboto";
    font-style: normal;
    font-size: 12px;
    margin: 0;
  }
}

.div-customer-review {
  display: flex;
  flex-direction: row;

  align-items: center;

  .div-name-info {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    .text-name-review {
      color: #000000;
      font-weight: 400;
      font-family: "Roboto";
      font-style: normal;
      font-size: 12px;
      margin: 0;
    }
  }

  .rank-customer {
    color: #000000;
    font-weight: 400;
    font-family: "Roboto";
    font-style: normal;
    font-size: 12px;
  }
}

.div-collaborator-review {
  display: flex;
  flex-direction: column;
  .text-name-review {
    color: #000000;
    font-weight: 400;
    font-family: "Roboto";
    font-style: normal;
    font-size: 12px;
    margin: 0;
  }
}

.div-star-review {
  display: flex;
  flex-direction: column;

  .text-order {
    color: #000000;
    font-weight: 400;
    font-family: "Roboto";
    font-style: normal;
    font-size: 12px;
    margin: 0;
  }

  .div-star {
    display: flex;
    flex-direction: row;
    .icon-star {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }

  .star-review {
    color: #000000;
    font-weight: 400;
    font-family: "Roboto";
    font-style: normal;
    font-size: 12px;
  }
}

.text-review {
  display: block;
  color: #000000;
  font-weight: 400;
  font-family: "Roboto";
  font-style: normal;
  font-size: 12px;
  width: 200px;
  text-decoration: none;
  margin: 0;
}

.text-short-review {
  display: block;
  width: 200px;
  color: #000000;
  font-weight: 400;
  font-family: "Roboto";
  font-style: normal;
  font-size: 12px;
  text-decoration: none;
  margin: 0;
}

.div-tab-review {
  display: flex;
  flex-direction: row;

  .div-item-review {
    padding: 5px;

    .text-tab {
      color: #8c8c8c;
      font-weight: 400;
      font-family: "Roboto";
      font-style: normal;
      font-size: 16px;
      text-decoration: none;
      margin: 0;
    }
  }

  .div-item-review-select {
    padding: 5px;
    border-bottom: 1px solid blue;

    .text-tab {
      color: #000000;
      font-weight: 400;
      font-family: "Roboto";
      font-style: normal;
      font-size: 16px;
      text-decoration: none;
      margin: 0;
    }
  }
}

.div-detail-review-collaborator {
  display: flex;
  flex-direction: column;

  .div-text-detail {
    display: flex;
    flex-direction: row;
    .title-detail {
      width: 80px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #202223;
      text-decoration: none;
      margin: 0;
    }

    .text-detail-review {
      display: block;
      width: 250px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #202223;
      text-decoration: none;
      margin: 0;
    }

    .div-star-review {
      display: flex;
      flex-direction: column;

      .text-order {
        color: #000000;
        font-weight: 400;
        font-family: "Roboto";
        font-style: normal;
        font-size: 12px;
        margin: 0;
      }

      .div-star {
        display: flex;
        flex-direction: row;
        .icon-star {
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }
      }

      .star-review {
        color: #000000;
        font-weight: 400;
        font-family: "Roboto";
        font-style: normal;
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 490px) {
  .div-date-review-collaborator {
    display: flex;
    flex-direction: row;
    width: 100%;

    .div-picker {
      width: 50%;
    }
    .text-date {
      color: #000000;
      font-family: "Roboto";
      font-style: normal;
      font-size: 12px;
      margin-left: 10px;
    }
  }
  .div-head-review {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
}
