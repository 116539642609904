@import "../../../../@theme/style.scss";

html,
body {
    scroll-behavior: smooth !important;
}

.affiliate-overview {

    html,
    body {
        --scroll-behavior: smooth !important;
        scroll-behavior: smooth !important;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // height: fit-content;
    height: 100%;
    width: 100%;
    background-color: #4158D0;
    background: linear-gradient(120deg, #C584FD 20%, #db94ff 50%, #7B97FE 70%, #6CD4FE 100%);
    overflow: hidden;
    scroll-behavior: smooth;
    position: relative;

    .affiliate-overview__introduction {
        display: flex;
        flex-direction: column;
        width: 0px;
        height: 0px;
        transition: all 1.5s ease;
        opacity: 0;
        overflow: hidden;
        z-index: -1;

        &.hide {

            .affiliate-overview__introduction--phone {
                height: 0;
                width: 0;
                opacity: 0;
            }

            .affiliate-overview__introduction--wave {
                // height: 0;
                // width: 0;
                opacity: 0;
                z-index: -1;
            }

            .affiliate-overview__introduction--star {
                z-index: 0;
            }

            .affiliate-overview__introduction--blank {
                height: 70vh;
                border-top-left-radius: 32px;
                border-top-right-radius: 32px;
                transition: all 1.5s ease;
                z-index: 1;

                .affiliate-overview__introduction--blank-label {
                    height: 0;
                    width: 0;
                    z-index: 0;
                    visibility: hidden;
                    display: none;
                }

                .affiliate-overview__introduction--blank-header {
                    opacity: 1;
                }

                &.hide {
                    height: 0px;
                    opacity: 0;
                    z-index: -1;
                }

                ;
            }

            .affiliate-overview__introduction--header {
                opacity: 1;
            }
        }

        .affiliate-overview__introduction--line {
            position: absolute;
            top: 0px;
            right: 0px;
            width: 200px;
            height: 200px;
            // transform: rotate(-15deg);
            // overflow: hidden;
        }

        .affiliate-overview__introduction--header {
            display: flex;
            flex-direction: column;
            padding: 12px;
            z-index: 1;

            .affiliate-overview__introduction--header-navigation {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: fit-content;

                .affiliate-overview__introduction--header-navigation-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $colorWhite;
                    padding: 16px;
                    transition: all 1.5s ease;
                    width: 40%;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

                    span {
                        font-family: "Work Sans", serif;
                        font-size: $textFontSize4XL;
                        font-weight: 700;
                        color: $colorDarkViolet;
                        text-transform: uppercase;
                    }

                    &.left-side {
                        border-radius: 10px 0px 0px 10px;
                    }

                    &.right-side {
                        border-radius: 0px 10px 10px 0px;
                    }

                    &.disable {
                        background-color: $colorExtraLightGray;
                        opacity: .5;
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

                        span {
                            color: $colorBlack;
                        }
                    }

                    &.activated {
                        transform: scale(1.02);
                    }
                }

            }

            .affiliate-overview__introduction--header-information {
                display: flex;
                align-items: center;
                gap: $gapXS;
                padding: 12px;

                .affiliate-overview__introduction--header-information-personal {
                    display: flex;
                    flex-direction: column;
                    width: 60%;
                    height: fit-content;
                    gap: $gapXS;

                    .affiliate-overview__introduction--header-information-personal-label {
                        font-size: $textFontSize4XL;
                        font-weight: $fontWeight2XL;
                        color: #3E00BA;
                    }

                    .affiliate-overview__introduction--header-information-personal-sub-label {
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightXL;
                        color: #3E00BA;
                    }
                }

                .affiliate-overview__introduction--header-information-assistant {
                    width: 40%;
                    height: fit-content;
                }
            }
        }

        .affiliate-overview__introduction--star {
            position: absolute;
            top: 0;
            left: 0;
            height: 400px;
            width: 350px;
            transition: all 1.5s ease;
        }

        .affiliate-overview__introduction--phone {
            position: absolute;
            bottom: 35%;
            left: 50%;
            z-index: 1;
            height: 400px;
            width: 400px;
            transform: translateX(-50%) translateY(40%) rotate(0deg);
            transition: all 1.5s ease;


        }

        .affiliate-overview__introduction--blank {
            display: flex;
            flex-direction: column;
            // align-items: center;
            // justify-content: center;
            padding: 12px;
            position: absolute;
            width: 100%;
            height: 20%;
            bottom: 1px;
            left: 0;
            gap: $gapL;
            background-color: $colorWhite;
            transition: all 1.5s ease;
            overflow-y: scroll;

            .affiliate-overview__introduction--blank-header {
                display: flex;
                flex-direction: column;
                gap: $gapXS;
                align-items: center;
                justify-content: center;
                opacity: 0;
                padding: 4px 12px;

                span {
                    font-size: $textFontSizeXL;
                    font-weight: $fontWeight2XL;
                    color: $colorBlack;
                }

                .affiliate-overview__introduction--blank-header-text-border {
                    text-shadow:
                        1px 1px 0 #b71eff,
                        -1px -1px 0 #b71eff,
                        1px -1px 0 #b71eff,
                        -1px 1px 0 #b71eff;
                    font-size: $textFontSize3XL;
                    font-weight: $fontWeight2XL;
                    font-family: "Work Sans", serif;
                    color: $colorWhite;
                    text-transform: uppercase;
                }

                .affiliate-overview__introduction--blank-header-text-neon {
                    font-size: $textFontSize4XL;
                    font-weight: $fontWeight2XL;
                    font-family: "Work Sans", serif;
                    color: $colorWhite;
                    text-transform: uppercase;
                    text-shadow:
                        2px 2px 5px #38b6ff,
                        4px 4px 10px #38b6ff;
                }

                &.hide {
                    opacity: 0;
                    z-index: -1;
                }
            }

            .affiliate-overview__introduction--blank-label {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                gap: $gapXS;

                span {
                    font-size: $textFontSizeXL;
                    font-weight: $fontWeightXL;
                    color: $colorBlack;
                }
            }

            .affiliate-overview__introduction--blank-button {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: 16px;
                background-color: #C584FD;
                border-radius: $borderRadiusL;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                cursor: pointer;
                transition: all 1.5s ease;
                width: 100%;
                height: fit-content;

                &:hover {
                    background-color: $colorViolet;
                }

                span {
                    font-size: $textFontSizeXL;
                    font-weight: $fontWeightXL;
                    color: $colorWhite;
                }

                &.hide {
                    background-color: black;
                    height: 0;
                    width: 0;
                    z-index: 0;
                    visibility: hidden;
                    display: none;
                }

            }

            .affiliate-overview__introduction--blank-child {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: $gapL;
                padding: 0px;
                // background-color: $colorExtraLightGray;
                opacity: 1;
                transition: all 1.5s ease;

                &.hide {
                    display: none;
                    width: 0px;
                    height: 0px;
                    opacity: 0;
                }

                .affiliate-overview__introduction--blank-child-container {
                    background-color: $colorExtraLightGray;
                    border-radius: $borderRadiusXL;
                    border: 1px solid white;
                    padding: 8px;
                    transition: all 1.5s ease;
                    width: 50%;

                    &:hover {
                        transform: scale(1.05);
                    }

                    .affiliate-overview__introduction--blank-child-promotion {
                        display: flex;
                        position: relative;
                        flex-direction: column;
                        align-items: center;
                        background-color: $colorWhite;
                        border-radius: $borderRadiusL;
                        border: 4px solid $colorViolet;
                        padding: 6px;
                        width: 100%;
                        gap: $gapXS;
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

                        .affiliate-overview__introduction--blank-child-promotion-tag-name {
                            position: absolute;
                            top: -16px;
                            left: -8px;
                            z-index: 1;

                            span {
                                font-family: "Work Sans", serif;
                                font-size: $textFontSizeXL;
                                font-weight: 700;
                                color: $colorWhite;
                            }
                        }

                        .affiliate-overview__introduction--blank-child-promotion-tag {
                            position: absolute;
                            top: -23px;
                            left: -15px;
                            width: 150px;
                            height: fit-content;
                        }

                        .affiliate-overview__introduction--blank-child-promotion-image {
                            width: 100px;
                            height: 100px;
                        }

                        .affiliate-overview__introduction--blank-child-promotion-benefit {
                            display: flex;
                            align-items: start;
                            gap: $gapXS;
                            width: 100%;
                            max-width: 200px;

                            .affiliate-overview__introduction--blank-child-promotion-benefit-icon {
                                font-size: $textFontSizeXL;
                                font-weight: $fontWeightL;
                                color: $colorGreen;
                                padding-top: 3px;
                            }

                            .affiliate-overview__introduction--blank-child-promotion-benefit-label {
                                font-size: $textFontSizeM;
                                font-weight: $fontWeightXL;
                                color: $colorBlack;
                            }
                        }

                        .affiliate-overview__introduction--blank-child-promotion-share {
                            display: flex;
                            align-items: center;
                            gap: $gapXS;
                            padding: 4px;
                            background-color: $colorWhite;
                            border-radius: $borderRadiusL;
                            // border: 2px solid $colorYellow;
                            box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
                            cursor: pointer;
                            transition: all 1.5s ease;

                            &:hover {
                                background-color: $colorViolet;

                                span {
                                    color: $colorWhite;
                                }
                            }

                            img {
                                height: 30px;
                                width: 30px;
                            }

                            span {
                                font-size: $textFontSizeL;
                                font-weight: 700;
                                text-transform: uppercase;
                                color: $colorViolet;
                            }
                        }
                    }
                }
            }

            .affiliate-overview__introduction--blank-guide {
                margin-top: 16px;
                padding: 8px;
                width: 100%;
                height: fit-content;
                background-color: $colorMoreLightGray;
                border-radius: $borderRadiusXL;

                .affiliate-overview__introduction--blank-guide-container {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    background-color: $colorWhite;
                    border: 4px solid $colorViolet;
                    border-radius: $borderRadiusL;

                    .affiliate-overview__introduction--blank-guide-container-tag-label {
                        position: absolute;
                        z-index: 1;
                        width: 230px;

                        span {
                            font-family: "Work Sans", serif;
                            font-size: $textFontSizeXL;
                            font-weight: 700;
                            color: $colorWhite;
                            text-transform: uppercase;
                        }

                        &.one-line {
                            top: -20px;
                            left: -10px;
                        }

                        &.two-line {
                            top: -27px;
                            left: -10px;
                        }
                    }

                    .affiliate-overview__introduction--blank-guide-container-tag {
                        position: absolute;
                        top: -33px;
                        left: -20px;
                        width: 230px;
                        height: fit-content;
                    }

                    .affiliate-overview__introduction--blank-guide-container-child {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        // Bên trái
                        .affiliate-overview__introduction--blank-guide-container-child-left {
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            width: 70%;
                            padding: 20px 0px 12px 12px;
                            gap: $gapXS;


                            .affiliate-overview__introduction--blank-guide-container-child-left-content {
                                display: flex;
                                height: 100%;
                                width: 100%;
                                gap: $gapXS;

                                .affiliate-overview__introduction--blank-guide-container-child-left-content-bullet-points {
                                    width: 30px;
                                    max-width: 30px;
                                    min-width: 30px;
                                    height: fit-content;
                                    max-height: fit-content;
                                    min-height: fit-content;
                                }


                                .affiliate-overview__introduction--blank-guide-container-child-left-content-label {
                                    display: flex;
                                    align-items: center;
                                    padding-top: 2px;

                                    span {
                                        font-size: $textFontSizeL;
                                        font-weight: $fontWeightXL;
                                        color: $colorViolet;

                                        .high-light {
                                            font-weight: $fontWeight2XL;
                                            color: $colorDarkViolet;
                                        }
                                    }

                                }
                            }

                        }

                        // Bên phải
                        .affiliate-overview__introduction--blank-guide-container-child-right {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 4px;
                            height: 100%;
                            width: 30%;

                            .affiliate-overview__introduction--blank-guide-container-child-right-container {
                                background-color: $colorExtraLightGray;
                                border-radius: $borderRadiusXL;
                                border: 0px solid white;
                                padding: 0px;
                                transition: all 1.5s ease;
                                height: fit-content;
                                width: fit-content;

                                // &:hover {
                                //     transform: scale(1.05);
                                // }

                                .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion {
                                    display: flex;
                                    position: relative;
                                    flex-direction: column;
                                    align-items: center;
                                    background-color: $colorWhite;
                                    border-radius: $borderRadiusL;
                                    border: 3px solid $colorViolet;
                                    padding: 4px;
                                    min-width: 50px;
                                    min-height: 50px;
                                    gap: 0;
                                    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

                                    .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-tag-name {
                                        position: absolute;
                                        top: -16px;
                                        left: -6px;
                                        z-index: 1;

                                        span {
                                            font-family: "Work Sans", serif;
                                            font-size: $textFontSizeS;
                                            font-weight: 700;
                                            color: $colorWhite;
                                        }
                                    }

                                    .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-tag {
                                        position: absolute;
                                        top: -13px;
                                        left: -9px;
                                        width: 90px;
                                        height: fit-content;
                                    }

                                    .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-image {
                                        width: 40px;
                                        height: 40px;
                                    }

                                    .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-benefit {
                                        display: flex;
                                        align-items: center;
                                        gap: $gapXS;
                                        width: 100%;
                                        max-width: 200px;

                                        .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-benefit-icon {
                                            font-size: $textFontSizeM;
                                            font-weight: $fontWeightXL;
                                            color: $colorGreen;
                                        }

                                        .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-benefit-label {
                                            font-size: $textFontSizeSM;
                                            font-weight: $fontWeightXL;
                                            color: $colorBlack;
                                        }
                                    }

                                    .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-share {
                                        display: flex;
                                        align-items: center;
                                        gap: $gapXS;
                                        padding: 0px 4px;
                                        background-color: $colorWhite;
                                        border-radius: $borderRadiusL;
                                        // border: 2px solid $colorYellow;
                                        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
                                        cursor: pointer;
                                        transition: all 1.5s ease;

                                        // &:hover {
                                        //     background-color: $colorViolet;

                                        //     span {
                                        //         color: $colorWhite;
                                        //     }
                                        // }

                                        img {
                                            height: 10px;
                                            width: 10px;
                                        }

                                        span {
                                            font-size: $textFontSizeSM;
                                            font-weight: $fontWeight2XL;
                                            text-transform: uppercase;
                                            color: $colorViolet;
                                        }
                                    }
                                }
                            }

                            .affiliate-overview__introduction--blank-guide-container-tag {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }

        .affiliate-overview__introduction--wave {
            position: absolute;
            width: 100%;
            height: 22%;
            bottom: 20%;
            left: 0;
            background: url("../../../../assets/images/wave.png");
            background-size: 100% 100%;
            transition: all 1.5s ease;
        }

        .affiliate-overview__introduction--benefit {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 12px;
            width: 100%;
            height: 100%;
            opacity: 1;
            z-index: 1;
            transition: all 1.5s ease;

            .affiliate-overview__introduction--benefit-header {
                display: flex;
                flex-direction: column;
                gap: $gapL;
                align-items: center;
                justify-content: center;
                opacity: 1;
                padding: 12px;
                z-index: 1;

                span {
                    font-size: $textFontSizeXL;
                    font-weight: $fontWeight2XL;
                    color: $colorBlack;
                }

                .affiliate-overview__introduction--benefit-header-text-border {
                    text-shadow:
                        1px 1px 0 #b71eff,
                        -1px -1px 0 #b71eff,
                        1px -1px 0 #b71eff,
                        -1px 1px 0 #b71eff;
                    font-size: 32px;
                    font-weight: $fontWeight2XL;
                    font-family: "Work Sans", serif;
                    color: $colorWhite;
                    text-transform: uppercase;
                }

                .affiliate-overview__introduction--benefit-header-text-neon {
                    font-size: 48px;
                    font-weight: $fontWeight2XL;
                    font-family: "Work Sans", serif;
                    color: $colorWhite;
                    text-transform: uppercase;
                    text-shadow:
                        2px 2px 5px #38b6ff,
                        4px 4px 10px #38b6ff;
                    line-height: 1.1;
                }
            }

            .affiliate-overview__introduction--benefit-header-list {
                display: flex;
                flex-wrap: wrap;
                background-color: rgba(223, 221, 221, 0.4);
                border-radius: $borderRadiusXL;
                width: 100%;
                height: 100%;
                opacity: 1;
                z-index: 1;
                padding: 12px;
                height: 63vh;

                .affiliate-overview__introduction--benefit-header-list-container {
                    display: flex;
                    flex-direction: column;
                    gap: $gapL;
                    overflow: auto;
                    width: 100%;
                    height: 100%;

                    .affiliate-overview__introduction--benefit-header-list-content {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: fit-content;
                        background-color: $colorWhite;
                        border-radius: $borderRadiusXL;

                        .affiliate-overview__introduction--benefit-header-list-content-child {
                            background-color: $colorWhite;
                            width: 80%;
                            height: 100%;
                            padding: 12px;
                            border-radius: $borderRadiusXL;


                            .affiliate-overview__introduction--benefit-header-list-content-child-header {
                                display: flex;
                                // align-items: center;
                                gap: $gapXS;

                                .affiliate-overview__introduction--benefit-header-list-content-child-header-icon {
                                    font-size: $textFontSize3XL;
                                    font-weight: $fontWeightXL;
                                    color: $colorViolet;
                                }

                                .affiliate-overview__introduction--benefit-header-list-content-child-header-text {
                                    font-size: $textFontSizeXL;
                                    font-weight: $fontWeight2XL;
                                    color: $colorViolet;
                                    text-transform: uppercase;
                                    font-family: "Work Sans", serif;
                                }
                            }

                            .affiliate-overview__introduction--benefit-header-list-content-child-content {
                                display: flex;
                                gap: $gapS;


                                .affiliate-overview__introduction--benefit-header-list-content-child-content-dot {
                                    width: 6px;
                                    height: 6px;
                                    max-width: 6px;
                                    max-height: 6px;
                                    min-width: 6px;
                                    min-height: 6px;
                                    border-radius: $borderRadiusFull;
                                    background-color: $colorViolet;
                                    margin-top: 6px;
                                }

                                span {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                    color: $colorViolet;
                                    font-family: "Work Sans", serif;
                                }
                            }

                        }

                        .affiliate-overview__introduction--benefit-header-list-content-icon {
                            display: flex;
                            align-items: center;
                            background-color: #9758f4;
                            width: 20%;
                            height: 100%;
                            border-radius: $borderRadiusXL;
                            padding: 4px;

                            .affiliate-overview__introduction--benefit-header-list-content-icon-image-container {
                                width: 100%;
                                height: 100px;

                                img {
                                    object-fit: fill;
                                }
                            }
                        }
                    }
                }

            }

            &.hide {
                height: 0px;
                opacity: 0;
                z-index: -1;
            }
        }

        .affiliate-overview__introduction--arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 1;
            z-index: 1;
            transition: all 1.5s ease;
            padding: 12px;

            .affiliate-overview__introduction--arrow-icon {
                padding: 12px;
                background: linear-gradient(to right, #DEDCFC 0%, #B4ABFE 50%, #CF91FC 100%);
                rotate: 90deg;
                opacity: 0.7;
                cursor: pointer;
                color: $colorBlack;
                border-radius: $borderRadiusFull;
                transition: all 1.5s ease;
                background-size: 120% 120%;
                background-position: left center;

                &:hover {
                    background-position: right center;
                    opacity: 1;
                }
            }

            &.hide {
                height: 0px;
                width: 0px;
                opacity: 0;
                z-index: -1;
            }
        }

        .affiliate-overview__introduction--footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 12px;
            z-index: 1;
            // background-color: $colorBlack;
            height: 100%;

            .affiliate-overview__introduction--footer-header {
                background: linear-gradient(to right, #DEDCFC 0%, #B4ABFE 50%, #CF91FC 100%);
                padding: 12px;
                border: 2px solid $colorWhite;
                border-radius: $borderRadiusXL;

                .affiliate-overview__introduction--footer-header-label {
                    font-size: $textFontSize3XL;
                    font-weight: $fontWeight2XL;
                    color: $colorWhite;
                    font-family: "Work Sans", serif;
                    text-transform: uppercase;

                    .high-light {
                        font-size: $textFontSize3XL;
                        font-weight: $fontWeight2XL;
                        color: $colorViolet;
                        font-family: "Work Sans", serif;
                        text-transform: uppercase;
                    }
                }

            }

            .affiliate-overview__introduction--footer-content {
                background-color: rgba(223, 221, 221, 0.4);
                border-radius: $borderRadiusXL;
                padding: 16px 12px;
                height: fit-content;
                width: 100%;
                margin-top: -4px;

                .affiliate-overview__introduction--footer-content-image {
                    width: 100%;
                    height: fit-content;
                }

                .affiliate-overview__introduction--footer-content-list-service {
                    display: flex;
                    flex-wrap: wrap;
                    // gap: $gapM;
                    align-items: center;
                    justify-content: center;
                    border-radius: $borderRadiusFull;

                    .affiliate-overview__introduction--footer-content-list-service-child {
                        height: 120px;
                        width: 120px;
                    }

                }
            }

            .affiliate-overview__introduction--footer-bottom {
                display: flex;
                align-items: end;
                height: 100%;

                .affiliate-overview__introduction--footer-bottom-button {
                    background: linear-gradient(to right, #DEDCFC 0%, #B4ABFE 50%, #CF91FC 100%);
                    padding: 12px;
                    border-radius: $borderRadiusFull;
                    rotate: -90deg;
                    opacity: 0.7;
                    cursor: pointer;
                    color: $colorBlack;
                    transition: all 1.5s ease;
                    background-size: 120% 120%;
                    background-position: left center;

                    &:hover {
                        background-position: right center;
                        opacity: 1;

                    }
                }

                .affiliate-overview__introduction--footer-bottom-modal {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: end;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 100vh;
                    background-color: rgba(0, 0, 0, 0.6);
                    opacity: 1;
                    // transition: all 1.5s ease;
                    overflow: hidden;

                    .affiliate-overview__introduction--footer-bottom-modal-information {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        gap: $gapL;
                        width: 100%;
                        height: 35vh;
                        max-height: 40vh;
                        background-color: $colorWhite;
                        border-top-left-radius: $borderRadiusXL;
                        border-top-right-radius: $borderRadiusXL;
                        transition: all 0.7s ease;

                        .affiliate-overview__introduction--footer-bottom-modal-information-title {
                            font-size: $textFontSize2XL;
                            font-weight: $fontWeight2XL;
                            color: #CF91FC;
                            font-family: "Work Sans", serif;
                            text-align: center;
                            padding-top: 12px;
                        }

                        .affiliate-overview__introduction--footer-bottom-modal-information-bullets-point {
                            font-size: $textFontSize2XL;
                            font-weight: $fontWeightL;
                            color: #CF91FC;
                            font-family: "Work Sans", serif;
                            padding: 0px 12px 0px 12px;

                            .high-light {
                                font-size: $textFontSize2XL;
                                font-weight: $fontWeight2XL;
                                color: #CF91FC;
                                font-family: "Work Sans", serif;
                            }


                        }

                        .affiliate-overview__introduction--footer-bottom-modal-information-social {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: $gapL;

                            .affiliate-overview__introduction--footer-bottom-modal-information-social-media {
                                width: fit-content;
                                height: fit-content;
                                padding: 12px;
                                border-radius: $borderRadiusFull;
                                background-color: $colorBlue;
                                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                color: $colorWhite;

                                &.facebook {
                                    background-color: $colorBlue;
                                }

                                &.youtube {
                                    background-color: $colorRed;
                                }

                                &.fanpage {
                                    background-color: $colorViolet;
                                }
                            }

                        }

                        .affiliate-overview__introduction--footer-bottom-modal-information-copyright {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 12px;
                            border-top: 1px solid $colorMoreLightGray;
                            text-align: center;

                            span {
                                font-size: $textFontSize2XL;
                                font-weight: $fontWeightL;
                                color: $colorLightGray;
                                font-family: "Work Sans", serif;
                            }
                        }
                    }

                    &.hide {
                        height: 0px;
                        width: 0px;

                        .affiliate-overview__introduction--footer-bottom-modal-information {
                            width: 100%;
                            height: 0px;
                            opacity: 0;
                        }
                    }

                }

            }
        }
    }

    .affiliate-overview__navigate {
        display: flex;
        flex-direction: column;
        padding: 12px;
        z-index: 1;
        position: absolute;
        top: 0;

        .affiliate-overview__navigate--child {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: fit-content;

            .affiliate-overview__navigate--child-button {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $colorWhite;
                padding: 16px;
                transition: all 1.5s ease;
                width: 200px;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                cursor: pointer;

                span {
                    font-family: "Work Sans", serif;
                    font-size: $textFontSize4XL;
                    font-weight: 700;
                    color: $colorDarkViolet;
                    text-transform: uppercase;
                }

                &.left-side {
                    border-radius: 10px 0px 0px 10px;
                }

                &.right-side {
                    border-radius: 0px 10px 10px 0px;
                }

                &.disable {
                    background-color: $colorExtraLightGray;
                    opacity: .5;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

                    span {
                        color: $colorBlack;
                    }
                }

                &.activated {
                    transform: scale(1.02);
                }
            }

        }
    }

    .affiliate-overview__landing-page {
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: $colorBlack;
        width: 100vw;
        height: 100vh;
        position: relative;
        overflow: hidden;

        .affiliate-overview__landing-page--container {
            display: flex;
            background-color: transparent;
            width: 100%;
            height: 100%;
            gap: $gapL;
            padding: 12px;


            .affiliate-overview__landing-page--container-child {
                display: flex;
                flex-direction: column;
                justify-content: center;

                &.left {
                    width: 50%;
                    height: 100%;
                    // background-color: blue;
                }

                &.right {
                    width: 50%;
                    height: 100%;
                    // background-color: red;
                }

                .affiliate-overview__landing-page--container-child-title {
                    display: flex;
                    align-items: center;
                    font-size: 4rem;
                    font-weight: $fontWeight2XL;
                    color: $colorWhite;
                    font-weight: "Work Sans", serif;
                    text-transform: uppercase;
                    line-height: 1.1;
                    text-shadow:
                        2px 2px 5px #38b6ff,
                        4px 4px 10px #38b6ff,
                        1px 1px 0 #b71eff,
                        -1px -1px 0 #b71eff,
                        1px -1px 0 #b71eff,
                        -1px 1px 0 #b71eff;

                    .orange {
                        font-size: 4.5rem;
                        font-weight: $fontWeight2XL;
                        font-weight: "Work Sans", serif;
                        text-transform: uppercase;
                        line-height: 1.1;
                        color: $colorOrange;
                        text-shadow:
                            2px 2px 5px #292727,
                    }

                    .violet {
                        font-size: 4.5rem;
                        font-weight: $fontWeight2XL;
                        font-weight: "Work Sans", serif;
                        text-transform: uppercase;
                        line-height: 1.1;
                        color: $colorViolet;
                        text-shadow:
                            2px 2px 5px #292727,
                    }

                    .vertical {
                        display: flex;
                        flex-direction: column;
                        padding-right: 4px;
                        // font-size: 4.5rem;
                        // font-weight: $fontWeight2XL;
                        // border-radius: $borderRadiusXL;
                        // background-color: $colorWhite;
                        // color: $colorViolet;
                        // padding: 4px 8px;
                        // text-shadow:
                        //     0px 0px 0px #292727,
                        // border-right: 2px solid $colorWhite;

                        span {
                            font-size: 1.5rem;
                            font-weight: $fontWeight2XL;
                            font-weight: "Work Sans", serif;
                            text-transform: uppercase;
                            line-height: 1.2;
                            color: $colorYellow;
                            text-shadow:
                                2px 2px 5px #292727,
                        }
                    }

                }


                .affiliate-overview__landing-page--container-child-caption {
                    font-size: 1rem;
                    font-weight: $fontWeightL;
                    color: $colorWhite;
                    font-weight: "Work Sans", serif;
                    line-height: 1.3;
                    padding-top: 16px;
                    width: 90%;

                }

                .affiliate-overview__landing-page--container-child-button {
                    margin-top: 16px;
                    padding: 12px 16px;
                    border-radius: $borderRadiusFull;
                    background-color: $colorWhite;
                    width: fit-content;
                    height: fit-content;
                    cursor: pointer;

                    a {
                        font-size: 1rem;
                        font-weight: $fontWeight2XL;
                        color: $colorViolet;
                        font-weight: "Work Sans", serif;
                        text-transform: uppercase;
                        line-height: 0.9;
                        background: linear-gradient(45deg, $colorOrange, $colorViolet, $colorDarkViolet);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }

        .affiliate-overview__landing-page--circle {
            position: absolute;
            border-radius: $borderRadiusFull;
            // border: 1px solid #4158D0;
            // top: 0px;
            // right: 0px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            transition: all 1.5s ease;

            &.extra-small {
                width: 50px;
                height: 50px;
            }

            &.small {
                width: 200px;
                height: 200px;
            }

            &.medium {
                width: 400px;
                height: 400px;
            }

            &.large {
                width: 600px;
                height: 600px;
            }

            &.extra-large {
                width: 800px;
                height: 800px;
            }

            &.color-right-to-left {
                background-color: #4158D0;
                background: linear-gradient(90deg, #C584FD 20%, #db94ff 50%, #7B97FE 70%, #6CD4FE 100%);
            }

            &.color-top-to-bottom {
                background-color: #4158D0;
                background: linear-gradient(200deg, #C584FD 20%, #db94ff 50%, #7B97FE 70%, #6CD4FE 100%);
            }

            &.color-blur {
                background-color: rgba(223, 221, 221, 0.4);
            }

            &.color-white {
                background-color: $colorWhite;
            }

            &.scale-up {
                transform: scale(1.1);
            }
        }

        .affiliate-overview__landing-page--arrow-down {
            position: absolute;
            border-radius: $borderRadiusFull;
            // border: 1px solid #4158D0;
            // top: 0px;
            // right: 0px;
            bottom: 0;
            // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            transition: all 1.5s ease;
            span {
                font-size: 3rem;
                color: $colorWhite;
            }
            animation: scroll-down 5s infinite;
        }

        @keyframes scroll-down {
            0% {
                opacity: 1;
                transform: translateY(-30px);
            }

            // 50% {
            //     opacity: 1;
            //     transform: translateY(0px);
            // }

            100% {
                opacity: .1;
                transform: translateY(0px);
            }
        }

    }

    .affiliate-overview__guide {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $gapL;
        // background-color: black;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        padding: 16px;

        .affiliate-overview__guide--code {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 50%;
            // background-color: yellow;

            .affiliate-overview__guide--code-title {
                font-size: 3.5rem;
                font-weight: $fontWeight2XL;
                color: $colorWhite;
                font-weight: "Work Sans", serif;
                text-transform: uppercase;
                line-height: 0.9;
                text-shadow:
                    2px 2px 5px #b71eff,
                    4px 4px 10px #b71eff,
            }

            .affiliate-overview__guide--code-voucher {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: $gapL;
                padding: 16px;
                // background-color: $colorExtraLightGray;
                opacity: 1;
                transition: all 1.5s ease;

                &.hide {
                    display: none;
                    width: 0px;
                    height: 0px;
                    opacity: 0;
                }

                .affiliate-overview__guide--code-voucher-container {
                    // background-color: $colorExtraLightGray;
                    border-radius: $borderRadiusXL;
                    // border: 1px solid white;
                    padding: 8px;
                    transition: all 1.5s ease;
                    width: 50%;

                    &:hover {
                        transform: scale(1.05);
                    }

                    .affiliate-overview__guide--code-voucher-container-child {
                        display: flex;
                        position: relative;
                        flex-direction: column;
                        align-items: center;
                        background-color: $colorWhite;
                        border-radius: $borderRadiusL;
                        border: 6px solid $colorViolet;
                        margin-top: 32px;
                        padding: 8px;
                        width: 300px;
                        gap: $gapM;
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

                        .affiliate-overview__guide--code-voucher-container-child-tag-name {
                            position: absolute;
                            top: -20px;
                            left: -10px;
                            z-index: 1;

                            span {
                                font-family: "Work Sans", serif;
                                font-size: 1.4rem;
                                font-weight: $fontWeight2XL;
                                color: $colorWhite;
                            }
                        }

                        .affiliate-overview__guide--code-voucher-container-child-tag {
                            position: absolute;
                            top: -35px;
                            left: -22px;
                            width: 250px;
                            height: fit-content;
                        }

                        .affiliate-overview__guide--code-voucher-container-child-image {
                            width: 130px;
                            height: 130px;
                        }

                        .affiliate-overview__guide--code-voucher-container-child-benefit {
                            display: flex;
                            align-items: start;
                            gap: $gapXS;
                            width: 100%;
                            max-width: 1000px;

                            .affiliate-overview__guide--code-voucher-container-child-benefit-icon {
                                font-size: $textFontSize2XL;
                                font-weight: $fontWeightL;
                                color: $colorGreen;
                                padding-top: 3px;
                            }

                            .affiliate-overview__guide--code-voucher-container-child-benefit-label {
                                font-size: $textFontSize2XL;
                                font-weight: $fontWeightXL;
                                color: $colorBlack;
                                line-height: 1.3;
                            }
                        }

                        .affiliate-overview__guide--code-voucher-container-child-share {
                            display: flex;
                            align-items: center;
                            gap: $gapXS;
                            padding: 4px;
                            background-color: $colorWhite;
                            border-radius: $borderRadiusL;
                            // border: 2px solid $colorYellow;
                            box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
                            cursor: pointer;
                            transition: all 1.5s ease;

                            &:hover {
                                background-color: $colorViolet;

                                span {
                                    color: $colorWhite;
                                }
                            }

                            img {
                                height: 35px;
                                width: 35px;
                            }

                            span {
                                font-size: $textFontSizeXL;
                                font-weight: $fontWeight2XL;
                                text-transform: uppercase;
                                color: $colorViolet;
                            }
                        }
                    }
                }
            }
        }

        .affiliate-overview__guide--benefit {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 50%;
            gap: $gapL;
            // background-color: blue;

            .affiliate-overview__guide--benefit-step {
                display: flex;
                align-items: center;
                gap: $gapL;

                .affiliate-overview__guide--benefit-step-image {
                    width: 190px;
                    height: fit-content;
                    background-color: transparent;
                    border-radius: $borderRadiusXL;
                }

                .affiliate-overview__guide--benefit-step-title {
                    display: flex;
                    flex-direction: column;
                    gap: $gapM;
                    width: fit-content;

                    .affiliate-overview__guide--benefit-step-title-container {
                        display: flex;
                        gap: $gapL;
                        align-items: center;
                        width: 500px;


                        .affiliate-overview__guide--benefit-step-title-container-bullet-points {
                            width: 30px;
                            min-width: 30px;
                            max-width: 30px;
                            height: 30px;
                            min-height: 30px;
                            max-height: 30px;
                            animation: scaleAnimation 4s infinite;
                        }

                        @keyframes scaleAnimation {
                            0% {
                                transform: rotate(0deg);
                            }

                            50% {
                                transform: rotate(180deg);
                            }

                            100% {
                                transform: rotate(0deg);
                            }
                        }

                        span {
                            font-size: 1.4rem;
                            font-weight: $fontWeightXL;
                            color: $colorWhite;
                            line-height: 1.3;

                            .high-light {
                                color: $colorOrange
                            }
                        }
                    }

                }

                &.right-to-left {
                    flex-direction: row-reverse;
                }
            }

        }
    }

    .affiliate-overview__benefit {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $gapL;
        width: 100vw;
        height: 100vh;
        padding: 16px;
        position: relative;

        .affiliate-overview__benefit--tag {
            position: absolute;
            top: 0;
            left: 32px;
            opacity: 1;

            img {
                height: 130px;
                width: 130px;
            }
        }

        .affiliate-overview__benefit--wire {
            position: absolute;
            background-color: transparent;
            z-index: 1;
            opacity: 0.8;
            transform: rotate(10deg);

            img {
                height: 100vh;
                width: 100vw;
            }
        }

        .affiliate-overview__benefit--service-available {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // background-color: $colorRed;
            width: 45%;
            height: 100%;
            gap: $gapL;
            padding: 16px;

            .affiliate-overview__benefit--service-available-list {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: $gapL;

                .affiliate-overview__benefit--service-available-list-child {
                    width: 110px;
                    height: 110px;
                }
            }
        }

        .affiliate-overview__benefit--list {
            display: flex;
            flex-direction: column;
            // align-items: center;
            justify-content: center;
            // background-color: $colorYellow;
            width: 55%;
            height: 100%;
            gap: $gapL;
            padding: 16px;

            .affiliate-overview__benefit--list-title {
                font-size: 3.5rem;
                font-weight: $fontWeight2XL;
                color: $colorWhite;
                font-weight: "Work Sans", serif;
                text-transform: uppercase;
                line-height: 1;
                text-shadow:
                    2px 2px 5px #2206f3,
                    4px 4px 10px #2206f3,
            }

            .affiliate-overview__benefit--list-sub-title {
                font-size: 1rem;
                font-weight: $fontWeightXL;
                color: $colorWhite;
                font-weight: "Work Sans", serif;
                line-height: 1.5;
                width: 80%;
            }

            .affiliate-overview__benefit--list-child {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: $gapL;
                padding-top: 16px;


                .affiliate-overview__benefit--list-child-information {
                    display: flex;
                    align-items: center;
                    gap: $gapM;
                    width: 40%;

                    .affiliate-overview__benefit--list-child-information-icon {
                        display: flex;
                        padding: 8px;
                        align-items: center;
                        border-radius: $borderRadiusL;
                        background-color: $colorOrange;
                        width: 55px;
                        min-width: 55px;
                        height: 55px;
                        min-height: 55px;
                    }

                    .affiliate-overview__benefit--list-child-information-title {
                        font-size: 1.2rem;
                        font-weight: $fontWeightXL;
                        color: $colorWhite;
                        font-weight: "Work Sans", serif;
                        line-height: 1.5;
                    }
                }
            }
        }
    }

    .affiliate-overview__footer {
        display: flex;
        flex-direction: column;
        background-color: black;
        width: 100%;
        height: fit-content;

        .affiliate-overview__footer--information {
            display: flex;
            align-items: center;
            padding: 0px;

            .affiliate-overview__footer--information-item {
                width: 25%;
                height: fit-content;

                img {
                    width: 100px;
                    height: fit-content;
                }

                span {
                    font-size: $textFontSize2XL;
                    font-weight: $fontWeightXL;
                    color: $colorWhite;

                    .high-light {
                        color: $colorViolet
                    }
                }
            }
        }

        .affiliate-overview__footer--copy-right {
            display: flex;
            align-items: center;
            padding: 16px;
            gap: $gapL;
            border-top: 1px solid $colorWhite;

            .affiliate-overview__footer--copy-right-item {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30%;
                height: fit-content;

                img {
                    width: 150px;
                    height: fit-content;
                    cursor: pointer;
                }

                span {
                    font-size: $textFontSize2XL;
                    font-weight: $fontWeightXL;
                    color: $colorWhite;

                    .high-light {
                        color: $colorViolet
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1366px) {
    .affiliate-overview {

        html,
        body {
            --scroll-behavior: smooth !important;
            scroll-behavior: smooth !important;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // height: fit-content;
        height: 100%;
        width: 100%;
        background-color: #4158D0;
        background: linear-gradient(120deg, #C584FD 20%, #db94ff 50%, #7B97FE 70%, #6CD4FE 100%);
        overflow: hidden;
        scroll-behavior: smooth;


        .affiliate-overview__landing-page {
            display: flex;
            align-items: center;
            justify-content: center;
            // background-color: $colorBlack;
            width: 100vw;
            height: 100vh;
            position: relative;
            overflow: hidden;

            .affiliate-overview__landing-page--container {
                display: flex;
                background-color: transparent;
                width: 100%;
                height: 100%;
                gap: $gapL;


                .affiliate-overview__landing-page--container-child {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    &.left {
                        width: 60%;
                        height: 100%;
                        // background-color: blue;
                    }

                    &.right {
                        width: 40%;
                        height: 100%;
                        // background-color: red;
                    }

                    .affiliate-overview__landing-page--container-child-title {
                        font-size: 5rem;
                        font-weight: $fontWeight2XL;
                        color: $colorWhite;
                        font-weight: "Work Sans", serif;
                        text-transform: uppercase;
                        line-height: 0.9;
                        text-shadow:
                            2px 2px 5px #38b6ff,
                            4px 4px 10px #38b6ff,
                            1px 1px 0 #b71eff,
                            -1px -1px 0 #b71eff,
                            1px -1px 0 #b71eff,
                            -1px 1px 0 #b71eff;

                        .orange {
                            font-size: 5rem;
                            font-weight: $fontWeight2XL;
                            color: $colorWhite;
                            font-weight: "Work Sans", serif;
                            text-transform: uppercase;
                            line-height: 0.9;
                            color: $colorOrange;
                            text-shadow:
                                2px 2px 5px #292727,
                        }

                        .violet {
                            font-size: 5rem;
                            font-weight: $fontWeight2XL;
                            color: $colorWhite;
                            font-weight: "Work Sans", serif;
                            text-transform: uppercase;
                            line-height: 0.9;
                            color: $colorViolet;
                            text-shadow:
                                2px 2px 5px #292727,
                        }
                    }

                    .affiliate-overview__landing-page--container-child-caption {
                        font-size: 1rem;
                        font-weight: $fontWeightL;
                        color: $colorWhite;
                        font-weight: "Work Sans", serif;
                        line-height: 1.3;
                        padding-top: 16px;
                        width: 90%;

                    }

                    .affiliate-overview__landing-page--container-child-button {
                        margin-top: 16px;
                        padding: 12px 16px;
                        border-radius: $borderRadiusFull;
                        background-color: $colorWhite;
                        width: fit-content;
                        height: fit-content;
                        cursor: pointer;

                        a {
                            font-size: 1rem;
                            font-weight: $fontWeight2XL;
                            color: $colorViolet;
                            font-weight: "Work Sans", serif;
                            text-transform: uppercase;
                            line-height: 0.9;
                            background: linear-gradient(45deg, $colorOrange, $colorViolet, $colorDarkViolet);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                }
            }

            .affiliate-overview__landing-page--circle {
                position: absolute;
                border-radius: $borderRadiusFull;
                // border: 1px solid #4158D0;
                // top: 0px;
                // right: 0px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                transition: all 1.5s ease;

                &.extra-small {
                    width: 50px;
                    height: 50px;
                }

                &.small {
                    width: 200px;
                    height: 200px;
                }

                &.medium {
                    width: 400px;
                    height: 400px;
                }

                &.large {
                    width: 600px;
                    height: 600px;
                }

                &.extra-large {
                    width: 800px;
                    height: 800px;
                }

                &.color-right-to-left {
                    background-color: #4158D0;
                    background: linear-gradient(90deg, #C584FD 20%, #db94ff 50%, #7B97FE 70%, #6CD4FE 100%);
                }

                &.color-top-to-bottom {
                    background-color: #4158D0;
                    background: linear-gradient(200deg, #C584FD 20%, #db94ff 50%, #7B97FE 70%, #6CD4FE 100%);
                }

                &.color-blur {
                    background-color: rgba(223, 221, 221, 0.4);
                }

                &.color-white {
                    background-color: $colorWhite;
                }

                &.scale-up {
                    transform: scale(1.1);
                }
            }
        }

        .affiliate-overview__guide {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: $gapL;
            // background-color: black;
            width: 100vw;
            height: 95vh;
            overflow: hidden;
            padding: 16px;

            .affiliate-overview__guide--code {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 50%;
                // background-color: yellow;

                .affiliate-overview__guide--code-title {
                    font-size: 3rem;
                    font-weight: $fontWeight2XL;
                    color: $colorWhite;
                    font-weight: "Work Sans", serif;
                    text-transform: uppercase;
                    line-height: 0.9;
                    text-shadow:
                        2px 2px 5px #b71eff,
                        4px 4px 10px #b71eff,
                }

                .affiliate-overview__guide--code-voucher {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: $gapL;
                    padding: 16px;
                    // background-color: $colorExtraLightGray;
                    opacity: 1;
                    transition: all 1.5s ease;

                    &.hide {
                        display: none;
                        width: 0px;
                        height: 0px;
                        opacity: 0;
                    }

                    .affiliate-overview__guide--code-voucher-container {
                        // background-color: $colorExtraLightGray;
                        border-radius: $borderRadiusXL;
                        // border: 1px solid white;
                        padding: 8px;
                        transition: all 1.5s ease;
                        width: 50%;

                        &:hover {
                            transform: scale(1.05);
                        }

                        .affiliate-overview__guide--code-voucher-container-child {
                            display: flex;
                            position: relative;
                            flex-direction: column;
                            align-items: center;
                            background-color: $colorWhite;
                            border-radius: $borderRadiusL;
                            border: 6px solid $colorViolet;
                            margin-top: 32px;
                            padding: 8px;
                            width: 250px;
                            gap: $gapM;
                            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

                            .affiliate-overview__guide--code-voucher-container-child-tag-name {
                                position: absolute;
                                top: -20px;
                                left: -10px;
                                z-index: 1;

                                span {
                                    font-family: "Work Sans", serif;
                                    font-size: 1.4rem;
                                    font-weight: $fontWeight2XL;
                                    color: $colorWhite;
                                }
                            }

                            .affiliate-overview__guide--code-voucher-container-child-tag {
                                position: absolute;
                                top: -35px;
                                left: -22px;
                                width: 250px;
                                height: fit-content;
                            }

                            .affiliate-overview__guide--code-voucher-container-child-image {
                                width: 130px;
                                height: fit-content;
                            }

                            .affiliate-overview__guide--code-voucher-container-child-benefit {
                                display: flex;
                                align-items: start;
                                gap: $gapXS;
                                width: 100%;
                                max-width: 1000px;

                                .affiliate-overview__guide--code-voucher-container-child-benefit-icon {
                                    font-size: $textFontSize2XL;
                                    font-weight: $fontWeightL;
                                    color: $colorGreen;
                                    padding-top: 3px;
                                }

                                .affiliate-overview__guide--code-voucher-container-child-benefit-label {
                                    font-size: $textFontSize2XL;
                                    font-weight: $fontWeightXL;
                                    color: $colorBlack;
                                    line-height: 1.3;
                                }
                            }

                            .affiliate-overview__guide--code-voucher-container-child-share {
                                display: flex;
                                align-items: center;
                                gap: $gapXS;
                                padding: 4px;
                                background-color: $colorWhite;
                                border-radius: $borderRadiusL;
                                // border: 2px solid $colorYellow;
                                box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
                                cursor: pointer;
                                transition: all 1.5s ease;

                                &:hover {
                                    background-color: $colorViolet;

                                    span {
                                        color: $colorWhite;
                                    }
                                }

                                img {
                                    height: 35px;
                                    width: 35px;
                                }

                                span {
                                    font-size: $textFontSizeXL;
                                    font-weight: $fontWeight2XL;
                                    text-transform: uppercase;
                                    color: $colorViolet;
                                }
                            }
                        }
                    }
                }
            }

            .affiliate-overview__guide--benefit {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 50%;
                gap: $gapL;
                // background-color: blue;

                .affiliate-overview__guide--benefit-step {
                    display: flex;
                    align-items: center;
                    gap: $gapL;

                    .affiliate-overview__guide--benefit-step-image {
                        width: 150px;
                        height: fit-content;
                        background-color: transparent;
                        border-radius: $borderRadiusXL;
                    }

                    .affiliate-overview__guide--benefit-step-title {
                        display: flex;
                        flex-direction: column;
                        gap: $gapM;
                        width: fit-content;

                        .affiliate-overview__guide--benefit-step-title-container {
                            display: flex;
                            gap: $gapL;
                            align-items: center;
                            width: 450px;


                            .affiliate-overview__guide--benefit-step-title-container-bullet-points {
                                width: 30px;
                                min-width: 30px;
                                max-width: 30px;
                                height: 30px;
                                min-height: 30px;
                                max-height: 30px;
                                animation: scaleAnimation 4s infinite;
                            }

                            @keyframes scaleAnimation {
                                0% {
                                    transform: rotate(0deg);
                                }

                                50% {
                                    transform: rotate(180deg);
                                }

                                100% {
                                    transform: rotate(0deg);
                                }
                            }

                            span {
                                font-size: 1.2rem;
                                font-weight: $fontWeightXL;
                                color: $colorWhite;
                                line-height: 1.4;

                                .high-light {
                                    color: $colorOrange
                                }
                            }
                        }

                    }

                    &.right-to-left {
                        flex-direction: row-reverse;
                    }
                }

            }
        }

        .affiliate-overview__benefit {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: $gapL;
            width: 100vw;
            height: 95vh;
            padding: 16px;

            .affiliate-overview__benefit--service-available {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                // background-color: $colorRed;
                width: 50%;
                height: 100%;
                gap: $gapL;

                .affiliate-overview__benefit--service-available-list {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: $gapL;

                    .affiliate-overview__benefit--service-available-list-child {
                        width: 100px;
                        height: 100px;
                    }
                }
            }


            .affiliate-overview__benefit--list {
                display: flex;
                flex-direction: column;
                // align-items: center;
                justify-content: center;
                // background-color: $colorYellow;
                width: 50%;
                height: 100%;
                gap: $gapL;

                .affiliate-overview__benefit--list-title {
                    font-size: 3rem;
                    font-weight: $fontWeight2XL;
                    color: $colorWhite;
                    font-weight: "Work Sans", serif;
                    text-transform: uppercase;
                    line-height: 1;
                    text-shadow:
                        2px 2px 5px #2206f3,
                        4px 4px 10px #2206f3,
                }

                .affiliate-overview__benefit--list-sub-title {
                    font-size: 1rem;
                    font-weight: $fontWeightXL;
                    color: $colorGray;
                    font-weight: "Work Sans", serif;
                    line-height: 1.5;
                }

                .affiliate-overview__benefit--list-child {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: $gapL;
                    padding-top: 16px;


                    .affiliate-overview__benefit--list-child-information {
                        display: flex;
                        align-items: center;
                        gap: $gapM;
                        width: 40%;

                        .affiliate-overview__benefit--list-child-information-icon {
                            display: flex;
                            padding: 8px;
                            align-items: center;
                            border-radius: $borderRadiusL;
                            background-color: $colorOrange;
                            width: 50px;
                            min-width: 50px;
                            height: 50px;
                            min-height: 50px;
                        }

                        .affiliate-overview__benefit--list-child-information-title {
                            font-size: 1rem;
                            font-weight: $fontWeightXL;
                            color: $colorWhite;
                            font-weight: "Work Sans", serif;
                            line-height: 1.5;
                        }
                    }
                }
            }
        }

        .affiliate-overview__footer {
            display: flex;
            flex-direction: column;
            background-color: black;
            width: 100%;
            height: fit-content;

            .affiliate-overview__footer--information {
                display: flex;
                align-items: center;
                padding: 0px;

                .affiliate-overview__footer--information-item {
                    width: 25%;
                    height: fit-content;

                    img {
                        width: 100px;
                        height: fit-content;
                    }

                    span {
                        font-size: $textFontSize2XL;
                        font-weight: $fontWeightXL;
                        color: $colorWhite;

                        .high-light {
                            color: $colorViolet
                        }
                    }
                }
            }

            .affiliate-overview__footer--copy-right {
                display: flex;
                align-items: center;
                padding: 16px;
                gap: $gapL;
                border-top: 1px solid $colorWhite;

                .affiliate-overview__footer--copy-right-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30%;
                    height: fit-content;

                    img {
                        width: 150px;
                        height: fit-content;
                    }

                    span {
                        font-size: $textFontSize2XL;
                        font-weight: $fontWeightXL;
                        color: $colorWhite;

                        .high-light {
                            color: $colorViolet
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .affiliate-overview {

        html,
        body {
            --scroll-behavior: smooth !important;
            scroll-behavior: smooth !important;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // height: fit-content;
        height: 100%;
        width: 100%;
        background-color: #4158D0;
        background: linear-gradient(120deg, #C584FD 20%, #db94ff 50%, #7B97FE 70%, #6CD4FE 100%);
        overflow: hidden;
        scroll-behavior: smooth;

        .affiliate-overview__landing-page {
            display: flex;
            align-items: center;
            justify-content: center;
            // background-color: $colorBlack;
            width: 100vw;
            height: 100vh;
            position: relative;
            overflow: hidden;

            .affiliate-overview__landing-page--container {
                display: flex;
                background-color: transparent;
                width: 100%;
                height: 100%;
                gap: $gapL;


                .affiliate-overview__landing-page--container-child {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    &.left {
                        width: 60%;
                        height: 100%;
                        // background-color: blue;
                    }

                    &.right {
                        width: 40%;
                        height: 100%;
                        // background-color: red;
                    }

                    .affiliate-overview__landing-page--container-child-title {
                        font-size: 5rem;
                        font-weight: $fontWeight2XL;
                        color: $colorWhite;
                        font-weight: "Work Sans", serif;
                        text-transform: uppercase;
                        line-height: 0.9;
                        text-shadow:
                            2px 2px 5px #38b6ff,
                            4px 4px 10px #38b6ff,
                            1px 1px 0 #b71eff,
                            -1px -1px 0 #b71eff,
                            1px -1px 0 #b71eff,
                            -1px 1px 0 #b71eff;

                        .orange {
                            font-size: 5rem;
                            font-weight: $fontWeight2XL;
                            color: $colorWhite;
                            font-weight: "Work Sans", serif;
                            text-transform: uppercase;
                            line-height: 0.9;
                            color: $colorOrange;
                            text-shadow:
                                2px 2px 5px #292727,
                        }

                        .violet {
                            font-size: 5rem;
                            font-weight: $fontWeight2XL;
                            color: $colorWhite;
                            font-weight: "Work Sans", serif;
                            text-transform: uppercase;
                            line-height: 0.9;
                            color: $colorViolet;
                            text-shadow:
                                2px 2px 5px #292727,
                        }
                    }

                    .affiliate-overview__landing-page--container-child-caption {
                        font-size: 1rem;
                        font-weight: $fontWeightL;
                        color: $colorWhite;
                        font-weight: "Work Sans", serif;
                        line-height: 1.3;
                        padding-top: 16px;
                        width: 90%;

                    }

                    .affiliate-overview__landing-page--container-child-button {
                        margin-top: 16px;
                        padding: 12px 16px;
                        border-radius: $borderRadiusFull;
                        background-color: $colorWhite;
                        width: fit-content;
                        height: fit-content;
                        cursor: pointer;

                        a {
                            font-size: 1rem;
                            font-weight: $fontWeight2XL;
                            color: $colorViolet;
                            font-weight: "Work Sans", serif;
                            text-transform: uppercase;
                            line-height: 0.9;
                            background: linear-gradient(45deg, $colorOrange, $colorViolet, $colorDarkViolet);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                }
            }

            .affiliate-overview__landing-page--circle {
                position: absolute;
                border-radius: $borderRadiusFull;
                // border: 1px solid #4158D0;
                // top: 0px;
                // right: 0px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                transition: all 1.5s ease;

                &.extra-small {
                    width: 50px;
                    height: 50px;
                }

                &.small {
                    width: 200px;
                    height: 200px;
                }

                &.medium {
                    width: 400px;
                    height: 400px;
                }

                &.large {
                    width: 600px;
                    height: 600px;
                }

                &.extra-large {
                    width: 800px;
                    height: 800px;
                }

                &.color-right-to-left {
                    background-color: #4158D0;
                    background: linear-gradient(90deg, #C584FD 20%, #db94ff 50%, #7B97FE 70%, #6CD4FE 100%);
                }

                &.color-top-to-bottom {
                    background-color: #4158D0;
                    background: linear-gradient(200deg, #C584FD 20%, #db94ff 50%, #7B97FE 70%, #6CD4FE 100%);
                }

                &.color-blur {
                    background-color: rgba(223, 221, 221, 0.4);
                }

                &.color-white {
                    background-color: $colorWhite;
                }

                &.scale-up {
                    transform: scale(1.1);
                }
            }
        }

        .affiliate-overview__guide {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: $gapL;
            // background-color: black;
            width: 100vw;
            height: 85vh;
            overflow: hidden;
            padding: 16px;

            .affiliate-overview__guide--code {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 50%;
                // background-color: yellow;

                .affiliate-overview__guide--code-title {
                    font-size: 3rem;
                    font-weight: $fontWeight2XL;
                    color: $colorWhite;
                    font-weight: "Work Sans", serif;
                    text-transform: uppercase;
                    line-height: 0.9;
                    text-shadow:
                        2px 2px 5px #b71eff,
                        4px 4px 10px #b71eff,
                }

                .affiliate-overview__guide--code-voucher {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: $gapL;
                    padding: 16px;
                    // background-color: $colorExtraLightGray;
                    opacity: 1;
                    transition: all 1.5s ease;

                    &.hide {
                        display: none;
                        width: 0px;
                        height: 0px;
                        opacity: 0;
                    }

                    .affiliate-overview__guide--code-voucher-container {
                        // background-color: $colorExtraLightGray;
                        border-radius: $borderRadiusXL;
                        // border: 1px solid white;
                        padding: 8px;
                        transition: all 1.5s ease;
                        width: 50%;

                        &:hover {
                            transform: scale(1.05);
                        }

                        .affiliate-overview__guide--code-voucher-container-child {
                            display: flex;
                            position: relative;
                            flex-direction: column;
                            align-items: center;
                            background-color: $colorWhite;
                            border-radius: $borderRadiusL;
                            border: 6px solid $colorViolet;
                            margin-top: 32px;
                            padding: 8px;
                            width: 250px;
                            gap: $gapM;
                            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

                            .affiliate-overview__guide--code-voucher-container-child-tag-name {
                                position: absolute;
                                top: -20px;
                                left: -10px;
                                z-index: 1;

                                span {
                                    font-family: "Work Sans", serif;
                                    font-size: 1.4rem;
                                    font-weight: $fontWeight2XL;
                                    color: $colorWhite;
                                }
                            }

                            .affiliate-overview__guide--code-voucher-container-child-tag {
                                position: absolute;
                                top: -35px;
                                left: -22px;
                                width: 250px;
                                height: fit-content;
                            }

                            .affiliate-overview__guide--code-voucher-container-child-image {
                                width: 130px;
                                height: fit-content;
                            }

                            .affiliate-overview__guide--code-voucher-container-child-benefit {
                                display: flex;
                                align-items: start;
                                gap: $gapXS;
                                width: 100%;
                                max-width: 1000px;

                                .affiliate-overview__guide--code-voucher-container-child-benefit-icon {
                                    font-size: $textFontSize2XL;
                                    font-weight: $fontWeightL;
                                    color: $colorGreen;
                                    padding-top: 3px;
                                }

                                .affiliate-overview__guide--code-voucher-container-child-benefit-label {
                                    font-size: $textFontSize2XL;
                                    font-weight: $fontWeightXL;
                                    color: $colorBlack;
                                    line-height: 1.3;
                                }
                            }

                            .affiliate-overview__guide--code-voucher-container-child-share {
                                display: flex;
                                align-items: center;
                                gap: $gapXS;
                                padding: 4px;
                                background-color: $colorWhite;
                                border-radius: $borderRadiusL;
                                // border: 2px solid $colorYellow;
                                box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
                                cursor: pointer;
                                transition: all 1.5s ease;

                                &:hover {
                                    background-color: $colorViolet;

                                    span {
                                        color: $colorWhite;
                                    }
                                }

                                img {
                                    height: 35px;
                                    width: 35px;
                                }

                                span {
                                    font-size: $textFontSizeXL;
                                    font-weight: $fontWeight2XL;
                                    text-transform: uppercase;
                                    color: $colorViolet;
                                }
                            }
                        }
                    }
                }
            }

            .affiliate-overview__guide--benefit {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 50%;
                gap: $gapL;
                // background-color: blue;

                .affiliate-overview__guide--benefit-step {
                    display: flex;
                    align-items: center;
                    gap: $gapL;

                    .affiliate-overview__guide--benefit-step-image {
                        width: 150px;
                        height: fit-content;
                        background-color: transparent;
                        border-radius: $borderRadiusXL;
                    }

                    .affiliate-overview__guide--benefit-step-title {
                        display: flex;
                        flex-direction: column;
                        gap: $gapM;
                        width: fit-content;

                        .affiliate-overview__guide--benefit-step-title-container {
                            display: flex;
                            gap: $gapL;
                            align-items: center;
                            width: 400px;


                            .affiliate-overview__guide--benefit-step-title-container-bullet-points {
                                width: 30px;
                                min-width: 30px;
                                max-width: 30px;
                                height: 30px;
                                min-height: 30px;
                                max-height: 30px;
                                animation: scaleAnimation 4s infinite;
                            }

                            @keyframes scaleAnimation {
                                0% {
                                    transform: rotate(0deg);
                                }

                                50% {
                                    transform: rotate(180deg);
                                }

                                100% {
                                    transform: rotate(0deg);
                                }
                            }

                            span {
                                font-size: 1.2rem;
                                font-weight: $fontWeightXL;
                                color: $colorWhite;
                                line-height: 1.4;

                                .high-light {
                                    color: $colorOrange
                                }
                            }
                        }

                    }

                    &.right-to-left {
                        flex-direction: row-reverse;
                    }
                }

            }
        }

        .affiliate-overview__benefit {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: $gapL;
            width: 100vw;
            height: 85vh;
            padding: 16px;

            .affiliate-overview__benefit--service-available {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                // background-color: $colorRed;
                width: 50%;
                height: 100%;
                gap: $gapL;

                .affiliate-overview__benefit--service-available-list {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: $gapL;

                    .affiliate-overview__benefit--service-available-list-child {
                        width: 100px;
                        height: 100px;
                    }
                }
            }


            .affiliate-overview__benefit--list {
                display: flex;
                flex-direction: column;
                // align-items: center;
                justify-content: center;
                // background-color: $colorYellow;
                width: 50%;
                height: 100%;
                gap: $gapL;

                .affiliate-overview__benefit--list-title {
                    font-size: 3rem;
                    font-weight: $fontWeight2XL;
                    color: $colorWhite;
                    font-weight: "Work Sans", serif;
                    text-transform: uppercase;
                    line-height: 1;
                    text-shadow:
                        2px 2px 5px #2206f3,
                        4px 4px 10px #2206f3,
                }

                .affiliate-overview__benefit--list-sub-title {
                    font-size: 1rem;
                    font-weight: $fontWeightXL;
                    color: $colorGray;
                    font-weight: "Work Sans", serif;
                    line-height: 1.5;
                }

                .affiliate-overview__benefit--list-child {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: $gapL;
                    padding-top: 16px;


                    .affiliate-overview__benefit--list-child-information {
                        display: flex;
                        align-items: center;
                        gap: $gapM;
                        width: 40%;

                        .affiliate-overview__benefit--list-child-information-icon {
                            display: flex;
                            padding: 8px;
                            align-items: center;
                            border-radius: $borderRadiusL;
                            background-color: $colorOrange;
                            width: 50px;
                            min-width: 50px;
                            height: 50px;
                            min-height: 50px;
                        }

                        .affiliate-overview__benefit--list-child-information-title {
                            font-size: 1rem;
                            font-weight: $fontWeightXL;
                            color: $colorWhite;
                            font-weight: "Work Sans", serif;
                            line-height: 1.5;
                        }
                    }
                }
            }
        }

        .affiliate-overview__footer {
            display: flex;
            flex-direction: column;
            background-color: black;
            width: 100%;
            height: fit-content;

            .affiliate-overview__footer--information {
                display: flex;
                align-items: center;
                padding: 0px;

                .affiliate-overview__footer--information-item {
                    width: 25%;
                    height: fit-content;

                    img {
                        width: 100px;
                        height: fit-content;
                    }

                    span {
                        font-size: $textFontSize2XL;
                        font-weight: $fontWeightXL;
                        color: $colorWhite;

                        .high-light {
                            color: $colorViolet
                        }
                    }
                }
            }

            .affiliate-overview__footer--copy-right {
                display: flex;
                align-items: center;
                padding: 16px;
                gap: $gapL;
                border-top: 1px solid $colorWhite;

                .affiliate-overview__footer--copy-right-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30%;
                    height: fit-content;

                    img {
                        width: 150px;
                        height: fit-content;
                    }

                    span {
                        font-size: $textFontSize2XL;
                        font-weight: $fontWeightXL;
                        color: $colorWhite;

                        .high-light {
                            color: $colorViolet
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .affiliate-overview {
        height: 100vh;

        .affiliate-overview__introduction {
            width: 100%;
            height: 100%;
            opacity: 1;
            z-index: 1;

            .affiliate-overview__introduction--header {

                .affiliate-overview__introduction--header-information {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: $gapXS;
                    padding: 12px;

                    .affiliate-overview__introduction--header-information-personal {
                        display: flex;
                        flex-direction: column;
                        width: 60%;
                        height: fit-content;
                        gap: $gapXS;

                        .affiliate-overview__introduction--header-information-personal-label {
                            font-size: $textFontSize4XL;
                            font-weight: $fontWeight2XL;
                            color: #3E00BA;
                        }

                        .affiliate-overview__introduction--header-information-personal-sub-label {
                            font-size: $textFontSize2XL;
                            font-weight: $fontWeightXL;
                            color: #3E00BA;
                        }
                    }

                    .affiliate-overview__introduction--header-information-assistant {
                        width: 40%;
                        height: fit-content;
                    }
                }
            }

            .affiliate-overview__introduction--phone {
                position: absolute;
                bottom: 35%;
                left: 50%;
                z-index: 1;
                height: 500px;
                width: 500px;
                transform: translateX(-50%) translateY(40%) rotate(-5deg);
                transition: all 1.5s ease;

                &.rotate {
                    transform: translateX(-50%) translateY(40%) rotate(-15deg) scale(1.15);
                }
            }

            .affiliate-overview__introduction--blank {
                align-items: center;

                .affiliate-overview__introduction--blank-header {

                    .affiliate-overview__introduction--blank-header-text-border {
                        text-shadow:
                            1px 1px 0 #b71eff,
                            -1px -1px 0 #b71eff,
                            1px -1px 0 #b71eff,
                            -1px 1px 0 #b71eff;
                        font-size: $textFontSize4XL;
                        font-weight: $fontWeight2XL;
                        font-family: "Work Sans", serif;
                        color: $colorWhite;
                        text-transform: uppercase;
                    }

                    .affiliate-overview__introduction--blank-header-text-neon {
                        font-size: $textFontSize4XL;
                        font-weight: $fontWeight2XL;
                        font-family: "Work Sans", serif;
                        color: $colorWhite;
                        text-transform: uppercase;
                        text-shadow:
                            2px 2px 5px #38b6ff,
                            4px 4px 10px #38b6ff;
                    }

                }

                .affiliate-overview__introduction--blank-label {

                    span {
                        font-size: $textFontSize2XL;
                        font-weight: $fontWeightXL;
                        color: $colorBlack;
                    }
                }

                .affiliate-overview__introduction--blank-button {
                    padding: 10px;
                    width: 90%;

                    span {
                        font-size: $textFontSize2XL;
                        font-weight: $fontWeightXL;
                        color: $colorWhite;
                    }
                }

                .affiliate-overview__introduction--blank-child {
                    gap: $gapL;

                    .affiliate-overview__introduction--blank-child-container {
                        padding: 12px;

                        &:hover {
                            transform: scale(1.1);
                        }

                        .affiliate-overview__introduction--blank-child-promotion {
                            padding: 16px;
                            gap: $gapS;

                            .affiliate-overview__introduction--blank-child-promotion-tag-name {
                                position: absolute;
                                top: -18px;
                                left: -4px;
                                z-index: 1;

                                span {
                                    font-family: "Work Sans", serif;
                                    font-size: $textFontSize2XL;
                                    font-weight: $fontWeight2XL;
                                    color: $colorWhite;
                                }
                            }

                            .affiliate-overview__introduction--blank-child-promotion-tag {
                                position: absolute;
                                top: -28px;
                                left: -17px;
                                width: 200px;
                                height: fit-content;
                            }

                            .affiliate-overview__introduction--blank-child-promotion-image {
                                width: 120px;
                                height: 120px;
                            }

                            .affiliate-overview__introduction--blank-child-promotion-share {

                                img {
                                    height: 30px;
                                    width: 30px;
                                }

                                span {
                                    font-size: $textFontSize2XL;
                                    font-weight: $fontWeight2XL;
                                    text-transform: uppercase;
                                    color: $colorViolet;
                                    font-family: "Work Sans", serif;
                                }
                            }

                            .affiliate-overview__introduction--blank-child-promotion-benefit {

                                .affiliate-overview__introduction--blank-child-promotion-benefit-icon {
                                    font-size: $textFontSize3XL;
                                    font-weight: $fontWeightL;
                                    color: $colorGreen;
                                    padding-top: 0px;
                                }

                                .affiliate-overview__introduction--blank-child-promotion-benefit-label {
                                    font-size: $textFontSizeXL;
                                    font-weight: $fontWeightXL;
                                    color: $colorBlack;
                                }
                            }
                        }
                    }
                }

                .affiliate-overview__introduction--blank-guide {
                    width: 90%;

                    .affiliate-overview__introduction--blank-guide-container {
                        padding: 2px;

                        .affiliate-overview__introduction--blank-guide-container-tag-label {

                            span {
                                font-family: "Work Sans", serif;
                                font-size: $textFontSize2XL;
                                font-weight: $fontWeight2XL;
                                color: $colorWhite;
                                text-transform: uppercase;
                            }
                        }

                        .affiliate-overview__introduction--blank-guide-container-tag {
                            position: absolute;
                            top: -33px;
                            left: -20px;
                            width: 250px;
                            height: fit-content;
                        }

                        .affiliate-overview__introduction--blank-guide-container-tag {}

                        .affiliate-overview__introduction--blank-guide-container-child {

                            .affiliate-overview__introduction--blank-guide-container-child-left {

                                .affiliate-overview__introduction--blank-guide-container-child-left-content {

                                    .affiliate-overview__introduction--blank-guide-container-child-left-content-bullet-points {
                                        width: 20px;
                                        max-width: 20px;
                                        min-width: 20px;
                                        height: fit-content;
                                        max-height: fit-content;
                                        min-height: fit-content;
                                    }


                                    .affiliate-overview__introduction--blank-guide-container-child-left-content-label {

                                        span {
                                            font-size: $textFontSize3XL;
                                            font-weight: $fontWeightXL;
                                            color: $colorViolet;
                                            line-height: 1.4;
                                        }
                                    }
                                }

                            }

                            // Bên phải
                            .affiliate-overview__introduction--blank-guide-container-child-right {
                                padding: 2px;
                                height: fit-content;
                                width: 30%;

                                .affiliate-overview__introduction--blank-guide-container-child-right-container {

                                    .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion {
                                        border: 3px solid $colorViolet;
                                        padding: 4px;

                                        .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-tag-name {
                                            position: absolute;
                                            top: -15px;
                                            left: -2px;
                                            z-index: 1;

                                            span {
                                                font-family: "Work Sans", serif;
                                                font-size: $textFontSizeL;
                                                font-weight: $fontWeight2XL;
                                                color: $colorWhite;
                                            }
                                        }

                                        .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-tag {
                                            position: absolute;
                                            top: -15px;
                                            left: -8px;
                                            width: 130px;
                                            height: fit-content;
                                        }

                                        .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-image {
                                            width: 50px;
                                            height: 50px;
                                        }

                                        .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-benefit {
                                            display: flex;
                                            align-items: start;
                                            gap: $gapXS;
                                            width: 100%;
                                            max-width: 200px;
                                            padding: 2px;

                                            .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-benefit-icon {
                                                font-size: $textFontSizeM;
                                                font-weight: $fontWeightXL;
                                                color: $colorGreen;
                                            }

                                            .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-benefit-label {
                                                font-size: $textFontSizeM;
                                                font-weight: $fontWeightXL;
                                                color: $colorBlack;
                                                line-height: 1;
                                            }
                                        }

                                        .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-share {
                                            padding: 4px 2px;
                                            border-radius: $borderRadiusS;

                                            img {
                                                height: 20px;
                                                width: 20px;
                                            }

                                            span {
                                                font-size: $textFontSizeL;
                                                font-weight: $fontWeight2XL;
                                                line-height: 1;
                                                font-family: "Work Sans", serif;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .affiliate-overview__introduction--wave {
                height: 30%;
            }

            .affiliate-overview__introduction--benefit {

                .affiliate-overview__introduction--benefit-header-list {
                    width: 95%;
                    height: 64vh;

                    .affiliate-overview__introduction--benefit-header-list-container {

                        .affiliate-overview__introduction--benefit-header-list-content {

                            .affiliate-overview__introduction--benefit-header-list-content-child {
                                width: 85%;

                                .affiliate-overview__introduction--benefit-header-list-content-child-header {

                                    .affiliate-overview__introduction--benefit-header-list-content-child-header-text {
                                        font-size: $textFontSize4XL;
                                    }
                                }

                                .affiliate-overview__introduction--benefit-header-list-content-child-content {

                                    span {
                                        font-size: $textFontSize2XL;
                                        font-weight: $fontWeightXL;
                                    }
                                }
                            }

                            .affiliate-overview__introduction--benefit-header-list-content-icon {
                                display: flex;
                                background-color: #9758f4;
                                width: 15%;
                                height: 100px;
                                border-radius: $borderRadiusXL;
                                padding: 4px;
                            }
                        }
                    }

                }
            }

            .affiliate-overview__introduction--arrow {
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 1;
                z-index: 1;
                transition: all 1.5s ease;
                padding: 12px;

                .affiliate-overview__introduction--arrow-icon {
                    padding: 12px;
                    background: linear-gradient(to right, #DEDCFC 0%, #B4ABFE 50%, #CF91FC 100%);
                    rotate: 90deg;
                    opacity: 0.7;
                    cursor: pointer;
                    color: $colorBlack;
                    border-radius: $borderRadiusFull;
                    transition: all 1.5s ease;
                    background-size: 120% 120%;
                    background-position: left center;

                    &:hover {
                        background-position: right center;
                        opacity: 1;
                    }
                }

                &.hide {
                    height: 0px;
                    width: 0px;
                    opacity: 0;
                    z-index: -1;
                }
            }

            .affiliate-overview__introduction--footer {

                .affiliate-overview__introduction--footer-bottom {

                    .affiliate-overview__introduction--footer-bottom-modal {

                        .affiliate-overview__introduction--footer-bottom-modal-information {
                            height: 25vh;

                            .affiliate-overview__introduction--footer-bottom-modal-information-title {
                                font-size: $textFontSize4XL;
                                font-weight: $fontWeight2XL;
                                color: #CF91FC;
                                font-family: "Work Sans", serif;
                                text-align: center;
                                padding-top: 12px;
                            }

                            .affiliate-overview__introduction--footer-bottom-modal-information-bullets-point {
                                font-size: $textFontSize4XL;
                                font-weight: $fontWeightL;
                                color: #CF91FC;
                                font-family: "Work Sans", serif;
                                padding: 0px 12px 0px 12px;

                                .high-light {
                                    font-size: $textFontSize4XL;
                                    font-weight: $fontWeight2XL;
                                    color: #CF91FC;
                                    font-family: "Work Sans", serif;
                                }


                            }

                            .affiliate-overview__introduction--footer-bottom-modal-information-social {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: $gapL;

                                .affiliate-overview__introduction--footer-bottom-modal-information-social-media {
                                    width: fit-content;
                                    height: fit-content;
                                    padding: 12px;
                                    border-radius: $borderRadiusFull;
                                    background-color: $colorWhite;
                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                    color: $colorWhite;

                                    &.facebook {
                                        background-color: $colorBlue;
                                    }

                                    &.youtube {
                                        background-color: $colorRed;
                                    }

                                    &.fanpage {
                                        background-color: $colorViolet;
                                    }
                                }

                            }

                            .affiliate-overview__introduction--footer-bottom-modal-information-copyright {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 12px;
                                border-top: 1px solid $colorMoreLightGray;
                                text-align: center;

                                span {
                                    font-size: $textFontSize4XL;
                                    font-weight: $fontWeightXL;
                                    color: $colorLightGray;
                                    font-family: "Work Sans", serif;
                                }
                            }
                        }
                    }

                }

                .affiliate-overview__introduction--footer-header {

                    .affiliate-overview__introduction--footer-header-label {
                        font-size: $textFontSize4XL;
                    }
                }

                .affiliate-overview__introduction--footer-content {
                    width: 95%;

                    .affiliate-overview__introduction--footer-content-list-service {

                        .affiliate-overview__introduction--footer-content-list-service-child {
                            height: 120px;
                            width: 120px;
                        }

                    }

                }
            }
        }

        .affiliate-overview__navigate {
            height: 0px;
            width: 0px;
            opacity: 0;
            z-index: -1;
        }

        .affiliate-overview__landing-page {
            height: 0px;
            width: 0px;
            opacity: 0;
            z-index: -1;
        }

        .affiliate-overview__guide {
            height: 0px;
            width: 0px;
            opacity: 0;
            z-index: -1;
        }

        .affiliate-overview__benefit {
            height: 0px;
            width: 0px;
            opacity: 0;
            z-index: -1;
        }

        .affiliate-overview__footer {
            height: 0px;
            width: 0px;
            opacity: 0;
            z-index: -1;
        }

    }
}

@media screen and (max-width: 480px) {
    .affiliate-overview {

        .affiliate-overview__introduction {
            width: 100%;
            height: 100%;
            opacity: 1;
            z-index: 1;

            .affiliate-overview__introduction--header {

                .affiliate-overview__introduction--header-information {
                    display: flex;
                    align-items: center;
                    gap: $gapXS;
                    padding: 4px;

                    .affiliate-overview__introduction--header-information-personal {
                        display: flex;
                        flex-direction: column;
                        width: 65%;
                        height: fit-content;
                        gap: $gapXS;

                        .affiliate-overview__introduction--header-information-personal-label {
                            font-size: $textFontSize3XL;
                            font-weight: $fontWeight2XL;
                            color: #3E00BA;
                        }

                        .affiliate-overview__introduction--header-information-personal-sub-label {
                            font-size: $textFontSizeL;
                            font-weight: $fontWeightXL;
                            color: #3E00BA;
                        }
                    }

                    .affiliate-overview__introduction--header-information-assistant {
                        width: 35%;
                        height: fit-content;
                    }
                }
            }

            .affiliate-overview__introduction--phone {
                position: absolute;
                bottom: 35%;
                left: 50%;
                z-index: 1;
                height: 340px;
                width: 340px;
                transform: translateX(-50%) translateY(40%) rotate(-5deg);
                transition: all 1.5s ease;

                &.rotate {
                    transform: translateX(-50%) translateY(40%) rotate(-15deg) scale(1.15);
                }
            }

            .affiliate-overview__introduction--blank {
                align-items: center;

                .affiliate-overview__introduction--blank-header {

                    .affiliate-overview__introduction--blank-header-text-border {
                        text-shadow:
                            1px 1px 0 #b71eff,
                            -1px -1px 0 #b71eff,
                            1px -1px 0 #b71eff,
                            -1px 1px 0 #b71eff;
                        font-size: $textFontSize2XL;
                        font-weight: $fontWeight2XL;
                        font-family: "Work Sans", serif;
                        color: $colorWhite;
                        text-transform: uppercase;
                    }

                    .affiliate-overview__introduction--blank-header-text-neon {
                        font-size: $textFontSize3XL;
                        font-weight: $fontWeight2XL;
                        font-family: "Work Sans", serif;
                        color: $colorWhite;
                        text-transform: uppercase;
                        text-shadow:
                            2px 2px 5px #38b6ff,
                            4px 4px 10px #38b6ff;
                    }

                }

                .affiliate-overview__introduction--blank-label {

                    span {
                        font-size: $textFontSizeL;
                        font-weight: $fontWeightXL;
                        color: $colorBlack;
                    }
                }

                .affiliate-overview__introduction--blank-button {
                    padding: 10px;
                    width: 90%;

                    span {
                        font-size: $textFontSizeL;
                        font-weight: $fontWeightXL;
                        color: $colorWhite;
                    }
                }

                .affiliate-overview__introduction--blank-child {
                    gap: $gapS;

                    .affiliate-overview__introduction--blank-child-container {
                        padding: 4px;

                        &:hover {
                            transform: scale(1.02);
                        }

                        .affiliate-overview__introduction--blank-child-promotion {
                            padding: 8px 2px;

                            .affiliate-overview__introduction--blank-child-promotion-tag-name {
                                position: absolute;
                                top: -18px;
                                left: -4px;
                                z-index: 1;

                                span {
                                    font-family: "Work Sans", serif;
                                    font-size: $textFontSizeL;
                                    font-weight: $fontWeight2XL;
                                    color: $colorWhite;
                                }
                            }

                            .affiliate-overview__introduction--blank-child-promotion-tag {
                                position: absolute;
                                top: -23px;
                                left: -13px;
                                width: 140px;
                                height: fit-content;
                            }

                            .affiliate-overview__introduction--blank-child-promotion-image {
                                width: 80px;
                                height: 80px;
                            }

                            .affiliate-overview__introduction--blank-child-promotion-share {

                                img {
                                    height: 25px;
                                    width: 25px;
                                }

                                span {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeight2XL;
                                    text-transform: uppercase;
                                    color: $colorViolet;
                                }
                            }

                            .affiliate-overview__introduction--blank-child-promotion-benefit {

                                .affiliate-overview__introduction--blank-child-promotion-benefit-icon {
                                    font-size: $textFontSizeM;
                                }

                                .affiliate-overview__introduction--blank-child-promotion-benefit-label {
                                    font-size: $textFontSizeL;
                                    font-weight: $fontWeightXL;
                                    color: $colorBlack;
                                }
                            }
                        }
                    }
                }

                .affiliate-overview__introduction--blank-guide {
                    width: 100%;

                    .affiliate-overview__introduction--blank-guide-container {

                        .affiliate-overview__introduction--blank-guide-container-tag-label {

                            span {
                                font-family: "Work Sans", serif;
                                font-size: $textFontSizeXL;
                                font-weight: $fontWeight2XL;
                                color: $colorWhite;
                                text-transform: uppercase;
                            }
                        }

                        .affiliate-overview__introduction--blank-guide-container-child {

                            .affiliate-overview__introduction--blank-guide-container-child-left {

                                .affiliate-overview__introduction--blank-guide-container-child-left-content {

                                    .affiliate-overview__introduction--blank-guide-container-child-left-content-bullet-points {
                                        width: 15px;
                                        max-width: 15px;
                                        min-width: 15px;
                                        height: fit-content;
                                        max-height: fit-content;
                                        min-height: fit-content;
                                    }


                                    .affiliate-overview__introduction--blank-guide-container-child-left-content-label {

                                        span {
                                            font-size: $textFontSizeM;
                                            font-weight: $fontWeightXL;
                                            color: $colorViolet;
                                        }
                                    }
                                }

                            }

                            .affiliate-overview__introduction--blank-child-promotion-benefit {

                                .affiliate-overview__introduction--blank-child-promotion-benefit-icon {
                                    font-size: $textFontSizeM;
                                }

                                .affiliate-overview__introduction--blank-child-promotion-benefit-label {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                    color: $colorBlack;
                                }
                            }

                            // Bên phải
                            .affiliate-overview__introduction--blank-guide-container-child-right {
                                padding: 2px;


                                .affiliate-overview__introduction--blank-guide-container-child-right-container {

                                    .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion {
                                        border: 2px solid $colorViolet;
                                        padding: 3px;

                                        .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-tag-name {
                                            position: absolute;
                                            top: -15px;
                                            left: -2px;
                                            z-index: 1;

                                            span {
                                                font-family: "Work Sans", serif;
                                                font-size: $textFontSizeSM;
                                                font-weight: $fontWeight2XL;
                                                color: $colorWhite;
                                            }
                                        }

                                        .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-tag {
                                            position: absolute;
                                            top: -10px;
                                            left: -6px;
                                            width: 75px;
                                            height: fit-content;
                                        }

                                        .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-image {
                                            width: 35px;
                                            height: 35px;
                                        }

                                        .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-benefit {
                                            display: flex;
                                            align-items: start;
                                            gap: $gapXS;
                                            width: 100%;
                                            max-width: 200px;
                                            padding: 2px;

                                            .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-benefit-icon {
                                                font-size: $textFontSizeSM;
                                                font-weight: $fontWeightXL;
                                                color: $colorGreen;
                                            }

                                            .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-benefit-label {
                                                font-size: 6px;
                                                font-weight: $fontWeightL;
                                                color: $colorBlack;
                                                line-height: 1;
                                            }
                                        }

                                        .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-share {
                                            padding: 2px 2px;
                                            border-radius: $borderRadiusS;

                                            span {
                                                line-height: 1;
                                            }
                                        }


                                    }
                                }
                            }
                        }
                    }
                }
            }

            .affiliate-overview__introduction--wave {
                height: 23%;
            }

            .affiliate-overview__introduction--benefit {

                .affiliate-overview__introduction--benefit-header {

                    .affiliate-overview__introduction--benefit-header-text-border {
                        font-size: 2rem;
                    }

                    .affiliate-overview__introduction--benefit-header-text-neon {
                        font-size: 2.5rem;
                    }
                }

                .affiliate-overview__introduction--benefit-header-list {
                    height: 55vh;

                    .affiliate-overview__introduction--benefit-header-list-container {

                        .affiliate-overview__introduction--benefit-header-list-content {

                            .affiliate-overview__introduction--benefit-header-list-content-child {

                                .affiliate-overview__introduction--benefit-header-list-content-child-header {

                                    .affiliate-overview__introduction--benefit-header-list-content-child-header-text {
                                        font-size: $textFontSizeXL;
                                    }
                                }

                                .affiliate-overview__introduction--benefit-header-list-content-child-content {

                                    .affiliate-overview__introduction--benefit-header-list-content-child-content-dot {}

                                    span {
                                        font-size: $textFontSizeL;
                                        font-weight: $fontWeightXL;
                                        color: $colorViolet;
                                        font-family: "Work Sans", serif;
                                        line-height: 1.2;
                                    }
                                }
                            }


                            .affiliate-overview__introduction--benefit-header-list-content-icon {
                                display: flex;
                                background-color: #9758f4;
                                width: 15%;
                                height: 110px;
                                border-radius: $borderRadiusXL;
                                padding: 4px;

                                .affiliate-overview__introduction--benefit-header-list-content-icon-image-container {
                                    width: 100%;
                                    height: 50px;

                                    img {
                                        object-fit: fill;
                                    }
                                }
                            }
                        }
                    }

                }
            }

            .affiliate-overview__introduction--arrow {
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 1;
                z-index: 1;
                transition: all 1.5s ease;
                padding: 12px;

                .affiliate-overview__introduction--arrow-icon {
                    padding: 12px;
                    background: linear-gradient(to right, #DEDCFC 0%, #B4ABFE 50%, #CF91FC 100%);
                    rotate: 90deg;
                    opacity: 0.7;
                    cursor: pointer;
                    color: $colorBlack;
                    border-radius: $borderRadiusFull;
                    transition: all 1.5s ease;
                    background-size: 120% 120%;
                    background-position: left center;

                    &:hover {
                        background-position: right center;
                        opacity: 1;
                    }
                }

                &.hide {
                    height: 0px;
                    width: 0px;
                    opacity: 0;
                    z-index: -1;
                }
            }

            .affiliate-overview__introduction--footer {


                .affiliate-overview__introduction--footer-header {

                    .affiliate-overview__introduction--footer-header-label {
                        font-size: $textFontSize3XL;
                    }
                }

                .affiliate-overview__introduction--footer-bottom {

                    .affiliate-overview__introduction--footer-bottom-modal {

                        .affiliate-overview__introduction--footer-bottom-modal-information {
                            height: 45vh;

                            .affiliate-overview__introduction--footer-bottom-modal-information-title {
                                font-size: $textFontSize2XL;
                                font-weight: $fontWeight2XL;
                                color: #CF91FC;
                                font-family: "Work Sans", serif;
                                text-align: center;
                                padding-top: 12px;
                            }

                            .affiliate-overview__introduction--footer-bottom-modal-information-bullets-point {
                                font-size: $textFontSize2XL;
                                font-weight: $fontWeightL;
                                color: #CF91FC;
                                font-family: "Work Sans", serif;
                                padding: 0px 12px 0px 12px;

                                .high-light {
                                    font-size: $textFontSize2XL;
                                    font-weight: $fontWeight2XL;
                                    color: #CF91FC;
                                    font-family: "Work Sans", serif;
                                }


                            }

                            .affiliate-overview__introduction--footer-bottom-modal-information-social {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: $gapL;

                                .affiliate-overview__introduction--footer-bottom-modal-information-social-media {
                                    width: fit-content;
                                    height: fit-content;
                                    padding: 12px;
                                    border-radius: $borderRadiusFull;
                                    background-color: $colorBlue;
                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                    color: $colorWhite;

                                    &.facebook {
                                        background-color: $colorBlue;
                                    }

                                    &.youtube {
                                        background-color: $colorRed;
                                    }

                                    &.fanpage {
                                        background-color: $colorViolet;
                                    }
                                }

                            }

                            .affiliate-overview__introduction--footer-bottom-modal-information-copyright {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 12px;
                                border-top: 1px solid $colorMoreLightGray;
                                text-align: center;

                                span {
                                    font-size: $textFontSize2XL;
                                    font-weight: $fontWeightL;
                                    color: $colorLightGray;
                                    font-family: "Work Sans", serif;
                                }
                            }
                        }
                    }

                }

                .affiliate-overview__introduction--footer-content {

                    .affiliate-overview__introduction--footer-content-list-service {

                        .affiliate-overview__introduction--footer-content-list-service-child {
                            height: 90px;
                            width: 90px;
                        }

                    }
                }
            }
        }

        .affiliate-overview__navigate {
            height: 0px;
            width: 0px;
            opacity: 0;
            z-index: -1;
        }

        .affiliate-overview__landing-page {
            height: 0px;
            width: 0px;
            opacity: 0;
            z-index: -1;
        }

        .affiliate-overview__guide {
            height: 0px;
            width: 0px;
            opacity: 0;
            z-index: -1;
        }

        .affiliate-overview__benefit {
            height: 0px;
            width: 0px;
            opacity: 0;
            z-index: -1;
        }

        .affiliate-overview__footer {
            height: 0px;
            width: 0px;
            opacity: 0;
            z-index: -1;
        }

    }
}

@media screen and (max-width: 380px) {
    .affiliate-overview {

        .affiliate-overview__introduction {
            width: 100%;
            height: 100%;
            opacity: 1;
            z-index: 1;

            .affiliate-overview__introduction--header {

                .affiliate-overview__introduction--header-information {
                    display: flex;
                    align-items: center;
                    gap: $gapXS;
                    padding: 4px;

                    .affiliate-overview__introduction--header-information-personal {
                        display: flex;
                        flex-direction: column;
                        width: 65%;
                        height: fit-content;
                        gap: $gapXS;

                        .affiliate-overview__introduction--header-information-personal-label {
                            font-size: $textFontSize3XL;
                            font-weight: $fontWeight2XL;
                            color: #3E00BA;
                        }

                        .affiliate-overview__introduction--header-information-personal-sub-label {
                            font-size: $textFontSizeL;
                            font-weight: $fontWeightXL;
                            color: #3E00BA;
                        }
                    }

                    .affiliate-overview__introduction--header-information-assistant {
                        width: 35%;
                        height: fit-content;
                    }
                }
            }

            .affiliate-overview__introduction--phone {
                position: absolute;
                bottom: 35%;
                left: 50%;
                z-index: 1;
                height: 310px;
                width: 310px;
                transform: translateX(-50%) translateY(40%) rotate(-5deg);
                transition: all 1.5s ease;

                &.rotate {
                    transform: translateX(-50%) translateY(40%) rotate(-15deg) scale(1.15);
                }
            }

            .affiliate-overview__introduction--blank {
                align-items: center;

                .affiliate-overview__introduction--blank-header {

                    .affiliate-overview__introduction--blank-header-text-border {
                        text-shadow:
                            1px 1px 0 #b71eff,
                            -1px -1px 0 #b71eff,
                            1px -1px 0 #b71eff,
                            -1px 1px 0 #b71eff;
                        font-size: $textFontSize3XL;
                        font-weight: $fontWeight2XL;
                        font-family: "Work Sans", serif;
                        color: $colorWhite;
                        text-transform: uppercase;
                    }

                    .affiliate-overview__introduction--blank-header-text-neon {
                        font-size: $textFontSize3XL;
                        font-weight: $fontWeight2XL;
                        font-family: "Work Sans", serif;
                        color: $colorWhite;
                        text-transform: uppercase;
                        text-shadow:
                            2px 2px 5px #38b6ff,
                            4px 4px 10px #38b6ff;
                    }

                }

                .affiliate-overview__introduction--blank-label {

                    span {
                        font-size: $textFontSizeL;
                        font-weight: $fontWeightXL;
                        color: $colorBlack;
                    }
                }

                .affiliate-overview__introduction--blank-button {
                    padding: 10px;
                    width: 90%;

                    span {
                        font-size: $textFontSizeL;
                        font-weight: $fontWeightXL;
                        color: $colorWhite;
                    }
                }

                .affiliate-overview__introduction--blank-child {
                    gap: $gapS;

                    .affiliate-overview__introduction--blank-child-container {
                        padding: 2px;

                        &:hover {
                            transform: scale(1.02);
                        }

                        .affiliate-overview__introduction--blank-child-promotion {
                            padding: 8px 2px;

                            .affiliate-overview__introduction--blank-child-promotion-tag-name {
                                position: absolute;
                                top: -18px;
                                left: -4px;
                                z-index: 1;

                                span {
                                    font-family: "Work Sans", serif;
                                    font-size: $textFontSizeL;
                                    font-weight: $fontWeight2XL;
                                    color: $colorWhite;
                                }
                            }

                            .affiliate-overview__introduction--blank-child-promotion-tag {
                                position: absolute;
                                top: -23px;
                                left: -13px;
                                width: 140px;
                                height: fit-content;
                            }

                            .affiliate-overview__introduction--blank-child-promotion-image {
                                width: 80px;
                                height: 80px;
                            }

                            .affiliate-overview__introduction--blank-child-promotion-share {

                                img {
                                    height: 25px;
                                    width: 25px;
                                }

                                span {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeight2XL;
                                    text-transform: uppercase;
                                    color: $colorViolet;
                                }
                            }

                            .affiliate-overview__introduction--blank-child-promotion-benefit {

                                .affiliate-overview__introduction--blank-child-promotion-benefit-icon {
                                    font-size: $textFontSizeM;
                                }

                                .affiliate-overview__introduction--blank-child-promotion-benefit-label {
                                    font-size: $textFontSizeM;
                                    font-weight: $fontWeightXL;
                                    color: $colorBlack;
                                }
                            }
                        }
                    }
                }

                .affiliate-overview__introduction--blank-guide {
                    width: 100%;

                    .affiliate-overview__introduction--blank-guide-container {

                        .affiliate-overview__introduction--blank-guide-container-tag-label {

                            span {
                                font-family: "Work Sans", serif;
                                font-size: $textFontSizeXL;
                                font-weight: $fontWeight2XL;
                                color: $colorWhite;
                                text-transform: uppercase;
                            }
                        }

                        .affiliate-overview__introduction--blank-guide-container-child {

                            .affiliate-overview__introduction--blank-guide-container-child-left {

                                .affiliate-overview__introduction--blank-guide-container-child-left-content {

                                    .affiliate-overview__introduction--blank-guide-container-child-left-content-bullet-points {
                                        width: 15px;
                                        max-width: 15px;
                                        min-width: 15px;
                                        height: fit-content;
                                        max-height: fit-content;
                                        min-height: fit-content;
                                    }


                                    .affiliate-overview__introduction--blank-guide-container-child-left-content-label {

                                        span {
                                            font-size: $textFontSizeM;
                                            font-weight: $fontWeightXL;
                                            color: $colorViolet;
                                        }
                                    }
                                }

                            }

                            // Bên phải
                            .affiliate-overview__introduction--blank-guide-container-child-right {
                                padding: 2px;
                                height: 100%;
                                width: 30%;

                                .affiliate-overview__introduction--blank-guide-container-child-right-container {

                                    .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion {
                                        border: 2px solid $colorViolet;
                                        padding: 3px;

                                        .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-tag-name {
                                            position: absolute;
                                            top: -15px;
                                            left: -2px;
                                            z-index: 1;

                                            span {
                                                font-family: "Work Sans", serif;
                                                font-size: $textFontSizeSM;
                                                font-weight: $fontWeight2XL;
                                                color: $colorWhite;
                                            }
                                        }

                                        .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-tag {
                                            position: absolute;
                                            top: -10px;
                                            left: -6px;
                                            width: 75px;
                                            height: fit-content;
                                        }

                                        .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-image {
                                            width: 35px;
                                            height: 35px;
                                        }

                                        .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-benefit {
                                            display: flex;
                                            align-items: start;
                                            gap: $gapXS;
                                            width: 100%;
                                            max-width: 200px;
                                            padding: 2px;

                                            .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-benefit-icon {
                                                font-size: $textFontSizeSM;
                                                font-weight: $fontWeightXL;
                                                color: $colorGreen;
                                            }

                                            .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-benefit-label {
                                                font-size: 6px;
                                                font-weight: $fontWeightL;
                                                color: $colorBlack;
                                                line-height: 1;
                                            }
                                        }

                                        .affiliate-overview__introduction--blank-guide-container-child-right-container-promotion-share {
                                            padding: 2px 2px;
                                            border-radius: $borderRadiusS;

                                            span {
                                                line-height: 1;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .affiliate-overview__introduction--wave {
                height: 23%;
            }

            .affiliate-overview__introduction--benefit {

                .affiliate-overview__introduction--benefit-header {

                    .affiliate-overview__introduction--benefit-header-text-border {
                        font-size: 1.5rem;
                    }

                    .affiliate-overview__introduction--benefit-header-text-neon {
                        font-size: 2rem;
                    }
                }

                .affiliate-overview__introduction--benefit-header-list {
                    height: 50vh;

                    .affiliate-overview__introduction--benefit-header-list-container {

                        .affiliate-overview__introduction--benefit-header-list-content {

                            .affiliate-overview__introduction--benefit-header-list-content-child {

                                .affiliate-overview__introduction--benefit-header-list-content-child-header {

                                    .affiliate-overview__introduction--benefit-header-list-content-child-header-icon {
                                        font-size: $textFontSizeL;
                                    }

                                    .affiliate-overview__introduction--benefit-header-list-content-child-header-text {
                                        font-size: $textFontSizeL;
                                    }
                                }

                                .affiliate-overview__introduction--benefit-header-list-content-child-content {

                                    .affiliate-overview__introduction--benefit-header-list-content-child-content-dot {}

                                    span {
                                        font-size: $textFontSizeM;
                                        font-weight: $fontWeightXL;
                                        color: $colorViolet;
                                        font-family: "Work Sans", serif;
                                        line-height: 1.2;
                                    }
                                }
                            }

                            .affiliate-overview__introduction--benefit-header-list-content-icon {
                                display: flex;
                                background-color: #9758f4;
                                width: 15%;
                                height: 100px;
                                border-radius: $borderRadiusXL;
                                padding: 4px;

                                .affiliate-overview__introduction--benefit-header-list-content-icon-image-container {
                                    width: 100%;
                                    height: 50px;

                                    img {
                                        object-fit: fill;
                                    }
                                }
                            }
                        }
                    }

                }

            }

            .affiliate-overview__introduction--arrow {
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 1;
                z-index: 1;
                transition: all 1.5s ease;
                padding: 12px;

                .affiliate-overview__introduction--arrow-icon {
                    padding: 12px;
                    background: linear-gradient(to right, #DEDCFC 0%, #B4ABFE 50%, #CF91FC 100%);
                    rotate: 90deg;
                    opacity: 0.7;
                    cursor: pointer;
                    color: $colorBlack;
                    border-radius: $borderRadiusFull;
                    transition: all 1.5s ease;
                    background-size: 120% 120%;
                    background-position: left center;

                    &:hover {
                        background-position: right center;
                        opacity: 1;
                    }
                }

                &.hide {
                    height: 0px;
                    width: 0px;
                    opacity: 0;
                    z-index: -1;
                }
            }

            .affiliate-overview__introduction--footer {

                .affiliate-overview__introduction--footer-header {

                    .affiliate-overview__introduction--footer-header-label {
                        font-size: $textFontSize2XL;
                    }
                }

                .affiliate-overview__introduction--footer-bottom {

                    .affiliate-overview__introduction--footer-bottom-modal {

                        .affiliate-overview__introduction--footer-bottom-modal-information {
                            height: 60vh;

                            .affiliate-overview__introduction--footer-bottom-modal-information-title {
                                font-size: $textFontSizeXL;
                                font-weight: $fontWeight2XL;
                                color: #CF91FC;
                                font-family: "Work Sans", serif;
                                text-align: center;
                                padding-top: 12px;
                            }

                            .affiliate-overview__introduction--footer-bottom-modal-information-bullets-point {
                                font-size: $textFontSizeXL;
                                font-weight: $fontWeightL;
                                color: #CF91FC;
                                font-family: "Work Sans", serif;
                                padding: 0px 12px 0px 12px;

                                .high-light {
                                    font-size: $textFontSizeXL;
                                    font-weight: $fontWeight2XL;
                                    color: #CF91FC;
                                    font-family: "Work Sans", serif;
                                }


                            }

                            .affiliate-overview__introduction--footer-bottom-modal-information-social {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: $gapL;

                                .affiliate-overview__introduction--footer-bottom-modal-information-social-media {
                                    width: fit-content;
                                    height: fit-content;
                                    padding: 8px;
                                    border-radius: $borderRadiusFull;
                                    background-color: $colorWhite;
                                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                                    color: $colorWhite;

                                    &.facebook {
                                        background-color: $colorBlue;
                                    }

                                    &.youtube {
                                        background-color: $colorRed;
                                    }

                                    &.fanpage {
                                        background-color: $colorViolet;
                                    }
                                }

                            }

                            .affiliate-overview__introduction--footer-bottom-modal-information-copyright {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 12px;
                                border-top: 1px solid $colorMoreLightGray;
                                text-align: center;

                                span {
                                    font-size: $textFontSizeXL;
                                    font-weight: $fontWeightL;
                                    color: $colorLightGray;
                                    font-family: "Work Sans", serif;
                                }
                            }
                        }
                    }

                }

                .affiliate-overview__introduction--footer-content {

                    .affiliate-overview__introduction--footer-content-list-service {

                        .affiliate-overview__introduction--footer-content-list-service-child {
                            height: 70px;
                            width: 70px;
                        }

                    }
                }
            }
        }

        .affiliate-overview__navigate {
            height: 0px;
            width: 0px;
            opacity: 0;
            z-index: -1;
        }

        .affiliate-overview__landing-page {
            height: 0px;
            width: 0px;
            opacity: 0;
            z-index: -1;
        }

        .affiliate-overview__guide {
            height: 0px;
            width: 0px;
            opacity: 0;
            z-index: -1;
        }

        .affiliate-overview__benefit {
            height: 0px;
            width: 0px;
            opacity: 0;
            z-index: -1;
        }

        .affiliate-overview__footer {
            height: 0px;
            width: 0px;
            opacity: 0;
            z-index: -1;
        }
    }
}