@import "../../@theme/style.scss";

.button-custom {
    color: $colorWhite;
    background-color: $colorViolet;
    border-radius: $borderRadiusL;
    transition: all 0.5s ease;
    padding: 9px 10px;
    border: 1px solid $colorViolet;
    border-radius: $borderRadiusL;
    font-size: $textFontSizeM;
    font-weight: $fontWeightXL;
    cursor: pointer;

    &:hover {
        background-color: $colorDarkPurple;
        border-color: $colorDarkPurple;
    }

    &.disable {
        background-color: $colorExtraLightGray;
        color: $colorGray;
        border-color: $colorExtraLightGray;
        cursor: not-allowed;
    }

    &.normal {
        background-color: $colorMoreLightGray;
        color: $colorGray;
        border-color: $colorMoreLightGray;

        &:hover {
            background-color: $colorLightGray;
            color: $colorWhite
        }
    }

    &.full-screen {
        width: 100%;
    }

    &.border-full {
        border-radius: $borderRadiusFull;
    }
}

.button-custom-checked {
    color: $colorViolet;
    background-color: $colorWhite;
    border-radius: $borderRadiusL;
    border: 1px solid $colorViolet;
    transition: all 0.3s ease;
    padding: 8px 10px;
    font-size: $textFontSizeM;
    font-weight: $fontWeightXL;
    cursor: pointer;

    &:hover {
        background-color: $colorViolet;
        color: $colorWhite;
        border-color: $colorViolet;
    }

    &.disable {
        background-color: $colorExtraLightGray;
        color: $colorBlack;
        border-color: $colorExtraLightGray;
        cursor: not-allowed;
    }

    &.normal {
        background-color: $colorExtraLightGray;
        color: $colorBlack;
        border-color: $colorExtraLightGray;
        // cursor: not-allowed;
    }
}

.button-custom-select {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 4px;
    border: 1px solid $colorViolet;
    border-radius: $borderRadiusL;
    cursor: pointer;
    transition: 0.5s all ease;
    gap: $gapS;

    &.disable {
        border-color: $colorLightGray;
        cursor: not-allowed;

        &:hover {
            background-color: $colorWhite;

            .button-custom-select__label {
                color: $colorLightGray;
            }

            .button-custom-select__line,
            .button-custom-select__option {
                display: flex;
                gap: $gapL;
                background-color: $colorMoreLightGray;
                .button-custom-select__option--label {
                    padding: 0 4px 0 4px;
                }
            }

        }
    }

    .button-custom-select__label {
        display: flex;
        gap: $gapXS;
        align-items: center;
        color: $colorViolet;
        font-weight: $fontWeightXL;
        font-size: $textFontSizeM;

        &.disable {
            color: $colorLightGray;
        }
    }

    .button-custom-select__line {
        width: 1px;
        height: 20px;
        border-radius: $borderRadiusL;
        background-color: $colorMoreLightGray;
    }

    .button-custom-select__option {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 6px;
        background-color: $colorMoreLightGray;
        border-radius: $borderRadiusL;

        span {
            font-size: $textFontSizeM;
            font-weight: $fontWeightL;
        }
    }

    &:hover {
        // border: 0px;
        background-color: $colorViolet;

        .button-custom-select__label {
            color: $colorWhite;
        }

        .button-custom-select__line,
        .button-custom-select__option {
            background-color: $colorWhite;
        }
    }

}

.button-custom-content {
    display: flex;
    flex-direction: column;

    .button-custom-content__child {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: $fontWeightL;
        font-size: $textFontSizeM;
        color: $colorBlack;
        padding: 8px;
        cursor: pointer;
        border-radius: $borderRadiusL;
        transition: 0.3s all ease-out;
        margin-bottom: 4px;

        &:hover {
            background-color: $colorViolet;
            color: $colorWhite;
        }

        &.checked {
            background-color: $colorViolet;
            font-weight: $fontWeightL;
            font-size: $textFontSizeM;
            color: $colorWhite;
        }
    }

    .button-custom-content__child--text {
        display: flex;
        align-items: center;
        gap: $gapS;

        .button-custom-content__child--text-number {
            text-align: center;
            background-color: $colorMoreLightGray;
            padding: 2px 4px;
            border-radius: $borderRadiusL;
            font-weight: $fontWeightL;
            font-size: $textFontSizeM;
            min-width: 20px;
            color: $colorBlack;

            &.checked {
                background-color: $colorWhite;
                color: $colorBlack;
            }

            &.hidden {
                display: none;
            }
        }
    }

}