.btn-add-withdraw-collaborator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-left: 20px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
}

.btn-add-withdraw-collaborator-hide {
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-left: 20px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
}

.btn-add-w {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
  margin-top: 20px;
}

.img-thumbnail {
  width: 100px !important;
  height: 100px !important;
}

.input-money {
  margin-top: 10px;
}

.select-code-phone {
  width: 300px !important;
  height: 40px !important;
}

.list-item {
  background-color: rgb(255, 255, 255);
  padding: 5px;
  position: absolute;
  width: 400px;
  border: 1px solid rgb(175, 175, 175);
  border-radius: 5px;
}

.error {
  color: red;
  font-size: 12px;
}

@media screen and (max-width: 900px) {
  .btn-add-withdraw-collaborator {
    width: 50% !important;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #48cae4;
    color: white;
    box-shadow: 2px 3px 2px rgb(216, 216, 216);
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 490px) {
  .btn-add-withdraw-collaborator {
    width: 100% !important;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #48cae4;
    color: white;
    box-shadow: 2px 3px 2px rgb(216, 216, 216);
    margin-top: 5px;
  }
}
