.btn-add-group-customer {
  width: 15%;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-left: 20px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-name-group-customer {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: black;
  text-decoration: none;
  margin: 0;
}

.text-date-group {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: black;
  text-decoration: none;
  margin: 0;
}

@media screen and (max-width: 490px) {
  .btn-add-group-customer {
    width: 90%;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #48cae4;
    color: white;
    margin-left: 20px;
    box-shadow: 2px 3px 2px rgb(216, 216, 216);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
