.title-lesson {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  text-decoration: none;
}

.div-select-add-lesson {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
}
.div-tab-lesson {
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  .div-item-tab {
    padding: 5px;
    margin-right: 5px;

    .text-tab {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #a3a3a3;
      text-decoration: none;
    }
  }

  .div-item-tab-select {
    padding: 5px;
    margin-right: 5px;
    border-bottom: 1px solid blue;

    .text-tab {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      text-decoration: none;
    }
  }
}
