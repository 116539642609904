// Font Size
$textFontSizeSM: 8px;
$textFontSizeS: 10px;
$textFontSizeM: 12px;
$textFontSizeL: 14px;
$textFontSizeXL: 16px;
$textFontSize2XL: 18px;
$textFontSize3XL: 20px;
$textFontSize4XL: 22px;
// Opacity
$opacityS: 0.8;
$opacityM: 0.7;
$opacityL: 0.6;
// Gap
$gapXS: 4px;
$gapS: 8px;
$gapM: 12px;
$gapL: 16px;
$gapXL: 20px;
$gap2XL: 24px;
// Border Radius
$borderRadiusS: 4px;
$borderRadiusM: 6px;
$borderRadiusL: 8px;
$borderRadiusXL: 16px;
$borderRadiusFull: 9999px;
// Line Height
$lineHeightS: 12px;
$lineHeightM: 16px;
$lineHeightL: 20px;
$lineHeightXL: 24px;
$lineHeight2XL: 28px;
$lineHeight3XL: 32px;
$lineHeight4XL: 36px;
// Font Weight
$fontFamily: "Roboto";
$fontWeightM: 300;
$fontWeightL: 400;
$fontWeightXL: 500;
$fontWeight2XL: 700;
$fontStyleNormal: normal;
$fontStyleItalic: italic;

// Black Palette
$colorBlack: #43464b;
// White Palette
$colorWhite: #ffffff;
// Slate
$colorDarkSlate: #475569;
$colorSlate: #64748b;
$colorLightSlate: #cbd5e1;
$colorMoreLightSlate: #e2e8f0;
$colorExtraLightSlate: #f1f5f9;
// Gray Palette
$colorGray: #79808c;
$colorLightGray: #9ca3af;
$colorMoreLightGray: #e5e7eb;
$colorExtraLightGray: #f3f4f6;
// Green Palette
$colorGreen: #22c55e;
$colorLightGreen: #86efac;
$colorMoreLightGreen: #bbf7d0;
$colorExtraLightGreen: #dcfce7;
// Lime Palette
$colorLime: #84cc16;
$colorLightLime: #bef264;
$colorMoreLightLime: #d9f99d;
$colorExtraLightLime: #ecfccb;
// Amber Palette
$colorExtraLightAmber: #fef3c7;
// Red Palette
$colorRed: #ef4444;
$colorLightRed: #fca5a5;
$colorMoreLightRed: #fecaca;
$colorExtraLightRed: #fee2e2;
// Yellow Palette
$colorYellow: #eab308;
$colorExtraLightYellow: #fef9c3;
// Yellow Palette
$colorDarkAmber: #d97706;
$colorAmber: #f59e0b;
$colorLightAmber: #fcd34d;
$colorMoreLightAmber: #fde68a;
$colorExtraLightAmber: #fef3c7;
// Cyan Palette
$colorDarkCyan: #0891b2;
$colorCyan: #06b6d4;
$colorLightCyan: #67e8f9;
$colorMoreLightCyan: #a5f3fc;
$colorExtraLightCyan: #cffafe;
// Blue Palette
$colorDarkBlue: #1d4ed8;
$colorBlue: #3b82f6;
$colorLightBlue: #93c5fd;
$colorMoreLightBlue: #bfdbfe;
$colorExtraLightBlue: #dbeafe;
// Purple Palette
$colorDarkPurple: #9333ea;
$colorPurple: #a855f7;
$colorLitleLightPurple: #c084fc;
$colorLightPurple: #d8b4fe;
$colorMoreLightPurple: #e9d5ff;
$colorExtraLightPurple: #f3e8ff;
// Stone
$colorStone: #78716c;
$colorLightStone: #d6d3d1;
$colorMoreLightStone: #e7e5e4;
$colorExtraLightStone: #f5f5f4;
// Orange
$colorOrange: #f97316;
$colorLightOrange: #fdba74;
$colorMoreLightOrange: #fed7aa;
$colorExtraLightOrange: #ffedd5;
// Violet
$colorDarkViolet: #7c3aed;
$colorViolet: #8b5cf6;
$colorLightViolet: #d8b4fe;
$colorMoreLightViolet: #e9d5ff;
$colorExtraLightViolet: #f3e8ff;
// Other
$colorBorderAntDesign: #DBDBDB;


// Old css value
$colorBackgroundWhite: #ffffff1f;
$colorBackgroundBlack: #21212125;
$color1: #2463eb;
$backGroundColor1: #f0f6ff;
$color2: #c88a04;
$backGroundColor2: #fefce7;
$colorSuccess: #00cf3a;
$backGroundColorSuccess: #f2fdf5;
$colorError: #dc2828;
$backGroundColorError: #fef1f1;
$bgStarCard : #eef2ff;

$colorPending: #c88a04;
$backGroundPending: #fefce7;

$colorConfirmDoing: #2463eb;
$backGroundConfirmDoing: #f0f6ff;

$colorDiscount: #dc2828;
$backGroundDiscount: #fef1f1;



h3 {
  font-family: $fontFamily;
  font-style: $fontStyleNormal;
  font-weight: $fontWeightXL;
  line-height: 18px;
}

p {
  font-family: $fontFamily;
  font-style: $fontStyleNormal;
  font-weight: $fontWeightL;
  font-size: $textFontSizeM;
  line-height: 18px;
  // margin: 4px 0px;
}

span {
  font-family: $fontFamily;
  font-style: $fontStyleNormal;
  font-weight: $fontWeightL;
  font-size: $textFontSizeM;
  // color: $colorBlack;
  line-height: 1;
}

.button-info {
  color: $colorBlack;
  background-color: $colorWhite;
  width: 100%;
}

.button-primary {
  color: $colorWhite;
  background-color: $color2;
  width: 100%;
}

.button-success {
  color: $colorWhite;
  background-color: $colorSuccess;
  width: 100%;
}

.button-danger {
  color: $colorWhite;
  background-color: $colorError;
  width: 100%;
}

.text-gray {
  color: $colorGray;
}

// Grid Column
.grid-column-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-column-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-column-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-column-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-column-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-column-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-column-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-column-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-column-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-column-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-column-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-column-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}