.div-add-collaborator {
  display: flex;
  flex-direction: column;

  .label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: black;
    margin-bottom: 20px;
  }

  .input {
    margin-top: 10px;
  }

  .list-item-add-collaborator-order {
    background-color: rgb(255, 255, 255);
    padding: 5px 5px;
    position: absolute;
    width: 400px;
    border: 1px solid rgb(175, 175, 175);
    border-radius: 5px;
    z-index: 1000;

    .div-item-add-order {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 5px;
      border: none;
      background-color: white;
      width: 95%;

      .div-name {
        display: flex;
        flex-direction: row;

        .img-collaborator {
          width: 30px;
          height: 30px;
          border-radius: 4px;
          margin-right: 10px;
        }

        .text-name {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: black;
        }
      }
    }

    .div-item-add-order-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #d6d6d6;
      border-radius: 4px;
      border: none;
      width: 95%;
      margin-top: 10px;
      padding: 5px;

      .div-name {
        display: flex;
        flex-direction: row;

        .img-collaborator {
          width: 30px;
          height: 30px;
          border-radius: 4px;
          margin-right: 10px;
        }

        .text-name {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: black;
        }
      }
    }

    .div-item-add-order-favorite {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: #ffffff;
      border-radius: 4px;
      border-bottom: 1px solid red;
      border-top: 1px solid red;
      border-left: none;
      border-right: none;
      width: 95%;
      margin-top: 10px;
      padding: 5px;

      .div-name {
        display: flex;
        flex-direction: row;

        .img-collaborator {
          width: 30px;
          height: 30px;
          border-radius: 4px;
          margin-right: 10px;
        }

        .text-name {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: black;
        }
      }

      .icon-heart {
        color: red;
        margin-left: 50px;
      }
    }
  }

  .btn-add-collaborator-order {
    width: 40% !important;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #48cae4;
    color: white;
    margin-top: 30px;
  }

  .text-add-ctv-order {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: black;
    margin: 0;
  }

  .text-add-ctv-order-hide {
    display: none;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: black;
  }
}
