.btn-edit-service-option {
  display: flex;
  float: right;
  width: 100px;
  height: 30px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
}
