@import "../../../@theme/style.scss";

.card__information {
    display: flex;
    flex-direction: column;
    gap: $gapL;

    .card__information--avatar {
        background-color: $colorExtraLightViolet;
        border-radius: $borderRadiusL;
        // margin: 12px 12px;
        padding: 16px 16px;
        display: flex;
        justify-content: center;

        .card__information--avatar-image {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .card__information--avatar-information {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 12px;
            gap: $gapS;

            .card__information--avatar-information-name {
                font-size: $textFontSizeXL;
                font-weight: $fontWeightXL;
                color: $colorBlack;
            }

            .card__information--avatar-information-other {
                display: flex;
                align-items: center;
                gap: $gapXS;

                .card__information--avatar-information-other-subtext {
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightXL;
                    color: $colorBlack;
                }

                .card__information--avatar-information-other-subtext-label {
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightL;
                    color: $colorGray;
                    opacity: $opacityS;
                }
            }

            .card__information--avatar-information-status {
                padding: 8px 16px;
                border-radius: $borderRadiusFull;
            }
        }
    }

    .card__information--detail-info {
        display: flex;
        flex-direction: column;
        gap: $gapL;

        // margin: 16px 12px 16px 12px;
        .card__information--detail-info-label {
            font-size: $textFontSizeM;
            font-weight: $fontWeightXL;
            color: $colorBlack;
        }
    }
}