.btn-see-activity {
  display: flex;
  border: none;
  background-color: transparent;
  padding: 5px;
  float: left;
}

.div-item {
  padding: 10px;
  border-bottom: 1px solid rgb(221, 221, 221);
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .text-time {
    color: rgb(0, 0, 0);
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    margin-top: 10px;
  }

  .text-name {
    color: black;
    font-size: 18px;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    margin-left: 5px;
  }
}

.div-date {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-bottom: 30px;

  .picker {
    width: 300px;
  }
}
