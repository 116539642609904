.card-multi-info {
    min-width: 45%;
    min-height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 63px;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    filter: drop-shadow(0px 4px 4px rgba(8, 7, 7, 0.189));
    padding: 10px;

    .main-info {
        min-width: 25%;
        .text-title {
            color: #060606;
            margin-bottom: 10px;
            text-align: center;
        }
        .div-detail {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .text-detail {
                font-size: 25px;
                // text-align: center;
            }
            .percent-period {
                margin-left: 5px;
                font-size: 15px;
                color: brown;
                // text-align: center;
            }
        }

    }
    .second-info {
        min-width: 75%;
        margin-left: 20px;
        .div-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 5px 15px;
        }
        .div-detail {
            display: flex;
            flex-direction: row;
            .percent-period {
                margin-left: 10px;
                color: brown;
            }
        }
    }
}