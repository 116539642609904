.div-tab-service {
  display: flex;
  flex-direction: row;

  .div-item-tab-selected {
    padding: 5px;
    margin-right: 10px;
    border-bottom: 2px solid #0805b8;

    .text-tab {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #000000;
      text-decoration: none;
      margin: 0;
    }
  }

  .div-item-tab {
    padding: 5px;
    margin-right: 10px;

    .text-tab {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #a5a5a5;
      text-decoration: none;
      margin: 0;
    }
  }
}

.text-date-price {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  text-decoration: none;
  margin: 0;
}

.div-time-date-price {
  display: flex;
  flex-direction: row;

  .div-item {
    display: flex;
    flex-direction: column;

    .text-item {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: #000000;
      text-decoration: none;
      margin: 0;
    }
  }
}

.div-picker {
  display: flex;
  flex-direction: row;
  align-items: center;
}
