.div-time-create {
  display: flex;
  flex-direction: column;

  .text-date-create {
    color: #000000;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin: 0;
  }
}

.div-customer {
  display: flex;
  flex-direction: column;

  .text-name {
    color: #000000;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin: 0;
  }

  .text-phone {
    color: #000000;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin: 0;
  }
}

.text-description {
  color: #000000;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: block;
  width: 200px;
  margin: 0;
}

.text-address-cleaning {
  color: #000000;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: block;
  width: 150px;
  margin: 0;
}


.text-uncontacted {
  color: #ff0000;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: block;
  width: 150px;
  margin: 0;
}

.div-uncontacted {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .btn-contacted {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 50px;
    height: 25px;
    background-color: #00cf3a;
    border-radius: 6px;

    .text-btn-contacted {
      color: #000000;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      margin: 0;
    }
  }
}

.title-header-modal {
  color: #000000;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}

.text-body-modal {
  color: #000000;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin: 0;
}

.div-btn-change-status {
  display: flex;
  flex-direction: column;

  .btn-change-done {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    width: 70px;
    height: 25px;
    background-color: #00cf3a;

    .text-change-done {
      color: #ffffff;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      margin: 0;
    }
  }

  .btn-change-cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    width: 60px;
    height: 25px;
    background-color: #ff0000;
    margin-top: 5px;

    .text-change-cancel {
      color: #ffffff;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      margin: 0;
    }
  }
}

.text-pending-request {
  color: #efd10d;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-decoration: none;
  margin: 0;
}

.text-done-request {
  color: #00cf3a;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-decoration: none;
  margin: 0;
}

.text-cancel-request {
  color: #ff0000;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-decoration: none;
  margin: 0;
}

.text-delete-deep {
  color: #000000;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  margin: 0;
}

.text-delete-deep-hide {
  display: none;
  color: #000000;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
}

.btn-contacted-deep {
  width: 50px;
  height: 20px;
  background-color: #00cf3a;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 5px;
  border-radius: 4px;

  .text-btn-contacted {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }
}
