@import "../index.scss";
@import "../../../@theme/style.scss";


.container-collaborator-by-customer {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  padding-right: 20px;
  padding: 5px;
}

.div-list-collaborator_toggle-switch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20px;
  padding: 5px;
}

.div-note-collaborator {
  p {
    padding: 5px 0px;
  }
}

.div-select-collaborator {
  p {
    padding: 5px 0px;
  }
}

.div-list-address-default_title {
  padding: 5px 0px;
  color: black;
}

.div-item-address {
  display: flex;
  flex-direction: row;
  padding: 0px 5px 5px 0px;
  border-bottom: 1px solid black;
  align-items: center;
}

.icon-map-address {
  margin-right: 10px;
}

.item-address {
  padding: 5px 5px;
  border: 1px solid;
  margin-bottom: 5px;
  border-radius: 4px;
}

.item-tip-ctv {
  border: 1px solid #212529;
  padding: 5px;
  border-radius: 6px;
  width: 70px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-order_tip {
  display: flex;
  flex-direction: row;

  div {
    margin-right: 1rem;
  }
}

.item-tip-ctv_selected {
  background-color: var(--selected-color);
  color: var(--white-color);
}



// Son Css

.container-create-order {
  display: flex;
  gap: $gapL;
  height: 100%;
  width: 100%;

  .container-create-order__info {
    display: flex;
    flex-direction: column;
    gap: $gapM;
    height: 100%;
    width: 65%;
    background-color: $colorWhite;
    border-radius: $borderRadiusXL;
    padding: 16px;
    overflow-y: auto;
    scrollbar-gutter: stable;

    .container-create-order__info--container {
      display: flex;
      gap: $gapL;

      .container-create-order__info--container-child {
        display: flex;
        flex-direction: column;
        gap: $gapM;
        width: 100%;

        .container-create-order__info--container-child-label {
          font-size: $textFontSizeM;
          font-weight: $fontWeightXL;
          color: $colorBlack;
        }
      }
    }

    .container-create-order__info--service {
      display: flex;
      flex-direction: column;
      gap: $gapM;

      .container-create-order__info--service-label {
        font-weight: $fontWeightXL;
        font-size: $textFontSizeM;
        color: $colorBlack;
      }

      .container-create-order__info--service-container {
        display: flex;
        flex-wrap: wrap;
        gap: $gapL;

        .container-create-order__info--service-container-child {
          display: flex;
          flex-direction: column;
          padding: 8px 4px;
          background-color: $colorWhite;
          border-radius: $borderRadiusL;
          // border: 1px solid $colorLightGray;
          width: fit-content;
          align-items: center;
          width: 85px;
          height: 76px;
          gap: $gapXS;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px, rgba(0, 0, 0, 0.2) 0px 0px 3px 0px;
          cursor: pointer;
          transition: all 0.3s ease-out;

          &:hover {
            background-color: $colorLightViolet;
            // box-shadow: rgba(139, 92, 246, 0.5) 0px 0px 10px 0px, rgba(139, 92, 246, 0.7) 0px 0px 3px 0px;

            .container-create-order__info--service-container-child-icon {
              color: $colorBlack;
            }

            .container-create-order__info--service-container-child-label {
              color: $colorBlack;
            }
          }

          &.selected {
            background-color: $colorViolet;
            // box-shadow: rgba(139, 92, 246, 0.5) 0px 0px 10px 0px, rgba(139, 92, 246, 0.7) 0px 0px 3px 0px;

            .container-create-order__info--service-container-child-icon {
              color: $colorWhite;
            }

            .container-create-order__info--service-container-child-label {
              color: $colorWhite;
            }
          }

          .container-create-order__info--service-container-child-icon {
            display: flex;
            align-items: center;
            font-size: $textFontSize2XL;
            color: $colorBlack;
          }

          .container-create-order__info--service-container-child-label {
            text-align: center;
            font-weight: $fontWeightL;
            font-size: $textFontSizeM;
            color: $colorBlack;
          }
        }
      }
    }

    .container-create-order__info--time {
      display: flex;
      flex-direction: column;
      gap: $gapL;

      .container-create-order__info--time-label {
        font-weight: $fontWeightXL;
        font-size: $textFontSizeM;
        color: $colorBlack;
      }

      .container-create-order__info--time-container {
        display: flex;
        gap: $gapL;

        .container-create-order__info--time-container-child {
          display: flex;
          flex-direction: column;
          padding: 8px;
          background-color: $colorWhite;
          border-radius: $borderRadiusL;
          // border: 1px solid $colorLightGray;
          width: fit-content;
          align-items: center;
          width: 118px;
          gap: $gapXS;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px, rgba(0, 0, 0, 0.2) 0px 0px 3px 0px;
          cursor: pointer;
          transition: all 0.3s ease-out;

          &:hover {
            background-color: $colorViolet;
            // box-shadow: rgba(139, 92, 246, 0.5) 0px 0px 10px 0px, rgba(139, 92, 246, 0.7) 0px 0px 3px 0px;

            .container-create-order__info--time-container-child-icon {
              color: $colorWhite;
            }

            .container-create-order__info--time-container-child-label {
              color: $colorWhite;
            }
          }

          &.selected {
            background-color: $colorViolet;
            // box-shadow: rgba(139, 92, 246, 0.5) 0px 0px 10px 0px, rgba(139, 92, 246, 0.7) 0px 0px 3px 0px;

            .container-create-order__info--time-container-child-icon {
              color: $colorWhite;
            }

            .container-create-order__info--time-container-child-label {
              color: $colorWhite;
            }
          }

          .container-create-order__info--time-container-child-icon {
            display: flex;
            align-items: center;
            font-size: $textFontSize2XL;
            color: $colorBlack;
          }

          .container-create-order__info--time-container-child-label {
            text-align: center;
            font-weight: $fontWeightL;
            font-size: $textFontSizeM;
            color: $colorBlack;
          }
        }
      }
    }

    .container-create-order__info--paymemnt-method {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: $gapM;

      .container-create-order__info--paymemnt-method-label {
        font-size: $textFontSizeM;
        font-weight: $fontWeightXL;
      }
    }

    .container-create-order__info-select-collaborator {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: $gapM;

      .container-create-order__info-select-collaborator--label {
        display: flex;
        align-items: center;
        gap: $gapS;

        .container-create-order__info-select-collaborator--label-header {
          font-size: $textFontSizeM;
          font-weight: $fontWeightXL;
          color: $colorBlack;
        }

        .container-create-order__info-select-collaborator--label-favourite-collaborator {
          display: flex;
          align-items: center;
          gap: $gapXS;

          span {
            font-size: $textFontSizeM;
            font-weight: $fontWeightL;
            color: $colorLightGray;
          }
        }
      }
    }

    .container-create-order__info--favorite-collaborator-list {
      display: flex;
      flex-wrap: wrap;
      gap: $gapL;
    }

    .container-create-order__info--tip {
      display: flex;
      flex-direction: column;
      gap: $gapM;

      .container-create-order__info--tip-header {
        display: flex;
        gap: $gapM;
        align-items: center;

        .container-create-order__info--tip-header-label {
          font-size: $textFontSizeM;
          font-weight: $fontWeightXL;
          color: $colorBlack;
        }

        .container-create-order__info--tip-header-suggest {
          display: flex;
          gap: $gapM;

          .container-create-order__info--tip-header-suggest-child {
            padding: 4px 8px;
            border: 1px solid $colorLightGray;
            border-radius: $borderRadiusL;
            background-color: $colorWhite;
            transition: all 0.3s ease-out;
            cursor: pointer;

            &.selected {
              border-color: $colorViolet;
              background-color: $colorViolet;
              // box-shadow: rgba(139, 92, 246, 0.5) 0px 0px 10px 0px, rgba(139, 92, 246, 0.7) 0px 0px 3px 0px;

              span {
                color: $colorWhite;
                font-weight: $fontWeightXL;
              }
            }

            &.disable {
              border-color: $colorBlack;
              background-color: $colorMoreLightGray;
              cursor: not-allowed;
              // box-shadow: rgba(139, 92, 246, 0.5) 0px 0px 10px 0px, rgba(139, 92, 246, 0.7) 0px 0px 3px 0px;

              span {
                color: $colorBlack;
                font-weight: $fontWeightXL;
              }

              &:hover {
                border-color: $colorBlack;
                background-color: $colorMoreLightGray;
  
                span {
                  color: $colorBlack;
                }
              }
            }

            span {
              font-size: $textFontSizeM;
              font-weight: $fontWeightL;
              color: $colorBlack;
            }

            &:hover {
              border-color: $colorViolet;
              background-color: $colorViolet;

              span {
                color: $colorWhite;
              }
            }
          }
        }
      }

    }

    .container-create-order__info--note {
      display: flex;
      flex-direction: column;
      gap: $gapM;

      span {
        font-size: $textFontSizeM;
        font-weight: $fontWeightXL;
        color: $colorBlack;
      }
    }

    .container-create-order__info--voucher {
      display: flex;
      flex-direction: column;
      gap: $gapM;

      .container-create-order__info--voucher-label {
        font-size: $fontWeightL;
        font-weight: $fontWeightXL;
        color: $colorBlack;
      }

      .container-create-order__info--voucher-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: $gapL;

        .container-create-order__info--voucher-container-child {
          display: flex;
          flex-direction: column;
          align-items: center;
          border: 1px solid $colorViolet;
          border-radius: $borderRadiusL;
          background-color: $colorWhite;
          padding: 4px 8px;
          transition: all 0.3s ease-out;
          cursor: pointer;

          .container-create-order__info--voucher-container-child-code {
            font-size: $textFontSizeM;
            font-weight: $fontWeightXL;
            color: $colorOrange;
          }

          .container-create-order__info--voucher-container-child-money {
            font-size: $textFontSizeM;
            font-weight: $fontWeightXL;
            color: $colorBlack;
          }

          .container-create-order__info--voucher-container-child-sub-label {
            font-size: $textFontSizeM;
            font-weight: $fontWeightL;
            color: $colorLightGray;
          }

          &:hover {
            background-color: $colorViolet;

            .container-create-order__info--voucher-container-child-code {
              color: $colorWhite;
            }

            .container-create-order__info--voucher-container-child-money {
              color: $colorWhite;
            }

            .container-create-order__info--voucher-container-child-sub-label {
              color: $colorWhite;
            }
          }

          &.selected {
            background-color: $colorViolet;

            .container-create-order__info--voucher-container-child-code {
              color: $colorWhite;
            }

            .container-create-order__info--voucher-container-child-money {
              color: $colorWhite;
            }

            .container-create-order__info--voucher-container-child-sub-label {
              color: $colorWhite;
            }
          }
        }
      }

    }
  }

  .container-create-order__bill {
    height: 100%;
    width: 35%;
    border-radius: $borderRadiusXL;
    display: flex;
    flex-direction: column;
    gap: $gapXL;
    padding-bottom: 16px;

    .container-create-order__bill--price {
      width: 100%;
      height: fit-content;
      border-radius: $borderRadiusXL;
      background-color: $colorWhite;

      .container-create-order__bill--price-header {
        position: relative;
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: $gapL;

        .container-create-order__bill--price-header-tag {
          display: flex;
          justify-content: space-between;

          .container-create-order__bill--price-header-tag-info {
            background-color: $colorViolet;
            width: fit-content;
            height: fit-content;
            border-bottom-right-radius: 20px;
            border-top-left-radius: 16px;
            padding: 4px 12px 4px 8px;

            span {
              font-size: $textFontSizeM;
              font-weight: $fontWeightXL;
              color: $colorWhite;
            }
          }

          .container-create-order__bill--price-header-tag-detail {
            background-color: $colorMoreLightGray;
            width: fit-content;
            height: fit-content;
            border-bottom-left-radius: 20px;
            border-top-right-radius: 16px;
            padding: 4px 12px 4px 12px;
            cursor: pointer;

            span {
              font-size: $textFontSizeXL;
              font-weight: $fontWeightL;
              color: $colorBlack;
            }
          }
        }


        .container-create-order__bill--price-header-logo {
          position: absolute;
          top: 10%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: $colorViolet;
          border-radius: $borderRadiusL;

          img {
            height: 45px;
            width: 45px;
          }
        }

        .container-create-order__bill--price-header-payment-method {
          display: flex;
          flex-direction: column;
          text-align: center;
          justify-content: center;
          align-items: center;
          padding-bottom: 8px;

          .container-create-order__bill--price-header-payment-method-option {
            font-size: $textFontSizeM;
            font-weight: $fontWeightXL;
            color: $colorBlack;
            text-transform: uppercase;
          }

          .container-create-order__bill--price-header-payment-method-time {
            font-size: $textFontSizeM;
            font-weight: $fontWeightL;
            color: $colorLightGray;
          }
        }
      }

      .container-create-order__bill--price-line {
        position: relative;

        .container-create-order__bill--price-line-dashed {
          // border: none;
          // border-top: 1px dashed black;
          background: repeating-linear-gradient(90deg,
              /* góc của đường thẳng (ngang) */
              transparent, transparent 10px,
              $colorLightGray 10px,
              /* bắt đầu màu đen */
              $colorLightGray 15px
              /* chiều rộng của ô đen */
            );
          width: 100%;
          height: 1px;
          background-color: transparent;
        }

        .container-create-order__bill--price-line-circle {
          position: absolute;
          width: 30px;
          height: 30px;
          border-radius: $borderRadiusFull;
          background-color: #E9EDF7;

          &.left {
            top: 0;
            left: 0;
            transform: translate(-50%, -50%);
          }

          &.right {
            top: 0;
            right: 0;
            transform: translate(50%, -50%);
          }
        }
      }

    }

    .container-create-order__bill--detail {
      width: 100%;
      height: fit-content;

      border-radius: $borderRadiusXL;
      background-color: $colorWhite;

      .container-create-order__bill--detail-header {
        position: relative;
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        gap: $gapL;

        .container-create-order__bill--detail-header-tag {
          background-color: $colorViolet;
          width: fit-content;
          height: fit-content;
          border-bottom-right-radius: 20px;
          border-top-left-radius: 16px;
          padding: 4px 12px 4px 8px;

          span {
            font-size: $textFontSizeM;
            font-weight: $fontWeightXL;
            color: $colorWhite;
          }
        }

        .container-create-order__bill--detail-header-options {
          display: flex;
          align-items: center;
          gap: $gapM;
          padding-right: 16px;

          .container-create-order__bill--detail-header-options-note {
            padding: 8px 4px;
            border-bottom-left-radius: $borderRadiusL;
            border-bottom-right-radius: $borderRadiusL;

            &.pet {
              background-color: $colorExtraLightBlue;
              color: $colorBlue;
            }

            &.favorite-collaborator {
              background-color: $colorExtraLightRed;
              color: $colorRed;
            }

            .container-create-order__bill--detail-header-options-note-icon {
              font-size: $textFontSize2XL;
            }


          }
        }
      }

      .container-create-order__bill--detail-label {
        display: flex;
        align-self: center;
        justify-content: center;
        padding: 8px 0px;

        span {
          font-size: $textFontSizeM;
          font-weight: $fontWeightXL;
          color: $colorBlack;
          text-transform: uppercase;
        }
      }

      .container-create-order__bill--detail-line {
        position: relative;

        .container-create-order__bill--detail-line-dashed {
          // border: none;
          // border-top: 1px dashed black;
          background: repeating-linear-gradient(90deg,
              /* góc của đường thẳng (ngang) */
              transparent, transparent 10px,
              $colorLightGray 10px,
              /* bắt đầu màu đen */
              $colorLightGray 15px
              /* chiều rộng của ô đen */
            );
          width: 100%;
          height: 1px;
          background-color: transparent;
        }

        .container-create-order__bill--detail-line-circle {
          position: absolute;
          width: 30px;
          height: 30px;
          border-radius: $borderRadiusFull;
          background-color: #E9EDF7;

          &.left {
            top: 0;
            left: 0;
            transform: translate(-50%, -50%);
          }

          &.right {
            top: 0;
            right: 0;
            transform: translate(50%, -50%);
          }
        }
      }
    }

  }
}

@media screen and (max-width: 1366px) {
  .container-create-order {
    display: flex;
    gap: $gapL;
    height: 100%;
    width: 100%;

    .container-create-order__info {
      display: flex;
      flex-direction: column;
      gap: $gapM;
      height: 100%;
      width: 60%;
      background-color: $colorWhite;
      border-radius: $borderRadiusXL;
      padding: 16px;
      overflow-y: auto;
      scrollbar-gutter: stable;

      .container-create-order__info--container {
        display: flex;
        gap: $gapL;

        .container-create-order__info--container-child {
          display: flex;
          flex-direction: column;
          gap: $gapM;
          width: 100%;

          .container-create-order__info--container-child-label {
            font-size: $textFontSizeM;
            font-weight: $fontWeightXL;
            color: $colorBlack;
          }
        }
      }

      .container-create-order__info--service {
        display: flex;
        flex-direction: column;
        gap: $gapM;

        .container-create-order__info--service-label {
          font-weight: $fontWeightXL;
          font-size: $textFontSizeM;
          color: $colorBlack;
        }

        .container-create-order__info--service-container {
          display: flex;
          flex-wrap: wrap;
          gap: $gapL;

          .container-create-order__info--service-container-child {
            display: flex;
            flex-direction: column;
            padding: 8px 4px;
            background-color: $colorWhite;
            border-radius: $borderRadiusL;
            // border: 1px solid $colorLightGray;
            width: fit-content;
            align-items: center;
            width: 85px;
            height: 76px;
            gap: $gapXS;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px, rgba(0, 0, 0, 0.2) 0px 0px 3px 0px;
            cursor: pointer;
            transition: all 0.3s ease-out;

            &:hover {
              background-color: $colorLightViolet;
              // box-shadow: rgba(139, 92, 246, 0.5) 0px 0px 10px 0px, rgba(139, 92, 246, 0.7) 0px 0px 3px 0px;

              .container-create-order__info--service-container-child-icon {
                color: $colorBlack;
              }

              .container-create-order__info--service-container-child-label {
                color: $colorBlack;
              }
            }

            &.selected {
              background-color: $colorViolet;
              // box-shadow: rgba(139, 92, 246, 0.5) 0px 0px 10px 0px, rgba(139, 92, 246, 0.7) 0px 0px 3px 0px;

              .container-create-order__info--service-container-child-icon {
                color: $colorWhite;
              }

              .container-create-order__info--service-container-child-label {
                color: $colorWhite;
              }
            }

            .container-create-order__info--service-container-child-icon {
              display: flex;
              align-items: center;
              font-size: $textFontSize2XL;
              color: $colorBlack;
            }

            .container-create-order__info--service-container-child-label {
              text-align: center;
              font-weight: $fontWeightL;
              font-size: $textFontSizeM;
              color: $colorBlack;
            }
          }
        }
      }

      .container-create-order__info--time {
        display: flex;
        flex-direction: column;
        gap: $gapL;

        .container-create-order__info--time-label {
          font-weight: $fontWeightXL;
          font-size: $textFontSizeM;
          color: $colorBlack;
        }

        .container-create-order__info--time-container {
          display: flex;
          gap: $gapL;

          .container-create-order__info--time-container-child {
            display: flex;
            flex-direction: column;
            padding: 8px;
            background-color: $colorWhite;
            border-radius: $borderRadiusL;
            // border: 1px solid $colorLightGray;
            width: fit-content;
            align-items: center;
            width: 118px;
            gap: $gapXS;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px, rgba(0, 0, 0, 0.2) 0px 0px 3px 0px;
            cursor: pointer;
            transition: all 0.3s ease-out;

            &:hover {
              background-color: $colorViolet;
              // box-shadow: rgba(139, 92, 246, 0.5) 0px 0px 10px 0px, rgba(139, 92, 246, 0.7) 0px 0px 3px 0px;

              .container-create-order__info--time-container-child-icon {
                color: $colorWhite;
              }

              .container-create-order__info--time-container-child-label {
                color: $colorWhite;
              }
            }

            &.selected {
              background-color: $colorViolet;
              // box-shadow: rgba(139, 92, 246, 0.5) 0px 0px 10px 0px, rgba(139, 92, 246, 0.7) 0px 0px 3px 0px;

              .container-create-order__info--time-container-child-icon {
                color: $colorWhite;
              }

              .container-create-order__info--time-container-child-label {
                color: $colorWhite;
              }
            }

            .container-create-order__info--time-container-child-icon {
              display: flex;
              align-items: center;
              font-size: $textFontSize2XL;
              color: $colorBlack;
            }

            .container-create-order__info--time-container-child-label {
              text-align: center;
              font-weight: $fontWeightL;
              font-size: $textFontSizeM;
              color: $colorBlack;
            }
          }
        }
      }

      .container-create-order__info--paymemnt-method {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: $gapM;

        .container-create-order__info--paymemnt-method-label {
          font-size: $textFontSizeM;
          font-weight: $fontWeightXL;
        }
      }

      .container-create-order__info-select-collaborator {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: $gapM;

        .container-create-order__info-select-collaborator--label {
          display: flex;
          align-items: center;
          gap: $gapS;

          .container-create-order__info-select-collaborator--label-header {
            font-size: $textFontSizeM;
            font-weight: $fontWeightXL;
            color: $colorBlack;
          }

          .container-create-order__info-select-collaborator--label-favourite-collaborator {
            display: flex;
            align-items: center;
            gap: $gapXS;

            span {
              font-size: $textFontSizeM;
              font-weight: $fontWeightL;
              color: $colorLightGray;
            }
          }
        }
      }

      .container-create-order__info--favorite-collaborator-list {
        display: flex;
        flex-wrap: wrap;
        gap: $gapL;
      }

      .container-create-order__info--tip {
        display: flex;
        flex-direction: column;
        gap: $gapM;

        .container-create-order__info--tip-header {
          display: flex;
          gap: $gapM;
          align-items: center;

          .container-create-order__info--tip-header-label {
            font-size: $textFontSizeM;
            font-weight: $fontWeightXL;
            color: $colorBlack;
          }

          .container-create-order__info--tip-header-suggest {
            display: flex;
            gap: $gapM;

            .container-create-order__info--tip-header-suggest-child {
              padding: 4px 8px;
              border: 1px solid $colorLightGray;
              border-radius: $borderRadiusL;
              background-color: $colorWhite;
              transition: all 0.3s ease-out;
              cursor: pointer;

              &.selected {
                border-color: $colorViolet;
                background-color: $colorViolet;
                // box-shadow: rgba(139, 92, 246, 0.5) 0px 0px 10px 0px, rgba(139, 92, 246, 0.7) 0px 0px 3px 0px;

                span {
                  color: $colorWhite;
                  font-weight: $fontWeightXL;
                }
              }

              span {
                font-size: $textFontSizeM;
                font-weight: $fontWeightL;
                color: $colorBlack;
              }

              &:hover {
                border-color: $colorViolet;
                background-color: $colorViolet;

                span {
                  color: $colorWhite;
                }
              }
            }
          }
        }

      }

      .container-create-order__info--note {
        display: flex;
        flex-direction: column;
        gap: $gapM;

        span {
          font-size: $textFontSizeM;
          font-weight: $fontWeightXL;
          color: $colorBlack;
        }
      }

      .container-create-order__info--voucher {
        display: flex;
        flex-direction: column;
        gap: $gapM;

        .container-create-order__info--voucher-label {
          font-size: $fontWeightL;
          font-weight: $fontWeightXL;
          color: $colorBlack;
        }

        .container-create-order__info--voucher-container {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: $gapL;

          .container-create-order__info--voucher-container-child {
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid $colorViolet;
            border-radius: $borderRadiusL;
            background-color: $colorWhite;
            padding: 4px 8px;
            transition: all 0.3s ease-out;
            cursor: pointer;

            .container-create-order__info--voucher-container-child-code {
              font-size: $textFontSizeM;
              font-weight: $fontWeightXL;
              color: $colorOrange;
            }

            .container-create-order__info--voucher-container-child-money {
              font-size: $textFontSizeM;
              font-weight: $fontWeightXL;
              color: $colorBlack;
            }

            .container-create-order__info--voucher-container-child-sub-label {
              font-size: $textFontSizeM;
              font-weight: $fontWeightL;
              color: $colorLightGray;
            }

            &:hover {
              background-color: $colorViolet;

              .container-create-order__info--voucher-container-child-code {
                color: $colorWhite;
              }

              .container-create-order__info--voucher-container-child-money {
                color: $colorWhite;
              }

              .container-create-order__info--voucher-container-child-sub-label {
                color: $colorWhite;
              }
            }

            &.selected {
              background-color: $colorViolet;

              .container-create-order__info--voucher-container-child-code {
                color: $colorWhite;
              }

              .container-create-order__info--voucher-container-child-money {
                color: $colorWhite;
              }

              .container-create-order__info--voucher-container-child-sub-label {
                color: $colorWhite;
              }
            }
          }
        }

      }
    }

    .container-create-order__bill {
      height: 100%;
      width: 40%;
      border-radius: $borderRadiusXL;
      display: flex;
      flex-direction: column;
      gap: $gapXL;
      padding-bottom: 16px;

      .container-create-order__bill--price {
        width: 100%;
        height: fit-content;
        border-radius: $borderRadiusXL;
        background-color: $colorWhite;

        .container-create-order__bill--price-header {
          position: relative;
          width: 100%;
          height: fit-content;
          display: flex;
          flex-direction: column;
          gap: $gapL;

          .container-create-order__bill--price-header-tag {
            display: flex;
            justify-content: space-between;

            .container-create-order__bill--price-header-tag-info {
              background-color: $colorViolet;
              width: fit-content;
              height: fit-content;
              border-bottom-right-radius: 20px;
              border-top-left-radius: 16px;
              padding: 4px 12px 4px 8px;

              span {
                font-size: $textFontSizeM;
                font-weight: $fontWeightXL;
                color: $colorWhite;
              }
            }

            .container-create-order__bill--price-header-tag-detail {
              background-color: $colorMoreLightGray;
              width: fit-content;
              height: fit-content;
              border-bottom-left-radius: 20px;
              border-top-right-radius: 16px;
              padding: 4px 12px 4px 12px;
              cursor: pointer;

              span {
                font-size: $textFontSizeXL;
                font-weight: $fontWeightL;
                color: $colorBlack;
              }
            }
          }


          .container-create-order__bill--price-header-logo {
            position: absolute;
            top: 10%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $colorViolet;
            border-radius: $borderRadiusL;

            img {
              height: 45px;
              width: 45px;
            }
          }

          .container-create-order__bill--price-header-payment-method {
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            align-items: center;
            padding-bottom: 8px;

            .container-create-order__bill--price-header-payment-method-option {
              font-size: $textFontSizeM;
              font-weight: $fontWeightXL;
              color: $colorBlack;
              text-transform: uppercase;
            }

            .container-create-order__bill--price-header-payment-method-time {
              font-size: $textFontSizeM;
              font-weight: $fontWeightL;
              color: $colorLightGray;
            }
          }
        }

        .container-create-order__bill--price-line {
          position: relative;

          .container-create-order__bill--price-line-dashed {
            // border: none;
            // border-top: 1px dashed black;
            background: repeating-linear-gradient(90deg,
                /* góc của đường thẳng (ngang) */
                transparent, transparent 10px,
                $colorLightGray 10px,
                /* bắt đầu màu đen */
                $colorLightGray 15px
                /* chiều rộng của ô đen */
              );
            width: 100%;
            height: 1px;
            background-color: transparent;
          }

          .container-create-order__bill--price-line-circle {
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: $borderRadiusFull;
            background-color: #E9EDF7;

            &.left {
              top: 0;
              left: 0;
              transform: translate(-50%, -50%);
            }

            &.right {
              top: 0;
              right: 0;
              transform: translate(50%, -50%);
            }
          }
        }

      }

      .container-create-order__bill--detail {
        width: 100%;
        height: fit-content;

        border-radius: $borderRadiusXL;
        background-color: $colorWhite;

        .container-create-order__bill--detail-header {
          position: relative;
          width: 100%;
          height: fit-content;
          display: flex;
          justify-content: space-between;
          gap: $gapL;

          .container-create-order__bill--detail-header-tag {
            background-color: $colorViolet;
            width: fit-content;
            height: fit-content;
            border-bottom-right-radius: 20px;
            border-top-left-radius: 16px;
            padding: 4px 12px 4px 8px;

            span {
              font-size: $textFontSizeM;
              font-weight: $fontWeightXL;
              color: $colorWhite;
            }
          }

          .container-create-order__bill--detail-header-options {
            display: flex;
            align-items: center;
            gap: $gapM;
            padding-right: 16px;

            .container-create-order__bill--detail-header-options-note {
              padding: 8px 4px;
              border-bottom-left-radius: $borderRadiusL;
              border-bottom-right-radius: $borderRadiusL;

              &.pet {
                background-color: $colorExtraLightBlue;
                color: $colorBlue;
              }

              &.favorite-collaborator {
                background-color: $colorExtraLightRed;
                color: $colorRed;
              }

              .container-create-order__bill--detail-header-options-note-icon {
                font-size: $textFontSize2XL;
              }


            }
          }
        }

        .container-create-order__bill--detail-label {
          display: flex;
          align-self: center;
          justify-content: center;
          padding: 8px 0px;

          span {
            font-size: $textFontSizeM;
            font-weight: $fontWeightXL;
            color: $colorBlack;
            text-transform: uppercase;
          }
        }

        .container-create-order__bill--detail-line {
          position: relative;

          .container-create-order__bill--detail-line-dashed {
            // border: none;
            // border-top: 1px dashed black;
            background: repeating-linear-gradient(90deg,
                /* góc của đường thẳng (ngang) */
                transparent, transparent 10px,
                $colorLightGray 10px,
                /* bắt đầu màu đen */
                $colorLightGray 15px
                /* chiều rộng của ô đen */
              );
            width: 100%;
            height: 1px;
            background-color: transparent;
          }

          .container-create-order__bill--detail-line-circle {
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: $borderRadiusFull;
            background-color: #E9EDF7;

            &.left {
              top: 0;
              left: 0;
              transform: translate(-50%, -50%);
            }

            &.right {
              top: 0;
              right: 0;
              transform: translate(50%, -50%);
            }
          }
        }
      }

    }
  }
}

@media screen and (max-width: 1024px) {
  .container-create-order {
    display: flex;
    flex-direction: column;
    gap: $gapL;
    height: fit-content;
    width: 100%;

    .container-create-order__info {
      display: flex;
      flex-direction: column;
      gap: $gapM;
      height: 100%;
      width: 100%;
      background-color: $colorWhite;
      border-radius: $borderRadiusXL;
      padding: 16px;
      overflow-y: auto;
      scrollbar-gutter: stable;

      .container-create-order__info--container {
        display: flex;
        gap: $gapL;

        .container-create-order__info--container-child {
          display: flex;
          flex-direction: column;
          gap: $gapM;
          width: 100%;

          .container-create-order__info--container-child-label {
            font-size: $textFontSizeM;
            font-weight: $fontWeightXL;
            color: $colorBlack;
          }
        }
      }

      .container-create-order__info--service {
        display: flex;
        flex-direction: column;
        gap: $gapM;

        .container-create-order__info--service-label {
          font-weight: $fontWeightXL;
          font-size: $textFontSizeM;
          color: $colorBlack;
        }

        .container-create-order__info--service-container {
          display: flex;
          flex-wrap: wrap;
          gap: $gapL;

          .container-create-order__info--service-container-child {
            display: flex;
            flex-direction: column;
            padding: 8px 4px;
            background-color: $colorWhite;
            border-radius: $borderRadiusL;
            // border: 1px solid $colorLightGray;
            width: fit-content;
            align-items: center;
            width: 85px;
            height: 76px;
            gap: $gapXS;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px, rgba(0, 0, 0, 0.2) 0px 0px 3px 0px;
            cursor: pointer;
            transition: all 0.3s ease-out;

            &:hover {
              background-color: $colorLightViolet;
              // box-shadow: rgba(139, 92, 246, 0.5) 0px 0px 10px 0px, rgba(139, 92, 246, 0.7) 0px 0px 3px 0px;

              .container-create-order__info--service-container-child-icon {
                color: $colorBlack;
              }

              .container-create-order__info--service-container-child-label {
                color: $colorBlack;
              }
            }

            &.selected {
              background-color: $colorViolet;
              // box-shadow: rgba(139, 92, 246, 0.5) 0px 0px 10px 0px, rgba(139, 92, 246, 0.7) 0px 0px 3px 0px;

              .container-create-order__info--service-container-child-icon {
                color: $colorWhite;
              }

              .container-create-order__info--service-container-child-label {
                color: $colorWhite;
              }
            }

            .container-create-order__info--service-container-child-icon {
              display: flex;
              align-items: center;
              font-size: $textFontSize2XL;
              color: $colorBlack;
            }

            .container-create-order__info--service-container-child-label {
              text-align: center;
              font-weight: $fontWeightL;
              font-size: $textFontSizeM;
              color: $colorBlack;
            }
          }
        }
      }

      .container-create-order__info--time {
        display: flex;
        flex-direction: column;
        gap: $gapL;

        .container-create-order__info--time-label {
          font-weight: $fontWeightXL;
          font-size: $textFontSizeM;
          color: $colorBlack;
        }

        .container-create-order__info--time-container {
          display: flex;
          gap: $gapL;

          .container-create-order__info--time-container-child {
            display: flex;
            flex-direction: column;
            padding: 8px;
            background-color: $colorWhite;
            border-radius: $borderRadiusL;
            // border: 1px solid $colorLightGray;
            width: fit-content;
            align-items: center;
            width: 118px;
            gap: $gapXS;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px, rgba(0, 0, 0, 0.2) 0px 0px 3px 0px;
            cursor: pointer;
            transition: all 0.3s ease-out;

            &:hover {
              background-color: $colorViolet;
              // box-shadow: rgba(139, 92, 246, 0.5) 0px 0px 10px 0px, rgba(139, 92, 246, 0.7) 0px 0px 3px 0px;

              .container-create-order__info--time-container-child-icon {
                color: $colorWhite;
              }

              .container-create-order__info--time-container-child-label {
                color: $colorWhite;
              }
            }

            &.selected {
              background-color: $colorViolet;
              // box-shadow: rgba(139, 92, 246, 0.5) 0px 0px 10px 0px, rgba(139, 92, 246, 0.7) 0px 0px 3px 0px;

              .container-create-order__info--time-container-child-icon {
                color: $colorWhite;
              }

              .container-create-order__info--time-container-child-label {
                color: $colorWhite;
              }
            }

            .container-create-order__info--time-container-child-icon {
              display: flex;
              align-items: center;
              font-size: $textFontSize2XL;
              color: $colorBlack;
            }

            .container-create-order__info--time-container-child-label {
              text-align: center;
              font-weight: $fontWeightL;
              font-size: $textFontSizeM;
              color: $colorBlack;
            }
          }
        }
      }

      .container-create-order__info--paymemnt-method {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: $gapM;

        .container-create-order__info--paymemnt-method-label {
          font-size: $textFontSizeM;
          font-weight: $fontWeightXL;
        }
      }

      .container-create-order__info-select-collaborator {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: $gapM;

        .container-create-order__info-select-collaborator--label {
          display: flex;
          align-items: center;
          gap: $gapS;

          .container-create-order__info-select-collaborator--label-header {
            font-size: $textFontSizeM;
            font-weight: $fontWeightXL;
            color: $colorBlack;
          }

          .container-create-order__info-select-collaborator--label-favourite-collaborator {
            display: flex;
            align-items: center;
            gap: $gapXS;

            span {
              font-size: $textFontSizeM;
              font-weight: $fontWeightL;
              color: $colorLightGray;
            }
          }
        }
      }

      .container-create-order__info--favorite-collaborator-list {
        display: flex;
        flex-wrap: wrap;
        gap: $gapL;
      }

      .container-create-order__info--tip {
        display: flex;
        flex-direction: column;
        gap: $gapM;

        .container-create-order__info--tip-header {
          display: flex;
          gap: $gapM;
          align-items: center;

          .container-create-order__info--tip-header-label {
            font-size: $textFontSizeM;
            font-weight: $fontWeightXL;
            color: $colorBlack;
          }

          .container-create-order__info--tip-header-suggest {
            display: flex;
            gap: $gapM;

            .container-create-order__info--tip-header-suggest-child {
              padding: 4px 8px;
              border: 1px solid $colorLightGray;
              border-radius: $borderRadiusL;
              background-color: $colorWhite;
              transition: all 0.3s ease-out;
              cursor: pointer;

              &.selected {
                border-color: $colorViolet;
                background-color: $colorViolet;
                // box-shadow: rgba(139, 92, 246, 0.5) 0px 0px 10px 0px, rgba(139, 92, 246, 0.7) 0px 0px 3px 0px;

                span {
                  color: $colorWhite;
                  font-weight: $fontWeightXL;
                }
              }

              span {
                font-size: $textFontSizeM;
                font-weight: $fontWeightL;
                color: $colorBlack;
              }

              &:hover {
                border-color: $colorViolet;
                background-color: $colorViolet;

                span {
                  color: $colorWhite;
                }
              }
            }
          }
        }

      }

      .container-create-order__info--note {
        display: flex;
        flex-direction: column;
        gap: $gapM;

        span {
          font-size: $textFontSizeM;
          font-weight: $fontWeightXL;
          color: $colorBlack;
        }
      }

      .container-create-order__info--voucher {
        display: flex;
        flex-direction: column;
        gap: $gapM;

        .container-create-order__info--voucher-label {
          font-size: $fontWeightL;
          font-weight: $fontWeightXL;
          color: $colorBlack;
        }

        .container-create-order__info--voucher-container {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: $gapL;

          .container-create-order__info--voucher-container-child {
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid $colorViolet;
            border-radius: $borderRadiusL;
            background-color: $colorWhite;
            padding: 4px 8px;
            transition: all 0.3s ease-out;
            cursor: pointer;

            .container-create-order__info--voucher-container-child-code {
              font-size: $textFontSizeM;
              font-weight: $fontWeightXL;
              color: $colorOrange;
            }

            .container-create-order__info--voucher-container-child-money {
              font-size: $textFontSizeM;
              font-weight: $fontWeightXL;
              color: $colorBlack;
            }

            .container-create-order__info--voucher-container-child-sub-label {
              font-size: $textFontSizeM;
              font-weight: $fontWeightL;
              color: $colorLightGray;
            }

            &:hover {
              background-color: $colorViolet;

              .container-create-order__info--voucher-container-child-code {
                color: $colorWhite;
              }

              .container-create-order__info--voucher-container-child-money {
                color: $colorWhite;
              }

              .container-create-order__info--voucher-container-child-sub-label {
                color: $colorWhite;
              }
            }

            &.selected {
              background-color: $colorViolet;

              .container-create-order__info--voucher-container-child-code {
                color: $colorWhite;
              }

              .container-create-order__info--voucher-container-child-money {
                color: $colorWhite;
              }

              .container-create-order__info--voucher-container-child-sub-label {
                color: $colorWhite;
              }
            }
          }
        }

      }
    }

    .container-create-order__bill {
      height: 100%;
      width: 100%;
      border-radius: $borderRadiusXL;
      display: flex;
      flex-direction: row;
      gap: $gapL;
      padding-bottom: 16px;

      .container-create-order__bill--price {
        width: 100%;
        height: fit-content;
        border-radius: $borderRadiusXL;
        background-color: $colorWhite;

        .container-create-order__bill--price-header {
          position: relative;
          width: 100%;
          height: fit-content;
          display: flex;
          flex-direction: column;
          gap: $gapL;

          .container-create-order__bill--price-header-tag {
            display: flex;
            justify-content: space-between;

            .container-create-order__bill--price-header-tag-info {
              background-color: $colorViolet;
              width: fit-content;
              height: fit-content;
              border-bottom-right-radius: 20px;
              border-top-left-radius: 16px;
              padding: 4px 12px 4px 8px;

              span {
                font-size: $textFontSizeM;
                font-weight: $fontWeightXL;
                color: $colorWhite;
              }
            }

            .container-create-order__bill--price-header-tag-detail {
              background-color: $colorMoreLightGray;
              width: fit-content;
              height: fit-content;
              border-bottom-left-radius: 20px;
              border-top-right-radius: 16px;
              padding: 4px 12px 4px 12px;
              cursor: pointer;

              span {
                font-size: $textFontSizeXL;
                font-weight: $fontWeightL;
                color: $colorBlack;
              }
            }
          }


          .container-create-order__bill--price-header-logo {
            position: absolute;
            top: 10%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $colorViolet;
            border-radius: $borderRadiusL;

            img {
              height: 45px;
              width: 45px;
            }
          }

          .container-create-order__bill--price-header-payment-method {
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            align-items: center;
            padding-bottom: 8px;

            .container-create-order__bill--price-header-payment-method-option {
              font-size: $textFontSizeM;
              font-weight: $fontWeightXL;
              color: $colorBlack;
              text-transform: uppercase;
            }

            .container-create-order__bill--price-header-payment-method-time {
              font-size: $textFontSizeM;
              font-weight: $fontWeightL;
              color: $colorLightGray;
            }
          }
        }

        .container-create-order__bill--price-line {
          position: relative;

          .container-create-order__bill--price-line-dashed {
            // border: none;
            // border-top: 1px dashed black;
            background: repeating-linear-gradient(90deg,
                /* góc của đường thẳng (ngang) */
                transparent, transparent 10px,
                $colorLightGray 10px,
                /* bắt đầu màu đen */
                $colorLightGray 15px
                /* chiều rộng của ô đen */
              );
            width: 100%;
            height: 1px;
            background-color: transparent;
          }

          .container-create-order__bill--price-line-circle {
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: $borderRadiusFull;
            background-color: #E9EDF7;

            &.left {
              top: 0;
              left: 0;
              transform: translate(-50%, -50%);
            }

            &.right {
              top: 0;
              right: 0;
              transform: translate(50%, -50%);
            }
          }
        }

      }

      .container-create-order__bill--detail {
        width: 100%;
        height: fit-content;

        border-radius: $borderRadiusXL;
        background-color: $colorWhite;

        .container-create-order__bill--detail-header {
          position: relative;
          width: 100%;
          height: fit-content;
          display: flex;
          justify-content: space-between;
          gap: $gapL;

          .container-create-order__bill--detail-header-tag {
            background-color: $colorViolet;
            width: fit-content;
            height: fit-content;
            border-bottom-right-radius: 20px;
            border-top-left-radius: 16px;
            padding: 4px 12px 4px 8px;

            span {
              font-size: $textFontSizeM;
              font-weight: $fontWeightXL;
              color: $colorWhite;
            }
          }

          .container-create-order__bill--detail-header-options {
            display: flex;
            align-items: center;
            gap: $gapM;
            padding-right: 16px;

            .container-create-order__bill--detail-header-options-note {
              padding: 8px 4px;
              border-bottom-left-radius: $borderRadiusL;
              border-bottom-right-radius: $borderRadiusL;

              &.pet {
                background-color: $colorExtraLightBlue;
                color: $colorBlue;
              }

              &.favorite-collaborator {
                background-color: $colorExtraLightRed;
                color: $colorRed;
              }

              .container-create-order__bill--detail-header-options-note-icon {
                font-size: $textFontSize2XL;
              }


            }
          }
        }

        .container-create-order__bill--detail-label {
          display: flex;
          align-self: center;
          justify-content: center;
          padding: 8px 0px;

          span {
            font-size: $textFontSizeM;
            font-weight: $fontWeightXL;
            color: $colorBlack;
            text-transform: uppercase;
          }
        }

        .container-create-order__bill--detail-line {
          position: relative;

          .container-create-order__bill--detail-line-dashed {
            // border: none;
            // border-top: 1px dashed black;
            background: repeating-linear-gradient(90deg,
                /* góc của đường thẳng (ngang) */
                transparent, transparent 10px,
                $colorLightGray 10px,
                /* bắt đầu màu đen */
                $colorLightGray 15px
                /* chiều rộng của ô đen */
              );
            width: 100%;
            height: 1px;
            background-color: transparent;
          }

          .container-create-order__bill--detail-line-circle {
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: $borderRadiusFull;
            background-color: #E9EDF7;

            &.left {
              top: 0;
              left: 0;
              transform: translate(-50%, -50%);
            }

            &.right {
              top: 0;
              right: 0;
              transform: translate(50%, -50%);
            }
          }
        }
      }

    }
  }
}

@media screen and (max-width: 768px) {
  .container-create-order {
    display: flex;
    flex-direction: column;
    gap: $gapL;
    height: fit-content;
    width: 100%;

    .container-create-order__info {
      display: flex;
      flex-direction: column;
      gap: $gapM;
      height: 100%;
      width: 100%;
      background-color: $colorWhite;
      border-radius: $borderRadiusXL;
      padding: 16px;
      overflow-y: auto;
      scrollbar-gutter: stable;

      .container-create-order__info--container {
        display: flex;
        gap: $gapL;

        .container-create-order__info--container-child {
          display: flex;
          flex-direction: column;
          gap: $gapM;
          width: 100%;

          .container-create-order__info--container-child-label {
            font-size: $textFontSizeM;
            font-weight: $fontWeightXL;
            color: $colorBlack;
          }
        }
      }

      .container-create-order__info--service {
        display: flex;
        flex-direction: column;
        gap: $gapM;

        .container-create-order__info--service-label {
          font-weight: $fontWeightXL;
          font-size: $textFontSizeM;
          color: $colorBlack;
        }

        .container-create-order__info--service-container {
          display: flex;
          flex-wrap: wrap;
          gap: $gapL;

          .container-create-order__info--service-container-child {
            display: flex;
            flex-direction: column;
            padding: 8px 4px;
            background-color: $colorWhite;
            border-radius: $borderRadiusL;
            // border: 1px solid $colorLightGray;
            width: fit-content;
            align-items: center;
            width: 85px;
            height: 76px;
            gap: $gapXS;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px, rgba(0, 0, 0, 0.2) 0px 0px 3px 0px;
            cursor: pointer;
            transition: all 0.3s ease-out;

            &:hover {
              background-color: $colorLightViolet;
              // box-shadow: rgba(139, 92, 246, 0.5) 0px 0px 10px 0px, rgba(139, 92, 246, 0.7) 0px 0px 3px 0px;

              .container-create-order__info--service-container-child-icon {
                color: $colorBlack;
              }

              .container-create-order__info--service-container-child-label {
                color: $colorBlack;
              }
            }

            &.selected {
              background-color: $colorViolet;
              // box-shadow: rgba(139, 92, 246, 0.5) 0px 0px 10px 0px, rgba(139, 92, 246, 0.7) 0px 0px 3px 0px;

              .container-create-order__info--service-container-child-icon {
                color: $colorWhite;
              }

              .container-create-order__info--service-container-child-label {
                color: $colorWhite;
              }
            }

            .container-create-order__info--service-container-child-icon {
              display: flex;
              align-items: center;
              font-size: $textFontSize2XL;
              color: $colorBlack;
            }

            .container-create-order__info--service-container-child-label {
              text-align: center;
              font-weight: $fontWeightL;
              font-size: $textFontSizeM;
              color: $colorBlack;
            }
          }
        }
      }

      .container-create-order__info--time {
        display: flex;
        flex-direction: column;
        gap: $gapL;

        .container-create-order__info--time-label {
          font-weight: $fontWeightXL;
          font-size: $textFontSizeM;
          color: $colorBlack;
        }

        .container-create-order__info--time-container {
          display: flex;
          gap: $gapL;

          .container-create-order__info--time-container-child {
            display: flex;
            flex-direction: column;
            padding: 8px;
            background-color: $colorWhite;
            border-radius: $borderRadiusL;
            // border: 1px solid $colorLightGray;
            width: fit-content;
            align-items: center;
            width: 118px;
            gap: $gapXS;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px, rgba(0, 0, 0, 0.2) 0px 0px 3px 0px;
            cursor: pointer;
            transition: all 0.3s ease-out;

            &:hover {
              background-color: $colorViolet;
              // box-shadow: rgba(139, 92, 246, 0.5) 0px 0px 10px 0px, rgba(139, 92, 246, 0.7) 0px 0px 3px 0px;

              .container-create-order__info--time-container-child-icon {
                color: $colorWhite;
              }

              .container-create-order__info--time-container-child-label {
                color: $colorWhite;
              }
            }

            &.selected {
              background-color: $colorViolet;
              // box-shadow: rgba(139, 92, 246, 0.5) 0px 0px 10px 0px, rgba(139, 92, 246, 0.7) 0px 0px 3px 0px;

              .container-create-order__info--time-container-child-icon {
                color: $colorWhite;
              }

              .container-create-order__info--time-container-child-label {
                color: $colorWhite;
              }
            }

            .container-create-order__info--time-container-child-icon {
              display: flex;
              align-items: center;
              font-size: $textFontSize2XL;
              color: $colorBlack;
            }

            .container-create-order__info--time-container-child-label {
              text-align: center;
              font-weight: $fontWeightL;
              font-size: $textFontSizeM;
              color: $colorBlack;
            }
          }
        }
      }

      .container-create-order__info--paymemnt-method {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: $gapM;

        .container-create-order__info--paymemnt-method-label {
          font-size: $textFontSizeM;
          font-weight: $fontWeightXL;
        }
      }

      .container-create-order__info-select-collaborator {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: $gapM;

        .container-create-order__info-select-collaborator--label {
          display: flex;
          align-items: center;
          gap: $gapS;

          .container-create-order__info-select-collaborator--label-header {
            font-size: $textFontSizeM;
            font-weight: $fontWeightXL;
            color: $colorBlack;
          }

          .container-create-order__info-select-collaborator--label-favourite-collaborator {
            display: flex;
            align-items: center;
            gap: $gapXS;

            span {
              font-size: $textFontSizeM;
              font-weight: $fontWeightL;
              color: $colorLightGray;
            }
          }
        }
      }

      .container-create-order__info--favorite-collaborator-list {
        display: flex;
        flex-wrap: wrap;
        gap: $gapL;
      }

      .container-create-order__info--tip {
        display: flex;
        flex-direction: column;
        gap: $gapM;

        .container-create-order__info--tip-header {
          display: flex;
          gap: $gapM;
          align-items: center;

          .container-create-order__info--tip-header-label {
            font-size: $textFontSizeM;
            font-weight: $fontWeightXL;
            color: $colorBlack;
          }

          .container-create-order__info--tip-header-suggest {
            display: flex;
            gap: $gapM;

            .container-create-order__info--tip-header-suggest-child {
              padding: 4px 8px;
              border: 1px solid $colorLightGray;
              border-radius: $borderRadiusL;
              background-color: $colorWhite;
              transition: all 0.3s ease-out;
              cursor: pointer;

              &.selected {
                border-color: $colorViolet;
                background-color: $colorViolet;
                // box-shadow: rgba(139, 92, 246, 0.5) 0px 0px 10px 0px, rgba(139, 92, 246, 0.7) 0px 0px 3px 0px;

                span {
                  color: $colorWhite;
                  font-weight: $fontWeightXL;
                }
              }

              span {
                font-size: $textFontSizeM;
                font-weight: $fontWeightL;
                color: $colorBlack;
              }

              &:hover {
                border-color: $colorViolet;
                background-color: $colorViolet;

                span {
                  color: $colorWhite;
                }
              }
            }
          }
        }

      }

      .container-create-order__info--note {
        display: flex;
        flex-direction: column;
        gap: $gapM;

        span {
          font-size: $textFontSizeM;
          font-weight: $fontWeightXL;
          color: $colorBlack;
        }
      }

      .container-create-order__info--voucher {
        display: flex;
        flex-direction: column;
        gap: $gapM;

        .container-create-order__info--voucher-label {
          font-size: $fontWeightL;
          font-weight: $fontWeightXL;
          color: $colorBlack;
        }

        .container-create-order__info--voucher-container {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: $gapL;

          .container-create-order__info--voucher-container-child {
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid $colorViolet;
            border-radius: $borderRadiusL;
            background-color: $colorWhite;
            padding: 4px 8px;
            transition: all 0.3s ease-out;
            cursor: pointer;

            .container-create-order__info--voucher-container-child-code {
              font-size: $textFontSizeM;
              font-weight: $fontWeightXL;
              color: $colorOrange;
            }

            .container-create-order__info--voucher-container-child-money {
              font-size: $textFontSizeM;
              font-weight: $fontWeightXL;
              color: $colorBlack;
            }

            .container-create-order__info--voucher-container-child-sub-label {
              font-size: $textFontSizeM;
              font-weight: $fontWeightL;
              color: $colorLightGray;
            }

            &:hover {
              background-color: $colorViolet;

              .container-create-order__info--voucher-container-child-code {
                color: $colorWhite;
              }

              .container-create-order__info--voucher-container-child-money {
                color: $colorWhite;
              }

              .container-create-order__info--voucher-container-child-sub-label {
                color: $colorWhite;
              }
            }

            &.selected {
              background-color: $colorViolet;

              .container-create-order__info--voucher-container-child-code {
                color: $colorWhite;
              }

              .container-create-order__info--voucher-container-child-money {
                color: $colorWhite;
              }

              .container-create-order__info--voucher-container-child-sub-label {
                color: $colorWhite;
              }
            }
          }
        }

      }
    }

    .container-create-order__bill {
      height: 100%;
      width: 100%;
      border-radius: $borderRadiusXL;
      display: flex;
      flex-direction: column;
      gap: $gapXL;
      padding-bottom: 16px;

      .container-create-order__bill--price {
        width: 100%;
        height: fit-content;
        border-radius: $borderRadiusXL;
        background-color: $colorWhite;

        .container-create-order__bill--price-header {
          position: relative;
          width: 100%;
          height: fit-content;
          display: flex;
          flex-direction: column;
          gap: $gapL;

          .container-create-order__bill--price-header-tag {
            display: flex;
            justify-content: space-between;

            .container-create-order__bill--price-header-tag-info {
              background-color: $colorViolet;
              width: fit-content;
              height: fit-content;
              border-bottom-right-radius: 20px;
              border-top-left-radius: 16px;
              padding: 4px 12px 4px 8px;

              span {
                font-size: $textFontSizeM;
                font-weight: $fontWeightXL;
                color: $colorWhite;
              }
            }

            .container-create-order__bill--price-header-tag-detail {
              background-color: $colorMoreLightGray;
              width: fit-content;
              height: fit-content;
              border-bottom-left-radius: 20px;
              border-top-right-radius: 16px;
              padding: 4px 12px 4px 12px;
              cursor: pointer;

              span {
                font-size: $textFontSizeXL;
                font-weight: $fontWeightL;
                color: $colorBlack;
              }
            }
          }


          .container-create-order__bill--price-header-logo {
            position: absolute;
            top: 10%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $colorViolet;
            border-radius: $borderRadiusL;

            img {
              height: 45px;
              width: 45px;
            }
          }

          .container-create-order__bill--price-header-payment-method {
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            align-items: center;
            padding-bottom: 8px;

            .container-create-order__bill--price-header-payment-method-option {
              font-size: $textFontSizeM;
              font-weight: $fontWeightXL;
              color: $colorBlack;
              text-transform: uppercase;
            }

            .container-create-order__bill--price-header-payment-method-time {
              font-size: $textFontSizeM;
              font-weight: $fontWeightL;
              color: $colorLightGray;
            }
          }
        }

        .container-create-order__bill--price-line {
          position: relative;

          .container-create-order__bill--price-line-dashed {
            // border: none;
            // border-top: 1px dashed black;
            background: repeating-linear-gradient(90deg,
                /* góc của đường thẳng (ngang) */
                transparent, transparent 10px,
                $colorLightGray 10px,
                /* bắt đầu màu đen */
                $colorLightGray 15px
                /* chiều rộng của ô đen */
              );
            width: 100%;
            height: 1px;
            background-color: transparent;
          }

          .container-create-order__bill--price-line-circle {
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: $borderRadiusFull;
            background-color: #E9EDF7;

            &.left {
              top: 0;
              left: 0;
              transform: translate(-50%, -50%);
            }

            &.right {
              top: 0;
              right: 0;
              transform: translate(50%, -50%);
            }
          }
        }

      }

      .container-create-order__bill--detail {
        width: 100%;
        height: fit-content;

        border-radius: $borderRadiusXL;
        background-color: $colorWhite;

        .container-create-order__bill--detail-header {
          position: relative;
          width: 100%;
          height: fit-content;
          display: flex;
          justify-content: space-between;
          gap: $gapL;

          .container-create-order__bill--detail-header-tag {
            background-color: $colorViolet;
            width: fit-content;
            height: fit-content;
            border-bottom-right-radius: 20px;
            border-top-left-radius: 16px;
            padding: 4px 12px 4px 8px;

            span {
              font-size: $textFontSizeM;
              font-weight: $fontWeightXL;
              color: $colorWhite;
            }
          }

          .container-create-order__bill--detail-header-options {
            display: flex;
            align-items: center;
            gap: $gapM;
            padding-right: 16px;

            .container-create-order__bill--detail-header-options-note {
              padding: 8px 4px;
              border-bottom-left-radius: $borderRadiusL;
              border-bottom-right-radius: $borderRadiusL;

              &.pet {
                background-color: $colorExtraLightBlue;
                color: $colorBlue;
              }

              &.favorite-collaborator {
                background-color: $colorExtraLightRed;
                color: $colorRed;
              }

              .container-create-order__bill--detail-header-options-note-icon {
                font-size: $textFontSize2XL;
              }


            }
          }
        }

        .container-create-order__bill--detail-label {
          display: flex;
          align-self: center;
          justify-content: center;
          padding: 8px 0px;

          span {
            font-size: $textFontSizeM;
            font-weight: $fontWeightXL;
            color: $colorBlack;
            text-transform: uppercase;
          }
        }

        .container-create-order__bill--detail-line {
          position: relative;

          .container-create-order__bill--detail-line-dashed {
            // border: none;
            // border-top: 1px dashed black;
            background: repeating-linear-gradient(90deg,
                /* góc của đường thẳng (ngang) */
                transparent, transparent 10px,
                $colorLightGray 10px,
                /* bắt đầu màu đen */
                $colorLightGray 15px
                /* chiều rộng của ô đen */
              );
            width: 100%;
            height: 1px;
            background-color: transparent;
          }

          .container-create-order__bill--detail-line-circle {
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: $borderRadiusFull;
            background-color: #E9EDF7;

            &.left {
              top: 0;
              left: 0;
              transform: translate(-50%, -50%);
            }

            &.right {
              top: 0;
              right: 0;
              transform: translate(50%, -50%);
            }
          }
        }
      }

    }
  }
}


.container-create-order__content-child {
  display: flex;
  margin-bottom: 4px;

  .container-create-order__content-child--default-address {
    display: flex;
    align-items: center;
    gap: $gapS;
    padding: 0px 8px;
    border-radius: $borderRadiusL;

    span {
      color: $colorBlack;
      font-size: $textFontSizeM;
      font-weight: $fontWeightL;
    }
  }

  .container-create-order__content-child--add-address {
    display: flex;
    align-items: center;
    gap: $gapXS;
    border-left: 1px solid $colorLightGray;
    width: fit-content;
    padding: 0px 8px;
    cursor: pointer;
    transition: all 0.3s ease-out;

    &:hover {
      span {
        color: $colorDarkViolet;
      }
    }

    span {
      color: $colorViolet;
      font-size: $textFontSizeM;
      font-weight: $fontWeightL;
      transition: all 0.3s ease-out;
    }

    .container-create-order__content-child--add-address-icon {
      color: $colorViolet;
    }
  }
}

.container-create-order-flex {
  display: flex;
  width: 100%;
  gap: $gapL;
}

// Thanh scroll-bar
.container-create-order__info::-webkit-scrollbar {
  width: 0.7em;
}

.container-create-order__info::-webkit-scrollbar-track {
  background-color: transparent;
  margin-block: 2em;
  border-radius: 8px;
}

.container-create-order__info::-webkit-scrollbar-thumb {
  // border-radius: $borderRadiusFull;
  border-top-left-radius: $borderRadiusFull;
  border-bottom-left-radius: $borderRadiusFull;
  border-right: 4px white solid;
  background-color: $colorExtraLightGray;
}

.container-create-order__info::-webkit-scrollbar-thumb:hover {
  background-color: $colorMoreLightGray;
}