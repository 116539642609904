@import "../../../../../../../@theme/style.scss";

.div-list-collaborator {
  display: flex;
  flex-direction: column;
  width: 100%;

  .div-item-list {
    display: flex;
    min-height: 50px;
    width: 100%;
    flex-direction: column;
    border-bottom: 1px solid #000;
    margin-top: 10px;

    .div-column-1 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .text-title {
        font-family: "Roboto";
        font-weight: 500;
        font-size: 18px;
        color: black;
        text-decoration: none;
        margin: 0;
      }

      .text-money-plus {
        font-family: "Roboto";
        font-weight: 500;
        font-size: 18px;
        color: #00cf3a;
        text-decoration: none;
        margin: 0;
      }

      .text-money-deduction {
        font-family: "Roboto";
        font-weight: 500;
        font-size: 18px;
        color: #ff0000;
        text-decoration: none;
        margin: 0;
      }
    }

    .text-date-history {
      font-family: "Roboto";
      font-weight: 400;
      font-size: 12px;
      color: rgb(170, 170, 170);
      text-decoration: none;
      margin: 0;
    }

    .div-surplus {
      display: flex;
      flex-direction: row;
      align-items: center;

      .div-text-title-surplus {
        width: 70px;

        .text-title-surplus {
          font-family: "Roboto";
          font-weight: 500;
          font-size: 12px;
          color: black;
          text-decoration: none;
          margin: 0;
        }
      }

      .div-money {
        display: flex;
        width: 80px;
        align-items: flex-end;
        justify-content: flex-end;

        .text-money {
          font-family: "Roboto";
          font-weight: 500;
          font-size: 12px;
          color: black;
          text-decoration: none;
          margin: 0;
        }
      }
    }

    .text-surplus {
      font-family: "Roboto";
      font-weight: 400;
      font-size: 12px;
      color: black;
      text-decoration: none;
      margin: 0 0 0 5px;
    }

    .div-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;

      .icon-plus {
        color: #00cf3a;
      }

      .icon-deduction {
        color: #ff0000;
      }
    }
  }
}

.div-pagination {
  margin-top: 10px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.div-monney {
  display: flex;
  flex-direction: column;

  .text-title-monney {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000 !important;
    margin: 0;
  }

  .text-monney {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000000 !important;
    margin: 0 0 0 5px;
  }
}

@media screen and (max-width: 490px) {
  .div-monney {
    display: flex;
    flex-direction: column;

    .text-title-monney {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #000000 !important;
      margin: 0;
    }

    .text-monney {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000 !important;
      margin: 0 0 0 5px;
    }
  }

  .div-list-collaborator {
    display: flex;
    flex-direction: column;
    width: 100%;

    .div-item-list {
      display: flex;
      min-height: 50px;
      width: 100%;
      flex-direction: column;
      border-bottom: 1px solid #000;
      margin-top: 10px;

      .div-column-1 {
        display: flex;
        flex-direction: row;

        .text-title {
          font-family: "Roboto";
          font-weight: 500;
          font-size: 12px;
          color: black;
          text-decoration: none;
          width: 70%;
        }

        .text-money-plus {
          font-family: "Roboto";
          font-weight: 500;
          font-size: 12px;
          color: #00cf3a;
          text-decoration: none;
          width: 30%;
          text-align: right;
        }

        .text-money-deduction {
          font-family: "Roboto";
          font-weight: 500;
          font-size: 12px;
          color: #ff0000;
          text-decoration: none;
          width: 30%;
          text-align: right;
        }
      }

      .text-date-history {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 12px;
        color: rgb(170, 170, 170);
        text-decoration: none;
      }

      .div-surplus {
        display: flex;
        flex-direction: row;

        .div-text-title-surplus {
          width: 60px;

          .text-title-surplus {
            font-family: "Roboto";
            font-weight: 500;
            font-size: 12px;
            color: black;
            text-decoration: none;
            margin: 0;
          }
        }

        .div-money {
          display: flex;
          width: 80px;
          align-items: flex-end;
          justify-content: flex-end;

          .text-money {
            font-family: "Roboto";
            font-weight: 500;
            font-size: 12px;
            color: black;
            text-decoration: none;
            margin: 0;
          }
        }
      }

      .text-surplus {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 12px;
        color: black;
        text-decoration: none;
        margin: 0 0 0 5px;
      }

      .div-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;

        .icon-plus {
          color: #00cf3a;
        }

        .icon-deduction {
          color: #ff0000;
        }
      }
    }
  }
}

// ~~~ Trang lịch sử tài chính của đối tác ~~~
.collaborator-history {
  display: flex;
  flex-direction: column;
  gap: $gapL;

  /* Bố cục của các thẻ thể hiện giá trị tổng */
  .collaborator-history__top {
    display: flex;
    gap: $gapL;
    .collaborator-history__top--left {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: $gapL;
      width: 20%;
    }
    .collaborator-history__top--right {
      width: 80%;
    }

  }

  /* Bố cục của các thẻ chính */
  .collaborator-history__body {
    display: flex;
    flex-direction: column;
    gap: $gapL;
    padding-bottom: 16px;

    /* Bố cục của thẻ lịch sử dòng tiền */
    .collaborator-history__body--financial-history {
      background-color: $colorWhite;
      border-radius: $borderRadiusL;
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 3px;

      /* Header */
      .collaborator-history__body--financial-history-header {
        display: flex;
        padding: 12px 12px;
        border-bottom: 2px solid $colorExtraLightGray;

        span {
          font-size: $textFontSizeM;
          font-weight: $fontWeightXL;
        }
      }

      /* Body */
      .collaborator-history__body--financial-history-body {
        padding: 16px 20px;
        
      }
    }

    /* Bố cục của thẻ yêu cầu nạp rút */
    .collaborator-history__body--withdrawal {
      
    }
  }
}




.collaborator-activity__statistics--overview-statistic {
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $gapXS;
  background-color: $colorWhite;
  border-radius: $borderRadiusL;
  box-shadow:
    rgba(67, 71, 85, 0.27) 0px 1px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.75em 2em;

  .collaborator-activity__statistics--overview-statistic-icon {
    padding: 8px;
    width: 36px;
    height: 36px;
    border-radius: $borderRadiusFull;

    &.green {
      background-color: $colorExtraLightGreen;
    }

    &.red {
      background-color: $colorExtraLightRed;
    }

    &.blue {
      background-color: $colorExtraLightBlue;
    }

    &.yellow {
      background-color: $colorExtraLightYellow;
    }
  }

  .collaborator-activity__statistics--overview-statistic-header {
    padding-top: 4px;
    font-size: $textFontSizeM;
    font-weight: $fontWeightXL;
  }

  .collaborator-activity__statistics--overview-statistic-number {
    font-size: $textFontSize2XL;
    font-weight: $fontWeightXL;

    &.green {
      color: $colorGreen;
    }

    &.red {
      color: $colorRed;
    }

    &.blue {
      color: $colorBlue;
    }

    &.yellow {
      color: $colorYellow;
    }
  }

  .collaborator-activity__statistics--overview-statistic-previous-period {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gapXS;

    .collaborator-activity__statistics--overview-statistic-previous-period-label {
      font-size: $textFontSizeM;
      font-weight: $fontWeightL;
      color: $colorGray;
      opacity: $opacityM;
    }

    .collaborator-activity__statistics--overview-statistic-previous-period-number {
      display: flex;
      gap: $gapXS;
      align-items: center;
      white-space: nowrap;

      &.uptrend {
        color: $colorGreen;
      }

      &.downtrend {
        color: $colorRed;
      }
    }
  }
}