@import "../../../../../../../../src/@theme/style.scss";

.collaborator-information {
  display: flex;
  padding-bottom: 16px;
  gap: $gapL;

  .collaborator-information__input-field {
    display: flex;
    gap: $gapL;

    .collaborator-information__input-field--child {
      width: 100%;
    }
  }

  .collaborator-information__left {
    display: flex;
    flex-direction: column;
    width: 60%;
    gap: $gapL;

    .collaborator-information__left--card {
      width: 100%;
      height: fit-content;
      background-color: white;
      border-radius: $borderRadiusL;
      padding-bottom: 8px;

      .collaborator-information__left--card-header {
        display: flex;
        padding: 12px 12px;
        border-bottom: 2px solid $colorExtraLightGray;

        span {
          font-weight: $fontWeightXL;
          font-size: $textFontSizeM;
          font-family: $fontFamily;
          color: $colorBlack;
        }
      }

      .collaborator-information__left--card-body {
        display: flex;
        flex-direction: column;
        gap: $gapL;
        padding: 16px 14px 0px 14px;

      }
    }
  }

  .collaborator-information__right {
    display: flex;
    flex-direction: column;
    width: 40%;
    gap: $gapL;

    .collaborator-information__right--card {
      width: 100%;
      height: fit-content;
      background-color: white;
      border-radius: $borderRadiusL;

      .collaborator-information__right--card-header {
        display: flex;
        padding: 12px 12px;
        border-bottom: 2px solid $colorExtraLightGray;

        span {
          font-weight: $fontWeightXL;
          font-size: $textFontSizeM;
          font-family: $fontFamily;
          color: $colorBlack;
        }
      }

      .collaborator-information__right--card-body {
        display: flex;
        flex-direction: column;
        gap: $gapL;
        padding: 16px 14px 0px 14px;


        .collaborator-information__right--card-body-overview {
          padding-bottom: 16px;
          border-bottom: 2px solid $colorExtraLightGray;

          .collaborator-information__right--card-body-overview-label {
            padding-bottom: 4px;
            span {
              color: $colorGray
            }
          }

          .collaborator-information__right--card-body-overview-checklist {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .collaborator-information__right--card-body-overview-checklist-checkbox {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 6px 12px;

              background-color: $colorExtraLightGray;
              border-radius: $borderRadiusS;
              transition: 0.3s ease-in;
              cursor: not-allowed;

              &.unchecked {
                color: white;
                background-color: $colorViolet;
              }
            }
          }
        }

        .collaborator-information__right--card-body-upload {
          display: flex;
          flex-direction: column;
          gap: $gapL;
          padding-top: 8px;
          overflow-y: hidden;
          scrollbar-gutter: stable both-edges;
          padding-right: 4px;
        }

        .collaborator-information__right--card-body-upload:hover,
        .collaborator-information__right--card-body-upload:active,
        .collaborator-information__right--card-body-upload:focus {
          overflow-y: auto;
        }

        .collaborator-information__right--card-body-upload::-webkit-scrollbar {
          width: 0.6em;
        }

        .collaborator-information__right--card-body-upload::-webkit-scrollbar-track {
          background-color: #ebe0f8;
          margin-block: 1em;
          border-radius: 8px;
        }

        .collaborator-information__right--card-body-upload::-webkit-scrollbar-thumb {
          border-radius: 8px;
          border: 3px solid #ebe0f8;
          background-color: #8b5cf6;
        }

        .collaborator-information__right--card-body-upload::-webkit-scrollbar-thumb:hover {
          background-color: #7e53b2;
        }
      }
    }
  }
}



.btn-update {
  width: auto;
  float: right;
  background-color: bisque;
  border: none;
  color: black;
}

.btn-update:hover {
  float: right;
  background-color: rgb(209, 235, 250);
  border: none;
  color: black;
}

.label-birthday-info {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  text-decoration: none;
  margin: 0;
}

.select {
  width: 300px !important;
  height: 40px !important;
  margin-left: 20px;
  border: 1px solid rgb(211, 210, 210);
  border-radius: 5px;
}

.list-item {
  background-color: rgb(255, 255, 255);
  padding: 5px;
  position: absolute;
  width: 80%;
  border: 1px solid rgb(175, 175, 175);
  border-radius: 5px;

  .text-name {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }
}

.input-seach-collaborator {
  width: 100%;
}

.document-content {
  overflow: hidden;
}

.document-content:hover,
.document-content:active,
.document-content:focus {
  overflow-y: auto;
}

.document-content::-webkit-scrollbar {
  width: 0.6em;
}

.document-content::-webkit-scrollbar-track {
  background-color: #ebe0f8;
  margin-block: 1em;
  border-radius: 8px;
}

.document-content::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid #ebe0f8;
  background-color: #8b5cf6;
}

.document-content::-webkit-scrollbar-thumb:hover {
  background-color: #7e53b2;
}