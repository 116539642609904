.select-code-phone {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  margin-left: 20px;
  border-color: rgb(216, 216, 216);
}

.loading {
  display: flex;
  position: absolute;
  flex: 1;
  z-index: 100;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.btn-block {
  background-color: aquamarine;
  border: none;
}

.img-unlock {
  width: 25px;
  height: 25px;
}

.img-toggle {
  width: 30px;
  height: 30px;
}

.img-toggle-hide {
  display: none;
}

.img-watch {
  width: 25px;
  height: 25px;
}

.text-click-block {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  margin: 0;
}

.text-dropdown {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  margin: 0;
}

.text-click-block-hide {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  display: none;
}

.text-id-collaborator {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #19202c;
  margin: 0;
}

.div-collaborator-table {
  display: flex;
  flex-direction: row;
  align-items: center;

  .img_collaborator {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    margin-right: 5px;
  }

  .div-name-collaborator {
    display: flex;
    flex-direction: column;
    .text-name-collaborator {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      text-decoration-line: underline;
      color: #023e8a !important;
      margin: 0;
    }

    .text-phone-collaborator {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #212121 !important;
      margin: 0;
    }
  }
}

.div-create-ctv {
  display: flex !important;
  flex-direction: column;

  .text-create-ctv {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }
}

.text-phone-ctv {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000 !important;
  text-decoration: none !important;
  margin: 0;
}

.text-city-ctv {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  text-decoration: none;
  margin: 0;
}

.div-status-ctv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .icon-status {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }

  .text-online {
    color: #00cf3a !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-decoration: none !important;
    margin: 0;
  }

  .text-offline {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #979797;
    margin: 0;
  }

  .text-pending-cola {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #f7941d !important;
    text-decoration: none !important;
    margin: 0;
  }

  .text-lock-time {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #f7941d;
    text-decoration: none !important;
    margin: 0;
  }
}

.icon-verify {
  color: #00cf3a !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none !important;
}

.icon-nonverify {
  color: #ff0000 !important;
  margin-right: 5px;
  width: 15px !important;
  height: 15px !important;
}
.icon-delete {
  color: #1689bb !important;
}

.icon-on-toggle {
  color: #00cf3a !important;
}

.icon-off-toggle {
  color: #cccccc !important;
}

.icon-time-on {
  color: #1689bb !important;
}

.icon-time-off {
  color: #ff0000 !important;
}

.text-input {
  width: 300px;
  height: 40px;
  border-radius: 5px;
}
.text-name-modal {
  font-size: medium;
  color: orangered;
  margin-left: 5px;
  margin-right: 5px;
}

.icon-unlock {
  color: rgb(43, 159, 121);
}

.icon-lock {
  color: orangered;
}

.div-table {
  background-color: white;
  border-radius: 10px;
}

.div-header-colla {
  margin-bottom: 10px;
  flex-direction: row;
  display: flex;
  .dropdown {
    display: flex;
    height: 48px;
    width: 15%;
    border-radius: 5px;
    border: 1px solid black;
    align-items: center;
    justify-content: center;

    .icon {
      color: #000000;
    }
    .text-filter {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      margin-top: 10px;
    }
  }

  .input {
    border: none;
  }
}

.text-verify {
  color: #00cf3a !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-decoration: none !important;
  margin: 0;
}

.text-nonverify {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #ff0000;
  text-decoration: none !important;
  margin: 0;
}

.div-verify {
  display: flex;
  flex-direction: column;
  align-items: center;

  .text-verify {
    color: #00cf3a !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-decoration: none !important;
  }

  .text-nonverify {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #ff0000;
    text-decoration: none !important;
    margin: 0;
  }

  .btn-contacted {
    width: 50px;
    height: 20px;
    background-color: #00cf3a;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 5px;
    border-radius: 4px;

    .text-contacted {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 12px;
      margin: 0;
      color: #000000;
      text-decoration: none;
    }
  }

  .btn-contacted-hide {
    display: none;
  }
}

@media screen and (max-width: 490px) {
  .div-header-colla {
    margin-bottom: 10px;
    flex-direction: column;
    display: flex;

    .input-search {
      width: 90%;
      border-radius: 5px;
      border: 1px solid black;
      margin-left: 15px;
      align-items: center;
    }
    .input {
      border: none;
    }
  }
}
