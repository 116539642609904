.avatar {
  width: 100px !important;
  @include img-fit();
  height: 100px !important;
  border-radius: 50px;
}
.div-flex {
  display: flex;
  flex-direction: column;
}
.b-shadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.b-shadow-2 {
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}
.gird-3-1-1-1 {
  display: grid;
  grid-template-columns: 3fr repeat(3, 1fr);
}
.text-align-end {
  text-align: end;
}
.common-container {
  background-color: var(--white-color);
  width: auto;
  border-radius: 5px;
  padding: 16px 12px;
}
.fw-500 {
  font-weight: 500;
}

.status-pending {
  color: var(--pending-color) !important;
  border: 1px solid var(--pending-color) !important;
  background-color: var(--bg-pending-color) !important;
}

.status-not-process {
  color: #000000 !important;
  border: 1px solid #dedede !important;
  background-color: #f8f8f8 !important;
}

.status-processing {
  color: var(--pending-color) !important;
  border: 1px solid var(--pending-color) !important;
  background-color: var(--bg-pending-color) !important;
}

.status-confirm-doing {
  color: var(--confirm-doing-color) !important;
  border: 1px solid var(--confirm-doing-color) !important;
  background-color: var(--bg-confirm-doing-color) !important;
}

.status-done {
  color: var(--success-color) !important;
  border: 1px solid var(--success-color) !important;
  background-color: var(--bg-success-color) !important;
}

.status-not-contact {
  color: var(--error-color) !important;
  border: 1px solid var(--error-color) !important;
  background-color: var(--bg-error-color) !important;
}

.box-common {
  background-color: var(--bg-color);
  border-radius: 5px;
  padding: 1rem;
  @extend .b-shadow-2;
}
.mr-t {
  margin-top: 0.75rem;
}
.fz-14 {
  font-size: 14px;
}
.fz-16 {
  font-size: 16px;
}
#color-selected {
  color: var(--selected-color);
}
