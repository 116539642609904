.info-bill-container {
  @extend .common-container;
  padding: 4px 8px 10px 8px;
}
.info-bill_header {
  background-color: var(--bg-color-2);
  padding: 10px 8px;
  border-radius: 4px 4px 0px 0px;
  border-bottom: solid 1px #1f242e;
}
.info-bill_item {
  align-items: center;
  margin-top: 0.5rem;
  padding: 10px;
}
.detail-bill_p-discount {
  color: var(--red-color);
}
.detail-bill_p-voucher {
  color: var(--yellow-color);
}
.info-bill-container_title {
  @include flexbox(space-between, center);
  button {
    margin-bottom: 0.5rem;
  }
}
.info-bill-container_title-service {
  margin: 5px 0px;
  padding: 4px 6px;
  border-radius: 5px;
  span {
    font-size: 14px;
    @extend .fw-500;
  }
}
