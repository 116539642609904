.btn-edit-account {
  margin-top: 20px;
  border: none;
  background-color: aquamarine;
  border-radius: 4px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
  width: auto;
  padding: 5px;
}

.text-label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
