.btn-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 30px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
}
