.div-title-role {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;

  .title-role {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }

  .div-item-role {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 25%;

    .div-item-per {
      display: flex;
      flex-direction: row;

      align-items: center;
      width: 100%;

      .text-name-per {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        text-decoration: none;
        color: #000000;
        margin: 0 0 0 5px;
      }
    }
  }
}

.div-add-role {
  display: flex;
  flex-direction: column;
  margin-top: 10%;

  .btn-add-role {
    background-color: rgb(9, 22, 255);
    border: none;
    width: 100px;
    border-radius: 4px;
    color: black;
    margin-top: 10px;
    color: white;
    box-shadow: 2px 3px 2px rgb(216, 216, 216);
  }
}

.div-input {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 490px) {
  .div-title-role {
    display: flex;
    flex-direction: column !important;

    .title-role {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #000000;
      text-decoration: none;
      margin: 0;
    }

    .div-item-role {
      display: flex;
      flex-direction: column;
      padding: 10px;
      width: 100% !important;

      .div-item-per {
        display: flex;
        flex-direction: row;

        align-items: center;
        width: 100%;

        .text-name-per {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          text-decoration: none;
          color: #000000;
          margin: 0 0 0 5px;
        }
      }
    }
  }
}
