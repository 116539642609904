@mixin flexbox(
  $justifyContent: flex-start,
  $alignItems: flex-start,
  $flexdirection: "row"
) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItems;
  flex-direction: $flexdirection;
}

@mixin bordered($borderWidth, $borderColor: black) {
  border: $borderWidth solid $borderColor;
}

@mixin img-fit {
  @include dimension(100%, 100%);
  object-fit: cover;
}

@mixin dimension($width: auto, $height: auto) {
  width: $width;
  height: $height;
}

@mixin padding-x($paddingLeft, $paddingRight) {
  padding-left: $paddingLeft;
  padding-right: $paddingRight;
}

@mixin padding-y($paddingTop, $paddingBottom) {
  padding-top: $paddingTop;
  padding-bottom: $paddingBottom;
}
@mixin gird($columns, $gap: 1) {
  display: grid;
  grid-template-columns: $columns;
  gap: $gap;
}
