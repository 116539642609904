.container-dash {
  width: 100%;

  p {
    margin: 0px 0px 6px 0px;
  }


  .div-chart_total_service_collaborator {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    height: auto;

    .chart {
      height: 500px !important;
      width: 49% !important;
      padding: 10px;
      border-radius: 8px;
      background-color: white;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
      box-shadow: 2px 3px #d6d6d6;

      .div-head-chart {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .text-total-money {
          color: #000000;
          font-family: "Roboto";
          font-style: normal;
          font-size: 12px;
        }
        .div-date {
          display: flex;
          flex-direction: row;
          width: 100%;
          margin-bottom: 10px;

          .text-date {
            color: #000000;
            font-family: "Roboto";
            font-style: normal;
            font-size: 12px;
            margin-left: 10px;
          }

          .input-picker {
            width: 100px;
          }
          .picker {
            width: 300px;
          }
        }
      }

      .type-select {
        width: 110px;
        min-height: 32px;
        border-radius: 8px;
        margin-right: 5px;
        border-color: rgb(211, 211, 211);
        color: rgb(204, 204, 204);
      }

      .label-persen-active {
        color: black;
        font-weight: 600;
        font-size: 18px;
        font-family: "Roboto", sans-serif;
      }

      .label-active {
        color: black;
        font-weight: 600;
        font-size: 30px;
        font-family: "Roboto", sans-serif;
      }
      .div-persen {
        display: flex;
        align-items: center;

        .label-persen {
          color: black;
          font-weight: 600;
          font-size: 18px;
          font-family: "Roboto", sans-serif;
        }
        .label-total {
          color: #d0d1d2;
          font-weight: 200;
          font-size: 14px;
          font-family: "Roboto", sans-serif;
          margin-left: 20px;
        }
      }
      .progress-persent {
        padding-right: 50px;
      }
      .div-container-on {
        display: flex;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 50px;

        .div-on {
          display: flex;
          flex-direction: row;

          .line-on {
            width: 30px;
            height: 4px;
            background-color: #00b4d8;
            border-radius: 5px;
            margin-top: 10px;
            margin-right: 20px;
          }
          .line-off {
            width: 30px;
            height: 4px;
            background-color: #e6e8f4;
            border-radius: 5px;
            margin-top: 10px;
            margin-right: 20px;
          }
          .total-div-on {
            display: flex;
            flex-direction: column;

            .text-on {
              color: #d0d1d2;
              font-weight: 200;
              font-size: 14px;
              font-family: "Roboto", sans-serif;
            }
            .text-total-on {
              color: black;
              font-weight: 500;
              font-size: 14px;
              font-family: "Roboto", sans-serif;
            }
          }
        }
      }
    }

    .div-right-conection-dashboard {
      display: flex;
      flex-direction: row;
      width: 49%;
      justify-content: space-between;

      .div-right {
        display: flex;
        flex-direction: column;
        width: 49%;
        .div-connect-service {
          display: flex;
          width: 100%;
          flex-direction: column;
          background-color: white;
          border-radius: 8px;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
          box-shadow: 2px 3px #d6d6d6;
          margin-right: 10px;
          padding-bottom: 5px;

          .div-progress {
            min-height: 120px;
            align-items: center;
            justify-content: center;
            display: flex;
          }

          .div-progress-text {
            .title-progress {
              text-align: center;
              color: black;
              font-weight: 600;
              font-size: 18px;
              font-family: "Roboto", sans-serif;
            }
          }

          .div-success {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 15%;

            .square {
              width: 20px;
              height: 20px;
              background-color: #48cae4;
              border-radius: 5px;
            }
            .text-success-square {
              color: black;
              font-weight: 500;
              font-size: 14px;
              font-family: "Roboto", sans-serif;
              margin: 3px 0px 0px 10px;
            }
            .unsquare {
              width: 20px;
              height: 20px;
              background-color: #e6e8f4;
              border-radius: 5px;
            }
          }
        }

        .div-top-collaborator {
          display: flex;
          flex-direction: column;
          width: 100%;
          background-color: white;
          border-radius: 8px;
          filter: drop-shadow(0px 4px 4px rgba(38, 37, 37, 0.1));
          box-shadow: 2px 3px #d6d6d6;
          flex: 1;
          margin-top: 5px;

          .text-top {
            text-align: center;
            color: black;
            font-weight: 600;
            font-size: 18px;
            font-family: "Roboto", sans-serif;
            padding-top: 10px;
          }

          .level {
            flex-direction: column;

            .level-ctv1 {
              display: flex;
              background-color: #023e8a;
              height: 38px;
              margin-left: 25px;
              margin-right: 25px;
              border-radius: 5px 5px 5px 0px;
              align-items: center;
              justify-content: space-between;
              padding: 20px 10px 0px 10px;
              .text-level {
                color: white;
                font-weight: normal;
                font-size: 11px;
                font-family: "Roboto", sans-serif;
                width: 150;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }

              .text-level-number {
                color: white;
                font-weight: normal;
                font-size: 11px;
                font-family: "Roboto", sans-serif;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }

            .level-ctv2 {
              display: flex;
              height: 38px;
              background-color: #0077b6;
              margin-left: 25px;
              margin-right: 35px;
              border-radius: 0px 5px 5px 0px;
              align-items: center;
              justify-content: space-between;
              padding: 20px 10px 0px 10px;
              .text-level {
                color: white;
                font-weight: normal;
                font-size: 11px;
                font-family: "Roboto", sans-serif;
                width: 100px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .text-level-number {
                color: white;
                font-weight: normal;
                font-size: 11px;
                font-family: "Roboto", sans-serif;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }

            .level-ctv3 {
              display: flex;
              height: 38px;
              background-color: #0096c7;
              margin-left: 25px;
              margin-right: 50px;
              border-radius: 0px 5px 5px 0px;
              align-items: center;
              justify-content: space-between;
              padding: 20px 10px 0px 10px;
              .text-level {
                color: white;
                font-weight: normal;
                font-size: 11px;
                font-family: "Roboto", sans-serif;
                width: 88px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .text-level-number {
                color: white;
                font-weight: normal;
                font-size: 11px;
                font-family: "Roboto", sans-serif;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
            .level-ctv4 {
              display: flex;
              height: 38px;
              background-color: #00b4d8;
              margin-left: 25px;
              margin-right: 60px;
              border-radius: 0px 5px 5px 0px;
              align-items: center;
              justify-content: space-between;
              padding: 20px 10px 0px 10px;
              .text-level {
                color: white;
                font-weight: normal;
                font-size: 11px;
                font-family: "Roboto", sans-serif;
                width: 77px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .text-level-number {
                color: white;
                font-weight: normal;
                font-size: 11px;
                font-family: "Roboto", sans-serif;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
            .level-ctv5 {
              display: flex;
              height: 38px;
              background-color: #48cae4;
              margin-left: 25px;
              margin-right: 70px;
              border-radius: 0px 5px 5px 5px;
              align-items: center;
              justify-content: space-between;
              padding: 20px 10px 0px 10px;
              .text-level {
                color: white;
                font-weight: normal;
                font-size: 11px;
                font-family: "Roboto", sans-serif;
                width: 66px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .text-level-number {
                color: white;
                font-weight: normal;
                font-size: 11px;
                font-family: "Roboto", sans-serif;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }

          .div-seemore {
            text-align: right;
            padding: 10px;
          }
        }
      }

      .col-activity-dashboard {
        display: flex;
        flex-direction: column;
        width: 49%;
        background-color: #fff;
        border-radius: 8px;
        min-height: 80%;

        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));

        .label-activity {
          color: black;
          font-weight: 600;
          font-size: 27px;
          font-family: "Roboto", sans-serif;
          text-decoration: none;
          text-align: center;
          padding-top: 20px;
          padding-bottom: 10px;
        }

        .div-seemore {
          display: flex;
          align-items: end;
          justify-content: end;
          margin-top: 10px;
          padding-right: 10px;
          padding-bottom: 10px;
          background-color: transparent;
          border: none;
        }
      }
    }
  }

  .label-service {
    color: black;
    font-weight: 600;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    margin-bottom: 5px;
  }

  .div-action {
    display: flex;
    flex-direction: column;

    .btn-click {
      background-color: #3bbe7f;
      border: none;
      border-radius: 5px;
      color: white;
      width: 75px;
      height: 21px;
    }

    .btn-details {
      background-color: #0075ff;
      border: none;
      border-radius: 5px;
      color: white;
      margin-top: 2px;
      width: 75px;
      height: 21px;
    }

    .btn-details:hover {
      background-color: #b9daff;
      border: none;
      border-radius: 5px;
      color: white;
      margin-top: 2px;
      width: 75px;
      height: 21px;
    }
  }
  .div-card-service {
    background-color: #ffffff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
    .div-entries {
      display: flex;
      justify-content: flex-end;
      padding: 10px;

      .select-entries {
        width: 50px;
        height: 30px;
        margin-left: 10px;
        border: 1px solid rgb(211, 211, 211);
        border-radius: 5px;
      }
    }
  }

  .div-column-date {
    display: flex;
    flex-direction: column;

    .text-date {
      color: #023e8a;
      font-weight: 500;
      font-size: 12px;
      font-family: "Roboto";
      text-decoration: none;
    }
    .text-time {
      color: #023e8a;
      font-weight: 500;
      font-size: 12px;
      font-family: "Roboto";
      text-decoration: none;
      text-transform: capitalize;
    }
  }

  .div-chart-pie {
    background-color: white;
    border-radius: 4px;
    padding: 10px;
    border: 1px solid rgb(178, 178, 178);
    box-shadow: 2px 3px #d6d6d6;

    .div-pieChart {
      display: flex;
      flex-direction: row;
      align-items: center;

      .div-chart-de {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        height: 20%;
      }

      .div-service-hours {
        display: flex;
        align-items: center;
        .div-towhours {
          width: 40px;
          height: 10px;
          background-color: #fcd34d;
          margin-right: 10px;
        }
        .div-threehours {
          width: 40px;
          height: 10px;
          background-color: #f477ef;
          margin-right: 10px;
        }
        .div-fourhours {
          width: 40px;
          height: 10px;
          background-color: #bae6fd;
          margin-right: 10px;
        }
        .div-different {
          width: 40px;
          height: 10px;
          background-color: #2acb9e;
          margin-right: 10px;
        }
      }
    }

    .div-chart-progress {
      display: flex;
    }
  }

  .div-list {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;

    .div-line {
      flex-direction: column;

      .circle {
        width: 5px;
        height: 5px;
        background-color: gray;
        border-radius: 5px;
      }

      .line-vertical {
        width: 1px;
        min-height: 100%;
        background-color: #d0d1d2;
        margin-left: 2px;
      }
    }

    .div-details-activity {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-top: -5px;

      .text-date-activity {
        color: black;
        font-weight: bold;
        font-size: 11px;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        .text-time-activity {
          color: #aeaeae;
          font-weight: 200;
          font-size: 11px;
          font-family: "Roboto", sans-serif;
          text-decoration: none;
        }
      }
      .text-content-activity {
        color: black;
        font-weight: 200;
        font-size: 11px;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        width: "100%";
        overflow: hidden;
        white-space: pre-wrap;
        margin-bottom: 10px;
      }

      .text-brand-activity {
        color: 0096C7;
        font-weight: 200;
        font-size: 11px;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
      }
    }
  }

  .text-pending {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #f3dc0b !important;
  }

  .text-confirm {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #023e8a;
  }

  .text-doing {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #023e8a;
  }

  .text-cancel {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ff0000;
  }
  .text-done {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #00cf3a;
  }
  .text-address-dashboard {
    display: flex;
    flex-wrap: wrap;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: black;
    width: 300px;
  }
  .div-column-service {
    display: flex;
    flex-direction: column;
    .text-service {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #11263c;
      text-transform: capitalize;
    }
  }

  .title-head-column {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #010101;
    text-decoration: none;
  }

  .text-collaborator {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-decoration-line: underline;
    color: #023e8a;
  }

  .text-find-collaborator {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-decoration: none;
    color: #000000;
  }

  .div-total-report-dashboard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
    .div-chart-pie-total-dash {
      display: flex;
      flex-direction: column;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
      background-color: white;
      border-radius: 8px;
      padding: 10px;
      width: 49%;
      height: 360px;

      .title-chart-area {
        color: black;
        font-weight: 500;
        font-size: 20px;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        margin-bottom: 10px;
      }

      .div-select-city {
        display: flex;
        flex-direction: row;
        width: 100%;

        .div-select-item {
          display: flex;
          flex-direction: column;

          .div-item-city {
            display: flex;
            flex-direction: column;
            background-color: rgb(255, 255, 255);
            padding: 5px;
            position: absolute;
            width: 180px;
            border: 1px solid rgb(175, 175, 175);
            border-radius: 5px;
            z-index: 9999999;
            margin-top: 40px;
            margin-left: 10px;

            .div-item-list {
              display: flex;
              align-items: center;
              height: 30px;
              width: 100%;
              border-bottom: 1px solid #d6d6d6;
            }

            .div-item-list-selected {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              background-color: #48cae4;
              height: 30px;
            }
          }

          .div-select-city-input {
            display: flex;
            align-items: center;
            border-radius: 4px;
            height: 40px;
            border: 1px solid #d6d6d6;
            margin-left: 10px;
            width: 180px;
            padding-left: 10px;
          }
        }
      }

      .div-pie-chart {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        .div-total-piechart {
          display: flex;
          flex-direction: column;
          width: 20%;
          min-height: 100px;
          border-radius: 8px;
          border: 1px solid #d6d6d6;
          padding: 5px;
          justify-content: center;

          .item-total {
            display: flex;
            flex-direction: row;

            .title-total {
              color: black;
              font-weight: 500;
              font-size: 16px;
              font-family: "Roboto", sans-serif;
              text-decoration: none;
              width: 100px;
            }

            .text-colon {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              color: #000000;
            }

            .number-total {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              color: #000000;
              margin-left: 10px;
            }
          }
        }

        .div-pie {
          width: 80%;
          height: 100%;
        }

        .div-title-note {
          display: flex;
          flex-direction: column;
          width: 200px;

          .div-square-ser {
            display: flex;
            flex-direction: row;

            .square-two {
              width: 20px;
              height: 20px;
              background-color: #0088fe;
              border-radius: 5px;
              margin-right: 10px;
            }

            .square-three {
              width: 20px;
              height: 20px;
              background-color: #48cae4;
              border-radius: 5px;
              margin-right: 10px;
            }

            .square-four {
              width: 20px;
              height: 20px;
              background-color: #00cf3a;
              border-radius: 5px;
              margin-right: 10px;
            }
          }
        }
      }
    }
    .div-chart-user {
      width: 49%;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
      background-color: white;
      border-radius: 8px;
      padding: 20px;
      margin-bottom: 30px;

      .div-total-time-area {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .div-time-area {
          display: flex;
          flex-direction: row;

          .text-time {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            color: #11263c;
            margin-right: 10px;
          }

          .text-area {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            color: #11263c;
            margin-left: 30px;
            margin-right: 10px;
          }
        }
        .div-total {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .text-total-user {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            color: #11263c;
            margin-right: 10px;
          }

          .div-total {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 30px;
            border: 1px solid #bbbbbb;
            border-radius: 6px;
          }

          .text-number-total {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            color: #11263c;
            margin-right: 10px;
            text-decoration: none;
          }
        }
      }
    }
  }

  .div-chart-pie-total-cancel-dash {
    display: flex;
    flex-direction: column;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    margin-top: 10px;
    width: 50%;

    .div-pie-chart-cancel {
      display: flex;
      flex-direction: row;
      width: 100%;

      .div-total-piechart {
        display: flex;
        flex-direction: column;
        width: 30%;
        min-height: 100px;
        border-right: 1px solid #d6d6d6;
        padding: 5px;
        justify-content: center;

        .item-total {
          display: flex;
          flex-direction: row;

          .title-total {
            color: black;
            font-weight: 500;
            font-size: 12px;
            font-family: "Roboto", sans-serif;
            text-decoration: none;
            width: 200px;
          }

          .text-colon {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #000000;
          }

          .number-total {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #000000;
            margin-left: 10px;
          }
        }
      }

      .div-pie-cancel {
        width: 70%;
        height: 100%;
      }
    }
  }
}

.div-content-tool-chart {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 5px;
  border-radius: 5px;

  .date-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: black;
  }
  .money-text-dashboard {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #00cf3a !important;
  }
}

.div-content-tool-chart-user {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 5px;
  border-radius: 5px;

  .date-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: black;
  }
  .money-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: black !important;
  }

  .money-text-new {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4376cc;
  }

  .money-text-old {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #82ca9d;
  }
}

@media (max-width: 1200px) {
  .container-dash {
    width: 100%;

    .div-chart_total_service_collaborator {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
      height: auto;

      .chart {
        height: 550px !important;
        width: 100% !important;
        padding: 10px;
        border-radius: 8px;
        background-color: white;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
        box-shadow: 2px 3px #d6d6d6;

        .div-head-chart {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .text-total-money {
            color: #000000;
            font-family: "Roboto";
            font-style: normal;
            font-size: 12px;
          }
          .div-date {
            display: flex;
            flex-direction: row;
            width: 60%;
            margin-bottom: 10px;

            .text-date {
              color: #000000;
              font-family: "Roboto";
              font-style: normal;
              font-size: 12px;
              margin-left: 10px;
            }

            .input-picker {
              width: 100px;
            }
            .picker {
              width: 300px;
            }
          }
        }

        .type-select {
          width: 110px;
          min-height: 32px;
          border-radius: 8px;
          margin-right: 5px;
          border-color: rgb(211, 211, 211);
          color: rgb(204, 204, 204);
        }

        .label-persen-active {
          color: black;
          font-weight: 600;
          font-size: 18px;
          font-family: "Roboto", sans-serif;
        }

        .label-active {
          color: black;
          font-weight: 600;
          font-size: 20px;
          font-family: "Roboto", sans-serif;
        }
        .div-persen {
          display: flex;
          align-items: center;

          .label-persen {
            color: black;
            font-weight: 600;
            font-size: 18px;
            font-family: "Roboto", sans-serif;
          }
          .label-total {
            color: #d0d1d2;
            font-weight: 200;
            font-size: 14px;
            font-family: "Roboto", sans-serif;
            margin-left: 20px;
          }
        }
        .progress-persent {
          padding-right: 50px;
        }
        .div-container-on {
          display: flex;
          justify-content: space-between;
          padding-left: 20px;
          padding-right: 50px;

          .div-on {
            display: flex;
            flex-direction: row;

            .line-on {
              width: 30px;
              height: 4px;
              background-color: #00b4d8;
              border-radius: 5px;
              margin-top: 10px;
              margin-right: 20px;
            }
            .line-off {
              width: 30px;
              height: 4px;
              background-color: #e6e8f4;
              border-radius: 5px;
              margin-top: 10px;
              margin-right: 20px;
            }
            .total-div-on {
              display: flex;
              flex-direction: column;

              .text-on {
                color: #d0d1d2;
                font-weight: 200;
                font-size: 14px;
                font-family: "Roboto", sans-serif;
              }
              .text-total-on {
                color: black;
                font-weight: 500;
                font-size: 14px;
                font-family: "Roboto", sans-serif;
              }
            }
          }
        }
      }

      .div-right-conection-dashboard {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        margin-top: 10px;
        .div-right {
          display: flex;
          flex-direction: row;
          width: 49%;
          .div-connect-service {
            display: flex;
            width: 45%;
            flex-direction: column;
            background-color: white;
            border-radius: 8px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
            box-shadow: 2px 3px #d6d6d6;
            margin-right: 10px;
            padding-bottom: 5px;

            .div-progress {
              min-height: 120px;
              align-items: center;
              justify-content: center;
              display: flex;
            }

            .div-progress-text {
              .title-progress {
                text-align: center;
                color: black;
                font-weight: 600;
                font-size: 18px;
                font-family: "Roboto", sans-serif;
              }
            }

            .div-success {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: 15%;

              .square {
                width: 20px;
                height: 20px;
                background-color: #48cae4;
                border-radius: 5px;
              }
              .text-success-square {
                color: black;
                font-weight: 500;
                font-size: 14px;
                font-family: "Roboto", sans-serif;
                margin: 3px 0px 0px 10px;
              }
              .unsquare {
                width: 20px;
                height: 20px;
                background-color: #e6e8f4;
                border-radius: 5px;
              }
            }
          }

          .div-top-collaborator {
            display: flex;
            flex-direction: column;
            width: 54%;
            background-color: white;
            border-radius: 8px;
            filter: drop-shadow(0px 4px 4px rgba(38, 37, 37, 0.1));
            box-shadow: 2px 3px #d6d6d6;
            flex: 1;

            .text-top {
              text-align: center;
              color: black;
              font-weight: 600;
              font-size: 18px;
              font-family: "Roboto", sans-serif;
              padding-top: 10px;
            }

            .level {
              flex-direction: column;

              .level-ctv1 {
                display: flex;
                background-color: #023e8a;
                height: 38px;
                margin-left: 25px;
                margin-right: 25px;
                border-radius: 5px 5px 5px 0px;
                align-items: center;
                justify-content: space-between;
                padding: 20px 10px 0px 10px;
                .text-level {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  width: 150;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }

                .text-level-number {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }

              .level-ctv2 {
                display: flex;
                height: 38px;
                background-color: #0077b6;
                margin-left: 25px;
                margin-right: 35px;
                border-radius: 0px 5px 5px 0px;
                align-items: center;
                justify-content: space-between;
                padding: 20px 10px 0px 10px;
                .text-level {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  width: 100px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .text-level-number {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }

              .level-ctv3 {
                display: flex;
                height: 38px;
                background-color: #0096c7;
                margin-left: 25px;
                margin-right: 50px;
                border-radius: 0px 5px 5px 0px;
                align-items: center;
                justify-content: space-between;
                padding: 20px 10px 0px 10px;
                .text-level {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  width: 88px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .text-level-number {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
              .level-ctv4 {
                display: flex;
                height: 38px;
                background-color: #00b4d8;
                margin-left: 25px;
                margin-right: 60px;
                border-radius: 0px 5px 5px 0px;
                align-items: center;
                justify-content: space-between;
                padding: 20px 10px 0px 10px;
                .text-level {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  width: 77px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .text-level-number {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
              .level-ctv5 {
                display: flex;
                height: 38px;
                background-color: #48cae4;
                margin-left: 25px;
                margin-right: 70px;
                border-radius: 0px 5px 5px 5px;
                align-items: center;
                justify-content: space-between;
                padding: 20px 10px 0px 10px;
                .text-level {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  width: 66px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .text-level-number {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
            }

            .div-seemore {
              text-align: right;
              padding: 10px;
            }
          }
        }

        .col-activity-dashboard {
          display: flex;
          flex-direction: column;
          width: 49%;
          background-color: #fff;
          border-radius: 8px;
          min-height: 80%;

          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));

          .label-activity {
            color: black;
            font-weight: 600;
            font-size: 27px;
            font-family: "Roboto", sans-serif;
            text-decoration: none;
            text-align: center;
            padding-top: 20px;
            padding-bottom: 10px;
          }

          .div-seemore {
            display: flex;
            align-items: end;
            justify-content: end;
            margin-top: 10px;
            padding-right: 10px;
            padding-bottom: 10px;
            background-color: transparent;
            border: none;
          }
        }
      }
    }

    .label-service {
      color: black;
      font-weight: 600;
      font-size: 20px;
      font-family: "Roboto", sans-serif;
      margin-bottom: 5px;
    }

    .div-action {
      display: flex;
      flex-direction: column;

      .btn-click {
        background-color: #3bbe7f;
        border: none;
        border-radius: 5px;
        color: white;
        width: 75px;
        height: 21px;
      }

      .btn-details {
        background-color: #0075ff;
        border: none;
        border-radius: 5px;
        color: white;
        margin-top: 2px;
        width: 75px;
        height: 21px;
      }
    }
    .div-card-service {
      background-color: #ffffff;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
      padding: 10px;
      border-radius: 8px;
      margin-bottom: 20px;
      .div-entries {
        display: flex;
        justify-content: flex-end;
        padding: 10px;

        .select-entries {
          width: 50px;
          height: 30px;
          margin-left: 10px;
          border: 1px solid rgb(211, 211, 211);
          border-radius: 5px;
        }
      }
    }

    .div-column-service {
      display: flex;
      flex-direction: column;
    }

    .div-column-date {
      display: flex;
      flex-direction: column;

      .text-date {
        color: #023e8a;
        font-weight: 500;
        font-size: 12px;
        font-family: "Roboto";
        text-decoration: none;
      }
      .text-time {
        color: #023e8a;
        font-weight: 500;
        font-size: 12px;
        font-family: "Roboto";
        text-decoration: none;
        text-transform: capitalize;
      }
    }

    .div-chart-pie {
      background-color: white;
      border-radius: 4px;
      padding: 10px;
      border: 1px solid rgb(178, 178, 178);
      box-shadow: 2px 3px #d6d6d6;

      .div-pieChart {
        display: flex;
        flex-direction: row;
        align-items: center;

        .div-chart-de {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80%;
          height: 20%;
        }

        .div-service-hours {
          display: flex;
          align-items: center;
          .div-towhours {
            width: 40px;
            height: 10px;
            background-color: #fcd34d;
            margin-right: 10px;
          }
          .div-threehours {
            width: 40px;
            height: 10px;
            background-color: #f477ef;
            margin-right: 10px;
          }
          .div-fourhours {
            width: 40px;
            height: 10px;
            background-color: #bae6fd;
            margin-right: 10px;
          }
          .div-different {
            width: 40px;
            height: 10px;
            background-color: #2acb9e;
            margin-right: 10px;
          }
        }
      }

      .div-chart-progress {
        display: flex;
      }
    }

    .div-list {
      display: flex;
      flex-direction: row;
      padding-left: 20px;
      padding-right: 20px;

      .div-line {
        flex-direction: column;

        .circle {
          width: 5px;
          height: 5px;
          background-color: gray;
          border-radius: 5px;
        }

        .line-vertical {
          width: 1px;
          min-height: 100%;
          background-color: #d0d1d2;
          margin-left: 2px;
        }
      }

      .div-details-activity {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        margin-top: -5px;

        .text-date-activity {
          color: black;
          font-weight: bold;
          font-size: 11px;
          font-family: "Roboto", sans-serif;
          text-decoration: none;
          .text-time-activity {
            color: #aeaeae;
            font-weight: 200;
            font-size: 11px;
            font-family: "Roboto", sans-serif;
            text-decoration: none;
          }
        }
        .text-content-activity {
          color: black;
          font-weight: 200;
          font-size: 11px;
          font-family: "Roboto", sans-serif;
          text-decoration: none;
          width: "100%";
          overflow: hidden;
          white-space: pre-wrap;
          margin-bottom: 10px;
        }

        .text-brand-activity {
          color: 0096C7;
          font-weight: 200;
          font-size: 11px;
          font-family: "Roboto", sans-serif;
          text-decoration: none;
        }
      }
    }

    .text-pending {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #f3dc0b !important;
    }

    .text-confirm {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #023e8a;
    }

    .text-doing {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #023e8a;
    }

    .text-cancel {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #ff0000;
    }
    .text-done {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #00cf3a;
    }
    .text-address-dashboard {
      display: flex;
      flex-wrap: wrap;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: black;
      display: block;
      width: 150px;
    }

    .text-service {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #11263c;
      text-transform: capitalize;
    }

    .text-collaborator {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      text-decoration-line: underline;
      color: #023e8a;
    }

    .text-find-collaborator {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-decoration: none;
      color: #000000;
    }

    .div-chart-user {
      width: 100%;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
      background-color: white;
      border-radius: 8px;
      padding: 20px;
      margin-bottom: 30px;

      .div-total-time-area {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .div-time-area {
          display: flex;
          flex-direction: row;

          .text-time {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            color: #11263c;
            margin-right: 10px;
          }

          .text-area {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            color: #11263c;
            margin-left: 30px;
            margin-right: 10px;
          }
        }
        .div-total {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .text-total-user {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            color: #11263c;
            margin-right: 10px;
          }

          .div-total {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 30px;
            border: 1px solid #bbbbbb;
            border-radius: 6px;
          }

          .text-number-total {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            color: #11263c;
            margin-right: 10px;
            text-decoration: none;
          }
        }
      }
    }

    .div-chart-pie-total-dash {
      display: flex;
      flex-direction: column;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
      background-color: white;
      border-radius: 8px;
      padding: 10px;

      .title-chart-area {
        color: black;
        font-weight: 500;
        font-size: 20px;
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        margin-bottom: 10px;
      }

      .div-select-city {
        display: flex;
        flex-direction: row;
        width: 100%;

        .div-select-item {
          display: flex;
          flex-direction: column;

          .div-item-city {
            display: flex;
            flex-direction: column;
            background-color: rgb(255, 255, 255);
            padding: 5px;
            position: absolute;
            width: 180px;
            border: 1px solid rgb(175, 175, 175);
            border-radius: 5px;
            z-index: 9999999;
            margin-top: 40px;
            margin-left: 10px;

            .div-item-list {
              display: flex;
              align-items: center;
              height: 30px;
              width: 100%;
              border-bottom: 1px solid #d6d6d6;
            }

            .div-item-list-selected {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              background-color: #48cae4;
              height: 30px;
            }
          }

          .div-select-city-input {
            display: flex;
            align-items: center;
            border-radius: 4px;
            height: 40px;
            border: 1px solid #d6d6d6;
            margin-left: 10px;
            width: 180px;
            padding-left: 10px;
          }
        }
      }

      .div-pie-chart {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        .div-total-piechart {
          display: flex;
          flex-direction: column;
          width: 20%;
          min-height: 100px;
          border-radius: 8px;
          border: 1px solid #d6d6d6;
          padding: 5px;
          justify-content: center;

          .item-total {
            display: flex;
            flex-direction: row;

            .title-total {
              color: black;
              font-weight: 500;
              font-size: 16px;
              font-family: "Roboto", sans-serif;
              text-decoration: none;
              width: 100px;
            }

            .text-colon {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              color: #000000;
            }

            .number-total {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              color: #000000;
              margin-left: 10px;
            }
          }
        }

        .div-pie {
          width: 80%;
          height: 100%;
        }

        .div-title-note {
          display: flex;
          flex-direction: column;
          width: 200px;

          .div-square-ser {
            display: flex;
            flex-direction: row;

            .square-two {
              width: 20px;
              height: 20px;
              background-color: #0088fe;
              border-radius: 5px;
              margin-right: 10px;
            }

            .square-three {
              width: 20px;
              height: 20px;
              background-color: #48cae4;
              border-radius: 5px;
              margin-right: 10px;
            }

            .square-four {
              width: 20px;
              height: 20px;
              background-color: #00cf3a;
              border-radius: 5px;
              margin-right: 10px;
            }
          }
        }
      }
    }

    .div-chart-pie-total-cancel-dash {
      display: flex;
      flex-direction: column;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
      background-color: white;
      border-radius: 8px;
      padding: 10px;
      margin-top: 5px;
      width: 100%;

      .div-pie-chart-cancel {
        display: flex;
        flex-direction: row;
        width: 100%;

        .div-total-piechart {
          display: flex;
          flex-direction: column;
          width: 30%;
          min-height: 100px;
          border-right: 1px solid #d6d6d6;
          padding: 5px;
          justify-content: center;

          .item-total {
            display: flex;
            flex-direction: row;

            .title-total {
              color: black;
              font-weight: 500;
              font-size: 12px;
              font-family: "Roboto", sans-serif;
              text-decoration: none;
              width: 200px;
            }

            .text-colon {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              color: #000000;
            }

            .number-total {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              color: #000000;
              margin-left: 10px;
            }
          }
        }

        .div-pie-cancel {
          width: 70%;
          height: 100%;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .container-dash {
    width: 100%;

    .div-chart_total_service_collaborator {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
      height: auto;

      .chart {
        height: 550px !important;
        width: 100% !important;
        padding: 10px;
        border-radius: 8px;
        background-color: white;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
        box-shadow: 2px 3px #d6d6d6;

        .div-head-chart {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .text-total-money {
            color: #000000;
            font-family: "Roboto";
            font-style: normal;
            font-size: 12px;
          }
          .div-date {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-bottom: 10px;

            .text-date {
              color: #000000;
              font-family: "Roboto";
              font-style: normal;
              font-size: 12px;
              margin-left: 10px;
            }

            .input-picker {
              width: 100px;
            }
            .picker {
              width: 300px;
            }
          }
        }

        .type-select {
          width: 110px;
          min-height: 32px;
          border-radius: 8px;
          margin-right: 5px;
          border-color: rgb(211, 211, 211);
          color: rgb(204, 204, 204);
        }

        .label-persen-active {
          color: black;
          font-weight: 600;
          font-size: 18px;
          font-family: "Roboto", sans-serif;
        }

        .label-active {
          color: black;
          font-weight: 600;
          font-size: 30px;
          font-family: "Roboto", sans-serif;
        }
        .div-persen {
          display: flex;
          align-items: center;

          .label-persen {
            color: black;
            font-weight: 600;
            font-size: 18px;
            font-family: "Roboto", sans-serif;
          }
          .label-total {
            color: #d0d1d2;
            font-weight: 200;
            font-size: 14px;
            font-family: "Roboto", sans-serif;
            margin-left: 20px;
          }
        }
        .progress-persent {
          padding-right: 50px;
        }
        .div-container-on {
          display: flex;
          justify-content: space-between;
          padding-left: 20px;
          padding-right: 50px;

          .div-on {
            display: flex;
            flex-direction: row;

            .line-on {
              width: 30px;
              height: 4px;
              background-color: #00b4d8;
              border-radius: 5px;
              margin-top: 10px;
              margin-right: 20px;
            }
            .line-off {
              width: 30px;
              height: 4px;
              background-color: #e6e8f4;
              border-radius: 5px;
              margin-top: 10px;
              margin-right: 20px;
            }
            .total-div-on {
              display: flex;
              flex-direction: column;

              .text-on {
                color: #d0d1d2;
                font-weight: 200;
                font-size: 14px;
                font-family: "Roboto", sans-serif;
              }
              .text-total-on {
                color: black;
                font-weight: 500;
                font-size: 14px;
                font-family: "Roboto", sans-serif;
              }
            }
          }
        }
      }

      .div-right-conection-dashboard {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        margin-top: 10px;
        .div-right {
          display: flex;
          flex-direction: row;
          width: 100%;
          .div-connect-service {
            display: flex;
            width: 100%;
            flex-direction: column;
            background-color: white;
            border-radius: 8px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
            box-shadow: 2px 3px #d6d6d6;
            margin-right: 10px;
            padding-bottom: 5px;

            .div-progress {
              min-height: 120px;
              align-items: center;
              justify-content: center;
              display: flex;
            }

            .div-progress-text {
              .title-progress {
                text-align: center;
                color: black;
                font-weight: 600;
                font-size: 18px;
                font-family: "Roboto", sans-serif;
              }
            }

            .div-success {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: 15%;

              .square {
                width: 20px;
                height: 20px;
                background-color: #48cae4;
                border-radius: 5px;
              }
              .text-success-square {
                color: black;
                font-weight: 500;
                font-size: 14px;
                font-family: "Roboto", sans-serif;
                margin: 3px 0px 0px 10px;
              }
              .unsquare {
                width: 20px;
                height: 20px;
                background-color: #e6e8f4;
                border-radius: 5px;
              }
            }
          }

          .div-top-collaborator {
            display: flex;
            flex-direction: column;
            width: 100%;
            background-color: white;
            border-radius: 8px;
            filter: drop-shadow(0px 4px 4px rgba(38, 37, 37, 0.1));
            box-shadow: 2px 3px #d6d6d6;
            flex: 1;

            .text-top {
              text-align: center;
              color: black;
              font-weight: 600;
              font-size: 18px;
              font-family: "Roboto", sans-serif;
              padding-top: 10px;
            }

            .level {
              flex-direction: column;

              .level-ctv1 {
                display: flex;
                background-color: #023e8a;
                height: 38px;
                margin-left: 25px;
                margin-right: 25px;
                border-radius: 5px 5px 5px 0px;
                align-items: center;
                justify-content: space-between;
                padding: 20px 10px 0px 10px;
                .text-level {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  width: 150;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }

                .text-level-number {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }

              .level-ctv2 {
                display: flex;
                height: 38px;
                background-color: #0077b6;
                margin-left: 25px;
                margin-right: 35px;
                border-radius: 0px 5px 5px 0px;
                align-items: center;
                justify-content: space-between;
                padding: 20px 10px 0px 10px;
                .text-level {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  width: 100px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .text-level-number {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }

              .level-ctv3 {
                display: flex;
                height: 38px;
                background-color: #0096c7;
                margin-left: 25px;
                margin-right: 50px;
                border-radius: 0px 5px 5px 0px;
                align-items: center;
                justify-content: space-between;
                padding: 20px 10px 0px 10px;
                .text-level {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  width: 88px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .text-level-number {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
              .level-ctv4 {
                display: flex;
                height: 38px;
                background-color: #00b4d8;
                margin-left: 25px;
                margin-right: 60px;
                border-radius: 0px 5px 5px 0px;
                align-items: center;
                justify-content: space-between;
                padding: 20px 10px 0px 10px;
                .text-level {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  width: 77px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .text-level-number {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
              .level-ctv5 {
                display: flex;
                height: 38px;
                background-color: #48cae4;
                margin-left: 25px;
                margin-right: 70px;
                border-radius: 0px 5px 5px 5px;
                align-items: center;
                justify-content: space-between;
                padding: 20px 10px 0px 10px;
                .text-level {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  width: 66px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .text-level-number {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
            }

            .div-seemore {
              text-align: right;
              padding: 10px;
            }
          }
        }

        .col-activity-dashboard {
          display: flex;
          flex-direction: column;
          width: 100%;
          background-color: #fff;
          border-radius: 8px;
          min-height: 80%;
          margin-top: 10px;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));

          .label-activity {
            color: black;
            font-weight: 600;
            font-size: 27px;
            font-family: "Roboto", sans-serif;
            text-decoration: none;
            text-align: center;
            padding-top: 20px;
            padding-bottom: 10px;
          }

          .div-seemore {
            display: flex;
            align-items: end;
            justify-content: end;
            margin-top: 10px;
            padding-right: 10px;
            padding-bottom: 10px;
            background-color: transparent;
            border: none;
          }
        }
      }
    }

    .label-service {
      color: black;
      font-weight: 600;
      font-size: 20px;
      font-family: "Roboto", sans-serif;
      margin-bottom: 5px;
    }

    .div-action {
      display: flex;
      flex-direction: column;

      .btn-click {
        background-color: #3bbe7f;
        border: none;
        border-radius: 5px;
        color: white;
        width: 75px;
        height: 21px;
      }

      .btn-details {
        background-color: #0075ff;
        border: none;
        border-radius: 5px;
        color: white;
        margin-top: 2px;
        width: 75px;
        height: 21px;
      }
    }
    .div-card-service {
      background-color: #ffffff;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
      padding: 10px;
      border-radius: 8px;
      margin-bottom: 20px;
      .div-entries {
        display: flex;
        justify-content: flex-end;
        padding: 10px;

        .select-entries {
          width: 50px;
          height: 30px;
          margin-left: 10px;
          border: 1px solid rgb(211, 211, 211);
          border-radius: 5px;
        }
      }
    }

    .div-column-service {
      display: flex;
      flex-direction: column;
    }

    .div-column-date {
      display: flex;
      flex-direction: column;

      .text-date {
        color: #023e8a;
        font-weight: 500;
        font-size: 12px;
        font-family: "Roboto";
        text-decoration: none;
      }
      .text-time {
        color: #023e8a;
        font-weight: 500;
        font-size: 12px;
        font-family: "Roboto";
        text-decoration: none;
        text-transform: capitalize;
      }
    }

    .div-chart-pie {
      background-color: white;
      border-radius: 4px;
      padding: 10px;
      border: 1px solid rgb(178, 178, 178);
      box-shadow: 2px 3px #d6d6d6;

      .div-pieChart {
        display: flex;
        flex-direction: row;
        align-items: center;

        .div-chart-de {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80%;
          height: 20%;
        }

        .div-service-hours {
          display: flex;
          align-items: center;
          .div-towhours {
            width: 40px;
            height: 10px;
            background-color: #fcd34d;
            margin-right: 10px;
          }
          .div-threehours {
            width: 40px;
            height: 10px;
            background-color: #f477ef;
            margin-right: 10px;
          }
          .div-fourhours {
            width: 40px;
            height: 10px;
            background-color: #bae6fd;
            margin-right: 10px;
          }
          .div-different {
            width: 40px;
            height: 10px;
            background-color: #2acb9e;
            margin-right: 10px;
          }
        }
      }

      .div-chart-progress {
        display: flex;
      }
    }

    .div-list {
      display: flex;
      flex-direction: row;
      padding-left: 20px;
      padding-right: 20px;

      .div-line {
        flex-direction: column;

        .circle {
          width: 5px;
          height: 5px;
          background-color: gray;
          border-radius: 5px;
        }

        .line-vertical {
          width: 1px;
          min-height: 100%;
          background-color: #d0d1d2;
          margin-left: 2px;
        }
      }

      .div-details-activity {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        margin-top: -5px;

        .text-date-activity {
          color: black;
          font-weight: bold;
          font-size: 11px;
          font-family: "Roboto", sans-serif;
          text-decoration: none;
          .text-time-activity {
            color: #aeaeae;
            font-weight: 200;
            font-size: 11px;
            font-family: "Roboto", sans-serif;
            text-decoration: none;
          }
        }
        .text-content-activity {
          color: black;
          font-weight: 200;
          font-size: 11px;
          font-family: "Roboto", sans-serif;
          text-decoration: none;
          width: "100%";
          overflow: hidden;
          white-space: pre-wrap;
          margin-bottom: 10px;
        }

        .text-brand-activity {
          color: 0096C7;
          font-weight: 200;
          font-size: 11px;
          font-family: "Roboto", sans-serif;
          text-decoration: none;
        }
      }
    }

    .text-pending {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #f3dc0b !important;
    }

    .text-confirm {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #023e8a;
    }

    .text-doing {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #023e8a;
    }

    .text-cancel {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #ff0000;
    }
    .text-done {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #00cf3a;
    }
    .text-address-dashboard {
      display: flex;
      flex-wrap: wrap;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: black;
      display: block;
      width: 100px;
    }

    .text-service {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #11263c;
      text-transform: capitalize;
    }

    .text-collaborator {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      text-decoration-line: underline;
      color: #023e8a;
    }

    .text-find-collaborator {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-decoration: none;
      color: #000000;
    }
    .div-total-report-dashboard {
      display: flex;
      flex-direction: column;
      height: auto;

      .div-chart-pie-total-dash {
        display: flex;
        flex-direction: column;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
        background-color: white;
        border-radius: 8px;
        padding: 10px;
        width: 100%;
        height: 300px;

        .title-chart-area {
          color: black;
          font-weight: 500;
          font-size: 20px;
          font-family: "Roboto", sans-serif;
          text-decoration: none;
          margin-bottom: 10px;
        }

        .div-select-city {
          display: flex;
          flex-direction: row;
          width: 100%;

          .div-select-item {
            display: flex;
            flex-direction: column;

            .div-item-city {
              display: flex;
              flex-direction: column;
              background-color: rgb(255, 255, 255);
              padding: 5px;
              position: absolute;
              width: 180px;
              border: 1px solid rgb(175, 175, 175);
              border-radius: 5px;
              z-index: 9999999;
              margin-top: 40px;
              margin-left: 10px;

              .div-item-list {
                display: flex;
                align-items: center;
                height: 30px;
                width: 100%;
                border-bottom: 1px solid #d6d6d6;
              }

              .div-item-list-selected {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                background-color: #48cae4;
                height: 30px;
              }
            }

            .div-select-city-input {
              display: flex;
              align-items: center;
              border-radius: 4px;
              height: 40px;
              border: 1px solid #d6d6d6;
              margin-left: 10px;
              width: 180px;
              padding-left: 10px;
            }
          }
        }

        .div-pie-chart {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;

          .div-total-piechart {
            display: flex;
            flex-direction: column;
            width: 20%;
            min-height: 100px;
            border-radius: 8px;
            border: 1px solid #d6d6d6;
            padding: 5px;
            justify-content: center;

            .item-total {
              display: flex;
              flex-direction: row;

              .title-total {
                color: black;
                font-weight: 500;
                font-size: 16px;
                font-family: "Roboto", sans-serif;
                text-decoration: none;
                width: 100px;
              }

              .text-colon {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: #000000;
              }

              .number-total {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #000000;
                margin-left: 10px;
              }
            }
          }

          .div-pie {
            width: 80%;
            height: 100%;
          }

          .div-title-note {
            display: flex;
            flex-direction: column;
            width: 200px;

            .div-square-ser {
              display: flex;
              flex-direction: row;

              .square-two {
                width: 20px;
                height: 20px;
                background-color: #0088fe;
                border-radius: 5px;
                margin-right: 10px;
              }

              .square-three {
                width: 20px;
                height: 20px;
                background-color: #48cae4;
                border-radius: 5px;
                margin-right: 10px;
              }

              .square-four {
                width: 20px;
                height: 20px;
                background-color: #00cf3a;
                border-radius: 5px;
                margin-right: 10px;
              }
            }
          }
        }
      }
      .div-chart-user {
        width: 100%;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
        background-color: white;
        border-radius: 8px;
        padding: 20px;

        .div-total-time-area {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .div-time-area {
            display: flex;
            flex-direction: row;

            .text-time {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 28px;
              color: #11263c;
              margin-right: 10px;
            }

            .text-area {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 28px;
              color: #11263c;
              margin-left: 30px;
              margin-right: 10px;
            }
          }
          .div-total {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .text-total-user {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 28px;
              color: #11263c;
              margin-right: 10px;
            }

            .div-total {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100px;
              height: 30px;
              border: 1px solid #bbbbbb;
              border-radius: 6px;
            }

            .text-number-total {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 28px;
              color: #11263c;
              margin-right: 10px;
              text-decoration: none;
            }
          }
        }
      }
    }

    .div-chart-pie-total-cancel-dash {
      display: flex;
      flex-direction: column;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
      background-color: white;
      border-radius: 8px;
      padding: 10px;
      margin-top: 5px;
      width: 100%;

      .div-pie-chart-cancel {
        display: flex;
        flex-direction: row;
        width: 100%;

        .div-total-piechart {
          display: flex;
          flex-direction: column;
          width: 30%;
          min-height: 100px;
          border-right: 1px solid #d6d6d6;
          padding: 5px;
          justify-content: center;

          .item-total {
            display: flex;
            flex-direction: row;

            .title-total {
              color: black;
              font-weight: 500;
              font-size: 12px;
              font-family: "Roboto", sans-serif;
              text-decoration: none;
              width: 200px;
            }

            .text-colon {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              color: #000000;
            }

            .number-total {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              color: #000000;
              margin-left: 10px;
            }
          }
        }

        .div-pie-cancel {
          width: 70%;
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 490px) {
  .container-dash {
    width: 100%;
    .div-chart_total_service_collaborator {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
      height: auto;

      .chart {
        height: 550px !important;
        width: 100% !important;
        padding: 10px;
        border-radius: 8px;
        background-color: white;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
        box-shadow: 2px 3px #d6d6d6;

        .div-head-chart {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          .text-total-money {
            color: #000000;
            font-family: "Roboto";
            font-style: normal;
            font-size: 12px;
          }
          .div-date {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-bottom: 10px;

            .text-date {
              color: #000000;
              font-family: "Roboto";
              font-style: normal;
              font-size: 12px;
              margin-left: 10px;
            }

            .input-picker {
              width: 100px;
            }
            .picker {
              width: 300px;
            }
          }
        }

        .type-select {
          width: 110px;
          min-height: 32px;
          border-radius: 8px;
          margin-right: 5px;
          border-color: rgb(211, 211, 211);
          color: rgb(204, 204, 204);
        }

        .label-persen-active {
          color: black;
          font-weight: 600;
          font-size: 18px;
          font-family: "Roboto", sans-serif;
        }

        .label-active {
          color: black;
          font-weight: 600;
          font-size: 30px;
          font-family: "Roboto", sans-serif;
        }
        .div-persen {
          display: flex;
          align-items: center;

          .label-persen {
            color: black;
            font-weight: 600;
            font-size: 18px;
            font-family: "Roboto", sans-serif;
          }
          .label-total {
            color: #d0d1d2;
            font-weight: 200;
            font-size: 14px;
            font-family: "Roboto", sans-serif;
            margin-left: 20px;
          }
        }
        .progress-persent {
          padding-right: 50px;
        }
        .div-container-on {
          display: flex;
          justify-content: space-between;
          padding-left: 20px;
          padding-right: 50px;

          .div-on {
            display: flex;
            flex-direction: row;

            .line-on {
              width: 30px;
              height: 4px;
              background-color: #00b4d8;
              border-radius: 5px;
              margin-top: 10px;
              margin-right: 20px;
            }
            .line-off {
              width: 30px;
              height: 4px;
              background-color: #e6e8f4;
              border-radius: 5px;
              margin-top: 10px;
              margin-right: 20px;
            }
            .total-div-on {
              display: flex;
              flex-direction: column;

              .text-on {
                color: #d0d1d2;
                font-weight: 200;
                font-size: 14px;
                font-family: "Roboto", sans-serif;
              }
              .text-total-on {
                color: black;
                font-weight: 500;
                font-size: 14px;
                font-family: "Roboto", sans-serif;
              }
            }
          }
        }
      }

      .div-right-conection-dashboard {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 10px;

        .div-right {
          display: flex;
          flex-direction: column;
          width: 100%;

          .div-connect-service {
            display: flex;
            width: 100%;
            flex-direction: column;
            background-color: white;
            border-radius: 8px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
            box-shadow: 2px 3px #d6d6d6;
            margin-right: 10px;
            padding-bottom: 5px;

            .div-progress {
              min-height: 120px;
              align-items: center;
              justify-content: center;
              display: flex;
            }

            .div-progress-text {
              .title-progress {
                text-align: center;
                color: black;
                font-weight: 600;
                font-size: 18px;
                font-family: "Roboto", sans-serif;
              }
            }

            .div-success {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: 15%;

              .square {
                width: 20px;
                height: 20px;
                background-color: #48cae4;
                border-radius: 5px;
              }
              .text-success-square {
                color: black;
                font-weight: 500;
                font-size: 14px;
                font-family: "Roboto", sans-serif;
                margin: 3px 0px 0px 10px;
              }
              .unsquare {
                width: 20px;
                height: 20px;
                background-color: #e6e8f4;
                border-radius: 5px;
              }
            }
          }

          .div-top-collaborator {
            display: flex;
            flex-direction: column;
            width: 100%;
            background-color: white;
            border-radius: 8px;
            filter: drop-shadow(0px 4px 4px rgba(38, 37, 37, 0.1));
            box-shadow: 2px 3px #d6d6d6;
            flex: 1;

            .text-top {
              text-align: center;
              color: black;
              font-weight: 600;
              font-size: 18px;
              font-family: "Roboto", sans-serif;
              padding-top: 10px;
            }

            .level {
              flex-direction: column;

              .level-ctv1 {
                display: flex;
                background-color: #023e8a;
                height: 38px;
                margin-left: 25px;
                margin-right: 25px;
                border-radius: 5px 5px 5px 0px;
                align-items: center;
                justify-content: space-between;
                padding: 20px 10px 0px 10px;
                .text-level {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  width: 150;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }

                .text-level-number {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }

              .level-ctv2 {
                display: flex;
                height: 38px;
                background-color: #0077b6;
                margin-left: 25px;
                margin-right: 35px;
                border-radius: 0px 5px 5px 0px;
                align-items: center;
                justify-content: space-between;
                padding: 20px 10px 0px 10px;
                .text-level {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  width: 100px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .text-level-number {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }

              .level-ctv3 {
                display: flex;
                height: 38px;
                background-color: #0096c7;
                margin-left: 25px;
                margin-right: 50px;
                border-radius: 0px 5px 5px 0px;
                align-items: center;
                justify-content: space-between;
                padding: 20px 10px 0px 10px;
                .text-level {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  width: 88px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .text-level-number {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
              .level-ctv4 {
                display: flex;
                height: 38px;
                background-color: #00b4d8;
                margin-left: 25px;
                margin-right: 60px;
                border-radius: 0px 5px 5px 0px;
                align-items: center;
                justify-content: space-between;
                padding: 20px 10px 0px 10px;
                .text-level {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  width: 77px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .text-level-number {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
              .level-ctv5 {
                display: flex;
                height: 38px;
                background-color: #48cae4;
                margin-left: 25px;
                margin-right: 70px;
                border-radius: 0px 5px 5px 5px;
                align-items: center;
                justify-content: space-between;
                padding: 20px 10px 0px 10px;
                .text-level {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  width: 66px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .text-level-number {
                  color: white;
                  font-weight: normal;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
            }

            .div-seemore {
              text-align: right;
              padding: 10px;
            }
          }
        }

        .col-activity-dashboard {
          display: flex;
          flex-direction: column;
          width: 100%;
          background-color: #fff;
          border-radius: 8px;
          min-height: 80%;
          margin-top: 10px;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));

          .label-activity {
            color: black;
            font-weight: 600;
            font-size: 27px;
            font-family: "Roboto", sans-serif;
            text-decoration: none;
            text-align: center;
            padding-top: 20px;
            padding-bottom: 10px;
          }

          .div-seemore {
            display: flex;
            align-items: end;
            justify-content: end;
            margin-top: 10px;
            padding-right: 10px;
            padding-bottom: 10px;
            background-color: transparent;
            border: none;
          }
        }
      }
    }
  }
}
