.container-app-customer {
  background-color: white;
  border-radius: 5px;
  width: 99%;
  min-height: 60%;
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;

  .label-kh {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #25213b;
    text-decoration: none;
  }

  .div-body-app-customer {
    display: flex;
    flex-direction: row;

    .form-input {
      margin-top: 50px;
    }

    .input-version {
      width: 300px;
    }

    .input-image {
      border: none;
      width: 300px;
    }

    .img-background-header {
      width: 400px;
      height: 150px;
      border-radius: 10px;
    }

    .div-col-right {
      margin-left: 100px;
    }
  }

  .btn-update {
    width: 200px;
    height: 40px;
    border-radius: 5px;
    background-color: #48cae4;
    color: white;

    margin-top: 50px;
  }
}

@media screen and (max-width: 490px) {
  .container-app-customer {
    background-color: white;
    border-radius: 5px;
    width: 99%;
    min-height: 60%;
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;

    .label-kh {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      color: #25213b;
      text-decoration: none;
    }

    .div-body-app-customer {
      display: flex;
      flex-direction: column;

      .form-input {
        margin-top: 50px;
      }

      .input-version {
        width: 300px;
      }

      .input-image {
        border: none;
        width: 300px;
      }

      .img-background-header {
        width: 100%;
        height: 150px;
        border-radius: 10px;
      }

      .div-col-right {
        margin-left: 0px;
      }
    }

    .btn-update {
      width: 200px;
      height: 40px;
      border-radius: 5px;
      background-color: #48cae4;
      color: white;

      margin-top: 50px;
    }
  }
}
