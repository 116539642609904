@import "/src/@theme/style.scss";

/* ~~~ SON CSS ~~~ */

.range-date-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border: 1px solid $colorViolet;
  border-radius: $borderRadiusL;
  cursor: pointer;
  transition: 0.5s all ease;
  gap: $gapS;

  .range-date-picker__label {
    display: flex;
    gap: $gapXS;
    align-items: center;
    color: $colorViolet;
    font-weight: $fontWeightXL;
    font-size: $textFontSizeM;
  }

  .range-date-picker__line {
    width: 1px;
    height: 20px;
    border-radius: $borderRadiusL;
    background-color: $colorMoreLightGray;
  }

  .range-date-picker__option {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    background-color: $colorMoreLightGray;
    border-radius: $borderRadiusL;

    .range-date-picker__option--title {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: $gapXS;
      font-size: $textFontSizeM;
      font-weight: $fontWeightL;
    }
  }

  &:hover {
    // border: 0px;
    background-color: $colorViolet;

    .range-date-picker__label {
      color: $colorWhite;
    }

    .range-date-picker__line,
    .range-date-picker__option {
      background-color: $colorWhite;
    }
  }

}

.btn-date-picker {
  display: flex;
  gap: $gapXS;
  justify-content: center;
  align-items: center;
  border: 1px solid $colorBorderAntDesign;
  border-radius: $borderRadiusS;
  line-height: $lineHeightL;
  padding: 6.5px 12px;
}

.div-body-modal {
  background-color: black;
  display: flex;
  flex-direction: column;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  margin-top: 5px;
  position: absolute;
  z-index: 1000;
  background-color: white;
  padding: 0px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);

  &.right {
    right: 225px;
  }

  .div-main {
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid #d3d3d3;


    .div-left-body-modal {
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      border-right: 2px solid #d3d3d3;
      padding-bottom: 1em;

      .div-btn-date {
        display: flex;
        justify-content: start;
        align-items: center;
        height: 40px;
        width: 130px;
        padding-left: 10px;
        cursor: pointer;
        background-color: transparent;
        // border-bottom: 1px solid gray;
        transition-duration: 300ms;


        .text-btn-date {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: black;
          text-decoration: none !important;
          margin: 0;
          transition-duration: 500ms;
        }

        .text-btn-date:hover {
          color: #8b5cf6;
        }
      }

      .div-btn-date-select {
        display: flex;
        justify-content: start;
        align-items: center;
        background-color: #8b5cf6;
        height: 40px;
        width: 130px;
        padding-left: 10px;
        border-radius: 2px;
        cursor: default;
        // transition-duration: 1000ms;

        .text-btn-date {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: rgb(0, 0, 0);
          text-decoration: none !important;
          color: white;
          margin: 0;
          transition-duration: 500ms;
        }
      }
    }

    .div-right-body-modal {
      .calendar-picker {
        border: none;
        color: black;
        // background-color: rgb(239, 239, 239);
        border-radius: 8px;
        margin-top: 10px;
      }

      .classname_title {
        color: black;
      }

      .div-tab-time {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-bottom: 2px solid #d3d3d3;

        .div-tab-item {
          text-align: center;
          margin-left: 15px;
          margin-right: 15px;
          width: 50px;
        }

        .div-tab-item-select {
          text-align: center;
          // margin-left: 15px;
          // margin-right: 15px;
          border-bottom: 0px;
          width: 50px;
          color: #8b5cf6;
          font-weight: bold;
        }
      }
    }
  }

  .div-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;

    .btn-cancel-date {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: 1px solid #d6d6d6;
      width: auto;
      height: 30px;
      border-radius: 4px;
    }

    .btn-confirm-date {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(64, 0, 255);
      width: auto;
      height: 30px;
      color: white;
      margin-left: 10px;
      border-radius: 4px;
      border: none;
    }
  }
}

.title-time {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: rgb(78, 77, 77);
  text-decoration: none !important;
  margin-bottom: 5px;
}


// CSS Calendar
.react-calendar {
  min-width: 400px;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  // padding-left: 18px;
  // padding-right: 18px;
  font-weight: 500;
  // box-shadow: rgba(0, 0, 0.1, 0.2) 0px 24px 2px 0px;
  // box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  // font-family: Arial, Helvetica, sans-serif;
  // line-height: 1.5em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* ~~~ button styles ~~~ */
.react-calendar button {
  margin: 0;
  border-radius: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  // margin-bottom: 5px;
  font-size: 2rem !important;

}

.react-calendar__navigation span {
  color: #8b5cf6;
  min-width: 44px;
  background: none;
  font-size: 1.2rem !important;

  // font-size: 2rem;
  // margin-top: 8px;
}

.react-calendar__navigation button {
  color: #8b5cf6;
  min-width: 44px;
  background: none;
  // font-size: 2rem;
  // margin-top: 8px;
}


.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation__label {
  font-size: 2rem;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

/* ~~~ weekend styles ~~~ */
.react-calendar__month-view__days__day--weekend {
  color: black;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  // background: red !important;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 1rem;
}

abbr[title] {
  text-decoration: none;
}

/* ~~~ Css cho những ngày disabled ~~~ */
.react-calendar__tile:disabled {
  // background-color: #f0f0f0;
  color: #ababab;
  opacity: $opacityS;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
  color: #bab9b9;
}

// Css cho những ngày bên ngoài ngày ĐÃ CHỌN
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #8b5cf6;
  // border-radius: 4px;
  color: white
}


.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

/* ~~~ Css cho tất cả những ngày ĐÃ CHỌN (KỂ CẢ 1 NGÀY HAY NHIỀU NGÀY) ~~~ */
.react-calendar__tile--active {
  background: #6f48eb;
  // border-radius: 0px !important;
  // font-weight: bold;
  color: white;
}

/* ~~~ Css cho ngày chọn đầu tiên hoặc cuối cùng~~~ */
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #6f48eb;
  color: white;
}

/* ~~~ Css cho những ngày ở tháng kế tiếp hoặc phía sau ~~~ */
.react-calendar__month-view__days__day--neighboringMonth {
  opacity: $opacityS;
}

/* ~~~ Css cho những ngày muốn chọn (CHƯA CHỌN) ~~~ */
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f5effb;
  // color: black;
  transition-duration: 500ms;
}

/* ~~~ Css cho những ngày trong danh sách ngày ĐÃ CHỌN ~~~ */
.react-calendar__tile--range {
  background: #f3effc !important;
  color: #000000;
  // border-top: 1px dashed blueviolet;
  // border-bottom: 1px dashed blueviolet;
  // border-radius: 0 !important;
  border: 0px !important
}

/* ~~~ Css label ngày trong tuần ~~~ */
.react-calendar__month-view__weekdays {
  font-weight: bold;
  font-size: 1.1em;
  // border-bottom: 2px solid #E6EAEC;
  // margin-bottom: 6px;
}

/* ~~~ Css cho ô ngày hiện tại ~~~ */
.react-calendar__tile--now {
  // background: #fefe5f !important;
  // border: 2px solid yellow;
  // border-radius: 10px !important;
  // color: orange !important;  
  // font-weight: bold;
  border: 1px dashed #8b5cf6;
  // border-radius: 4px ;
  // border-radius: 14px !important;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  // background: #fefe5f ;
  color: black;
}

/* ~~~ Css cho ô ngày bắt đầu nếu chọn nhiều ngày ~~~ */
.react-calendar__tile--rangeStart {
  // border-top-right-radius: 0;
  // border-bottom-right-radius: 0;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  background: #8b5cf6 !important;
  color: white;
  transition-duration: 500ms;
  // font-weight: bold;
  border-top: 0px;
  border-bottom: 0px
}

/* ~~~ Css cho ô ngày kết thúc nếu chọn nhiều ngày ~~~ */
.react-calendar__tile--rangeEnd {
  // border-top-left-radius: 0;
  // border-bottom-left-radius: 0;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  background: #8b5cf6 !important;
  color: white;
  transition-duration: 500ms;
  // font-weight: bold;
  border-top: 0px;
  border-bottom: 0px
}


/* ~~~ Css cho các ngày thuộc kì trước ~~~ */
// .first-day-in-term {
//   color: white;
//   background: #008631 !important;
//   font-weight: bold;
//   border-top-left-radius: 14px !important;
//   border-bottom-left-radius: 14px  !important;
//   transition-duration: 500ms;
// }

// .last-day-in-term {
//   color: white;
//   background: #008631 !important;
//   font-weight: bold;
//   border-top-right-radius: 14px !important;
//   border-bottom-right-radius: 14px !important;
//   transition-duration: 500ms;
// }

// .day-in-term {
//   color: white;
//   background: #cbfcdd !important;
//   font-weight: bold;
//   color: #008631;
//   transition-duration: 500ms;
// }

// .day-in-term:hover {
//   color: white;
//   transition-duration: 500ms;
// }

// .one-day-term {
//   color: white;
//   background: #008631 !important;
//   font-weight: bold;
//   border-radius: 14px !important;
//   transition-duration: 500ms;
// }


/* ~~~ Css grid view cho chế độ xem theo từng tháng ~~~ */
.react-calendar__month-view__days {
  display: grid !important;
  grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%;

  .react-calendar__tile {
    max-width: initial !important;
  }
}

/* ~~~ Css grid view cho các chế độ xem còn lại ~~~ */
.react-calendar__year-view__months,
.react-calendar__decade-view__years,
.react-calendar__century-view__decades {
  display: grid !important;
  grid-template-columns: 20% 20% 20% 20% 20%;

  &.react-calendar__year-view__months {
    grid-template-columns: 25% 25% 25% 25%;
  }

  .react-calendar__tile {
    max-width: initial !important;
  }
}


@media screen and (max-width: 570px) {
  .react-calendar {
    min-width: 300px;
    max-width: 100%;
    background-color: #fff;
    color: #222;
    // padding-left: 18px;
    // padding-right: 18px;
    // box-shadow: rgba(0, 0, 0.1, 0.2) 0px 24px 2px 0px;
    // box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.5em;
  }
}