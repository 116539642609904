@import "../../../../../../../../src/@theme/style.scss";

.title-test-exam {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 18px;
  color: black;
  text-decoration: none;
}

.note-answers {
  display: flex;
  flex-direction: column;

  .text-true {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 14px;
    color: #00b106;
    text-decoration: none;
    margin: 0;
  }

  .text-warning {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 14px;
    color: #ffce1b;
    text-decoration: none;
    margin: 0;
  }

  .text-false {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 14px;
    color: rgb(255, 5, 5);
    text-decoration: none;
    margin: 0;
  }
}

.div-exam-test {
  display: flex;
  flex-direction: row;

  .div-test {
    display: flex;
    flex-direction: column;
    width: 100%;

    .div-head-test {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .div-score {
        display: flex;
        flex-direction: column;

        .title-score {
          font-family: "Roboto";
          font-weight: 400;
          font-size: 16px;
          color: black;
          text-decoration: none;
          margin: 0;
        }
      }

      .div-time-test {
        display: flex;
        flex-direction: column;

        .title-time {
          font-family: "Roboto";
          font-weight: 400;
          font-size: 16px;
          color: black;
          text-decoration: none;
          margin: 0;
        }
      }
    }

    .div-question-test {
      display: flex;
      flex-direction: column;

      .title-question {
        font-family: "Roboto";
        font-weight: 500;
        font-size: 16px;
        color: black;
        text-decoration: none;
        margin: 0;
      }

      .text-answer-default {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.558);
        text-decoration: none;
        margin: 0;
      }

      .text-answer-true {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 14px;
        color: #00b106;
        text-decoration: none;
        margin: 0;
      }

      .text-answer-warning {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 14px;
        color: #ffce1b;
        text-decoration: none;
        margin: 0;
      }

      .text-answer-false {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 14px;
        color: rgb(255, 5, 5);
        text-decoration: none;
        margin: 0;
      }
    }
  }
}

.div-tab-exam {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  .item-tab-exam {
    padding: 5px;
    margin-right: 10px;

    .text-tab {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      color: rgb(182, 182, 182);
      text-decoration: none;
      margin: 0;
    }
  }

  .item-tab-exam-select {
    padding: 5px;
    border-bottom: 1px solid blue;
    margin-right: 10px;

    .text-tab {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      color: black;
      text-decoration: none;
      margin: 0;
    }
  }
}

.collaborator-exam {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;

  // align-items: center;
  .collaborator-exam__lesson {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $gapL;

    .collaborator-exam__lesson--child {
      display: flex;
      flex-direction: column;
       box-shadow:
    rgba(67, 71, 85, 0.27) 0px 1px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.75em 2em;
      background-color: $colorWhite;
      border-radius: $borderRadiusL;
      padding: 12px 12px;
      gap: $gapXS;

      .collaborator-exam__lesson--child-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .collaborator-exam__lesson--child-header-name {
          font-weight: $fontWeightXL;
          font-size: $textFontSizeM;
        }

        .collaborator-exam__lesson--child-header-status {
          padding: 4px;
          //  background-color: $colorExtraLightGreen;
          border-radius: $borderRadiusFull;

          &.done {
            color: $colorGreen;
            background-color: $colorExtraLightGreen;
          }

          &.not-done {
            // color: $co;
            background-color: $colorExtraLightGray;
            cursor: pointer;
          }
        }
      }

      .collaborator-exam__lesson--child-subcontent {
        span {
          font-weight: $fontWeightL;
          color: $colorGray;
          opacity: $opacityM;
        }
      }

      .collaborator-exam__lesson--child-owner {
        display: flex;
        gap: $gapM;
        padding-top: 4px;

        .collaborator-exam__lesson--child-owner-avatar {
          height: 28px;
          width: 28px;
          border-radius: $borderRadiusFull;
        }

        .collaborator-exam__lesson--child-owner-body {
          display: flex;
          justify-content: space-between;
          // align-items: center;
          width: 100%;

          .collaborator-exam__lesson--child-owner-body-info {
            display: flex;
            flex-direction: column;

            .collaborator-exam__lesson--child-owner-body-info-name {
              font-size: $textFontSizeM;
              font-weight: $fontWeightXL;
            }

            .collaborator-exam__lesson--child-owner-body-info-time {
              font-size: $textFontSizeM;
              font-weight: $fontWeightL;
              color: $colorGray;
              opacity: $opacityM;
            }
          }

          .collaborator-exam__lesson--child-owner-body-more-detail {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            span {
              font-size: $textFontSizeM;
              font-weight: $fontWeightL;
              color: $colorGray;
              opacity: $opacityM;
              cursor: pointer;
              transition-duration: 0.3s;

              &:hover {
                color: $colorBlack;
                opacity: 1;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  .div-item-lesson-collaborator {
    display: flex;
    flex-direction: column;
    width: 80%;
    background-color: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    padding: 10px;
    margin-top: 10px;
    border-radius: 4px;

    .div-title-lesson {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .text-title {
        font-family: "Roboto";
        font-weight: 500;
        font-size: 14px;
        color: rgb(0, 0, 0);
        text-decoration: none;
        margin: 0;
      }

      .img {
        width: 50px;
        height: 50px;
      }
    }

    .text-description {
      font-family: "Roboto";
      font-weight: 400;
      font-size: 12px;
      color: rgb(141, 138, 138);
      text-decoration: none;
      margin: 0;
    }

    .see-answer {
      font-family: "Roboto";
      font-weight: 400;
      font-size: 12px;
      color: rgb(0, 0, 0);
      text-decoration: none;
      text-align: end;
      margin: 0;
    }
  }

  .div-item-lesson-collaborator-hide {
    display: flex;
    flex-direction: column;
    width: 80%;
    background-color: rgb(231, 231, 231);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    padding: 10px;
    margin-top: 10px;
    border-radius: 4px;

    .div-title-lesson {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .text-title {
        font-family: "Roboto";
        font-weight: 500;
        font-size: 14px;
        color: rgb(0, 0, 0);
        text-decoration: none;
        margin: 0;
      }

      .img {
        width: 50px;
        height: 50px;
      }
    }

    .text-description {
      font-family: "Roboto";
      font-weight: 400;
      font-size: 12px;
      color: rgb(0, 0, 0);
      text-decoration: none;
      margin: 0;
    }

    .see-answer {
      font-family: "Roboto";
      font-weight: 400;
      font-size: 12px;
      color: rgb(0, 0, 0);
      text-decoration: none;
      text-align: end;
      margin: 0;
    }

    .div-answer {
      max-width: 0;
      overflow: hidden;
      display: inline-block;
      transition: max-height 0.3s;
    }
  }
}