.btn-add-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  height: 48px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-left: 20px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
}

.btn-add-banner-drawer {
  display: flex;
  height: 30px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-left: 20px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
  align-items: center;
  justify-content: center;
  float: right;
  margin-top: 20px;
}

.img-thumbnail-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 100px;
  border-radius: 8px;
  margin-top: 17px;
}

.upload-thumbnail-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 100px;
  border-radius: 8px;
  border: 0.5px solid #9b9b9b;
}

.input-group {
  margin-bottom: 10px;
  border: none;
}

.select-code-phone {
  width: 300px !important;
  height: 40px !important;
}

.label-img {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #19202c;
  align-items: center;
}
