.div-title-collaborator {
  display: flex;
  flex-direction: column;
  align-items: center;

  .text-money-title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    color: #000;
    text-decoration: none !important;
    margin-top: 20px;
  }

  .text-money-title-blue {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 17px;
    color: #2e2aff;
    text-decoration: none !important;
    margin-top: 20px;
  }

  .div-top {
    margin-top: 35px;
  }

  .div-title-report {
    display: flex;
    flex-direction: row;
    align-items: center;

    .text-title-column {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 13px !important;
      margin: 0;
      color: #000;
      text-decoration: none !important;
    }

    .text-title-column-blue {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 13px !important;
      margin: 0;
      color: #2e2aff;
      text-decoration: none !important;
    }

    .btn-question {
      border: none;
      background-color: transparent;
      width: 10px;
      height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .icon-question {
      color: aqua;
    }
  }
}

.div-title-collaborator-id {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .text-money-title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 17px;
    color: #000;
    text-decoration: none !important;
    margin-top: 20px;
  }

  .div-top {
    margin-top: 35px;
  }

  .div-title-report {
    display: flex;
    flex-direction: row;
    align-items: center;

    .text-title-column {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 13px !important;
      color: #000;
      text-decoration: none !important;
      margin: 0;
    }

    .icon-question {
      color: aqua;
    }
  }
}

.text-money {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px !important;
  line-height: 17px;
  color: #000;
  text-decoration: none !important;
}

.text-money-blue {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px !important;
  line-height: 17px;
  color: #2e2aff;
  text-decoration: none !important;
}

.div-content {
  width: 160px;

  .text-content {
    color: #000000;
    font-family: "Roboto";
    font-style: normal;
    font-size: 14px;
  }
}

.div-header-report {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .div-date {
    display: flex;
    flex-direction: row;
    width: 40%;
    align-items: center;

    .text-date {
      color: #000000;
      font-family: "Roboto";
      font-style: normal;
      font-size: 12px;
      margin-left: 10px;
    }
  }

  .input-search-report {
    height: 40px;
    width: 60%;
  }
}

.loading {
  position: fixed;
  z-index: 1051;
  height: auto;
  width: auto;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.div-name-ctv-report {
  display: flex;
  flex-direction: column;

  .text-name-report {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #023e8a;
    margin: 0;
  }

  .text-id {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }
}

@media screen and (max-width: 490px) {
  .div-header-report {
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;

    .div-date {
      display: flex;
      flex-direction: row;
      width: 100% !important;

      align-items: center;

      .text-date {
        color: #000000;
        font-family: "Roboto";
        font-style: normal;
        font-size: 12px;
        margin-left: 10px;
      }
    }

    .input-search-report {
      height: 40px;
      width: 100% !important;
    }
  }
}
