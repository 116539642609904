.div-header-collaborator {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  .title-cv {
    color: black;
    font-weight: 400;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    margin: 0;
  }
}

.div-container-collaborator {
  background-color: white;
  border-radius: 15px;
  margin: 10px;
  padding: 10px;

  .div-tab-collaborator {
    display: flex;
    flex-direction: row;

    .div-item-tab {
      padding: 5px;
      margin-right: 5px;

      .text-tab {
        color: rgb(170, 170, 170);
        font-weight: 400;
        font-size: 14px;
        font-family: "Roboto";
        text-decoration: none;
        margin: 0;
      }
    }

    .div-item-tab-select {
      padding: 5px;
      margin-right: 5px;
      border-bottom: 1px solid blue;

      .text-tab {
        color: black;
        font-weight: 400;
        font-size: 14px;
        font-family: "Roboto";
        text-decoration: none;
        margin: 0;
      }
    }
  }
}
