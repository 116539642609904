.img-thumbnail {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}

.select-code-phone {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  margin-left: 20px;
  border-color: rgb(216, 216, 216);
}

.add {
  display: flex;
  .withdraw {
    margin-left: 20px;
  }
}

.btn-confirm-topup-collaborator {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-family: "Roboto";
  font-size: small;
  border-radius: 5px;
  background-color: aquamarine;
  width: auto;
  height: 30px;
}
.btn-confirm-topup-collaborator-hide {
  display: none;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  border-radius: 5px;
  background-color: aquamarine;
}

.body-modal {
  display: flex;
  flex-direction: column;

  .text-content {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    color: rgb(0, 0, 0) !important;
    margin-top: 5px;
    margin: 0;
  }
}

.text-name-modal {
  font-size: medium;
  color: orangered;
  margin-left: 5px;
  margin-right: 5px;
}

.icon-topup {
  color: rgb(43, 159, 121) !important;
  margin-right: 10px;
  width: 15px !important;
  height: 15px !important;
}

.text-topup {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: rgb(43, 159, 121) !important;
}

.icon-withdraw {
  color: #ff0000 !important;
  margin-right: 10px;
  width: 15px !important;
  height: 15px !important;
}

.text-withdraw {
  color: #ff0000 !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  margin: 0;
}

.text-done-topup {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #00cf3a;
  text-decoration: none;
  margin: 0;
}

.text-cancel-topup-ctv {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #ff0000 !important;
  text-decoration: none;
  margin: 0;
}

.text-pending-topup {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px !important;
  color: #ecd503 !important;
  text-decoration: none;
  margin: 0;
}

.text-transfered {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px !important;
  color: #19129a !important;
  text-decoration: none;
  margin: 0;
}

.div-name-topup {
  display: flex;
  flex-direction: column;

  .text-name-topup {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }

  .text-phone-topup {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000 !important;
    text-decoration: none;
  }
}

.div-total {
  display: flex;
  flex-direction: column;

  .total-revenue {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #000000;
    text-decoration: none;

    .text-money-revenue {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      text-decoration: none;
      margin-left: 10px;
    }

    .icon-up {
      color: #00cf3a;
    }
  }
  .total-expenditure {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #000000;
    text-decoration: none;

    .text-money-expenditure {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      text-decoration: none;
      margin-left: 10px;
    }
    .icon-down {
      color: #ff0000;
    }
  }
}

.div-header-topup {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;

  .div-topwith {
    display: flex;
    flex-direction: row;
  }

  .input-search-topup {
    height: 40px;
    width: 70%;
    margin-left: 10%;
  }
}

.div-date {
  display: flex;
  flex-direction: row;

  .picker {
    width: 300px;
  }
}

.text-name-verify {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  text-decoration: none;
  margin: 0;
}

.text-id {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  text-decoration: none;
  margin: 0;
}

.text-description-topup {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  text-decoration: none;
  display: block;
  width: 250px;
  margin: 0;
}

.text-money-topup {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  text-decoration: none;
  margin: 0;
}

.div-time-topup {
  display: flex;
  flex-direction: column;

  .text-time {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }
}

.div-delete-edit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .icon-edit {
    color: #ecd503;
    opacity: 0.5;
  }
}

.div-detail-topup {
  display: flex;
  flex-direction: column;

  .div-money-withdraw-topup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .icon-topup {
      color: #00cf3a !important;
      margin-bottom: 5px;
    }
    .icon-withdraw {
      color: #ff0000 !important;
      margin-bottom: 5px;
    }
    .text-topup {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      color: #00cf3a !important;
      text-decoration: none;
      margin: 0;
    }

    .text-withdraw {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      color: #ff0000 !important;
      text-decoration: none;
      margin: 0;
    }
  }

  .div-text-detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    .title-detail {
      width: 70px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #202223;
      text-decoration: none;
      margin: 0;
    }

    .text-detail {
      display: block;
      width: 250px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #202223;
      text-decoration: none;
      margin: 0;
    }
  }
}

.div-money-withdraw-topup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .icon-topup {
    color: #00cf3a !important;
    margin-bottom: 5px;
  }
  .icon-withdraw {
    color: #ff0000 !important;
    margin-bottom: 5px;
  }
  .text-topup {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #00cf3a !important;
    text-decoration: none;
    margin: 0;
  }

  .text-withdraw {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #ff0000 !important;
    text-decoration: none;
    margin: 0;
  }
}

@media screen and (max-width: 1440px) {
  .text-description-topup {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    display: block;
    width: 150px !important;
    margin: 0;
  }
}

@media screen and (max-width: 900px) {
  .div-header-topup {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    justify-content: center;
    margin: 0;

    .div-topwith {
      display: flex;
      flex-direction: column;
      width: 90%;
    }

    .input-search-topup {
      height: 40px;
      width: 90%;
      margin-top: 5px;
    }
  }
}
