// .container-dashboard {
//   min-height: 100vh;
//   width: 100%;
//   padding: 0px;
//   background-color: #eef3f5;

//   .sidebar {
//     background-color: white;
//     height: 100vh;
//     box-shadow: 1px 1px #e6e6e6;
//     width: 14%;
//   }

//   .main {
//     width: 85%;
//   }
// }

// .div-menu-das {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 4px;
//   width: 30px;
//   height: 30px;
//   border: 1px solid #d6d6d6;
//   background-color: white;
//   margin: 25px;
// }

.div-container-layout-dashboard {
  display: flex;
  flex-direction: row;
  background-color: rgb(255, 255, 255);
  min-height: auto;
  width: 100%;

  .div-side-bar {
    display: flex;
    flex: 5%;
    overflow: auto;
    background-color: white;
  }
  .div-side-bar-hide {
    overflow: auto;
    background-color: white;
  }

  .div-right-layout {
    display: flex;
    flex: 80%;
    flex-direction: column;
    background-color: rgb(227, 227, 227);

    .div-header-layout {
      display: flex;
      align-items: center;
      background-color: rgb(39, 130, 248);
      height: 60px;
      margin-left: 10px;
      margin-right: 10px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .div-content-layout {
      background-color: white;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 10px;
      border-radius: 8px;
      min-height: 900px;
    }
  }
}
