.btn-add-collaborator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: "auto";
  height: 32px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-left: 20px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
}

.btn-add-collaborator-hide {
  width: 15%;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-left: 20px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
  display: none;
}

.btn-add-ctv {
  width: 15%;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-left: 20px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
}

.textInput {
  width: 200px;
  height: 40px;
  border-radius: 5px;
  margin-left: 20px;
  border: 1px solid #dddd;
}

.btn-close {
  color: red;
  border: none;
  background-color: transparent;
  height: 50px;
  width: 40px;
}

@media screen and (max-width: 490px) {
  .btn-add-collaborator {
    width: 90%;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #48cae4;
    color: white;
    box-shadow: 2px 3px 2px rgb(216, 216, 216);
  }
}
