.img-thumbnail {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.select-code-phone {
  width: 400px;
  height: 40px;
  border-radius: 5px;
  margin-left: 20px;
  border-color: rgb(216, 216, 216);
}
