.div-report-order-create {
  background-color: white;
  padding: 10px;
  margin-top: 10px;
  border-radius: 8px;

  .text-id-report-order {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }
  .div-title-column {
    display: flex;
    flex-direction: row;
    align-items: center;

    .btn-question {
      border: none;
      background-color: transparent;
      width: 10px;
      height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .icon-question {
      color: aqua;
      margin-left: 10px;
    }
  }

  .div-title-collaborator-id {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .text-money-title {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 12px !important;
      color: #000;
      text-decoration: none !important;
      margin: 20px 0 0 0;
    }

    .div-top {
      margin-top: 35px;
    }

    .div-title-report {
      display: flex;
      flex-direction: row;
      align-items: center;

      .text-title-column {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 13px !important;
        color: #000;
        text-decoration: none !important;
        margin: 0;
      }

      .btn-question {
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .icon-question {
        color: aqua;
      }
    }
  }

  .text-money {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    color: #000;
    text-decoration: none !important;
    margin: 0;
  }

  .text-money-blue {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    color: #2e2aff;
    text-decoration: none !important;
    margin: 0;
  }

  .div-content {
    width: 160px;

    .text-content {
      color: #000000;
      font-family: "Roboto";
      font-style: normal;
      font-size: 14px;
    }
  }

  .div-header-report {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .div-date {
      display: flex;
      flex-direction: row;
      width: 40%;
      align-items: center;

      .text-date {
        color: #000000;
        font-family: "Roboto";
        font-style: normal;
        font-size: 12px;
        margin-left: 10px;
      }
    }

    .input-search-report {
      height: 40px;
      width: 60%;
    }
  }

  .loading {
    position: fixed;
    z-index: 1051;
    height: auto;
    width: auto;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .div-name-ctv {
    display: flex;
    flex-direction: column;

    .text-name-report {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #000000;
    }

    .text-id {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      text-decoration: none;
    }

    .text-id-report {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
    }
  }

  .div-title-order-report {
    display: flex;
    flex-direction: column;
    align-items: center;

    .text-money-title {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 12px !important;
      color: #000;
      text-decoration: none !important;
      margin: 20px 0 0 0;
    }

    .text-money-title-blue {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 12px !important;
      color: #2e2aff;
      text-decoration: none !important;
      margin: 20px 0 0 0;
    }

    .div-top {
      margin-top: 35px;
    }

    .div-title-report {
      display: flex;
      flex-direction: row;
      align-items: center;

      .text-title-column {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 13px !important;
        color: #000;
        text-decoration: none !important;
        margin: o;
      }

      .text-title-column-blue {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 13px !important;
        color: #2e2aff;
        text-decoration: none !important;
        margin: 0;
      }

      .btn-question {
        border: none;
        background-color: transparent;
        width: 10px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .icon-question {
        color: aqua;
      }
    }
  }

  .div-date-report-order {
    display: flex;
    flex-direction: column;
    .text-date-report-order {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 13px !important;
      color: #000000;
      margin: 0;
    }
  }

  .div-tab-report {
    display: flex;
    flex-direction: row;

    .div-item-tab {
      padding: 5px;

      .text-tab {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        color: #b1b1b1;
        text-decoration: none;
      }
    }

    .div-item-tab-select {
      padding: 5px;
      border-bottom: 1px solid blue;

      .text-tab {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        color: #000000;
        text-decoration: none;
      }
    }
  }
}
