.div-container-image {
  background-color: white;
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;

  .div-list-image {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .div-item-list-image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 200px !important;
      align-items: center;
      height: 220px;
      background-color: rgb(247, 247, 247);
      border-radius: 16px;
      margin-top: 10px;
      margin-right: 20px;

      .div-name-image {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        width: 100%;
        padding: 3px 10px;
      }

      .image {
        width: 180px;
        height: 160px;
        border-radius: 8px;
        margin-top: 10px;
        object-fit: cover;
      }

      .icon_menu {
        color: black;
      }
    }

    .div-item-list-image-select {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 200px !important;
      align-items: center;
      height: 220px;
      background-color: rgb(150, 255, 250);
      border-radius: 16px;
      margin-top: 10px;
      margin-right: 20px;

      .div-name-image {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        width: 100%;
        padding: 3px 10px;
      }

      .image {
        width: 180px;
        height: 160px;
        border-radius: 8px;
        margin-top: 10px;
        object-fit: cover;
      }

      .icon_menu {
        color: black;
      }
    }
  }

  .div-item-select-delete {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 10px;
    width: 150px;

    .text-select {
      color: black;
      font-weight: 500;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      text-decoration: none;
    }
  }

  #choose-image {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  .add-image {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: aqua;
    width: 120px;
    height: 30px;
    border-radius: 4px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    color: black;
    font-weight: 500;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
  }

  .div-detail-image {
    display: flex;
    flex-direction: column;
    background-color: aqua;

    .image_detail {
      width: "100%";
      height: 200px;
      margin-top: 20px;
      margin-bottom: 20px;
      border-radius: 10px;
      object-fit: cover;
    }

    .title-image {
      color: black;
      font-weight: 500;
      font-size: 16px;
      font-family: "Roboto", sans-serif;
      text-decoration: none;
    }

    .title-link {
      color: black;
      font-weight: 400;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      text-decoration: none;
      word-wrap: break-word;
    }
  }
}
