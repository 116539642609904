.customer-info_container {
  background-color: var(--bg-color);
  border-radius: 5px;
  padding: 1rem;
  @extend .b-shadow-2;
  // min-height: 130px;
}
.customer-info_box-title {
  @include flexbox(space-between, center);
  .customer-info_box-title_detail {
    text-decoration: underline;
    text-align: center;
  }
  .customer-info_box-title_title {
    font-size: 1rem;
    @extend .fw-500;
  }
}
.customer-info_detail {
  @include flexbox(space-between, start);
  margin-top: 0.4rem;
  .customer-info_detail-avatar {
    @include flexbox(center, center, column);
    width: 90px;
  }
  p {
    color: var(--black-color);
    font-size: 14px;
  }
  span {
    color: var(--text-color);
  }
}
.customer-info_avatar-img {
  border-radius: 50px;
  align-self: center;
}
.customer-info_detail-avatar_action {
  @include flexbox(space-between, center);
  padding-top: 0.3rem;
  width: 60px;
}
.customer-info_detail-avatar_rank {
  padding-top: 0.3rem;
}
.customer-info_detail-p-name {
  padding-top: 0.3rem;
}
