.btn-create-punish {
  display: flex;
  float: right;
  margin-bottom: 20px;
}

.btn-add-punish-reason {
  width: 15%;
  height: 30px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-left: 20px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
  float: right;
  margin-top: 20px;
}
