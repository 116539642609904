// .div-date-report-overview {
//   display: flex;
//   flex-direction: row;
//   width: 100%;
//   margin-bottom: 20px;

//   .div-same {
//     border: 1px solid #d6d6d6;
//     padding: 5px;
//     border-radius: 6px;
//     width: auto;
//     padding: 5px 10px;
//     margin: 0 0 0 5px;

//     .text-date-same {
//       color: #000000;
//       font-family: "Roboto";
//       font-style: normal;
//       font-weight: 400;
//       font-size: 14px;
//       margin: 0;
//     }
//   }
// }

// .div-chart-firt-overview {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   .div-chart-gross-income {
//     width: 49%;
//     height: auto;
//     background-color: white;
//     filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
//     border-radius: 8px;
//     padding: 10px;

//     .title-gross {
//       color: #000000;
//       font-family: "Roboto";
//       font-style: normal;
//       font-weight: 500;
//       font-size: 16px;
//       margin: 0;
//     }

//     .div-total-gross {
//       display: flex;
//       align-items: center;
//       width: 50%;
//       height: 30px;
//       border: 1px solid #d6d6d6;
//       border-radius: 4px;
//       margin: 5px 0;
//       padding-left: 10px;

//       .text-total-gross {
//         color: #000000;
//         font-family: "Roboto";
//         font-style: normal;
//         font-weight: 500;
//         font-size: 14px;
//         margin: 0;
//       }

//       .text-number-persent-up {
//         display: flex;
//         align-items: center;
//         color: #16a249;
//         font-family: "Roboto";
//         font-style: normal;
//         font-weight: 400;
//         font-size: 12px;
//         margin: 0 0 0 15px;
//       }
//       .text-number-persent-down {
//         display: flex;
//         align-items: center;
//         color: #dc2828;
//         font-family: "Roboto";
//         font-style: normal;
//         font-weight: 400;
//         font-size: 12px;
//         margin: 0 0 0 15px;
//       }

//       .text-same {
//         color: #787878;
//         font-family: "Roboto";
//         font-style: normal;
//         font-weight: 400;
//         font-size: 12px;
//         margin: 0 0 0 5px;
//       }
//     }
//   }
//   .div-chart-gross-income-area {
//     width: 49%;
//     height: auto;
//     background-color: white;
//     filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
//     border-radius: 8px;
//     padding: 10px;

//     .div-head-chart {
//       display: flex;
//       flex-direction: row;
//       justify-content: space-between;
//       .title-gross {
//         color: #000000;
//         font-family: "Roboto";
//         font-style: normal;
//         font-weight: 500;
//         font-size: 16px;
//         margin: 0;
//       }
//       .div-see-all {
//         display: flex;
//         flex-direction: row;
//         align-items: center;
//         border: 1px solid #d9d9d9;
//         border-radius: 4px;
//         padding: 2px 5px;

//         .text-all {
//           color: #000000;
//           font-family: "Roboto";
//           font-style: normal;
//           font-weight: 400;
//           font-size: 12px;
//           margin: 0;
//         }
//       }
//     }

//     .div-list-chart {
//       display: flex;
//       flex-direction: column;
//       margin-top: 10px;

//       .div-item-chart {
//         display: flex;
//         flex-direction: row;
//         border-bottom: 1px solid #cccccc;
//         justify-content: space-between;
//         padding: 10px 5px;

//         .div-name-area {
//           display: flex;
//           flex-direction: column;
//           .name-area {
//             color: #212121;
//             font-family: "Roboto";
//             font-style: normal;
//             font-weight: 500;
//             font-size: 14px;
//             margin: 0;
//           }
//         }
//         .div-name-service {
//           display: flex;
//           flex-direction: row;
//           align-items: center;

//           .image-service {
//             width: 50px;
//             height: 50px;
//             border-radius: 8px;
//             object-fit: contain;
//             margin-right: 10px;
//           }

//           .name-service {
//             color: #212121;
//             font-family: "Roboto";
//             font-style: normal;
//             font-weight: 400;
//             font-size: 14px;
//             margin: 0;
//           }
//         }
//         .div-number-area {
//           display: flex;
//           flex-direction: column;
//           align-items: end;
//           .money-area {
//             color: #212121;
//             font-family: "Roboto";
//             font-style: normal;
//             font-weight: 500;
//             font-size: 14px;
//             margin: 0;
//           }

//           .text-number-persent-up {
//             display: flex;
//             align-items: center;
//             color: #16a249;
//             font-family: "Roboto";
//             font-style: normal;
//             font-weight: 400;
//             font-size: 12px;
//             margin: 0 0 0 15px;
//           }
//           .text-number-persent-down {
//             display: flex;
//             align-items: center;
//             color: #dc2828;
//             font-family: "Roboto";
//             font-style: normal;
//             font-weight: 400;
//             font-size: 12px;
//             margin: 0 0 0 15px;
//           }
//         }
//       }
//     }
//   }
// }

// .div-content-chart-net-income {
//   display: flex;
//   flex-direction: column;
//   padding: 5px;
//   border-radius: 4px;
//   background-color: white;
//   border: 1px solid #000000;
//   width: 150px;

//   .text-content {
//     color: #2962ff;
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 14px;
//     margin: 0;
//   }

//   .text-content-same {
//     color: #82ca9d;
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 14px;
//     margin: 0;
//   }
// }

// @media screen and (max-width: 900px) {
//   .div-chart-firt-overview {
//     display: flex;
//     flex-direction: column;
//     .div-chart-gross-income {
//       width: 100%;
//       height: auto;
//       background-color: white;
//       filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
//       border-radius: 8px;
//       padding: 10px;
//       margin-top: 20px;

//       .title-gross {
//         text-decoration: underline dotted;
//         color: #000000;
//         font-family: "Roboto";
//         font-style: normal;
//         font-weight: 500;
//         font-size: 16px;
//         text-decoration-line: underline;
//         text-decoration-color: #d6d6d6;
//         margin: 0;
//       }

//       .div-total-gross {
//         display: flex;
//         align-items: center;
//         width: 50%;
//         height: 30px;
//         border: 1px solid #d6d6d6;
//         border-radius: 4px;
//         margin: 5px 0;
//         padding-left: 10px;

//         .text-total-gross {
//           color: #000000;
//           font-family: "Roboto";
//           font-style: normal;
//           font-weight: 500;
//           font-size: 14px;
//           margin: 0;
//         }

//         .text-number-persent-up {
//           display: flex;
//           align-items: center;
//           color: #16a249;
//           font-family: "Roboto";
//           font-style: normal;
//           font-weight: 400;
//           font-size: 12px;
//           margin: 0 0 0 15px;
//         }
//         .text-number-persent-down {
//           display: flex;
//           align-items: center;
//           color: #dc2828;
//           font-family: "Roboto";
//           font-style: normal;
//           font-weight: 400;
//           font-size: 12px;
//           margin: 0 0 0 15px;
//         }

//         .text-same {
//           color: #787878;
//           font-family: "Roboto";
//           font-style: normal;
//           font-weight: 400;
//           font-size: 12px;
//           margin: 0 0 0 5px;
//         }
//       }
//     }
//     .div-chart-gross-income-area {
//       width: 100%;
//       height: auto;
//       background-color: white;
//       filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
//       border-radius: 8px;
//       padding: 10px;
//       margin-top: 20px;
//       .title-gross {
//         text-decoration: underline dotted;
//         color: #000000;
//         font-family: "Roboto";
//         font-style: normal;
//         font-weight: 500;
//         font-size: 16px;
//         text-decoration-line: underline;
//         text-decoration-color: #d6d6d6;
//         margin: 0;
//       }
//       .div-list-chart {
//         display: flex;
//         flex-direction: column;
//         margin-top: 10px;

//         .div-item-chart {
//           display: flex;
//           flex-direction: row;
//           padding: 10px 5px;
//           border-bottom: 1px solid #cccccc;
//           justify-content: space-between;

//           .div-name-area {
//             display: flex;
//             flex-direction: column;
//             .name-area {
//               color: #212121;
//               font-family: "Roboto";
//               font-style: normal;
//               font-weight: 500;
//               font-size: 14px;
//             }
//           }
//           .div-number-area {
//             display: flex;
//             flex-direction: column;
//             align-items: end;
//             .money-area {
//               color: #212121;
//               font-family: "Roboto";
//               font-style: normal;
//               font-weight: 500;
//               font-size: 14px;
//               margin: 0;
//             }

//             .text-number-persent-up {
//               display: flex;
//               align-items: center;
//               color: #16a249;
//               font-family: "Roboto";
//               font-style: normal;
//               font-weight: 400;
//               font-size: 12px;
//               margin: 0 0 0 15px;
//             }
//             .text-number-persent-down {
//               display: flex;
//               align-items: center;
//               color: #dc2828;
//               font-family: "Roboto";
//               font-style: normal;
//               font-weight: 400;
//               font-size: 12px;
//               margin: 0 0 0 15px;
//             }
//           }
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 490px) {
//   .div-date-report-overview {
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     margin-bottom: 20px;

//     .div-same {
//       border: 1px solid #d6d6d6;
//       padding: 5px;
//       border-radius: 6px;
//       width: auto;
//       padding: 5px 10px;
//       margin: 5px 0 0 0;

//       .text-date-same {
//         color: #000000;
//         font-family: "Roboto";
//         font-style: normal;
//         font-weight: 400;
//         font-size: 14px;
//         margin: 0;
//       }
//     }
//   }
//   .div-chart-firt-overview {
//     display: flex;
//     flex-direction: column;
//     .div-chart-gross-income {
//       width: 100%;
//       height: auto;
//       background-color: white;
//       filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
//       border-radius: 8px;
//       padding: 10px;
//       margin-top: 20px;

//       .title-gross {
//         text-decoration: underline dotted;
//         color: #000000;
//         font-family: "Roboto";
//         font-style: normal;
//         font-weight: 500;
//         font-size: 16px;
//         text-decoration-line: underline;
//         text-decoration-color: #d6d6d6;
//         margin: 0;
//       }

//       .div-total-gross {
//         display: flex;
//         align-items: center;
//         width: 100%;
//         height: 30px;
//         border: 1px solid #d6d6d6;
//         border-radius: 4px;
//         margin: 5px 0;
//         padding-left: 10px;

//         .text-total-gross {
//           color: #000000;
//           font-family: "Roboto";
//           font-style: normal;
//           font-weight: 500;
//           font-size: 14px;
//           margin: 0;
//         }

//         .text-number-persent-up {
//           display: flex;
//           align-items: center;
//           color: #16a249;
//           font-family: "Roboto";
//           font-style: normal;
//           font-weight: 400;
//           font-size: 12px;
//           margin: 0 0 0 15px;
//         }
//         .text-number-persent-down {
//           display: flex;
//           align-items: center;
//           color: #dc2828;
//           font-family: "Roboto";
//           font-style: normal;
//           font-weight: 400;
//           font-size: 12px;
//           margin: 0 0 0 15px;
//         }

//         .text-same {
//           color: #787878;
//           font-family: "Roboto";
//           font-style: normal;
//           font-weight: 400;
//           font-size: 12px;
//           margin: 0 0 0 5px;
//         }
//       }
//     }
//     .div-chart-gross-income-area {
//       width: 100%;
//       height: auto;
//       background-color: white;
//       filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
//       border-radius: 8px;
//       padding: 10px;
//       margin-top: 20px;
//       .title-gross {
//         text-decoration: underline dotted;
//         color: #000000;
//         font-family: "Roboto";
//         font-style: normal;
//         font-weight: 500;
//         font-size: 16px;
//         text-decoration-line: underline;
//         text-decoration-color: #d6d6d6;
//         margin: 0;
//       }
//       .div-list-chart {
//         display: flex;
//         flex-direction: column;
//         margin-top: 10px;

//         .div-item-chart {
//           display: flex;
//           flex-direction: row;
//           padding: 10px 5px;
//           border-bottom: 1px solid #cccccc;
//           justify-content: space-between;

//           .div-name-area {
//             display: flex;
//             flex-direction: column;
//             .name-area {
//               color: #212121;
//               font-family: "Roboto";
//               font-style: normal;
//               font-weight: 500;
//               font-size: 14px;
//             }
//           }
//           .div-number-area {
//             display: flex;
//             flex-direction: column;
//             align-items: end;
//             .money-area {
//               color: #212121;
//               font-family: "Roboto";
//               font-style: normal;
//               font-weight: 500;
//               font-size: 14px;
//               margin: 0;
//             }

//             .text-number-persent-up {
//               display: flex;
//               align-items: center;
//               color: #16a249;
//               font-family: "Roboto";
//               font-style: normal;
//               font-weight: 400;
//               font-size: 12px;
//               margin: 0 0 15px;
//             }
//             .text-number-persent-down {
//               display: flex;
//               align-items: center;
//               color: #dc2828;
//               font-family: "Roboto";
//               font-style: normal;
//               font-weight: 400;
//               font-size: 12px;
//               margin: 0 0 0 15px;
//             }
//           }
//         }
//       }
//     }
//   }
// }



@import '../../../../index.scss';

.block-content {
    background-color: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    border-radius: 8px;
    padding: 10px;
    margin: 0px 20px 10px 0px;
    width: 50%;
    .total-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;
      width: 60%;
      height: 30px;
      border: 1px solid #d6d6d6;
      border-radius: 4px;
      margin: 5px 0;
      padding: 0px 10px;
      .text-value {
        p {
          font-weight: 500;
        }
      }
      .text-percent {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0px 0px 0px 15px;
        color: #16a249;
        span {
          color: #787878;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
        }
      }
      
    }
    .header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      .text-header {
        p {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
        }

      }
    }
}

.div-same {
  border: 1px solid #d6d6d6;
  padding: 5px;
  border-radius: 6px;
  width: auto;
  padding: 5px 10px;
  margin: 0 0 0 5px;

}
.tool-tip {
  border: 1px solid ;
  border-radius: 6px;
  padding: 5px 10px;
  background-color: white;
}


.div-item-chart {
  display: flex;
  flex-direction: row;
  padding: 10px 5px;
  border-bottom: 1px solid #cccccc;
  justify-content: space-between;

  .div-name-area {
    display: flex;
    flex-direction: column;

    .name-area {
      color: #212121;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .div-number-area {
    display: flex;
    flex-direction: column;
    align-items: end;

    .money-area {
      color: #212121;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      margin: 0;
    }

    .text-number-persent-up {
      display: flex;
      align-items: center;
      color: #16a249;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      margin: 0 0 15px;
    }

    .text-number-persent-down {
      display: flex;
      align-items: center;
      color: #dc2828;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      margin: 0 0 0 15px;
    }
  }
}

.item-service {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0px;
  .div-name-service {
    display: flex;
    flex-direction: row;
    align-items: center;
  
    .image-service {
      width: 50px;
      height: 50px;
      border-radius: 8px;
      object-fit: contain;
      margin-right: 10px;
    }
  }
  
  .div-info-service {
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    .money-area {
      color: #212121;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      margin: 0;
      text-align: end;
    }
  
    .text-number-persent-up {
      display: flex;
      align-items: center;
      color: #16a249;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      margin: 0 0 0 15px;
    }
  
    .text-number-persent-down {
      display: flex;
      align-items: center;
      color: #dc2828;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      margin: 0 0 0 15px;
    }
  }
}

@media screen and (max-width: 900px) {
  .block-content {
    width: 100%;
  }
}