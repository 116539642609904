@import "../../../../../@theme/style.scss";


.report-order-daily-revenue {
    display: flex;
    flex-direction: column;
    gap: $gapL;
    padding-bottom: 12px;

    .report-order-daily-revenue__header {
        display: flex;
        flex-direction: column;
        gap: $gapL;

        .report-order-daily-revenue__header--title {
            font-size: $textFontSize3XL;
            font-weight: $fontWeightXL;
            color: $colorBlack;
        }

        .report-order-daily-revenue__header--total-statistic {
            display: flex;
            gap: $gapL;
            // flex-wrap: wrap;
            align-items: center;
            // background-color: red;

            .report-order-daily-revenue__header--total-statistic-child {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 25%;
                background-color: white;
                padding: 16px;
                border-radius: $borderRadiusL;
                position: relative;
                // border-bottom: 4px solid black;

                .line {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 6px;
                    background-color: $colorBlack;
                    opacity: .8;
                    border-bottom-left-radius: $borderRadiusFull;
                    border-bottom-right-radius: $borderRadiusFull;
                }

                &.red {

                    .line {
                        background-color: $colorRed;
                    }

                    .report-order-daily-revenue__header--total-statistic-child-icon {
                        background-color: $colorExtraLightRed;

                        span {
                            color: $colorRed;
                        }
                    }

                    .report-order-daily-revenue__header--total-statistic-child-value {

                        .report-order-daily-revenue__header--total-statistic-child-value-numer {
                            color: $colorRed;
                        }
                    }
                }

                &.blue {
                    .line {
                        background-color: $colorBlue;
                    }

                    .report-order-daily-revenue__header--total-statistic-child-icon {
                        background-color: $colorExtraLightBlue;

                        span {
                            color: $colorBlue;
                        }
                    }

                    .report-order-daily-revenue__header--total-statistic-child-value {

                        .report-order-daily-revenue__header--total-statistic-child-value-numer {
                            color: $colorBlue;
                        }
                    }
                }

                &.green {
                    .line {
                        background-color: $colorGreen;
                    }

                    .report-order-daily-revenue__header--total-statistic-child-icon {
                        background-color: $colorExtraLightGreen;

                        span {
                            color: $colorGreen;
                        }
                    }

                    .report-order-daily-revenue__header--total-statistic-child-value {

                        .report-order-daily-revenue__header--total-statistic-child-value-numer {
                            color: $colorGreen;
                        }
                    }
                }

                &.yellow {
                    .line {
                        background-color: $colorYellow;
                    }

                    .report-order-daily-revenue__header--total-statistic-child-icon {
                        background-color: $colorExtraLightYellow;

                        span {
                            color: $colorYellow;
                        }
                    }

                    .report-order-daily-revenue__header--total-statistic-child-value {

                        .report-order-daily-revenue__header--total-statistic-child-value-numer {
                            color: $colorYellow;
                        }
                    }
                }

                .report-order-daily-revenue__header--total-statistic-child-icon {
                    border-radius: $borderRadiusFull;
                    padding: 12px;

                    span {
                        font-size: 1.5em;
                        font-weight: $fontWeightXL;
                        color: $colorBlack;
                    }
                }

                .report-order-daily-revenue__header--total-statistic-child-value {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: $gapS;

                    .report-order-daily-revenue__header--total-statistic-child-value-title {
                        font-size: 1em;
                        font-weight: $fontWeightXL;
                        color: $colorBlack;
                    }

                    .report-order-daily-revenue__header--total-statistic-child-value-numer {
                        font-size: 1em;
                        font-weight: $fontWeightL;
                        color: $colorBlack;
                    }
                }
            }

        }
    }

    .report-order-daily-revenue__chart {
        display: flex;
        flex-direction: column;
        gap: $gapL;
        background-color: white;
        border-radius: $borderRadiusL;
        padding: 16px;

        .report-order-daily-revenue__chart--header {
            display: flex;
            justify-content: space-between;

            span {
                font-size: $textFontSizeM;
                font-weight: $fontWeightXL;
                color: $colorBlack;
            }

            .report-order-daily-revenue__chart--header-total {
                display: flex;
                align-items: center;
                gap: $gapXS;
                padding: 4px;
                border: 1px solid $colorLightGray;
                border-radius: $borderRadiusL;

                .report-order-daily-revenue__chart--header-total-compare {
                    // display: flex;
                    // align-items: center;
                    // gap: $gapXS;

                    &.up {
                        span {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightL;
                            color: $colorGreen;
                        }
                    }

                    &.down {
                        span {
                            font-size: $textFontSizeM;
                            font-weight: $fontWeightL;
                            color: $colorRed;
                        }
                    }
                }

            }
        }
    }

    .report-order-daily-revenue__previous-period {
        display: flex;
        align-items: center;
        gap: $gapM;

        .report-order-daily-revenue__previous-period-child {
            display: flex;
            align-items: center;
            gap: $gapXS;
            padding: 6px;
            border-radius: $borderRadiusL;
            background-color: transparent;
            border: 1px solid $colorLightGray;

            span {
                font-size: $textFontSizeM;
                font-weight: $fontWeightL;
            }

            .line {
                width: 1px;
                height: 20px;
                border-radius: $borderRadiusL;
                background-color: $colorMoreLightGray;
            }

            .report-order-daily-revenue__previous-period-child-value {
                padding: 6px;
                background-color: $colorMoreLightGray;
                border-radius: $borderRadiusL;
                font-weight: $fontWeightXL;
            }

        }
    }

}