.div-date-create-punish {
  display: flex;
  flex-direction: column;

  .text-time {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: rgb(0, 0, 0);
    margin: 0;
  }
}

.img-toggle {
  width: 30px;
  height: 30px;
}
