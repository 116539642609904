.div-tab-invite-collaborator {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  // width: 100%;

  .div-item-tab {
    padding: 5px;
    .text-tab {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #989898;
      text-decoration: none;
      margin: 0;
    }
  }

  .div-item-tab-select {
    padding: 5px;
    border-bottom: 1px solid rgb(34, 0, 255);

    .text-tab {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      text-decoration: none;
      margin: 0;
    }
  }
}

.text-id-view {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  margin: 0;
}

.div-name-invite-collaborator {
  display: flex;
  flex-direction: column;
  .text-name {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    margin: 0;
  }
}

.div-date-create-invite {
  display: flex;
  flex-direction: column;
  .text-create {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    margin: 0;
  }
}

.text-step {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #3bb722;
}
