.btn-detail {
  width: 70px;
  height: 25px;
  background-color: #0075ff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  .text-btn-detail {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #ffffff;
  }
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  color: #000000;
  text-decoration: none;
  margin: 0;
}

.div-create-invite-detail {
  display: flex;
  flex-direction: column;
  .text-invite {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }
}

.text-name-invite {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  text-decoration: none;
  margin: 0;
}
