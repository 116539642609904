.btn-modal-new {
  height: 38px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-left: 20px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
}

.img-thumbnail {
  width: 100px !important;
  height: 100px !important;
}

.input-group {
  margin-bottom: 10px;
}

.select-code-phone {
  width: 300px !important;
  height: 40px !important;
}

.choose-thumbnail {
  border: none;
}
.title-new {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #19202c;
}
