.transfer-collaborator_container {
  width: 100%;
  // height: 100vh;
  .transfer-collaborator_header {
  }
  p {
    font-size: 12px;
    // color: var(--text-color);
  }
  // border: solid 1px;
}
.transfer-collaborator_total {
  @include flexbox(space-evenly);
  margin-top: 1rem;
}
.transfer-collaborator_action {
  width: 240px;
  margin: 1rem 0;
  @include flexbox(space-between);
  button {
    width: 100px;
  }
}
.transfer-collaborator_search {
  margin-top: 0.5rem;
  @include flexbox(center, center);
  .input-search {
    // margin-right: 1rem;
    margin-left: 1rem;
  }
}
.transfer-collaborator_transaction {
  margin: 0.5rem 0px;
  @include flexbox(space-between, center);
  width: 200px;
}
