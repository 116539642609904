.div-list {
  display: flex;

  flex-direction: column;

  .div-item-list {
    display: flex;
    width: 100%;
    min-height: 50px;
    flex-direction: column;
    border-bottom: 1px solid #000;
    margin-top: 10px;

    .div-column-1 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .text-title {
        font-family: "Roboto";
        font-weight: 500;
        font-size: 18px;
        color: black;
        text-decoration: none;
        margin: 0;
      }

      .text-money-plus {
        font-family: "Roboto";
        font-weight: 500;
        font-size: 18px;
        color: #00cf3a;
        text-decoration: none;
        margin: 0;
      }

      .text-money-deduction {
        font-family: "Roboto";
        font-weight: 500;
        font-size: 18px;
        color: #ff0000;
        text-decoration: none;
        margin: 0;
      }
    }

    .text-date-t {
      font-family: "Roboto";
      font-weight: 400;
      font-size: 14px;
      color: black;
      text-decoration: none;
      margin: 0;
    }

    .text-title-surplus {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 12px;
      color: black;
      text-decoration: none;
      margin: 0;
    }

    .text-surplus {
      font-family: "Roboto";
      font-weight: 400;
      font-size: 12px;
      color: black;
      text-decoration: none;
      margin-left: 5px;
      margin: 0;
    }

    .icon-plus {
      color: #00cf3a;
    }
    .icon-deduction {
      color: #ff0000;
    }
  }
}

.div-pagination-customer-history {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}
