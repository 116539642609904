.container-app-customer {
  background-color: white;
  border-radius: 5px;
  width: 99%;
  min-height: 60%;
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;

  .label-kh {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #25213b;
  }

  .form-input {
    margin-top: 50px;
  }

  .form-input-distance {
    margin-top: 10px;
  }

  .input-version {
    width: 300px;
  }

  .input-image {
    border: none;
    width: 300px;
  }

  .img-background-header {
    width: 400px;
    height: 150px;
    border-radius: 10px;
  }

  .btn-update {
    width: 200px;
    height: 40px;
    border-radius: 5px;
    background-color: #48cae4;
    color: white;

    margin-top: 50px;
  }
}

@media screen and (max-width: 490px) {
  .container-app-customer {
    background-color: white;
    border-radius: 5px;
    width: 99%;
    min-height: 60%;
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;

    .label-kh {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      color: #25213b;
    }

    .form-input {
      margin-top: 50px;
    }

    .form-input-distance {
      margin-top: 10px;
    }

    .input-version {
      width: 300px;
    }

    .input-image {
      border: none;
      width: 300px;
    }

    .img-background-header {
      width: 100%;
      height: 150px;
      border-radius: 10px;
    }

    .btn-update {
      width: 200px;
      height: 40px;
      border-radius: 5px;
      background-color: #48cae4;
      color: white;

      margin-top: 50px;
    }
  }
}
