.div-select-time-and-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;

  .div-date {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
  }
}

.div-chart-day-in-week {
  width: 100%;
  height: 400px;
  background-color: white;
  border-radius: 8px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  margin-top: 40px;
  padding-top: 10px;
}

.div-tooltip-content-week {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  padding: 5px;
  border: 1px solid pink;
}
