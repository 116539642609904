.image-avatar-business {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}

.upload-image-avatar-business {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  border: 1px solid #ccc;
}
