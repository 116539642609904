.label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: black;
  margin-bottom: 20px;
}

.input {
  margin-top: 10px;
}

.list-item {
  background-color: rgb(255, 255, 255);
  padding: 5px;
  position: absolute;
  width: 400px;
  border: 1px solid rgb(175, 175, 175);
  border-radius: 5px;
  z-index: 100;
}

.btn-push-noti {
  display: flex;
  width: 10% !important;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-top: 30px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
  align-items: center;
  justify-content: center;
}

.list-item-kh {
  background-color: rgb(255, 255, 255);
  padding: 5px;
  position: absolute;
  width: 350px;
  border: 1px solid rgb(175, 175, 175);
  border-radius: 5px;
  z-index: 99999;

  .div-item {
    border-bottom: 1px solid #d6d6d6;

    .text-name {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      margin: 0;
      color: #000000;
      text-decoration: none;
    }
  }
}

.div-list-customer {
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  margin-top: 5px;
  padding: 5px;

  .div-item-customer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #d6d6d6;

    .text-name-list {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      text-decoration: none;
      margin: 5px 0 0 0;
    }
  }
}

.btn-edit-notification {
  display: flex;
  width: 20% !important;
  height: 30px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-top: 30px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
  align-items: center;
  justify-content: center;
}
