.div-contai-wallet {
  width: 100%;
  height: 100px;
  padding: 5px;
  background-color: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
  border-radius: 8px;

  .text-beginnig {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }

  .div-wallet-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .div-item-wallet {
      display: flex;
      justify-content: space-between;
      border-right: 1px solid #000000;
      width: 33%;
      padding: 0px 10px;

      .text-name-wallet {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #727272;
        text-decoration: none;
        margin: 0;
      }

      .text-money-remainder {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #fb942e;
        text-decoration: none;
        margin: 0;
      }

      .text-money-gift {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #fb422e;
        text-decoration: none;
        margin: 0;
      }
    }
    .div-item-wallet-last {
      display: flex;
      justify-content: space-between;
      width: 33%;
      padding: 0px 10px;

      .text-name-wallet {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #727272;
        text-decoration: none;
        margin: 0;
      }

      .text-money-paypoint {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #d931ff;
        margin: 0;
        text-decoration: none;
      }
    }
  }
}

.div-tab-finance {
  display: flex;
  flex-direction: row;
  margin-top: 30px;

  .div-item-tab {
    padding: 5px;
    margin-right: 5px;

    .text-tab {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: rgb(199, 199, 199);
      text-decoration: none;
      margin: 0;
    }
  }
  .div-item-tab-select {
    padding: 5px;
    margin-right: 5px;
    border-bottom: 1px solid blue;

    .text-tab {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      text-decoration: none;
      margin: 0;
    }
  }
}

.div-current-remainder {
  display: flex;
  flex-direction: row;
  align-items: center;
  .text-money {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    margin-right: 0 5px 0 0;
  }

  .icon-deduction {
    color: #fb422e;
    margin-left: 5px;
  }

  .icon-plus {
    color: #00cf3a;
    margin-left: 5px;
  }

  .icon-minus {
    color: #727272;
    margin-left: 5px;
  }
}

.div-name-ctv {
  display: flex;
  flex-direction: column;

  .text-ctv {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }
}

.div-date-create {
  display: flex;
  flex-direction: column;

  .text-date {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }
}

.text-content {
  display: block;
  width: 250px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  text-decoration: none;
  margin: 0;
}
.div-date-finance {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
}

.detail-finance-table {
  display: flex;
  flex-direction: column;

  .div-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .title-detail {
      display: block;
      width: 60px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #202223;
      text-decoration: none;
      margin: 0;
    }

    .text-detail-finance {
      display: block;
      width: 300px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #202223;
      text-decoration: none;
      margin-left: 5px;
      margin: 0;
    }

    .icon-deduction {
      color: #fb422e;
    }

    .icon-plus {
      color: #00cf3a;
    }

    .icon-minus {
      color: #727272;
    }
  }
}

@media (max-width: 1200px) {
  .text-content {
    display: block;
    width: 200px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }
}

@media (max-width: 900px) {
  .text-content {
    display: block;
    width: 150px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }

  .div-contai-wallet {
    width: 100%;
    height: auto;
    padding: 5px;
    background-color: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    border-radius: 8px;

    .text-beginnig {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #000000;
      text-decoration: none;
      margin: 0;
    }

    .div-wallet-row {
      display: flex;
      flex-direction: column;

      .div-item-wallet {
        display: flex;
        width: 100%;
        margin: 0;
        border: none;
        border-bottom: 0.5px solid #8e8e8e;
        padding: 5px;

        .text-name-wallet {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #727272;
          text-decoration: none;
          margin: 0;
        }

        .text-money-remainder {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #fb942e;
          text-decoration: none;
          margin: 0;
        }

        .text-money-gift {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #fb422e;
          text-decoration: none;
          margin: 0;
        }
      }
      .div-item-wallet-last {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 5px;

        .text-name-wallet {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #727272;
          text-decoration: none;
          margin: 0;
        }

        .text-money-paypoint {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #d931ff;
          text-decoration: none;
          margin: 0;
        }
      }
    }
  }
}
