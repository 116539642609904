.div-head-used-prmotion {
  display: flex;
  flex-direction: row;

  .div-item-total {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    padding: 10px;
    margin-right: 20px;
    width: 15%;

    .text-done-total {
      color: #16a249;
      font-weight: 500;
      font-size: 14px;
      font-family: "Roboto";
      text-decoration: none;
      margin: 0;
    }

    .text-pending-total {
      color: #ece200;
      font-weight: 500;
      font-size: 14px;
      font-family: "Roboto";
      text-decoration: none;
      margin: 0;
    }
    .text-confirm-total {
      color: #2962ff;
      font-weight: 500;
      font-size: 14px;
      font-family: "Roboto";
      text-decoration: none;
      margin: 0;
    }

    .text-cancel-total {
      color: rgb(228, 53, 50);
      font-weight: 500;
      font-size: 14px;
      font-family: "Roboto";
      margin: 0;
      text-decoration: none;
    }

    .text-total {
      color: #000;
      font-weight: 400;
      font-size: 14px;
      font-family: "Roboto";
      text-decoration: none;
      margin: 0;
    }
  }
}

.title-column {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  font-family: "Roboto";
  text-decoration: none;
  margin: 0;
}

.div-date-used-prmotion {
  display: flex;
  flex-direction: column;
  .text-date {
    color: #000;
    font-weight: 400;
    font-size: 12px;
    font-family: "Roboto";
    text-decoration: none;
    margin: 0;
  }
}

.text-name-promotion {
  color: #000;
  font-weight: 400;
  font-size: 12px;
  font-family: "Roboto";
  text-decoration: none;
  margin: 0;
}

.text-status-done {
  color: #16a249;
  font-weight: 400;
  font-size: 12px;
  font-family: "Roboto";
  text-decoration: none;
  margin: 0;
}

.text-status-pending {
  color: #ece200;
  font-weight: 400;
  font-size: 12px;
  font-family: "Roboto";
  text-decoration: none;
  margin: 0;
}

.text-status-confirm {
  color: #2962ff;
  font-weight: 400;
  font-size: 12px;
  font-family: "Roboto";
  text-decoration: none;
  margin: 0;
}

.text-status-cancel {
  color: rgb(228, 53, 50);
  font-weight: 400;
  font-size: 12px;
  font-family: "Roboto";
  text-decoration: none;
  margin: 0;
}

@media (max-width: 1200px) {
  .div-head-used-prmotion {
    display: flex;
    flex-direction: row;

    .div-item-total {
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 8px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
      padding: 10px;
      margin-right: 20px;
      width: 20%;

      .text-done-total {
        color: rgb(27, 193, 16);
        font-weight: 500;
        font-size: 14px;
        font-family: "Roboto";
        text-decoration: none;
      }

      .text-cancel-total {
        color: rgb(228, 53, 50);
        font-weight: 500;
        font-size: 14px;
        font-family: "Roboto";
        text-decoration: none;
      }

      .text-total {
        color: #000;
        font-weight: 400;
        font-size: 14px;
        font-family: "Roboto";
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 900px) {
  .div-head-used-prmotion {
    display: flex;
    flex-direction: row;

    .div-item-total {
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 8px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
      padding: 10px;
      margin-right: 20px;
      width: 50%;

      .text-done-total {
        color: rgb(27, 193, 16);
        font-weight: 500;
        font-size: 14px;
        font-family: "Roboto";
        text-decoration: none;
      }

      .text-cancel-total {
        color: rgb(228, 53, 50);
        font-weight: 500;
        font-size: 14px;
        font-family: "Roboto";
        text-decoration: none;
      }

      .text-total {
        color: #000;
        font-weight: 400;
        font-size: 14px;
        font-family: "Roboto";
        text-decoration: none;
      }
    }
  }
}
