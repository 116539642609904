.img-customer-service {
  width: 80px;
  height: 80px;
  border-radius: 5px;
}

.img-service {
  width: 80px;
  height: 80px;
  border-radius: 5px;
}

.select-code-phone {
  width: 400px;
  height: 40px;
  border-radius: 5px;
  margin-left: 20px;
  border-color: rgb(216, 216, 216);
}

.label-service {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #25213b;
  margin: 0;
}

.div-head-service {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.text-service {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin: 0;
}

.text-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin: 0;
}

.img-lock-group-service {
  width: 30px;
  height: 30px;
}
