.title-condition {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: black;
  text-decoration: none;
  margin: 0;
}

.div-input-name {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;

  .label-input {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: black;
    text-decoration: none;
  }

  .input-name-group-customer {
    width: 20%;
    .title-condition {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      color: black;
      text-decoration: none;
      margin: 0;
    }

    .div-input-name {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      margin-top: 10px;

      .label-input {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: black;
        text-decoration: none;
      }

      .input-name-group-customer {
        width: 20%;
      }
    }

    .condition-level {
      background-color: rgba(211, 211, 211, 0.545);
      border-radius: 8px;
      padding: 5px;
      box-shadow: 2px 3px 2px rgb(239, 239, 239);

      .div-tab-condition {
        display: flex;
        flex-direction: row;
        box-shadow: 2px 3px 2px rgb(216, 216, 216);
        width: 100px;
        border-radius: 30px;
        padding: 5px;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        margin-bottom: 10px;

        .div-tab {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          border-radius: 30px;
          padding: 2px;

          .text-tab {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: rgb(147, 147, 147);
            text-decoration: none;
            margin: 0;
          }
        }

        .div-tab-selected {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: aqua;
          width: 50px;
          border-radius: 30px;
          padding: 2px;

          .text-tab {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: black;
            text-decoration: none;
            margin: 0;
          }
        }
      }

      .div-body {
        display: flex;
        flex-direction: row;
        background-color: rgb(255, 255, 255);
        width: 100%;
        min-height: 100px;
        border-radius: 8px;

        .div-input {
          display: flex;
          flex-direction: column;

          .condition-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 20px;
            background-color: aqua;
            border-radius: 20px;

            .text-btn {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: black;
              text-decoration: none;
              margin: 0;
            }
          }

          .div-input-condition {
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            margin-bottom: 10px;
            margin-left: 30px;

            .div-select-kind {
              display: flex;
              flex-direction: column;
              margin-right: 10px;

              .label-kind {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: black;
                text-decoration: none;
                margin: 0;
              }

              .select-kind {
                width: 200px;
                margin-top: 5px;
              }

              .select-date {
                width: 200px;
                margin-top: 5px;
              }

              .input-value {
                width: 200px;
                margin-top: 5px;
              }
            }

            .input-condition {
              margin-right: 40px;
            }

            .input-value-condition {
              height: 30px;
            }
          }
        }

        .div-footer-body {
          display: flex;
          flex-direction: column;
          background-color: rgb(199, 255, 255);
          padding: 10px;
          border-radius: 8px 0px 0px 8px;
          justify-content: space-between;
          align-items: center;

          .btn-add-group {
            border-radius: 20px;
            box-shadow: 1px 2px 2px rgb(216, 216, 216);
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: black;
            text-decoration: none;
            width: 150px;
          }
        }
      }
    }

    .btn-create-group-customer {
      background-color: aqua;
      border: none;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: black;
      text-decoration: none;
      margin-top: 20px;
      box-shadow: 2px 3px 2px rgb(239, 239, 239);
    }

    @media screen and (max-width: 490px) {
      .div-input-name {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        margin-top: 10px;

        .label-input {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: black;
          text-decoration: none;
        }

        .input-name-group-customer {
          width: 100% !important;
        }
      }

      .div-body {
        display: flex;
        flex-direction: row;
        background-color: rgb(255, 255, 255);
        width: 100%;
        min-height: 100px;
        border-radius: 8px;

        .div-input {
          display: flex;
          flex-direction: column;

          .condition-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 20px;
            background-color: aqua;
            border-radius: 20px;

            .text-btn {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: black;
              text-decoration: none;
              margin: 0;
            }
          }

          .div-input-condition {
            display: flex;
            flex-direction: column !important;
            margin-top: 10px;
            margin-bottom: 10px;
            margin-left: 0 !important;
            .select-kind {
              width: 200px;
              margin-top: 5px;
            }
            .div-select-kind {
              display: flex;
              flex-direction: column;
              margin-right: 10px;

              .label-kind {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: black;
                text-decoration: none;
                margin: 0;
              }

              .select-kind {
                width: 200px;
                margin-top: 5px;
              }

              .select-date {
                width: 200px;
                margin-top: 5px;
              }

              .input-value {
                width: 200px;
                margin-top: 5px;
              }
            }

            .input-condition {
              margin-right: 40px;
            }

            .input-value-condition {
              height: 30px;
            }
          }
        }

        .div-footer-body {
          display: flex;
          flex-direction: column;
          background-color: rgb(199, 255, 255);
          padding: 10px;
          border-radius: 8px 0px 0px 8px;
          justify-content: space-between;
          align-items: center;

          .btn-add-group {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px !important;
            box-shadow: 1px 2px 2px rgb(216, 216, 216);
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 8px;
            color: black;
            text-decoration: none;
            width: 50px !important;
          }
        }
      }
    }
  }
}

.condition-level {
  background-color: rgba(211, 211, 211, 0.545);
  border-radius: 8px;
  padding: 5px;
  box-shadow: 2px 3px 2px rgb(239, 239, 239);

  .div-tab-condition {
    display: flex;
    flex-direction: row;
    box-shadow: 2px 3px 2px rgb(216, 216, 216);
    width: 100px;
    border-radius: 30px;
    padding: 5px;
    align-items: center;
    justify-content: space-between;
    background-color: white;

    .div-tab {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      border-radius: 30px;
      padding: 2px;

      .text-tab {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: rgb(147, 147, 147);
        text-decoration: none;
        margin: 0;
      }
    }

    .div-tab-selected {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: aqua;
      width: 50px;
      border-radius: 30px;
      padding: 2px;

      .text-tab {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: black;
        text-decoration: none;
        margin: 0;
      }
    }
  }

  .div-body {
    display: flex;
    flex-direction: row;
    background-color: rgb(255, 255, 255);
    width: 100%;
    min-height: 100px;
    border-radius: 8px;

    .div-input {
      display: flex;
      flex-direction: column;

      .condition-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 20px;
        background-color: aqua;
        border-radius: 20px;

        .text-btn {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: black;
          text-decoration: none;
          margin: 0;
        }
      }

      .div-input-condition {
        display: flex;
        flex-direction: row;
        margin-top: 10px;

        .select-kind {
          width: 180px;
          margin-top: 5px;
        }

        .div-select-kind {
          display: flex;
          flex-direction: column;
          margin: 10px 10px;

          .label-kind {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: black;
            text-decoration: none;
            margin: 0;
          }

          .select-kind {
            width: 180px;
            margin-top: 5px;
          }

          .select-date {
            width: 180px;
            margin-top: 5px;
          }

          .input-value {
            width: 180px;
            margin-top: 5px;
          }
        }

        .input-condition {
          margin-right: 40px;
        }

        .input-value-condition {
          height: 30px;
        }
      }
    }

    .div-footer-body {
      display: flex;
      flex-direction: column;
      background-color: rgb(199, 255, 255);
      padding: 10px;
      border-radius: 8px 0px 0px 8px;
      justify-content: space-between;
      align-items: center;

      .btn-add-group {
        border-radius: 20px;
        box-shadow: 1px 2px 2px rgb(216, 216, 216);
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: black;
        text-decoration: none;
        width: 150px;
      }
    }
  }
}

.btn-create-group-customer {
  background-color: aqua;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: black;
  text-decoration: none;
  margin-top: 20px;
  box-shadow: 2px 3px 2px rgb(239, 239, 239);
}
