.div-listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-top: 10px;
  border-bottom: 1px solid rgb(208, 208, 208);

  .div-list {
    display: flex;
    flex-direction: column;

    .text-title {
      font-family: Helvetica;
      font-weight: 500;
      font-size: 20px;
      color: black;
      text-decoration: none;
      width: 800px;
    }
    .text-date {
      font-family: Helvetica;
      font-weight: 200;
      font-size: 14px;
      color: rgb(78, 78, 78);
      text-decoration: none;
    }
  }
}

.div-pagination {
  margin-top: 10px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.div-head-customer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .div-monney {
    display: flex;
    flex-direction: column;

    .text-title-monney {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000 !important;
      margin: 0;
    }
    .text-monney {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #000000 !important;
      margin: 0;
    }

    .total-revenue {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .text {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #000000;
        text-decoration: none;
        margin: 0;
      }
      .text-money-revenue {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        text-decoration: none;
        margin: 0 0 0 10px;
      }

      .icon-up {
        color: #00cf3a;
      }
    }
    .total-expenditure {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .text {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #000000;
        text-decoration: none;
        margin: 0;
      }

      .text-money-expenditure {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        text-decoration: none;
        margin: 0 0 0 10px;
      }
      .icon-down {
        color: #ff0000;
      }
    }
  }
}

.div-time {
  display: flex;
  flex-direction: column;

  .text-time {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #202223 !important;
    text-decoration: none;
    margin: 0;
  }
}

.div-withdraw-topup {
  display: flex;
  flex-direction: row;

  .text-topup {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #00cf3a;
    text-decoration: none;
    margin: 0;
  }

  .text-withdraw {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #dc2828;
    text-decoration: none;
    margin: 0;
  }
}

.text-money-detail-ctv {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #202223 !important;
  text-decoration: none;
  margin: 0;
}

.text-pending-topup {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ecd503 !important;
  text-decoration: none;
  margin: 0;
}

.text-transfered {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #19129a !important;
  text-decoration: none;
  margin: 0;
}
.text-done {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #00cf3a;
  text-decoration: none;
  margin: 0;
}

.text-cancel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #dc2828;
  text-decoration: none;
  margin: 0;
}
