.btn-add-customer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
}

.btn-add-customer-hide {
  display: none;
  width: 10%;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-left: 20px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
}

.btn-add-cus {
  width: 15%px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-left: 20px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
}

.textInput {
  width: 200px;
  height: 40px;
  border-radius: 5px;
  margin-left: 20px;
  border: 1px solid #dddd;
}

.btn-close {
  color: red;
  border: none;
  background-color: transparent;
  height: 50px;
  width: 40px;
}

@media screen and (max-width: 1440px) {
  .btn-add-customer-show {
    width: 100px;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #48cae4;
    color: white;
    margin-left: 20px;
    box-shadow: 2px 3px 2px rgb(216, 216, 216);
  }
}

@media screen and (max-width: 490px) {
  .btn-add-customer {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #48cae4;
    color: white;
    margin-left: 20px;
    box-shadow: 2px 3px 2px rgb(216, 216, 216);
  }
}
