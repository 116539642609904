@import "../../../../../@theme/style.scss";

.div-profile-customer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  .div-infomation-name {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 40%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
    border-radius: 8px;
    border: 1px solid #e9e9e9;

    .div-image-customer {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      padding: 10px;
      border-radius: 8px;

      .img-avatar {
        width: 100px;
        height: 100px;
        border-radius: 10px;
      }

      .div-name {
        display: flex;
        flex-direction: column;
        margin-left: 20px;

        .text-name {
          font-family: "Roboto";
          font-weight: 500;
          font-size: 24px;
          color: black;
          text-decoration: none;
          margin: 0;
        }

        .text-invite {
          font-family: "Roboto";
          font-weight: 400;
          font-size: 12px !important;
          color: black;
          text-decoration: none;
          margin: 0;
        }
      }
    }

    .div-rank-pay-member {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // box-shadow: 2px 3px 2px rgb(216, 216, 216);
      padding: 10px;
      border-radius: 8px;
      margin-top: 10px;
      border: 1px solid rgb(232, 232, 232);
      margin-top: 40px;
      background-color: #f1f1f1;

      .div-member {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .div-rank-customer {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 35px;

          .text-money {
            font-family: "Roboto";
            font-weight: 400;
            font-size: 14px !important;
            color: black;
            text-decoration: none;
            margin: 0;
          }

          .icon-rank {
            width: 30px;
            height: 30px;
          }
        }

        .text-title {
          font-family: "Roboto";
          font-weight: 500;
          font-size: 16px;
          color: black;
          text-decoration: none;
          margin: 0;
        }
      }
    }
  }

  .div-infomation {
    display: flex;
    flex-direction: column;
    width: 50%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
    border-radius: 8px;
    border: 1px solid #e9e9e9;
    padding: 15px;

    .btn-update-customer {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: #48cae4;
      color: white;
      width: 100px;
      margin-top: 20px;
      float: right;
    }

    .div-detail-infomation {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .div-left {
        display: flex;
        flex-direction: column;
        width: 60%;

        .div-name {
          display: flex;
          flex-direction: column;
        }

        .div-select {
          display: flex;
          flex-direction: column;
          margin-top: 20px;

          .input-select-gender {
            width: 80%;
            height: 40px;
            border-radius: 4px;
            border: 1px solid #ededed;
            padding-left: 10px;
            margin-top: 10px;
          }
        }
      }

      .div-right {
        display: flex;
        flex-direction: column;
        width: 60%;

        .div-staff {
          display: flex;
          flex-direction: column;

          .label-staff {
            font-family: "Roboto";
            font-weight: 400;
            font-size: 12px;
            color: black;
            text-decoration: none;
            margin: 0;
          }
        }
      }
    }
  }
}

.div-container-invite-code {
  display: flex;
  flex-direction: column;
  background-color: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  margin-top: 20px;
  border-radius: 8px;
  padding: 10px;

  .title-invite {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 16px;
    color: black;
    text-decoration: none;
    margin: 0;
  }

  .text-no-invite {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 14px;
    color: rgb(150, 150, 150);
    text-decoration: none;
    margin: 20px 20px;
    text-align: center;
  }

  .div-list-invite {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;

    .div-item-invite {
      display: flex;
      flex-direction: row;
      background-color: white;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
      width: 40%;
      margin-bottom: 20px;
      padding: 10px;
      border-radius: 10px;
      align-items: center;
      margin-right: 45px;
      margin-left: 45px;

      .img-customer-invite {
        width: 80px;
        height: 80px;
        border-radius: 90px;
      }

      .div-invite-progress {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        width: 100%;

        .div-row-info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .text-date-create {
            font-family: "Roboto";
            font-weight: 400;
            font-size: 12px;
            color: black;
            text-decoration: none;
            margin: 0;
          }

          .div-name-invite {
            display: flex;
            flex-direction: row;

            .title-name {
              font-family: "Roboto";
              font-weight: 500;
              font-size: 12px;
              color: black;
              text-decoration: none;
              width: 40px;
              margin: 0;
            }

            .title-colon {
              font-family: "Roboto";
              font-weight: 500;
              font-size: 12px;
              color: black;
              text-decoration: none;
              margin-right: 5px;
              margin: 0;
            }

            .text-name {
              font-family: "Roboto";
              font-weight: 400;
              font-size: 12px;
              color: black;
              text-decoration: none;
              margin: 0;
            }
          }
        }

        .text-step {
          font-family: "Roboto";
          font-weight: 400;
          font-size: 12px;
          color: black;
          text-decoration: none;
          margin: 0;
        }

        .text-step-done {
          font-family: "Roboto";
          font-weight: 400;
          font-size: 12px;
          color: #87d068;
          text-decoration: none;
          margin: 0;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .div-profile-customer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .div-infomation-name {
      display: flex;
      flex-direction: column;
      padding: 10px;
      width: 100%;

      .div-image-customer {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 10px;
        border-radius: 8px;

        .img-avatar {
          width: 100px;
          height: 100px;
          border-radius: 10px;
        }

        .div-name {
          display: flex;
          flex-direction: column;
          margin-left: 20px;

          .text-name {
            font-family: "Roboto";
            font-weight: 500;
            font-size: 24px;
            color: black;
            text-decoration: none;
            margin: 0;
          }

          .text-invite {
            font-family: "Roboto";
            font-weight: 400;
            font-size: 12px !important;
            color: black;
            text-decoration: none;
            margin: 0;
          }
        }
      }

      .div-rank-pay-member {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        box-shadow: 2px 3px 2px rgb(216, 216, 216);
        padding: 10px;
        border-radius: 8px;
        margin-top: 10px;

        .div-member {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .text-money {
            font-family: "Roboto";
            font-weight: 400;
            font-size: 12px !important;
            color: black;
            text-decoration: none;
            margin: 0;
          }

          .text-title {
            font-family: "Roboto";
            font-weight: 500;
            font-size: 14px;
            color: black;
            text-decoration: none;
            margin: 0;
          }
        }
      }
    }

    .div-infomation {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 10px;

      .btn-update-customer {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: #48cae4;
        color: white;
        width: 100px;
        margin-top: 20px;
        float: right;
      }

      .div-detail-infomation {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .div-left {
          display: flex;
          flex-direction: column;
          width: 60%;

          .input {
            width: 80%;
          }

          .div-select {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .input-select-gender {
              width: 80%;
              height: 40px;
              border-radius: 4px;
              border: 1px solid #ededed;
              padding-left: 10px;
              margin-top: 10px;
            }
          }
        }

        .div-right {
          display: flex;
          flex-direction: column;
          width: 60%;

          .div-staff {
            display: flex;
            flex-direction: column;

            .label-staff {
              font-family: "Roboto";
              font-weight: 400;
              font-size: 12px;
              color: black;
              text-decoration: none;
              margin: 0;
            }
          }
        }
      }
    }
  }

  .div-container-invite-code {
    background-color: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
    margin-top: 20px;
    border-radius: 8px;
    padding: 10px;

    .title-invite {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 16px;
      color: black;
      text-decoration: none;
      margin: 0;
    }

    .div-list-invite {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 30px;

      .div-item-invite {
        display: flex;
        flex-direction: row;
        background-color: white;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
        width: 100%;
        margin-bottom: 20px;
        padding: 10px;
        border-radius: 10px;
        align-items: center;
        margin-left: 40px;
        margin-right: 20px;

        .img-customer-invite {
          width: 80px;
          height: 80px;
          border-radius: 90px;
        }

        .div-invite-progress {
          display: flex;
          flex-direction: column;
          margin-left: 20px;
          width: 100%;

          .div-row-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .text-date-create {
              font-family: "Roboto";
              font-weight: 400;
              font-size: 12px;
              color: black;
              text-decoration: none;
              margin: 0;
            }

            .div-name-invite {
              display: flex;
              flex-direction: row;

              .title-name {
                font-family: "Roboto";
                font-weight: 500;
                font-size: 12px;
                color: black;
                text-decoration: none;
                width: 40px;
                margin: 0;
              }

              .title-colon {
                font-family: "Roboto";
                font-weight: 500;
                font-size: 12px;
                color: black;
                text-decoration: none;
                margin-right: 5px;
                margin: 0;
              }

              .text-name {
                font-family: "Roboto";
                font-weight: 400;
                font-size: 12px;
                color: black;
                text-decoration: none;
                margin: 0;
              }
            }
          }

          .text-step {
            font-family: "Roboto";
            font-weight: 400;
            font-size: 12px;
            color: black;
            text-decoration: none;
            margin: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 490px) {
  .div-profile-customer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .div-infomation-name {
      display: flex;
      flex-direction: column;
      padding: 10px;
      width: 100%;

      .div-image-customer {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 10px;
        border-radius: 8px;

        .img-avatar {
          width: 80px;
          height: 80px;
          border-radius: 10px;
        }

        .div-name {
          display: flex;
          flex-direction: column;
          margin-left: 20px;

          .text-name {
            font-family: "Roboto";
            font-weight: 500;
            font-size: 16px;
            color: black;
            text-decoration: none;
            margin: 0;
          }

          .text-invite {
            font-family: "Roboto";
            font-weight: 400;
            font-size: 12px !important;
            color: black;
            text-decoration: none;
            margin: 0;
          }
        }
      }

      .div-rank-pay-member {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        box-shadow: 2px 3px 2px rgb(216, 216, 216);
        padding: 10px;
        border-radius: 8px;
        margin-top: 10px;

        .div-member {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .div-rank-customer {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 35px;

            .text-money {
              font-family: "Roboto";
              font-weight: 400;
              font-size: 10px !important;
              color: black;
              text-decoration: none;
              margin: 0;
            }

            .icon-rank {
              width: 15px;
              height: 15px;
            }
          }

          .text-title {
            font-family: "Roboto";
            font-weight: 500;
            font-size: 10px;
            color: black;
            text-decoration: none;
            margin: 0;
          }
        }
      }
    }

    .div-infomation {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 10px;

      .btn-update-customer {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: #48cae4;
        color: white;
        width: 100px;
        margin-top: 20px;
        float: right;
      }

      .div-detail-infomation {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .div-left {
          display: flex;
          flex-direction: column;
          width: 60%;

          .input {
            width: 80%;
          }

          .div-select {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .input-select-gender {
              width: 80%;
              height: 40px;
              border-radius: 4px;
              border: 1px solid #ededed;
              padding-left: 10px;
              margin-top: 10px;
            }
          }
        }

        .div-right {
          display: flex;
          flex-direction: column;
          width: 60%;

          .div-staff {
            display: flex;
            flex-direction: column;

            .label-staff {
              font-family: "Roboto";
              font-weight: 400;
              font-size: 12px;
              color: black;
              text-decoration: none;
              margin: 0;
            }
          }
        }
      }
    }
  }

  .div-container-invite-code {
    background-color: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
    margin-top: 20px;
    border-radius: 8px;
    padding: 5px;

    .title-invite {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 16px;
      color: black;
      text-decoration: none;
      margin: 0;
    }

    .div-list-invite {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .div-item-invite {
        display: flex;
        flex-direction: row;
        background-color: white;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
        border-radius: 10px;
        align-items: center;
        width: 100%;

        .img-customer-invite {
          width: 30px;
          height: 30px;
          border-radius: 90px;
        }

        .div-invite-progress {
          display: flex;
          flex-direction: column;
          margin-left: 20px;
          width: 100%;

          .div-row-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .text-date-create {
              font-family: "Roboto";
              font-weight: 400;
              font-size: 10px;
              color: black;
              text-decoration: none;
              margin: 0;
            }

            .div-name {
              display: flex;
              flex-direction: row;

              .title-name {
                font-family: "Roboto";
                font-weight: 500;
                font-size: 10px;
                color: black;
                text-decoration: none;
                width: 30px;
                margin: 0;
              }

              .title-colon {
                font-family: "Roboto";
                font-weight: 500;
                font-size: 10px;
                color: black;
                text-decoration: none;
                margin-right: 5px;
                margin: 0;
              }

              .text-name {
                font-family: "Roboto";
                font-weight: 400;
                font-size: 10px;
                color: black;
                text-decoration: none;
                margin: 0;
              }
            }
          }

          .text-step {
            font-family: "Roboto";
            font-weight: 400;
            font-size: 12px;
            color: black;
            text-decoration: none;
          }
        }
      }
    }
  }
}


.detail-profile__list-inviter {
  display: flex;
  flex-wrap: wrap;
  gap: $gapL;

  .detail-profile__list-inviter--child {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 230px;
    background-color: $colorWhite;
    border-radius: $borderRadiusL;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    gap: $gapS;
    overflow-y: hidden;
    padding: 12px;

    .detail-profile__list-inviter--child-avatar {
      position: relative;
      border-radius: $borderRadiusFull;
      background-color: $colorExtraLightBlue;
      padding: 8px;
      transition: all 0.5s ease-out;

      &.rank-member {
        background-color: $colorExtraLightOrange;
      }

      &.rank-silver {
        background-color: $colorMoreLightSlate;
      }

      &.rank-gold {
        background-color: $colorExtraLightYellow;
      }

      &.rank-platinum {
        background-color: $colorExtraLightCyan;
      }

      .detail-profile__list-inviter--child-avatar-image {
        width: 100px;
        height: 100px;
        border-radius: $borderRadiusFull;
        background-color: transparent;
        transition: all 0.5s ease-out;
      }

      .detail-profile__list-inviter--child-avatar-rank {
        position: absolute;
        text-align: center;
        min-width: 50px;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 2px;
        background-color: $colorBlue;
        border-radius: $borderRadiusFull;
        color: $colorWhite;

        &.rank-member {
          background-color: $colorOrange;
        }

        &.rank-silver {
          background-color: $colorSlate;
        }

        &.rank-gold {
          background-color: $colorAmber;
        }

        &.rank-platinum {
          background-color: $colorCyan;
        }

        span {
          font-size: $textFontSizeS;
          font-weight: $fontWeightL;
          color: $colorWhite;
        }
      }
    }

    .detail-profile__list-inviter--child-name,
    .detail-profile__list-inviter--child-email {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      span {
        font-size: $textFontSizeXL;
        font-weight: $fontWeightXL;
        color: $colorBlack;
      }
    }

    .detail-profile__list-inviter--child-email span {
      font-size: $textFontSizeM;
      font-weight: $fontWeightL;
      color: $colorLightGray;
    }

    .detail-profile__list-inviter--child-statistic {
      display: flex;
      align-items: center;
      gap: $gapXS;

      .detail-profile__list-inviter--child-statistic-value {
        display: flex;
        align-items: center;
        gap: $gapXS;

        span {
          font-size: $textFontSizeM;
          font-weight: $fontWeightL;
          color: $colorBlack;
        }
      }

      .detail-profile__list-inviter--child-statistic-line {
        height: 12px;
        width: 1px;
        background-color: $colorBlack;
      }
    }

    .detail-profile__list-inviter--child-extra {
      display: flex;
      flex-direction: row;
      align-items: center;
      opacity: 0;
      transform: translateY(25px);
      width: fit-content;
      height: 0px;
      transition: all 0.4s ease-out;
      // background-color: black;
      gap: $gapS;
    }

    &:hover .detail-profile__list-inviter--child-extra {
      opacity: 1;
      transform: translateY(0);
      height: 20px;
    }

    &:hover .detail-profile__list-inviter--child-avatar {
      padding: 9px;
    }

    &:hover .detail-profile__list-inviter--child-avatar-image {
      width: 95px;
      height: 95px;
    }

    .detail-profile__list-inviter--child-extra-element {
      display: flex;
      align-items: center;
      gap: $gapXS;

      span {
        font-size: $textFontSizeM;
        font-weight: $fontWeightL;
        color: $colorBlack;
      }
    }
  }
}