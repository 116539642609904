@import "../../../@theme/style.scss";

.card__ranking {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $gapL;

    .card__ranking--image {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100px;
        background-color: $colorExtraLightAmber;
        border-radius: $borderRadiusXL;

        .card__ranking--image-content {
            display: flex;
            flex-direction: column;

            .card__ranking--image-content-subtext {
                font-size: $textFontSizeXL;
                font-weight: $fontWeightXL;
                color: $colorGray;
                opacity: $opacityS;
                padding: 2px 20px;
            }

            .card__ranking--image-content-total {
                font-size: $textFontSizeXL;
                font-weight: $fontWeightXL;
                padding: 2px 20px;
                color: $colorBlack;
            }
        }

        .card__ranking--image-image {
            height: 100%;
        }
    }

    .card__ranking--content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        gap: $gapL;

        .card__ranking--content-child {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // border-bottom: 1.5px solid $colorExtraLightGray;

            &.not-last {
                padding-bottom: 12px;
                border-bottom: 1.5px solid $colorExtraLightGray;
            }

            .card__ranking--content-child-container {
                display: flex;
                align-items: center;
                gap: $gapS;

                span {
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightL;
                    color: $colorLightGray;
                }
            }

            .card__ranking--content-child-money {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: $gapXS;
                padding: 4px 12px;
                border-radius: $borderRadiusL;
                width: 35%;

                span {
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightL;
                    color: $colorBlack;
                }
            }

            .card__ranking--content-child-money-month {
                background-color: $colorExtraLightGreen;
            }

            .card__ranking--content-child-money-year {
                background-color: $colorExtraLightYellow;
            }

            .card__ranking--content-child-money-total {
                background-color: $colorExtraLightBlue;
            }
        }
    }
}