.text-id {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  color: black;
}

// .div-service {
//   display: flex;
//   flex-direction: column;

//   .text-service {
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 14px;
//     color: black;
//     margin: 0;
//   }
// }

.div-create {
  display: flex;
  flex-direction: column;

  .text-create {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #202223;
    margin: 00;
  }
}

.div-worktime {
  display: flex;
  flex-direction: column;

  .text-worktime {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    color: black;
    margin: 0;
  }
}

.text-address {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: black;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
}
.text-collaborator {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-decoration-line: underline;
  color: #023e8a;
  margin: 0;
}

.text-pending-order {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #ece200 !important;
  margin: 0;
}

.text-confirm {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #023e8a;
  margin: 0;
}

.text-doing {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #023e8a;
  margin: 0;
}

.text-cancel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #ff0000;
}
.text-done {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #00cf3a;
  text-decoration: none;
  margin: 0;
}

.filter-input {
  width: 200px;
  height: 30px;
  border-radius: 5px;
}

.div-name {
  display: flex;
  flex-direction: column;

  .text-name {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #202223;
    text-decoration: none;
    margin: 0;
  }
}
