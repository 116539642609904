.div-upload {
  display: flex;
  flex-direction: column;
  .input-image {
    border: none;
  }

  .btn-image {
    display: flex;
    border: 1px solid #d6d6d6;
    border-radius: 8px;
    width: 100px;
    height: 30px;
    align-items: center;
    justify-content: center;
  }

  .img-thumbnail {
    width: 100px;
    height: 100px;
    border-radius: 8px;
  }

  .div-head-title-upload {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon-question {
      color: #29fff1;
      margin-bottom: 0px;
      margin-left: 10px;
    }

    .title-upload {
      font-family: "Roboto";
      font-style: italic;
      font-weight: 400;
      font-size: 12px;
      color: #000000;
      padding-bottom: 4px;
    }
  }

  .div-image {
    display: flex;
    flex-direction: row;

    .icon_delete_image {
      margin-left: 2px;
    }
  }
}

.avatar-uploader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  border: 1px solid #9b9b9b;
}
