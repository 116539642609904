.loading-pagination {
  position: fixed;
  z-index: 1051;
  height: auto;
  width: auto;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(254, 254, 254, 0.638);
  display: flex;
  align-items: center;
  justify-content: center;
}
