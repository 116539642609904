.btn-modal-edit-reason {
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
  border: none !important;
  border-radius: 5px;
  height: 35px;
  background-color: #48cae4;
  margin-top: 30px;
  color: #fff;
  width: 25%;
}

.img-thumbnail {
  width: 100px !important;
  height: 100px !important;
}

.input-group {
  margin-bottom: 10px;
}

.select-code-phone {
  width: 300px !important;
  height: 40px !important;
}
