.col-check {
  display: flex;
}

.div-check-document {
  display: flex;
  flex-direction: row;

  .check-document {
    width: 30%;
  }
  .form-input {
    width: 70%;

    .label-input {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      text-decoration: none;
      margin: 0;
    }
  }

  .div-col-download {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    float: right;
    .btn-download {
      background-color: transparent;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.col-img {
  display: flex;
  .input-file {
    border: none;
    width: 35%;
  }

  .div-img-thumbnail {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .img-thumbnail {
      width: 150px !important;
      height: 70px !important;
    }
  }
}

.btn-update {
  float: right;
  background-color: bisque;
  border: none;
  color: black;
}

.btn-update:hover {
  float: right;
  background-color: rgb(209, 235, 250);
  border: none;
  color: black;
}

.div-infomation {
  display: flex;
  flex-direction: column;

  .label-input {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }

  .div-thumbnail-infomation {
    display: flex;
    flex-direction: row;
    width: 70%;
    flex-wrap: wrap;

    .div-item-thumbnail-infomation {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .img-thumbnail-infomation {
        width: 100px;
        height: 50px;
        margin: 0px 6px;
        border: 1px solid black;
        border-radius: 5px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .div-check-document {
    display: flex;
    flex-direction: column;
    .check-document {
      width: 100%;
    }
    .form-input {
      width: 100%;
      margin-top: 20px;

      .label-input {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        text-decoration: none;
        margin: 0;
      }
    }

    .div-col-download {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 100%;
      float: right;
      .btn-download {
        background-color: transparent;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
