@import "../../../../../../../../src/@theme/style.scss";

// *** Default screen ***
.collaborator-overview {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;
  gap: $gapL;
  padding-bottom: 16px;

  // ~~~ Left container ~~~
  .collaborator-overview__left {
    display: flex;
    flex-direction: column;
    gap: $gapL;
    width: 100%;
  }

  // ~~~ Middle container ~~~
  .collaborator-overview__middle {
    display: flex;
    flex-direction: column;
    gap: $gapL;
    width: 100%;
  }

  // ~~~ Right container ~~~
  .collaborator-overview__right {
    display: flex;
    flex-direction: column;
    gap: $gapL;
    width: 100%;
  }
}

// *** Medium screen ***
@media screen and (max-width: 1024px) {
  .collaborator-overview {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    width: 100%;
    gap: $gapL;
    padding-bottom: 16px;

    // ~~~ Left container ~~~
    .collaborator-overview__left {
      display: flex;
      flex-direction: column;
      gap: $gapL;
      width: 100%;
    }

    // ~~~ Middle container ~~~
    .collaborator-overview__middle {
      display: flex;
      flex-direction: column;
      gap: $gapL;
      width: 100%;
    }

    // ~~~ Right container ~~~
    .collaborator-overview__right {
      display: flex;
      flex-direction: column;
      gap: $gapL;
      width: 100%;
    }
  }
}