@import "../../../../@theme/style.scss";

.label-noti {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: black;
  margin-bottom: 20px;
}

.input {
  margin-top: 10px;
}

.list-item {
  background-color: rgb(255, 255, 255);
  padding: 5px;
  position: absolute;
  width: 400px;
  border: 1px solid rgb(175, 175, 175);
  border-radius: 5px;
  z-index: 100;
}

.btn-add-push-noti {
  display: flex;
  width: 10%;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-top: 30px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
  align-items: center;
  justify-content: center;
}

.btn-push-noti-hide {
  display: none;
}

.list-item-kh {
  background-color: rgb(255, 255, 255);
  padding: 5px;
  position: absolute;
  width: 350px;
  border: 1px solid rgb(175, 175, 175);
  border-radius: 5px;
  z-index: 99999;

  .div-item {
    border-bottom: 1px solid #d6d6d6;

    .text-name {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      margin: 0;
      color: #000000;
      text-decoration: none;
    }
  }
}

.img-background-noti {
  width: 100%;
  height: 300px;
  border-radius: 6px;
}

.upload-img-background-noti {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.div-list-customer {
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  margin-top: 5px;
  padding: 5px;

  .div-item-customer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #d6d6d6;

    .text-name-list {
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      text-decoration: none;
      margin: 5px 0 0 0;
    }
  }
}

.btn-create-notification {
  display: flex;
  width: 15% !important;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-top: 30px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 490px) {
  .btn-add-push-noti {
    display: flex;
    width: 100% !important;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: #48cae4;
    color: white;
    margin-top: 20px;
    box-shadow: 2px 3px 2px rgb(216, 216, 216);
    align-items: center;
    justify-content: center;
  }
}



/* ~~~ Son CSS ~~~ */
.add-push-notification {
  display: flex;
  flex-direction: column;
  gap: $gapM;

  .add-push-notification__field {
    display: flex;
    width: 100%;
    gap: $gapL;

    .add-push-notification__field--child {
      width: 100%;

      &.drap-field {
        display: flex;
        gap: $gapXS;
        background-color: $colorExtraLightGray;
        padding: 8px;
        border-radius: $borderRadiusL;

        span {
          padding: 4px 8px;
          background-color: $colorWhite;
          border-radius: $borderRadiusS;
          border: 1px solid $colorGray;
          cursor: pointer;

          &:hover {
            background-color: $colorLightGray;
            color: $colorWhite;
          }
        }
      }
    }

    .add-push-notification__field--info {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: $gapL;

      .add-push-notification__field--info-customer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 9px 5px 20px;
        // border: 2px solid $colorMoreLightStone;
        background-color: $colorExtraLightGray;
        border-radius: $borderRadiusL;

        .add-push-notification__field--info-customer-text {
          font-weight: $fontWeightL;
          font-size: $textFontSizeM;
          color: $colorBlack;
        }

        .add-push-notification__field--info-customer-icon {
          padding: 6px;
          background-color: $colorExtraLightGray;
          color: $colorRed;
          cursor: pointer;
          transition: 0.3s all ease-in;
          border-radius: $borderRadiusFull;

          &:hover {
            color: $colorWhite;
            background-color: $colorRed;
          }
        }
      }

    }

    &.hidden {
      display: none;
    }

  }

  .add-push-notification__check-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    background-color: $colorExtraLightGray;
    border-radius: $borderRadiusS;
    transition: 0.3s ease-in;
    cursor: pointer;

    .add-push-notification__check-box--text {
      font-weight: $fontWeightL;
      font-size: $textFontSizeM;
    }

    &.unchecked {
      color: white;
      background-color: $colorViolet;
    }
  }

  .add-push-notification__radio {
    display: flex;
    align-items: center;
    // justify-content: center;
    gap: $gapM;

    .add-push-notification__radio--child {
      width: 50%;
      display: flex;
      align-items: center;
      gap: $gapS;

      .add-push-notification__radio--child-box {
        accent-color: $colorViolet;
      }

      .add-push-notification__radio--child-text {
        font-size: $textFontSizeM;
        font-weight: $fontWeightL;
        transition: 0.3s all ease-out;

        &.checked {
          color: $colorBlack;
        }

        &.un-checked {
          color: $colorGray;
          opacity: $opacityM;
        }
      }
    }
  }

  .add-push-notification__text {
    font-size: $textFontSizeM;
    font-weight: $fontWeightL;
    font-style: italic;
  }

  .add-push-notification__upload-field {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150px;
    border-radius: $borderRadiusL;
    border: 2px dashed #ccc;
    background-color: $colorExtraLightGray;
    cursor: pointer;
    transition: 0.3s all ease;

    .add-push-notification__upload-field--child {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: $gapXS;

      .add-push-notification__upload-field--child-sub-text {
        font-size: $textFontSizeM;
        font-weight: $fontWeightL;
        font-style: italic;
      }
    }

    &:hover {
      border-color: $colorBlue;
    }
  }
}

.add-push-notification__footer {
  display: flex;
  gap: $gapS;
}