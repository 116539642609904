@import "../../@theme/style.scss";

.header-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  width: 100%;
  height: 100%;

  .header-navigation__left {
    display: flex;
    align-items: center;

    .header-navigation__left--icon {
      color: $colorGray;
      cursor: pointer;
      padding: 0.6rem;
      border-radius: 100%;
      transition-duration: 300ms;

      &:hover {
        color: $colorViolet;
        background-color: $colorExtraLightGray;
      }
    }

    .header-navigation__left--version {
      font-size: $textFontSizeM;
      font-weight: $fontWeightXL;
      color: $colorBlack;
    }
  }

  .header-navigation__right {
    display: flex;
    margin-right: 25px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: auto;
    height: auto;
    gap: $gapXS;

    .header-navigation__right--icon {
      position: relative;
      color: #475569;
      cursor: pointer;
      padding: 0.6rem;
      border-radius: 100%;
      transition-duration: 300ms;

      &:hover {
        color: #8b5cf6;
        background-color: #f4f4f6;
      }
    }

    .header-navigation__right--user-info {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: $gapXS;

      .header-navigation__right--user-info-name {
        font-size: $textFontSizeM;
        font-weight: $fontWeightL;
        color: $colorBlack;
      }
    }
  }
}

.header-navigation-affiliate {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 24px;

  .header-navigation-affiliate__logo {
    height: fit-content;
    width: 88px;
    padding-right: 0px;
  }

  .header-navigation-affiliate__button {
    display: flex;
    gap: $gapXS;
    // align-items: center;
    padding: 9px 12px;
    width: fit-content;
    height: fit-content;
    background-color: $colorWhite;
    border-radius: $borderRadiusFull;
    color: $colorBlack;
    font-size: $textFontSizeM;
    font-weight: $fontWeightXL;
    cursor: pointer;
    transition: all 0.3s ease;

    &.activated {
      background-color: $colorDarkPurple;
      color: $colorWhite;
    }

    &:hover {
      background-color: $colorPurple;
      color: $colorWhite;
    }
  }

  .header-navigation-affiliate__icon {
    color: $colorGray;
    cursor: pointer;
    padding: 0.6rem;
    border-radius: 100%;
    transition-duration: 300ms;
    display: none;

    &:hover {
      color: $colorViolet;
      background-color: $colorExtraLightGray;
    }
  }

  .header-navigation-affiliate__left {
    display: flex;
    margin-right: 25px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: auto;
    height: auto;
    gap: $gapL;
  }

  .header-navigation-affiliate__right {
    display: flex;
    align-items: center;
    gap: $gapXS;

    .header-navigation-affiliate__right--icon {
      font-size: 24px;
      color: $colorBlack;
    }

    .header-navigation-affiliate__right--info {
      display: flex;
      align-items: center;
      gap: $gapXS;

      .eader-navigation-affiliate__right--info-name {
        font-size: $textFontSizeM;
        font-weight: $fontWeightXL;
        color: $colorBlack;
        cursor: pointer;

      }
      .header-navigation-affiliate__right--info-icon-down {
        display: flex;
        align-items: center;
        font-size: 32px;
        color: $colorBlack;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .header-navigation-affiliate {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 4px;

    .header-navigation-affiliate__logo {
      display: none;
      height: fit-content;
      width: 88px;
      padding-right: 0px;
    }

    .header-navigation-affiliate__button {
      display: flex;
      gap: $gapXS;
      // align-items: center;
      padding: 6px 8px;
      width: fit-content;
      height: fit-content;
      background-color: $colorWhite;
      border-radius: $borderRadiusFull;
      color: $colorBlack;
      font-size: $textFontSizeM;
      font-weight: $fontWeightXL;
      cursor: pointer;
      transition: all 0.3s ease;

      &.activated {
        background-color: $colorDarkPurple;
        color: $colorWhite;
      }

      &:hover {
        background-color: $colorPurple;
        color: $colorWhite;
      }
    }

    .header-navigation-affiliate__icon {
      color: $colorGray;
      cursor: pointer;
      padding: 0.6rem;
      border-radius: 100%;
      transition-duration: 300ms;
      display: flex;

      &:hover {
        color: $colorViolet;
        background-color: $colorExtraLightGray;
      }
    }

    .header-navigation-affiliate__left {
      display: flex;
      margin-right: 25px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: auto;
      height: auto;
      gap: $gapL;

    }

    .header-navigation-affiliate__right {
      display: flex;
      align-items: center;
      gap: $gapXS;
  
      .header-navigation-affiliate__right--icon {
        font-size: 20px;
        color: $colorBlack;
      }
  
      .header-navigation-affiliate__right--info {
        display: flex;
        align-items: center;
        gap: $gapXS;
  
        .header-navigation-affiliate__right--info-name {
          font-size: $textFontSizeM;
          font-weight: $fontWeightXL;
          color: $colorBlack;
          cursor: pointer;
          display: none;
        }

        .header-navigation-affiliate__right--info-icon-down {
          display: flex;
          align-items: center;
          font-size: 20px;
          color: $colorBlack;
        }
      }
    }
  }
}