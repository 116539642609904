@import "../../../../../@theme/style.scss";

.report-order-revenue {
    display: flex;
    flex-direction: column;
    gap: $gapL;
    padding-bottom: 12px;

    .report-order-revenue__header {
        display: flex;
        flex-direction: column;
        gap: $gapL;

        .report-order-revenue__header--title {
            font-size: $textFontSize3XL;
            font-weight: $fontWeightXL;
            color: $colorBlack;
        }
    }
}