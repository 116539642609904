.title-add {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  text-decoration: none;
  margin: 0;
}

.div-check-date {
  display: flex;
  flex-direction: column;

  .label-input-date {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    margin: 0 0 5px 0;
  }
}

.div-service-total {
  display: flex;
  flex-direction: column;
}

.div-body-condition {
  width: 100%;
  min-height: 200px;
  background-color: #fff;
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.1));
  margin-top: 30px;
  border-radius: 4px;
  padding: 5px;
  border: 1px solid #ebebeb;

  .div-or-and-one {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eaeaea7c;
    width: 35px;
    height: 20px;
    border-radius: 2px;
    margin-top: 7px;

    .text-and-or {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #000000;
      text-decoration: none;
      margin: 0;
    }
  }

  .div-condition-level-one {
    min-height: 100px;
    background-color: #eaeaea7c;
    border-radius: 4px;
    margin-top: 10px;
    padding: 10px;

    .div-or-and-two {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #c9c8c87c;
      width: 35px;
      height: 20px;
      border-radius: 2px;
      margin-top: 7px;

      .text-and-or {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        text-decoration: none;
        margin: 0;
      }
    }

    .div-money {
      .label-money {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        text-decoration: none;
        margin: 0;
      }
    }

    .div-condition-level-two {
      min-height: 100px;
      background-color: #c9c8c87c;
      border-radius: 4px;
      margin-top: 10px;
      padding: 10px;

      .div-or-and-con {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f7f7f7;
        width: 35px;
        height: 20px;
        border-radius: 2px;
        margin-top: 7px;

        .text-and-or {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #000000;
          text-decoration: none;
          margin: 0;
        }
      }

      .div-codition {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;

        .input-select {
          width: 400;
        }

        .delete-codition {
          margin-left: 20px;
          margin-top: 10px;
        }
      }
      .div-add {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;

        .btn-add-condition {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #d6d6d6;
          width: 20px;
          height: 20px;
          margin-right: 10px;

          border-radius: 4px;
          .text-btn {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #000000;
            text-decoration: none;
            margin: 0;
          }
        }
      }
    }

    .btn-delete-codition-level-two {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #d6d6d6;
      width: 20px;
      height: 20px;
      margin-top: 10px;
      border-radius: 4px;
      padding: 5px;
    }

    .div-add {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;

      .btn-add-codition-level-two {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #d6d6d6;
        width: 50px;
        height: 25px;
        margin-right: 10px;

        border-radius: 4px;
        .text-add {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          color: #000000;
          text-decoration: none;
          margin: 0;
        }
      }
    }
  }
  .btn-delete-condition-level-one {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d6d6d6;
    width: 50px;
    height: 25px;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 5px;

    .text-btn {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: #000000;
      text-decoration: none;
      margin: 0;
    }
  }

  .div-add {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;

    .btn-add-condition-level-one {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #d6d6d6;
      width: 50px;
      height: 25px;
      border-radius: 4px;
      margin-right: 10px;
      .text-add {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #000000;
        text-decoration: none;
        margin: 0;
      }
    }
  }
}

.btn-create-condition-reward {
  width: 15%;
  height: 38px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-left: 20px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
  margin-top: 20px;
  float: right;
}
