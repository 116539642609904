.div-chart-pie-month {
  width: 30%;
}

.div-chart-pie-year {
  width: 30%;
}

.div-select-city {
  display: flex;
  flex-direction: row;

  .div-select-item {
    display: flex;
    flex-direction: column;

    .div-item-city {
      display: flex;
      flex-direction: column;
      background-color: rgb(255, 255, 255);
      padding: 5px;
      position: absolute;
      width: 180px;
      border: 1px solid rgb(175, 175, 175);
      border-radius: 5px;
      z-index: 9999999;
      margin-top: 40px;
      margin-left: 10px;

      .div-item-list {
        display: flex;
        align-items: center;
        height: 30px;
        width: 100%;
        border-bottom: 1px solid #d6d6d6;
      }

      .div-item-list-selected {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #48cae4;
        height: 30px;
      }
    }

    .div-select-city-input {
      display: flex;
      align-items: center;
      border-radius: 4px;
      height: 40px;
      border: 1px solid #d6d6d6;
      margin-left: 10px;
      width: 180px;
      padding-left: 10px;
    }
  }
}

.div-chart-pie-total {
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;

  .title-chart-area {
    color: black;
    font-weight: 500;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    margin-bottom: 10px;
  }

  .div-pie-chart {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .div-total-piechart {
      display: flex;
      flex-direction: column;
      width: 30%;

      border-right: 1px solid #d6d6d6;
      padding: 5px;
      justify-content: center;

      .item-total {
        display: flex;
        flex-direction: row;

        .title-total {
          color: black;
          font-weight: 500;
          font-size: 14px;
          font-family: "Roboto", sans-serif;
          text-decoration: none;
          width: 200px;
          margin: 0;
        }

        .text-colon {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #000000;
          margin: 0;
        }

        .number-total {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
          margin: 0 0 0 10px;
        }
      }
    }

    .div-pie {
      width: 80%;
      height: 100%;
    }

    .div-title-note {
      display: flex;
      flex-direction: column;
      width: 200px;

      .div-square-ser {
        display: flex;
        flex-direction: row;

        .square-two {
          width: 20px;
          height: 20px;
          background-color: #0088fe;
          border-radius: 5px;
          margin-right: 10px;
        }

        .square-three {
          width: 20px;
          height: 20px;
          background-color: #48cae4;
          border-radius: 5px;
          margin-right: 10px;
        }

        .square-four {
          width: 20px;
          height: 20px;
          background-color: #00cf3a;
          border-radius: 5px;
          margin-right: 10px;
        }
      }
    }
  }
}

.div-chart-bar-service {
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
}
