@import "../../../../../@theme/style.scss";

.refferend-list-affiliate__content--middle-content-history-receiving {
    display: flex;
    padding: 16px;

    .refferend-list-affiliate__content--middle-content-history-receiving-left {
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 1px;

        .refferend-list-affiliate__content--middle-content-history-receiving-left-time {
            font-size: $textFontSizeM;
            font-weight: $fontWeightXL;
        }

        .refferend-list-affiliate__content--middle-content-history-receiving-left-date {
            font-size: $textFontSizeM;
            font-weight: $fontWeightL;
            color: $colorGray;
            opacity: $opacityM;
            text-transform: capitalize;
        }
    }

    .refferend-list-affiliate__content--middle-content-history-receiving-middle {
        width: 10%;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: -32px;

        .refferend-list-affiliate__content--middle-content-history-receiving-middle-icon {
            padding: 8px 8px;
            width: fit-content;
            height: fit-content;
            border-radius: $borderRadiusFull;

            &.up {
                background-color: $colorExtraLightGreen;
            }

            &.down {
                background-color: $colorExtraLightRed;
            }

            &.setting {
                background-color: $colorExtraLightBlue;
            }
        }

        .refferend-list-affiliate__content--middle-content-history-receiving-middle-line {
            width: 2px;
            height: 100%;
            background-color: $colorExtraLightGray;
        }
    }

    .refferend-list-affiliate__content--middle-content-history-receiving-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 75%;

        .refferend-list-affiliate__content--middle-content-history-receiving-right-top {
            display: flex;
            justify-content: space-between;
            border: 2px solid $colorExtraLightGray;
            padding: 4px 12px;
            border-radius: $borderRadiusL;
            margin-left: 8px;
            width: 100%;
            min-height: 55px;

            .refferend-list-affiliate__content--middle-content-history-receiving-right-top-title {
                font-size: $textFontSizeM;
                font-weight: $fontWeightXL;
            }

            .refferend-list-affiliate__content--middle-content-history-receiving-right-top-money {
                display: flex;
                align-items: center;
                gap: $gapXS;

                .refferend-list-affiliate__content--middle-content-history-receiving-right-top-money-title {
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightL;
                    color: $colorGray;
                    opacity: $opacityM;
                }
            }

            .refferend-list-affiliate__content--middle-content-history-receiving-right-top-transiction {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: end;

                // padding-top: 4px;
                .refferend-list-affiliate__content--middle-content-history-receiving-right-top-transiction-number {
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightXL;
                    padding: 4px 8px;
                    width: fit-content;
                    border-radius: $borderRadiusL;
                    white-space: nowrap;

                    &.up {
                        color: $colorGreen;
                        background-color: $colorExtraLightGreen;
                    }

                    &.down {
                        color: $colorRed;
                        background-color: $colorExtraLightRed;
                    }
                }

                .refferend-list-affiliate__content--middle-content-history-receiving-right-top-transiction-detail {
                    display: flex;
                    align-items: center;
                    gap: $gapXS;

                    .refferend-list-affiliate__content--middle-content-history-receiving-right-top-transiction-detail-label {
                        display: flex;
                        align-items: center;
                        gap: $gapXS;
                        transition: all 0.3s ease;
                        text-decoration: none;
                        cursor: pointer;

                        &:hover {
                            color: $colorBlue
                        }
                    }

                }
            }
        }

        .refferend-list-affiliate__content--middle-content-history-receiving-right-bottom {
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            justify-content: space-between;
            // align-items: center;
            border: 2px solid $colorExtraLightGray;
            border-top: none;
            width: 95%;
            padding: 4px 12px;
            // border-radius-t: $borderRadiusL;
            border-bottom-left-radius: $borderRadiusL;
            border-bottom-right-radius: $borderRadiusL;
            margin-left: 8px;
            // min-height: 50px;
            height: 40px;
            transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;
            overflow: hidden;
            /* Ensures content is hidden when height is 0 */
            opacity: 1;
            /* Default opacity */

            .refferend-list-affiliate__content--middle-content-history-receiving-right-bottom-item {
                display: flex;
                align-items: center;

                .refferend-list-affiliate__content--middle-content-history-receiving-right-bottom-item-label {
                    margin-right: 4px;
                }

                .refferend-list-affiliate__content--middle-content-history-receiving-right-bottom-item-link {
                    color: $colorBlue;
                }
            }

            &.hidden {
                height: 0;
                opacity: 0;
                padding: 0 12px;
            }
        }
    }

    .refferend-list-affiliate__content--middle-content-history-receiving-not-found {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;

        img {
            height: 100px;
            width: 100%;
        }

        .refferend-list-affiliate__content--middle-content-history-receiving-not-found-label {
            font-size: $textFontSizeM;
            font-weight: $fontWeightXL;
            color: $colorBlack;
            font-style: italic;
        }
    }
}
.refferend-list-affiliate__content--middle-content-pagination {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    padding-right: 16px;
}
