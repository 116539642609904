@import "../../../@theme/style.scss";

.div-date-create {
  display: flex;
  flex-direction: column;

  .text-create {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    color: #202223;
    text-decoration: none;
  }
}

.text-title-table {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  color: #202223;
  text-decoration: none;
}

.div-tab {
  display: flex;
  flex-direction: row;

  .div-item-tab {
    margin-left: 20px;

    .text-tab {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #979797;
      text-decoration: none;
      margin: 0;
    }
  }

  .div-item-tab-selected {
    margin-left: 20px;
    border-bottom: 2px solid #41a2f3;

    .text-tab {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #000000;
      text-decoration: none;
      margin: 0;
    }
  }
}



.manage-push-notification {
  display: flex;
  flex-direction: column;
  gap: $gapL;
  padding-bottom: 16px;

  .manage-push-notification__label {
    .manage-push-notification__label--header {
      font-weight: $fontWeightXL;
      font-size: $textFontSize3XL;
      color: $colorBlack;
    }
  }

  .manage-push-notification__function {
    display: flex;
    justify-content: space-between;
    gap: $gapL;
    width: 100%;
    padding: 16px;
    background-color: $colorWhite;
    border-radius: $borderRadiusL;
  }
}