.div-name-point {
  display: flex;
  flex-direction: column;
  .text-name {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    margin: 0;
  }

  .text-phone-point {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    margin: 0;
  }
}
.btn-verify-point-customer {
  border: none;
  font-family: "Roboto";
  font-size: small;
  border-radius: 5px;
  background-color: aquamarine;
  width: auto;
}

.text-point {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  margin: 0;
}
.text-type-point {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  margin: 0;
}

.div-day-create-point {
  display: flex;
  flex-direction: column;

  .text-day {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    margin: 0;
  }
}

.text-pending-point {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ece200;
  margin: 0;
}

.text-done-point {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #00cf3a;
  margin: 0;
}

.text-cancel-point {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ff0000 !important;
  margin: 0;
}

.btn-delete-point {
  background-color: transparent;
  border: none;
  color: #ff0000;
}

.text-cancel-point {
  color: #000000;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin: 0;
}

.text-description-topup-point {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  text-decoration: none;
  display: block;
  width: 200px;
  margin: 0;
}

.div-head-point {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .input-search-topup-point {
    width: 60%;
  }
}
