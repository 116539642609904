@import "../../../src/@theme/style.scss";


.card-statistics {
    background-color: white;
    border-radius: $borderRadiusL;

    // Icon sử dụng trong card
    .card-statistics__icon {
        padding: 7px;
        width: 36px;
        height: 36px;
        border-radius: $borderRadiusL;

        &.circle {
            border-radius: $borderRadiusFull;
        }

        &.green {
            background-color: $colorExtraLightGreen;
        }

        &.red {
            background-color: $colorExtraLightRed;
        }

        &.blue {
            background-color: $colorExtraLightBlue;
        }

        &.yellow {
            background-color: $colorExtraLightYellow;
        }

        &.dark-green {
            background-color: $colorGreen;
        }

        &.dark-purple {
            background-color: $colorViolet;
        }

        &.small {
            padding: 3px;
            width: 24px;
            height: 24px;
        }
    }

    /* ~~~ Tiêu đề thẻ ~~~ */
    .card-statistics__header {
        display: flex;
        justify-content: space-between;
        padding: 12px 12px;
        border-bottom: 2px solid $colorExtraLightGray;

        .card-statistics__header--right {
            display: flex;
            align-items: center;
            gap: $gapXS;

        }

        .card-statistics__header--left {
            .card-statistics__header--left-time {
                display: flex;
                align-items: center;
                gap: $gapXS;
            }
        }

        span {
            font-weight: $fontWeightXL;
            font-size: $textFontSizeM;
            font-family: $fontFamily;
            color: $colorBlack;
        }
    }

    /* ~~~ Nội dung hiển thị của thẻ ~~~ */
    .card-statistics__content {
        padding: 16px;
    }

    // ~~~ Thẻ tiêu chí đánh giá ~~~
    .card-statistics__overview-criteria {
        display: flex;
        padding: 0px 12px 12px 12px;
    }


    // ~~~ Thẻ tiến hành hồ sơ ~~~ 
    .card-statistics__overview-document {
        display: flex;
        flex-direction: column;
        padding: 16px 20px;
        gap: $gapM;

        .card-statistics__overview-document--child {
            display: flex;
            align-items: center;
            gap: $gapM;

            .card-statistics__overview-document--child-left {
                display: flex;
                gap: $gapM;
                align-items: center;

                .card-statistics__overview-document--child-left-label {
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightL;
                    color: $colorBlack;

                    &.not-upload {
                        color: $colorGray;
                        opacity: $opacityS;
                    }
                }

            }
        }
    }

    // ~~~ Thẻ thống kê chi tiết các loại đánh giá và số sao của CTV
    .card-statistics__rating-overview {
        display: flex;
        flex-direction: column;
        padding: 16px 20px;
        gap: $gapM;

        .card-statistics__rating-overview--average-point {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .card-statistics__rating-overview--average-point-label {
                font-size: $textFontSizeM;
                font-weight: $fontWeightL;
                color: $colorBlack;
                padding: 4px 0px;
                // opacity: $opacityS;
            }

            .card-statistics__rating-overview--average-point-number {
                display: flex;
                align-items: center;
                gap: $gapS;

                .card-statistics__rating-overview--average-point-number-sub {
                    font-weight: $fontWeightL;
                    color: $colorGray;
                    opacity: $opacityL;
                    // padding: 2px 0px;
                }

                .card-statistics__rating-overview--average-point-number-total {
                    background-color: $colorExtraLightYellow;
                    padding: 4px 6px;
                    border-radius: $borderRadiusL;
                    font-size: $textFontSizeXL;
                    font-weight: $fontWeightXL;
                    color: $colorYellow;
                    // opacity: $opacityS;
                }

            }
        }
    }

    // ~~~ Thẻ thống kê số lần được đánh giá, khen thưởng, vi phạm ~~~
    .card-statistics__rating-statistic {
        display: flex;
        flex-direction: column;
        padding: 16px 20px;
        gap: $gapL;

        .card-statistics__rating-statistic--card {
            display: flex;
            gap: $gapL;

            .card-statistics__rating-statistic--card-child {
                display: flex;
                align-items: center;
                width: 33.3333%;
                position: relative;
                height: 80px;
                border-radius: $borderRadiusL;
                overflow: hidden;
                transition: all 0.3s ease;
                cursor: pointer;

                &.card-child-blue {
                    background-color: $colorExtraLightBlue;
                }

                &.card-child-red {
                    background-color: $colorExtraLightRed;
                }

                &.card-child-green {
                    background-color: $colorExtraLightGreen;
                }

                .card-statistics__rating-statistic--card-child-label {
                    display: flex;
                    flex-direction: column;
                    gap: $gapS;
                    padding-left: 16px;

                    .card-statistics__rating-statistic--card-child-label-heading {
                        .card-statistics__rating-statistic--card-child-label-heading-icon {}

                        span {
                            font-size: $textFontSizeXL;
                            font-weight: $fontWeightXL;
                        }

                    }

                    .card-statistics__rating-statistic--card-child-label-number {
                        font-size: $textFontSizeXL;
                        font-weight: $fontWeightXL;

                        &.label-number-blue {
                            color: $colorBlue;
                        }

                        &.label-number-red {
                            color: $colorRed;
                        }

                        &.label-number-green {
                            color: $colorGreen;
                        }
                    }
                }

                .card-statistics__rating-statistic--card-child-circle {
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    top: -16px;
                    right: -16px;
                    border-radius: $borderRadiusFull;
                    transition: transform 0.3s ease; // Thêm transition cho transform

                    &.circle-blue {
                        background-color: $colorLightBlue;
                    }

                    &.circle-red {
                        background-color: $colorLightRed;
                    }

                    &.circle-green {
                        background-color: $colorLightGreen;
                    }

                }

                .card-statistics__rating-statistic--card-child-circle-outside {
                    width: 70px;
                    height: 70px;
                    position: absolute;
                    top: -24px;
                    right: -24px;
                    border-radius: $borderRadiusFull;
                    transition: transform 0.3s ease;

                    &.circle-outside-blue {
                        background-color: $colorMoreLightBlue;
                    }

                    &.circle-outside-red {
                        background-color: $colorMoreLightRed;
                    }

                    &.circle-outside-green {
                        background-color: $colorMoreLightGreen;
                    }
                }

                &:hover {
                    .card-statistics__rating-statistic--card-child-circle {
                        transform: scale(1.3);
                    }

                    .card-statistics__rating-statistic--card-child-circle-outside {
                        transform: scale(1.3);
                    }
                }

                .card-statistics__rating-statistic--card-child-line-bottom {
                    width: 60px;
                    height: 2px;
                    position: absolute;
                    bottom: 4px;
                    left: 90px;
                    border-radius: $borderRadiusFull;

                    &.line-bottom-blue {
                        background-color: $colorLightBlue;
                    }

                    &.line-bottom-red {
                        background-color: $colorLightRed;
                    }

                    &.line-bottom-green {
                        background-color: $colorLightGreen;
                    }
                }
            }
        }

        .card-statistics__rating-statistic--number {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: $gapS;

            .card-statistics__rating-statistic--number-subtext {
                font-size: $textFontSizeM;
                font-weight: $fontWeightL;
                color: $colorGray;
                opacity: $opacityS;
                text-transform: uppercase;
            }

            .card-statistics__rating-statistic--number-average {
                font-size: $textFontSize3XL;
                font-weight: $fontWeightXL;
            }

            .card-statistics__rating-statistic--number-star {
                display: flex;
                gap: $gapS;
            }
        }

        .card-statistics__rating-statistic--progress-bar {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: $gapL;

            // padding-top: 8px;
            .card-statistics__rating-statistic--progress-bar-child {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;

                .card-statistics__rating-statistic--progress-bar-child-label {
                    text-align: center;
                    width: 25%;
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightL;
                    // color: $colorGray;
                    // opacity: $opacityS;
                }

                .card-statistics__rating-statistic--progress-bar-child-container {
                    width: 100%;
                    height: fit-content;
                    position: relative;
                    border-radius: $borderRadiusFull;
                    background-color: $colorExtraLightGray;
                    text-align: center;

                    span {
                        top: -2px;
                        position: absolute;
                        font-style: italic;
                        font-size: $fontWeightL;
                        color: $colorBlack;

                        &.more-than-overal {
                            color: white;
                        }
                    }

                    .card-statistics__rating-statistic--progress-bar-child-container-bar {
                        height: 12px;
                        border-radius: $borderRadiusFull;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $colorBlue;
                    }

                    .card-statistics__rating-statistic--progress-bar-child-container-bar-bonus {
                        background-color: $colorGreen;
                    }

                    .card-statistics__rating-statistic--progress-bar-child-container-bar-punish {
                        background-color: $colorRed;
                    }
                }

                // .card-statistics__rating-statistic--progress-bar-child-number {
                //     display: flex;
                //     justify-content: space-between;
                //     align-items: center;
                //     margin-bottom: 2px;

                //     .card-statistics__rating-statistic--progress-bar-child-number-label {
                //         font-size: $textFontSizeM;
                //         font-weight: $fontWeightL;
                //         color: $colorGray;
                //         opacity: $opacityS;
                //     }

                //     .card-statistics__rating-statistic--progress-bar-child-number-total {
                //         font-size: $textFontSizeM;
                //         font-weight: $fontWeightXL;
                //         color: $colorBlack;
                //     }
                // }

                // .card-statistics__rating-statistic--progress-bar-child-container {
                //     width: 100%;
                //     position: relative;
                //     border-radius: $borderRadiusFull;
                //     background-color: $colorExtraLightGray;
                //     text-align: center;

                //     span {
                //         position: absolute;
                //         font-style: italic;
                //         font-size: $fontWeightL;
                //         color: $colorBlack;

                //         &.more-than-overal {
                //             color: white;
                //         }
                //     }

                //     .card-statistics__rating-statistic--progress-bar-child-container-bar {
                //         height: 8px;
                //         border-radius: $borderRadiusFull;
                //         display: flex;
                //         align-items: center;
                //         justify-content: center;
                //         background-color: $colorBlue;
                //     }

                //     .card-statistics__rating-statistic--progress-bar-child-container-bar-bonus {
                //         background-color: $colorGreen;
                //     }

                //     .card-statistics__rating-statistic--progress-bar-child-container-bar-punish {
                //         background-color: $colorRed;
                //     }
                // }
            }
        }
    }

    // ~~~ Thẻ hoạt động đơn hàng
    .card-statistics__activity-history {
        display: flex;
        flex-direction: column;
        // align-items: center;
        padding: 16px 14px;

        .card-statistics__activity-history--activity {
            display: flex;
            padding-bottom: 32px;

            &.last-item {
                padding-bottom: 7.5px;
            }

            .card-statistics__activity-history--activity-left {
                width: 15%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 4px;

                .card-statistics__activity-history--activity-left-id {
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightXL;
                    color: $colorBlue;
                    cursor: pointer;
                }

                .card-statistics__activity-history--activity-left-name-phone {
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightXL;
                    color: $colorBlack;
                }

                .card-statistics__activity-history--activity-left-date {
                    font-size: $textFontSizeM;
                    font-weight: $fontWeightL;
                    color: $colorGray;
                    opacity: $opacityS;
                    text-transform: capitalize;
                }
            }

            .card-statistics__activity-history--activity-line {
                width: 10%;
                align-items: center;
                display: flex;
                flex-direction: column;
                margin-bottom: -32px;

                .card-statistics__activity-history--activity-line-icon {
                    padding: 8px 8px;
                    width: fit-content;
                    height: fit-content;
                    border-radius: $borderRadiusFull;

                    &.collaborator {
                        background-color: $colorExtraLightBlue;
                    }

                    &.admin {
                        background-color: $colorExtraLightViolet;
                    }

                    &.verify {
                        background-color: $colorExtraLightRed;
                    }

                    &.system {
                        background-color: $colorExtraLightYellow;
                    }

                    &.create {
                        background-color: $colorExtraLightGreen;
                    }
                }

                .card-statistics__activity-history--activity-line-icon-line {
                    width: 2px;
                    height: 100%;
                    background-color: $colorExtraLightGray;
                }
            }

            .card-statistics__activity-history--activity-right {
                display: flex;
                flex-direction: column;
                // gap: $gapXS;
                width: 75%;
                padding-left: 4px;

                .card-statistics__activity-history--activity-right-header {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    .card-statistics__activity-history--activity-right-header-service {
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightL;
                        color: $colorBlack;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                    }

                    .card-statistics__activity-history--activity-right-header-money {
                        display: flex;
                        align-items: center;
                        white-space: nowrap;
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightL;

                        &.plus {
                            color: $colorGreen;
                        }

                        &.minus {
                            color: $colorRed
                        }
                    }


                }

                .card-statistics__activity-history--activity-right-address {
                    height: 50px;

                    span {
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightL;
                        color: $colorGray;
                        opacity: $opacityS;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                    }
                }

                .card-statistics__activity-history--activity-right-status {
                    width: fit-content;
                    padding: 4px 8px;
                    border-radius: $borderRadiusL;

                    // margin-top: 4px;
                    &.collaborator {
                        background-color: $colorExtraLightBlue;
                        border: 1px solid $colorBlue;
                        color: $colorBlue;
                    }

                    &.admin {
                        background-color: $colorExtraLightGreen;
                        border: 1px solid $colorGreen;
                        color: $colorGreen;
                    }

                    &.verify {
                        background-color: $colorExtraLightRed;
                        border: 1px solid $colorRed;
                        color: $colorRed;
                    }

                    &.system {
                        background-color: $colorExtraLightYellow;
                        border: 1px solid $colorYellow;
                        color: $colorYellow;
                    }

                    &.create {
                        background-color: $colorExtraLightGreen;
                        border: 1px solid $colorGreen;
                        color: $colorGreen;
                    }


                    span {
                        font-size: $textFontSizeM;
                        font-weight: $fontWeightL;
                    }
                }

            }

        }
        .card-statistics__activity-history--pagination {
            display: flex;
            justify-content: space-between;
        }
    }

    // ~~~ Thẻ thể hiện giá trị tổng kết ~~~
    .card-statistics__total {
        display: flex;
        align-items: center;
        padding: 12px 12px;
        gap: $gapM;

        .card-statistics__total--icon {
            background-color: $colorExtraLightViolet;
            padding: 12px 12px;
            border-radius: $borderRadiusFull;
        }

        .card-statistics__total--body {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .card-statistics__total--body-left {
                display: flex;
                flex-direction: column;

                .card-statistics__total--body-left-header {
                    font-weight: $fontWeightXL;
                    font-size: $textFontSizeM;
                    color: $colorGray;
                    opacity: $opacityM;
                    text-transform: uppercase;
                }

                .card-statistics__total--body-left-value {
                    padding-top: 2px;
                    font-weight: $fontWeightXL;
                    font-size: $textFontSizeXL;
                }
            }

            .card-statistics__total--body-right {
                display: flex;
                flex-direction: column;
            }
        }
    }

    // ~~~ Thẻ biểu đồ cột ~~~
    .card-statistics__bar-chart {
        padding: 16px 20px;
    }


}

.card-statistics__icon--layout {
    display: flex;
    gap: $gapS;
    align-items: center;

    .card-statistics__icon--layout-content {
        display: flex;
        flex-direction: column;
        gap: $gapXS;

        .card-statistics__icon--layout-content-sub {
            font-size: $textFontSizeM;
            font-weight: $fontWeightL;
            color: $colorGray;
            opacity: $opacityS;
        }

        .card-statistics__icon--layout-content-main {
            font-weight: $fontWeightL;
            font-size: $textFontSizeM;
            color: $colorBlack;
        }
    }
}