.btn-edit {
  width: 50px;
  border: none;
  outline: none;
  background: transparent;
  color: orange;

  &:hover {
    background-color: transparent !important;
    color: orange !important;
  }
}

.btn-delete {
  width: 50px;
  border: none;
  outline: none;
  background: transparent;
  color: red;

  &:hover {
    background-color: transparent !important;
    color: red !important;
  }
}

.modal-user-container {
  .modal-user-body {
    gap: 22px;
    display: flex;
    flex-wrap: wrap;
    .input-container {
      display: flex;
      flex-direction: column;
      width: 48%;

      &.max-width-input {
        width: 100%;
      }

      input {
        border-radius: 3px;
        height: 30px;
        border: 1px solid grey;
        outline: none;
        padding: 0 10px;
      }
    }
  }

  .modal-content {
    .modal-header {
      button {
        background-color: transparent !important; // trong suot
        color: white;
        border: none;
        font-size: 27px;
      }
    }
  }
}

.img-unlock-reason {
  width: 30px;
  height: 30px;
}
