.div-tab-promotion {
  display: flex;
  flex-direction: row;
  overflow: auto;
  overflow-y: hidden;
  white-space: nowrap;

  .div-tab-item-select {
    padding: 5px;
    border-bottom: 1px solid #2962ff;
    margin-right: 5px;

    .text-tab {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #202223;
      text-decoration: none;
      margin: 0;
    }
  }

  .div-tab-item {
    padding: 5px;
    margin-right: 5px;
    .text-tab {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #c8c8c8;
      text-decoration: none;
      margin: 0;
    }
  }
}

.div-tab-promotion::-webkit-scrollbar {
  height: 2px;
}

.switch-select-show-app {
  width: 20px;
  background-color: #00cf3a !important;
}
.switch {
  width: 20px;
}

.div-header-promotion {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .select-type-promotion {
    width: 15%;
  }

  .select-type-service {
    width: 15%;
  }
  .select-type-prmotion {
    width: 15%;
  }
  .input-search-promotion {
    width: 30%;
    border-radius: 5px;
    border: 1px solid rgb(182, 182, 182) !important;
  }

  .btn-add-promotion-v2 {
    width: auto;
    border: none;
    border-radius: 5px;
    background-color: #48cae4;
    color: white;
    box-shadow: 2px 3px 2px rgb(216, 216, 216);
  }

  .btn-edit-position {
    width: auto;
  }
}

.div-area-promotion {
  display: list-item;
  width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  .text-area {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #202223;
    margin: 0;
  }
}

.text-promotion {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #202223;
  margin: 0;
}

.image-promotion-order {
  width: 10px;
  height: 10px;
  border: 0.1px solid #000000;
  border-radius: 4px;
}

.div-use-promotion {
  display: flex;
  flex-direction: column;
  align-items: end;
  .text-title-use {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #202223;
    margin: 0;
  }

  .progress-bar {
    margin: 0;
    width: 80%;
    background: none;
  }
}

.div-img-promotion {
  display: flex;
  flex-direction: row;
  align-items: center;

  .div-name-promotion {
    display: flex;
    flex-direction: column;

    .div-name-brand {
      display: flex;
      flex-direction: row;
      align-items: center;

      .text-name-brand {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #202223;
        margin: 0;
      }
      .text-brand {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #202223;
        text-transform: uppercase;
        margin: 0 0 0 4px;
      }
    }

    .text-title-promotion {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #b4b4b4;
      margin: 0;
      .text-service {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #b4b4b4;
        text-decoration-line: underline;
        margin: 0 0 0 3px;
      }
    }

    .text-title-code {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #202223;
      margin: 0;
    }
  }

  .img-customer-promotion {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    margin-right: 5px;
  }
}

.div-date-promotion {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .text-line {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    margin: 0 5px;
  }

  .text-title-promotion {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    margin: 0;
  }
}

.text-title-promotion {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  margin: 0;
}

.div-doing-status-promotion {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #f2fdf5;
  border: 1px solid #16a249;
  height: 20px;
  padding: 5px;
  width: 100px;

  .text-doing-status {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #16a249;
    margin: 0;
  }
}

.div-text-upcoming {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #f3f4f6;
  border: 1px solid #4b5563;
  height: 20px;
  padding: 2px;
  width: auto;

  .text-upcoming {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4b5563;
    margin: 0;
  }
}

.div-cancel-promotion {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #ffaeae41;
  border: 1px solid #dc2828;
  height: 20px;
  padding: 2px;
  width: 60px;

  .text-cancel-promotion {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #dc2828;
    margin: 0;
  }
}

.div-out-stock {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #ffaeae41;
  border: 1px solid #dc2828;
  height: 20px;
  padding: 2px;
  width: 100px;

  .text-cancel-promotion {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #dc2828;
    margin: 0;
  }
}

.text-time-promotion {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #000000;
}

.div-title-column-position {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .text-column {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
  }

  .div-direction {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .icon-direction {
      width: 10px;
      height: 10px;
      padding: 0px;
    }
  }
}

@media screen and (max-width: 900px) {
  .div-header-promotion {
    display: flex;
    flex-direction: column !important;
    align-items: start !important;
    width: 100%;

    .select-type-service {
      width: 50% !important;
    }
    .select-type-promotion {
      width: 50%;
      margin-top: 10px;
    }
    .input-search-promotion {
      width: 50% !important;
      border-radius: 5px;
      border: 1px solid rgb(182, 182, 182) !important;
      margin-top: 10px;
    }
    .btn-add-promotion-v2 {
      width: 50%;
      border: none;
      border-radius: 5px;
      background-color: #48cae4;
      color: white;
      box-shadow: 2px 3px 2px rgb(216, 216, 216);
      margin: 10px 0;
    }

    .btn-edit-position {
      width: 50%;
      margin: 10px 0;
    }
  }
}

@media screen and (max-width: 490px) {
  .div-header-promotion {
    display: flex;
    flex-direction: column !important;
    align-items: start !important;
    width: 100%;

    .select-type-service {
      width: 100% !important;
    }
    .select-type-promotion {
      width: 100% !important;
      margin-top: 10px !important;
    }
    .input-search-promotion {
      width: 100%;
      border-radius: 5px;
      border: 1px solid rgb(182, 182, 182) !important;
      margin-top: 10px;
    }
    .btn-add-promotion-v2 {
      width: 100%;
      border: none;
      border-radius: 5px;
      background-color: #48cae4;
      color: white;
      box-shadow: 2px 3px 2px rgb(216, 216, 216);
      margin: 10px 0;
    }

    .btn-edit-position {
      width: 100%;
      margin: 10px 0;
    }
  }
}
