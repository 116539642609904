@import "../../@theme/style.scss";

.punish-drawer__footer {
    display: flex;
    flex-direction: column;
    gap: $gapS;
}

.punish-drawer__body {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    gap: $gapL;

    .punish-drawer__body--content {

    }

    .punish-drawer__body--image {
        width: 100%;
        height: 200px;
        background-color: $colorExtraLightOrange;
        border-radius: $borderRadiusL;

        .punish-drawer__body--image-container {
            width: 100%;
            height: 100%;
        }
    }

}