.div-tab-order {
    width: 100%;
    .div-tab {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        white-space: nowrap;
        .item-tab {
            padding: 5px;
            width: auto;
            margin-right: 24px;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            .text-title {
                color: black;
                font-weight: 400;
                font-size: 14px;
                font-family: "Roboto";
                text-decoration: none;
                margin: 0;
            }

            .number-total {
                margin-left: 7px;
                border-radius: 50%;
                font-size: 12px;
                padding: 2px 5px;
                background-color: #dcdcdc;
            }
        }

        .item-tab-select {
            padding: 5px;
            width: auto;
            border-bottom: 1px solid blue;
            margin-right: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            .text-title {
                color: blue;
                font-weight: 400;
                font-size: 14px;
                font-family: "Roboto";
                text-decoration: none;
                margin: 0;
            }

            .number-total {
                margin-left: 7px;
                border-radius: 50%;
                font-size: 12px;
                padding: 2px 5px;
                background-color: #dcdcdc;
            }
        }
    }
}



// .div-tab {
//   display: flex;
//   flex-direction: row;
//   overflow-x: auto;
//   width: 100%;

//   .item-tab {
//     padding: 5px;
//     width: auto;
//     margin-right: 5px;
//     cursor: pointer;

//     .text-title {
//       color: rgb(200, 199, 199);
//       font-weight: 400;
//       font-size: 14px;
//       font-family: "Roboto";
//       text-decoration: none;
//       margin: 0;
//     }
//   }
//   .item-tab-select {
//     padding: 5px;
//     width: auto;
//     border-bottom: 1px solid blue;
//     margin-right: 5px;

//     .text-title {
//       color: black;
//       font-weight: 400;
//       font-size: 14px;
//       font-family: "Roboto";
//       text-decoration: none;
//       margin: 0;
//     }
//   }
// }