.div-container-profile-ctv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  .div-tab-profile-collaborator {
    padding: 10px 10px 20px 10px;
    background-color: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    border-radius: 10px;
    margin-top: 10px;
    width: 79%;
  }

  .div-card-profile {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
    border-radius: 10px;
    margin-top: 10px;
    width: 19%;
    height: 300px;

    .headerCard {
      border: none;
      align-items: center;
      justify-content: center;
      display: flex;
      background-color: transparent;
      flex-direction: column;

      label {
        cursor: pointer;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 200;
        font-size: 14px;
        line-height: 16px;
        color: #11263c;
        margin-top: 20px;
      }

      #choose-image {
        opacity: 0;
        position: absolute;
        z-index: -1;
      }
      .rounded-circle {
        width: 200px;
        height: 200px;
        border-radius: 50px;
        border: 1px solid rgb(15, 73, 182);
        cursor: pointer;
      }
    }
  }

  .text-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text-name {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #11263c !important;
      text-decoration: none;
      margin: 20px 0 0 0;
    }
    .text-phone {
      display: flex;
      flex-direction: row;
      gap: 4px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #11263c !important;
      text-decoration: none;
      margin: 5px 0 0 0;
    }
    
    .text-sub {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #11263c !important;
      text-decoration: none;
      margin: 5px 0 0 0;
    }
    
    .text-sub-customer {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #11263c !important;
      text-decoration: none;
      margin: 5px 0 0 0;
    }

    .text-showmore{
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #11263c !important;
      text-decoration: none;
      margin: 5px 0 0 0;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .btn-default {
    background-color: white;
    border: 1px solid rgb(216, 216, 216);
    color: black;
    margin-right: 10px;
  }

  .btn-select {
    background-color: bisque;
    border: 1px solid rgb(216, 216, 216);
    color: black;
    margin-right: 10px;
  }

  .gender {
    display: flex;
    flex-direction: column;

    .select-gender {
      border-color: rgb(220, 220, 220);
      height: 38px;
      border-radius: 5px;
    }
  }
}

@media (max-width: 900px) {
  .div-container-profile-ctv {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    justify-content: space-between;

    .div-tab-profile-collaborator {
      padding: 10px 10px 20px 10px;
      background-color: white;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
      border-radius: 10px;
      margin-top: 10px;
      width: 100%;
    }

    .div-card-profile {
      display: flex;
      flex-direction: row;
      background-color: white;
      padding: 10px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
      border-radius: 10px;
      margin-top: 10px;
      width: 100%;
      height: fit-content;

      .headerCard {
        border: none;
        align-items: center;
        justify-content: center;
        display: flex;
        background-color: transparent;
        flex-direction: column;

        label {
          cursor: pointer;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 200;
          font-size: 14px;
          line-height: 16px;
          color: #11263c;
          margin-top: 20px;
        }

        #choose-image {
          opacity: 0;
          position: absolute;
          z-index: -1;
        }
        .rounded-circle {
          width: 200px;
          height: 200px;
          border-radius: 50px;
          border: 1px solid rgb(15, 73, 182);
          cursor: pointer;
        }
      }
    }

    .text-body {
      display: flex;
      flex-direction: column;
      align-items: normal;

      .text-name {
        
        font-family: "Roboto";
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        color: #11263c !important;
        text-decoration: none;
        margin: 1px 10px;
      }
      
      .text-phone {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        color: #11263c !important;
        text-decoration: none;
        margin: 1px 10px;
      }

      .text-sub {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        color: #11263c !important;
        text-decoration: none;
        margin: 1px 10px;
      }

      .text-sub-customer {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        color: #11263c !important;
        text-decoration: none;
        margin: 1px 10px;
      }

      .text-showmore { 
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        color: #11263c !important;
        text-decoration: none;
        margin: 1px 10px;
        text-decoration: underline;
      }
    }

    .btn-default {
      background-color: white;
      border: 1px solid rgb(216, 216, 216);
      color: black;
      margin-right: 10px;
    }

    .btn-select {
      background-color: bisque;
      border: 1px solid rgb(216, 216, 216);
      color: black;
      margin-right: 10px;
    }

    .gender {
      display: flex;
      flex-direction: column;

      .select-gender {
        border-color: rgb(220, 220, 220);
        height: 38px;
        border-radius: 5px;
      }
    }
  }
}
