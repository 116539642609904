.div-header-banner {
  display: flex;
  flex-direction: row;

  .field-search {
    width: 80%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid black;
    margin-left: 15px;
    align-items: center;
  }
}

.icon-menu {
  color: #000;
}

.text-link {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #19202c;
  margin: 0;
}

.img-unlock-banner {
  width: 30px;
  height: 30px;
}

.text-title-banner {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #19202c;
  margin: 0;
}
