$font-01: "Roboto";
.container-header-board {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;

  .card {
    width: 23%;
    height: 63px;
    border-radius: 15px;
    border: none;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    filter: drop-shadow(0px 4px 4px rgba(8, 7, 7, 0.189));
    margin-top: 5px;
    padding-left: 20px;
    padding-right: 20px;

    .img {
      width: 35px;
      height: 32px;
    }

    .div-details {
      display: flex;
      flex-direction: column;
      .text-detail {
        font-size: 14px;
        font-weight: 600;
        color: black;
        text-align: center;
        font-family: $font-01;
      }
      .text-title {
        font-size: 14px;
        font-weight: 600;
        color: black;
        font-family: $font-01;
      }
    }
  }
}

@media (max-width: 900px) {
  .container-header-board {
    border-radius: 5px;
    width: 99%;
    margin-top: 28px;
    display: flex;
    flex-direction: column;

    .card {
      width: 100%;
      height: 63px;
      border-radius: 15px;
      border: none;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      filter: drop-shadow(0px 4px 4px rgba(8, 7, 7, 0.189));
      margin-top: 5px;
      padding-left: 20px;
      padding-right: 20px;

      .img {
        width: 35px;
        height: 32px;
      }

      .div-details {
        display: flex;
        flex-direction: column;
        .text-detail {
          font-size: 14px;
          font-weight: 600;
          color: black;
          text-align: center;
          font-family: $font-01;
        }
        .text-title {
          font-size: 14px;
          font-weight: 600;
          color: black;
          font-family: $font-01;
        }
      }
    }
  }
}
