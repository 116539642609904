.div-input-custom {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 10px;

  .title-input-custom {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    margin: 0;
  }

  .text-error {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #ff1616;
    text-decoration: none;
  }
}
