.btn-modal-edit-topup {
  width: 10%;
  height: 48px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-left: 20px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
}

.btn-modal-edit-topup-drawer {
  width: 80px;
  height: 30px;
  border: none;
  border-radius: 5px;
  background-color: #48cae4;
  color: white;
  margin-top: 20px;
  box-shadow: 2px 3px 2px rgb(216, 216, 216);
}

.img-thumbnail {
  width: 100px !important;
  height: 100px !important;
}

.input-money {
  margin-top: 10px;
}

.select-code-phone {
  width: 300px !important;
  height: 40px !important;
}

.list-item {
  background-color: rgb(255, 255, 255);
  padding: 5px;
  position: absolute;
  width: 400px;
  border: 1px solid rgb(175, 175, 175);
  border-radius: 5px;
}

.btn-edit-topup {
  border: none;
  background-color: transparent;
  color: #ecd503;
}
